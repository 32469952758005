/* eslint-disable    func-names */
/* eslint-disable    no-restricted-syntax */

function isFunction(f) {
   const type = Object.prototype.toString.call(f).toLowerCase();
   return (type.search(/\bobject\b/g) !== -1 && type.search(/\bfunction\b/g) !== -1);
}

export function isGeneratorFunction(f) {
   const type = Object.prototype.toString.call(f).toLowerCase();
   return (type.search(/\bobject\b/g) !== -1 && (
      (type.search(/\bgeneratorfunction\b/g) !== -1) || (type.search(/\basyncgeneratorfunction\b/g) !== -1)
   ));
}

export function useSharable(iterable) {
   const iterator = iterable[Symbol.asyncIterator]();
   return {
      next(value) {
         return iterator.next(value);
      },
      [Symbol.asyncIterator]() {
         return this;
      }
   };
}

export function take(/* limit */) {

}

export function filter(fn){
   return async function* (iter) {
      for await (const item of useSharable(iter)) {
         if(fn(item)) yield item;
      }
   };
}

export function map(fn){
   return async function* (iter) {
      for await (const item of useSharable(iter)) {
         yield fn(item);
      }
   };
}

export function pipe(producer, ...args) {
   let iter = producer();
   for (const fn of args) {
      if(isGeneratorFunction(fn)) {
         iter = fn(iter);
      }
   }
   return iter;
}

export function useTransducer(title, ...generators) {
   let releaseFn = null;
   const queue = [];

   function feeder(value) {
      if(queue.length === 0 && isFunction(releaseFn)) {
         releaseFn();
      }
      queue.push(value);
   }

   async function *produce() {
      for(;;) {
         while(queue.length) {
            yield queue.shift();
         }
         // eslint-disable-next-line
         await new Promise(resolver => releaseFn = resolver);
      }
   }

   async function consume(iter, callback=console.log) {
      // eslint-disable-next-line
      for await (const item of useSharable(iter)) {
         callback("<<turn over>>");
      }
      // pipe 함수내에서는 'return' 문을 써서는 안되며, loop 를 벗어나려면 반드시 useSharable(iter) 인스턴스를 사용하는 루프안에서만 사용한다
      const errorMessage = `ALERT: abnormal ending of consuming of Transducer<${title}> ended!\n\nPlease reload page`;
      // eslint-disable-next-line
      if(window.alert) window.alert( errorMessage );
      console.error(errorMessage);
   }

   consume(pipe(produce, ...generators));

   return {feeder};

} /* useTransducer() */

export default {
   useTransducer,
   pipe,
   map,
   take,
   useSharable,
   isFunction,
   filter,
};
