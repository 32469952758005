import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/paper-tabs";
import "@polymer/iron-pages";
import "./worklist/grid-oldfilm";
import CommonUtils from "../public/resource/js/utils/common";
import mixinCommons from "./common-mixin";
import store from "./redux/store";
import {RelatedWorklistActionType} from "./redux/reducers/related-worklist.ts";
import {RelatedTechlistActionType} from "./redux/reducers/related-techlist";

class WorklistOldfilm extends mixinCommons(PolymerElement) {
   static get template() {
      return html`
         <style>
            :host {
               display: block;
               width: 100%;
               height: 100%;
            }

            .container {
               display: flex;
               flex-direction: row;
               width: 100%;
               background-color: #252934;
            }

            .tab-area {
               width: calc(100% - 270px);
            }

            .other-area {
               width: 270px;
               display: flex;
               align-items: center;
            }

            paper-tabs {
               --paper-tabs-selection-bar: {
                  border-bottom: 3px solid #0087cb;
               };

               --paper-tabs-content: {
                  font-family: NotoSansCJKMedium, NotoSansCJK, NotoSansCJKLight !important;
                  color: #aaaaaa;
                  background-color: #252934;
                  font-size: 12px;
               };
               height: 40px;
            }

            paper-tabs paper-tab.iron-selected {
               color: #0087cb;
            }

            .wrap-oldfilm-grid {
               width: 100%;
               height: 200px;
            }

            .wrap-grid-option {
               width: 100%;
               height: 35px;
            }

            iron-pages {
               height: calc(100% - 40px);
               width: 100%;
            }

            iron-pages div {
               height: 100%;
               width: 100%;
            }

            .oldfilmStatus {
               font-size: 12px;
               color: #aaaaaa;
               padding-right: 10px;

               -webkit-user-select: text;   /* Safari */
               -khtml-user-select: text;    /* Konqueror HTML */
               -moz-user-select: text;      /* Firefox */
               -ms-user-select: text;       /* Internet Explorer/Edge */
               user-select: text;           /* Non-prefixed version, currently supported by Chrome and Opera */
            }

            paper-toggle-button.related-sync-open {
               cursor: pointer;
               --paper-toggle-button-unchecked-bar-color: #a8a8a8;
               --paper-toggle-button-unchecked-button-color: #a8a8a8;
            }
            .filmbox-sync-area {
               width: 40px;
            }

            .patient-info-area {
               width: calc(100% - 40px);
               display: flex;
               justify-content: flex-end;
            }
         }
         </style>

         <div class="container">
            <div class="tab-area">
               <paper-tabs id="oldFilmTabs" selected="{{selected}}" noink>
                  <paper-tab>{{t("label.all")}}</paper-tab>
                  <paper-tab>{{t("label.modal")}}</paper-tab>
                  <paper-tab>{{t("label.body")}}</paper-tab>
                  <paper-tab>{{t("label.modalBody")}}</paper-tab>
                  <paper-tab>{{t("label.studyDesc")}}</paper-tab>
               </paper-tabs>
            </div>
            <div class="other-area">
               <div class="filmbox-sync-area">
                  <paper-toggle-button id="relatedSyncOpen" class="related-sync-open"
                     checked="{{relatedSyncOpenStatus.checked}}"
                     on-change="toggleRelatedSyncOpen"></paper-toggle-button>
                  <paper-tooltip id="relatedSyncOpenTooltip" for="relatedSyncOpen" offset="0" animation-delay="0">{{relatedSyncOpenStatus.title}}</paper-tooltip>
               </div>
               <div class="patient-info-area">
                  <div class="oldfilmStatus">[[patientID]], [[name]], [[sex]]/[[age]], {{t("label.count")}}([[count]]/[[allCount]])</div>
               </div>
            </div>
         </div>

         <iron-pages selected="0">
            <div><grid-oldfilm id="oldFilm"></grid-oldfilm></div>
         </iron-pages>
      `;
   }

   static get properties() {
      return {
         selectedRow: {
            type: Object,
            observer: "onSelectRow"
         },
         category: {
            type: Number,
            observer: "onSelectCategory"
         },
         selected: {
            type: Number,
            value: 0,
            observer: "_selectedTabChanged"
         },
         patientID: {
            type: String
         },
         birthDate: {
            type: String,
            value: "BirthDate"
         },
         name: {
            type: String,
         },
         sex: {
            type: String
         },
         age: {
            type: String
         },
         count: {
            type: Number,
            value: 0
         },
         allCount: {
            type: Number,
            value: 0
         },
         cnt: {
            type: Object,
            value: {
               "all": 0,
               "modality": 0,
               "bodypart": 0,
               "modality_bodypart": 0,
               "studyDesc": 0
            }
         },
         rows: {
            type: Object,
            value: {
               "all": [],
               "modality": [],
               "bodypart": [],
               "modality_bodypart": [],
               "studyDesc": []
            }
         },
         relatedSyncOpenStatus: {
            type: Object
         }
      };
   }

   constructor() {
      super();

      const bol = JSON.parse(localStorage.getItem("stopRelatedSyncOpen"));
      const checked = !(bol != null && bol === true);

      let title;
      if(checked) title = this.t("label.filmBoxSyncOff");
      else title = this.t("label.filmBoxSyncOn");

      this.relatedSyncOpenStatus = { checked, title };

      this.patientID = this.t("label.patientID");
      this.name = this.t("label.name");
      this.sex = this.t("label.sex");
      this.age = this.t("label.age");
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         this.category = store.getState().common.category;
         if (this.category === 0) {
            this.selectedRow = store.getState().worklist.row;
            this.selected = store.getState().relatedWorklist.activeTabCode;
         } else if (this.category === 1) {
            this.selectedRow = store.getState().techlist.row;
            this.selected = store.getState().relatedTechlist.activeTabCode;
         }
      });

      this.$.oldFilm.addEventListener("initOldFilmEvent", (e) => {
         this.cnt.all = e.detail.all.length;
         this.cnt.modality = e.detail.modality.length;
         this.cnt.bodypart = e.detail.bodypart.length;
         this.cnt.modality_bodypart = e.detail.modality_bodypart.length;
         this.cnt.studyDesc = e.detail.studyDesc.length;

         this.rows.all = e.detail.all;
         this.rows.modality = e.detail.modality;
         this.rows.bodypart = e.detail.bodypart;
         this.rows.modality_bodypart = e.detail.modality_bodypart;
         this.rows.studyDesc = e.detail.studyDesc;

         this.setRowData();
      });

      document.addEventListener("changedReportTabEvent", (e) => {
         this.$.oldFilmTabs.selectIndex(e.detail);
      });

      this.$.oldFilm.addEventListener("getFilmboxWindow", (e) => {
         this.dispatchEvent(new CustomEvent("getFilmboxWindow", e));
      });
   }

   onSelectRow(row) {
      const selectedRow = row?.detail||{}; // 선택된 row가 없는 경우 환자 정보 초기화 하기 위해 {} 셋팅
      this.patientID = selectedRow.patientID;
      this.birthDate = selectedRow.patientBirthDate;
      this.name = selectedRow.patientName;
      this.sex = selectedRow.patientSex;
      this.age = selectedRow.patientAge;
   }

   onSelectCategory(category) {
      if (category === 1 && this.id === "techOldFilm") {
         this.clearOldfilm();
      }
      this.clearRelatedExamPatientInfo();
   }

   // approveOpinionisResult(result) {
   //    this.$.oldFilm.approveOpinionisResult(result);
   // }

   // related tab changed
   _selectedTabChanged(newValue, oldValue) {
      if(CommonUtils.isNumeric(oldValue)) {
         if (this.category === 0)
            store.dispatch({ type: RelatedWorklistActionType.SET_ACTIVE_TAB, payload: newValue });
         else if (this.category === 1)
            store.dispatch({ type: RelatedTechlistActionType.SET_ACTIVE_TAB, payload: newValue });
         if(this.relatedSyncOpenStatus.checked) {
            // tab 이동시 viewing 컬럼 초기화
            this.clearViewing();
         }
         this.setRowData();
      }
   }


   toggleRelatedSyncOpen() {
      if(this.relatedSyncOpenStatus.checked) {
         this.relatedSyncOpenStatus.title = "Turn off sync with filmbox";
         localStorage.removeItem("stopRelatedSyncOpen");
      } else {
         this.relatedSyncOpenStatus.title = "Turn on sync with filmbox";
         localStorage.setItem("stopRelatedSyncOpen", true);
      }

      this.$.relatedSyncOpenTooltip.innerHTML = this.relatedSyncOpenStatus.title;
   }

   setRowData() {
      const selectedTab = this.$.oldFilmTabs.selected;
      switch (selectedTab) {
      case 0:
         this.$.oldFilm.setRowData(this.rows.all);
         this.count = this.cnt.all;
         this.allCount = this.cnt.all;
         break;
      case 1:
         this.$.oldFilm.setRowData(this.rows.modality);
         this.count = this.cnt.modality;
         this.allCount = this.cnt.all;
         break;
      case 2:
         this.$.oldFilm.setRowData(this.rows.bodypart);
         this.count = this.cnt.bodypart;
         this.allCount = this.cnt.all;
         break;
      case 3:
         this.$.oldFilm.setRowData(this.rows.modality_bodypart);
         this.count = this.cnt.modality_bodypart;
         this.allCount = this.cnt.all;
         break;
      case 4:
         this.$.oldFilm.setRowData(this.rows.studyDesc);
         this.count = this.cnt.studyDesc;
         this.allCount = this.cnt.all;
         break;
      default:
         this.count = 0;
         this.allCount = 0;
      }
   }

   clearRelatedExamPatientInfo() {
      this.patientID = "PatientID";
      this.birthDate = "BirthDate";
      this.name = "Name";
      this.sex = "Sex";
      this.age = "Age";
      this.count = 0;

      // 필더된 값도 초기화 필요.
      this.rows = [];
      this.cnt = {all: 0, bodypart: 0, modality: 0, modality_bodypart: 0};
   }

   hideOverlay() {
      this.$.oldFilm.hideOverlay();
   }

   clearOldfilm() {
      this.$.oldFilm.clearOldfilm();
   }

   // openPopup(popup) {
   //    this.$.oldFilm.openPopup(popup);
   // }

   setTabIndex(tabIndex) {
      this.selected = tabIndex || 0;
   }

   clearViewing() {
      this.$.oldFilm.clearViewing();
   }
}

window.customElements.define("worklist-oldfilm", WorklistOldfilm);
