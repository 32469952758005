/* eslint-disable no-else-return */
/* eslint-disable import/extensions */

import kbd_common from "./kbdevent-common.mjs";
import Env from "./env.mjs";

const env = Env(window);

function get_keyinfo_from_wordkey(e) {
   const normalized = kbd_common.getWordKey(e);
   const printable  = kbd_common.getPrintableWordKey(e);
   const modkeys    = kbd_common.get_computed_mod_keys(e);
   const action     = kbd_common.pickBestActrionFromKeyInfo(normalized, [...modkeys]);

   return {
      printable,
      normalized,
      keycode:             e.which,
      action,
      is_alt_composed_key: modkeys.has("alt"),
      is_hangul:           false,
      is_non_prinable:     false,
      is_word_key:         true,
      is_mod_key:          false,
      modkeys:             [...modkeys]
   };
}

// Mixed Case word --> lowercase ( "Enter" --> "enter" )
function isModKey({code}) { return !!kbd_common.SPECIAL_ALIASES[code]; }
function getModKey({code}) { return kbd_common.SPECIAL_ALIASES[code]; }
function getPrintableModKey(e) {
   const printable = getModKey(e);
   return kbd_common.wordkey_printable_map[printable] || printable;
}

function get_keyinfo_from_modkey(e) {
   const normalized = getModKey(e);
   const printable  = getPrintableModKey(e);
   const modkeys    = kbd_common.get_computed_mod_keys(e);
   const action     = kbd_common.pickBestActrionFromKeyInfo(normalized, [...modkeys]);

   return {
      printable,
      normalized,
      keycode:             e.which,
      action,
      is_alt_composed_key: modkeys.has("alt"),
      is_hangul:           false,
      is_non_prinable:     true,
      is_word_key:         false,
      is_mod_key:          true,
      modkeys:             [...modkeys]
   };
}

function get_keyinfo_from_normal_ascii(e) {
   const modkeys        = kbd_common.get_computed_mod_keys(e); // 설정된다해도 "shift" 만 있어야 한다
   let printable        = (e.which <2) ? String.fromCharCode(e.key) : e.key;
   if(kbd_common.normal_ascii_shift_map[printable] && !e.shiftKey) {     // shift 플래그가 없으면 lowercase로 변경
      printable = kbd_common.normal_ascii_shift_map[printable];
   }
   const lowercase_key  = kbd_common.normal_ascii_shift_map[printable];
   const normalized     = lowercase_key || e.key;

   // INFO: 이 함수 내에서는 ASCII 에 대해서만 고려하면 된다
   // WARN: capslock이 눌려져 있다해도 "shift"를 누른 것과 동일한 키로 처리해서는 안된다
   const is_capslock_on = (/* UpperCase -> lowercase */lowercase_key) ? !e.shiftKey : false;
   const action         = kbd_common.pickBestActrionFromKeyInfo(normalized, [...modkeys]);

   return {
      printable,
      normalized,
      action,
      is_hangul:           false, // 이 함수는 오직 32~126 ASCII Code 값의 문자에 대해서만 처리하기 때문이다
      is_capslock_on,
      is_non_prinable:     false,
      is_alt_composed_key: modkeys.has("alt"),
      is_mod_key:          false,
      modkeys:             [...modkeys]
   };
}

// @keyup & @keypress  (altKey 설정만 되어있을 경우 처리)
function get_keyinfo_from_alt_composited(e) {
   const index_in_normal_map  = kbd_common.alted_keys.indexOf(e.key);
   const index_in_shift_map   = kbd_common.shift_altedkeys.indexOf(e.key);
   const modkeys              = kbd_common.get_computed_mod_keys(e);  // 설정된다해도 "alt 또는 shift" 만 있어야 한다
   let printable              = e.key;
   let normalized             = String.fromCharCode(e.which); // ASCII 코드값이지만 대문자!!!

   if(kbd_common.isDeadKey(e)) {
      normalized = kbd_common.deadkey_to_ascii_map[e.which];
      printable  = kbd_common.alted_keys[kbd_common.alted_keys_ascii.indexOf(normalized)];

   } else if (kbd_common.ascii_key_pattern.test(e.key)) {
      const lowercaseKey = kbd_common.normal_ascii_shift_map[e.key];
      if (lowercaseKey) {
         normalized = lowercaseKey;
         printable = lowercaseKey;
      } else {
         normalized = e.key;
         printable = e.key;
      }

   } else if(index_in_normal_map !== -1) {
      normalized = kbd_common.alted_keys_ascii[index_in_normal_map];

   } else if(index_in_shift_map !== -1) {
      normalized = kbd_common.shift_altedkeys_ascii[index_in_shift_map];

   } else {
      console.log(`could not handle this case in <get_keyinfo_from_alt_composited<keyup>()`);
      console.trace(e);

      document.dispatchEvent(new CustomEvent("toastEvent", {
         bubbles: true, composed: true, detail: { msg: "Please check you are in Hangul Mode or CapsLock pressed", isErr: true }
      }));
      return {has_exception: true };
   }

   const is_capslock_on = (kbd_common.shift_altedkey_to_ascii_map[e.key]) ? !e.shiftKey : false;
   const action         = kbd_common.pickBestActrionFromKeyInfo(normalized, [...modkeys]);

   return {
      printable,
      normalized,
      action,
      is_hangul: false,    // altKey 설정이 되어 있으면 한글은 들어올 수 없다
      is_capslock_on,
      is_non_prinable: false,
      is_alt_composed_key: false,
      is_mod_key: false,
      modkeys: [...modkeys]
   };
}


// START:-------------- only for Windows
const wordkey_map = {
   "JunjaMode": "="
};

const shift_alted_keys_ascii = {
   "A":"a", "B":"b", "C":"c", "D":"d", "E":"e", "F":"f", "G":"g", "H":"h", "I":"i", "J":"j", "K":"k", "L":"l", "M":"m",
   "N":"n", "O":"o", "P":"p", "Q":"q", "R":"r", "S":"s", "T":"t", "U":"u", "V":"v", "W":"w", "X":"x", "Y":"y", "Z":"z",
   ")":"0", "!":"1", "@":"2", "#":"3", "$":"4", "%":"5", "^":"6", "&":"7", "*":"8", "(":"9",
   "_":"-", "+":"=", "{":"[", "}":"]", ":":";", [String.fromCharCode(34)]:"'", "<":",", ">":".", "?":"/", "|":"\\", "~":"`"
};

const ascii_to_alted_key_map = Object.entries(kbd_common.altedkey_to_ascii_map).reduce((acc, [value, name])=>{acc[name] = value; return acc;}, {});
const shift_ascii_to_alted_key_map = Object.entries(kbd_common.shift_altedkey_to_ascii_map).reduce((acc, [value, name])=>{acc[name] = value; return acc;}, {});
// END: -------------- only for Windows

function get_keyinfo_from_alt_composited_on_windows(e) {
   const modkeys  = kbd_common.get_computed_mod_keys(e);  // 설정된다해도 "alt 또는 shift" 만 있어야 한다
   let printable  = e.key;
   let normalized = e.key;

   if( !Object.is(wordkey_map[normalized], undefined) ) {
      printable = kbd_common.SPECIAL_ALIASES[normalized];
      normalized = wordkey_map[normalized];

   } else if( !Object.is(ascii_to_alted_key_map[printable], undefined)) {
      printable = ascii_to_alted_key_map[printable];

   } else if(!Object.is(shift_alted_keys_ascii[normalized], undefined)) {
      normalized = shift_alted_keys_ascii[normalized];
      printable = shift_ascii_to_alted_key_map[normalized];

   } else {
      console.trace(e);
      throw new Error(`could not handle this case in <get_keyinfo_from_alt_composited_keyup>()`);
   }

   const is_capslock_on = (kbd_common.shift_altedkey_to_ascii_map[e.key]) ? !e.shiftKey : false;
   const action         = kbd_common.pickBestActrionFromKeyInfo(normalized, [...modkeys]);

   return {
      printable,
      normalized,
      action,
      is_hangul: false,    // altKey 설정이 되어 있으면 한글은 들어올 수 없다
      is_capslock_on,
      is_non_prinable: false,
      is_alt_composed_key: false,
      is_mod_key: false,
      modkeys: [...modkeys]
   };
}

export function get_keyinfo_from_keyup_event(e) {

   if(kbd_common.isWordKey(e)) {
      // "space", "enter", "tab", "backspace", "doublequote", "backslash"
      return get_keyinfo_from_wordkey(e);

   } else if(isModKey(e)) {
      // "esc", "shift", "ctrl", "alt", "meta", "caplock", "backspace", "pageup", ...
      return get_keyinfo_from_modkey(e);

   } else if(kbd_common.isHangul(e.key)) {
      // WARN: 'altKey'가 설정되어 있을 경우, e.key 값으로는 한글여부를 알 수 없다!
      // This must be preceed to "hasNonASCIIDomainKey()" check, for its internal check routine.
      // If this routine is behinded to it, get_keyinfo_from_normal_ascii_keypress() routine have to deal with Hangul.
      // It will be ok even if ctrlKey|metaKey composited event
      // meta composited key could not be here
      return kbd_common.get_keyinfo_from_hangul(e);

   } else if(!kbd_common.hasNonASCIIDomainKey(e)) {
      // For the case of having no altKey|ctrlKey|metaKey.
      // Only for [a-zA-Z0-9] & special characters (`~!@#$%^&*()-_=+[{]}\|;:'"/?.>,<)
      return get_keyinfo_from_normal_ascii(e);

   } else if(kbd_common.is_alt_composed_key(e)) {
      // alt + (ctrl|meta|shift) + (char|number)
      return env.is_windows() ? get_keyinfo_from_alt_composited_on_windows(e) : get_keyinfo_from_alt_composited(e);

   } else { /* ctrlKey || metaKey */
      // then, this block should be the rest of all.
      return kbd_common.get_keyinfo_from_ctrl_meta(e);
   }
}

export default {
   get_keyinfo_from_keyup_event,
};
