/* eslint-disable    prefer-const */
/* eslint-disable    prefer-destructuring */
/* eslint-disable    import/extensions */
import $ from "cash-dom";
import FLeader, {
   __hb__, __dbl_click__, __ok__, __duplicated__, __tab__, /* __leader__, */
   __any__, __single_click__, __off_editing_focus__, __on_editing_focus__,
   __lower_word__, __word__, __number__, __special_char__, __empty_keystack__,
   __timeout__, __fulfilled__, __rejected__, __dialog_opened__, __dialog_closed__,
   __canceled__, __failed__, __no_more__, __begin__, __rollback__, __macro__, __next__, __done__,
   __macro_timeout__, __macro_abort__, __macro_waiting__
} from "./fleader.mjs";

import {Either, pipe, Noop, tryCatch} from "./FP.mjs";
import {normalizeSpecialKeyName} from "./keyboardevent.mjs";
import { defaultShortcutSetting } from "../resource/js/shortcut";
import RuleFactory, {SyncedRules} from "./synced_rules.mjs";
import defaultUserPreferences from "./default-userPreferences.json";

const console_log = (...args) => window.__debug__ && console.log(...args);

// ----[ 팝업 요소 내부구조 ]-------------------------------------------------
// <div class="container">
//    <div id="snackbar">
//       <div class="class-basic" id="basicMode">
//          <div class="class-functionlist" id="functionList"></div>
//          <input id="inputShortcut" class="input-shortcut" readonly>
//       </div>
//
//     <div class="class-advance" id="advancedMode">
//        <input id="searchKeywords" class="input-shortcut">
//        <div id="searchResult" style="overflow:auto;"></div>
//     </div>
//   </div>
// </div>
// ----------------------------------------------------------------------

export default function FLeaderApp(window, self, shortcutObj) {

   let app; // 전방선언
   let elAdvancedMode;  let elBasicMode;     let elSearchKeywords;
   let $functionList;   let $elSearchResult; let elSnackbar;
   let $inputKey;       let $displayKey; let ToastBox;
   let elDisplayInputSCP;

   const rAF = requestAnimationFrame;
   const DOMElement = qry => {
      return self[qry] ? self[qry] : $(`#${qry}`)[0];
   }

   // TODO: hide '.menuJSON' property
   const getTopCategory      = category => app.menuJSON[category];
   const getFilmBoxMenus     = () => getTopCategory("filmboxFunction");
   const getWorklistMenus    = () => getTopCategory("worklistFunction");
   const getMacroMenus       = () => getTopCategory("macroFunction");
   const getUserPreferencesMenus    = () => getTopCategory("userPreferences");

   const renderHideSearchBar = () => rAF(hideSearchBar);
   const renderAdvancedMode  = () => rAF(changeAdvancedMode);
   const cleanupHandler      = pipe(renderHideSearchBar, ()=>app.clearKeyStack(), ()=>app.clearMenuPathStack(), ()=>app.clearSearchKeywords(elSearchKeywords), hideKeyStack, ()=>app.clearTimeout(), ()=>app.clearDialogObjects());
   const readyToGo           = () => console.log(`FLeader is ready! v${app.version}`);
   // eslint-disable-next-line    no-unused-vars
   const updateAdvancedSearchPanel = () => rAF(() => {
      app.applyAdvancedSearch(null, $elSearchResult);
      renderAdvancedMode();
   });
   const updateAdvancedSearchResult = e => app.applyAdvancedSearch(e, $elSearchResult);
   const disableStackUpTrigger = ()=>app.enableStackUpTrigger(false);
   const enableStackUpTrigger = ()=>app.enableStackUpTrigger(true);


   // 템플릿(Template; 상용구) 처리 요청
   function handleTemplate(shortcut, prepend="", functionName="") {
      console_log(`handelTemplate(${shortcut}, ${prepend}, ${functionName})`);
      document.dispatchEvent(new CustomEvent("callReadingTemplateEvent",{ detail: {shortcut, prepend, functionName}}));
   }

   // 매크로 기능 호출
   function handleMacro(shortcut) {
      const matched = app.menuJSON.macroFunction.filter(macro=> macro.shortcut === shortcut);
      const commands = [];
      matched.forEach((macro) => {
         macro.functionList.forEach((command) => {
            const cmd = command.functionName;
            if(command.type ==="function"){
               if(cmd === "filmbox" || cmd === "worklist") {
                  commands.push(() => { console_log(`execute CommonFunction of macro '${cmd}'`); handleCommonFunction("filmbox", cmd); } );
               } else {
                  commands.push(() => { console_log(`execute WorklistFunction of macro '${cmd}'`); handleWorklistFunction(cmd); } );
               }
            } else if(command.type ==="preform"){
               commands.push(() => { console_log(`execute WorklistFunction of macro '${cmd}'`); handleTemplate("", " ", cmd); } );
            }
         });
      });

      if( commands.length === 0 ) {
         console_log(`macro has no function for `, shortcut);
         app.emit(__macro_abort__);
         app.popupNotice(`No such Macro -- ${shortcut}`, true);
      } else {
         // tick을 분리해서 매크로 실행전에 들어오는 불필요한 KeyboardEvent를 제거한다
         setTimeout(() => {
            app.emit(__begin__);
            app.emit(__macro__, commands );
            app.emit(__next__);
         },200);
      }
   }

   function handleWorklistFunction(functionName){
      const link = window.report.get() ? app.reportPopupElements : app.linkedElements;
      let btn;

      switch(functionName) {
      case "opinionSave":
         btn = link.$report_btn_save;
         if(!btn || btn.disabled) { // hh-report.js #800
            window.rejected({message:"'Save' disabled"});
            return;
         }
         if (link.$report?.onClickReportBtn)
            link.$report.onClickReportBtn("save");
         else
            btn.click();
         break;

      case "opinionApprove":
         btn = link.$report_btn_approve;
         if(!btn || btn.disabled) {
            window.rejected({message:"'Approve' disabled"});
            return;
         }
         if (link.$report?.onClickReportBtn)
            link.$report.onClickReportBtn("approve");
         else
            btn.click();
         break;

      case "opinionClear":
         btn = link.$report_btn_clear;
         if(!btn || btn.disabled) {
            window.rejected();
            return;
         }
         if (link.$report?.handleClear)
            link.$report.handleClear();
         else
            btn.click();
         window.fulfilled();
         break;

      case "opinionAddendum":
         btn = link.$report_btn_addendum;
         if(!btn || btn.disabled) {
            window.rejected({message:"'Addendum' disabled"});
            return;
         }
         if (link.$report?.onClickReportBtn)
            link.$report.onClickReportBtn("addendum");
         else
            btn.click();
         break;

      case "openCloseReportWindow":
         btn = link.$report_btn_popup;
         if(!btn) {
            window.rejected({message:"'open report window' not found"});
            return;
         }

         if(!btn || btn.disabled) {
            window.rejected({message:"'open report window' disabled"});
            return;
         }

         btn.click();
         window.fulfilled();
         break;

      case "copyReport":
         btn = link.$report_copy_btn;

         if(!btn) {
            window.rejected({message:"'copy report' not found"});
            return;
         }

         btn.click();
         window.fulfilled();
         break;
      case "pasteReport":
         btn = link.$report_paste_btn;

         if(!btn) {
            window.rejected({message:"'paste report' not found"});
            return;
         }

         if(!btn || btn.disabled) {
            window.rejected({message:"'paste report' disabled"});
            return;
         }
         btn.click();
         window.fulfilled();
         break;
      case "copyAndPasteRelatedReport":

         if (link.$related_copy_btn && Object.keys(link.$related_copy_btn).length === 0) {
            window.postMessage({event: "OLDFILM_SEND_DATA_FOR_POPUP_REPORT"}, document.location.href);

            return;
         }

         window.postMessage({event: "handleCopyAndPasteReportWithKey"}, document.location.href);

         window.fulfilled();
         break;
      default:
         document.dispatchEvent(new CustomEvent("callFleaderFuncEvent", {detail: {functionName}}));
         break;
      }
   }

   // INFO: 워크리스트와 필름박스는 서로 다른 윈도우에서 동작하고 있는데,
   // INFO: 워크리스트에서 필름박스와의 신호전달이 postMessage()를 사용해서 구축되어 있어 이 규약을 따르는 것이다
   function handleFilmboxFunction(shortcut="", functionName=""){
      // console.log("send to filmbox message",data);
      const payload = {
         shortcut,
         functionName,
         type: "filmboxFunction",
         target: "filmbox"
      };

      console_log(`window.postMessage(${document.location.href},`, payload);

      window.postMessage(payload, document.location.href);
      window.filmbox.focus();
   }

   function handleUserPreferences( functionName ) {
      let preference;

      switch(functionName) {
      // 판독중인 케이스에 대해, 'Enter' 키를 눌렀을 때 리포트 영역의 어떤 섹션으로 포커스를 옮길 것인지 토글한다
      case "toggleTrackingFocus":

         // 1) 현재 항목에 대한 객체 포인터를 찾는다
         preference = app.findUserPreference(functionName);

         // 2) 찾지 못했다면 그냥 패스 (JSON 포맷이 맞지 않거나 DB에서 해당 스키마가 없을 때를 대비)
         if(Object.is(preference, undefined)) break;

         // 2) 값을 토글한다: "Findings" | "Conclusion" | "Recommendation"
         preference.value = Either.doneIf(Number.isInteger, preference.value )
            .throwIf(value => !Number.isInteger(parseInt(value, 10)),  0)
            .done(value => parseInt(value, 10))
            .catch()
            .take();

         preference.value = (preference.value + 1) % 3;

         // 3) this.menuJSON에 반영한다
         preference.printableValue = {0:"Finding", 1:"Conclusion", 2:"Recommendation"}[preference.value];

         // 4) FLeader 화면을 다시 그린다
         updateUserPreferencesPanel( getUserPreferencesMenus() );
         break;

      default:
         console.log("unknown case");
         break;
      }
   }

   // #17564 [HPACS] Worklist <-> Filmbox 전환 버튼 숏컷 동일한 숏컷으로 기능 변경 필요
   // worklist와 filmbox에서 공통적으로 사용되는 함수를 처리하기 위한 함수
   function handleCommonFunction(shortcut, functionName) {
      console_log(`CommonFunction ${shortcut} - ${functionName}`);
      switch (functionName) {
      case "worklist" :
      case "filmbox" : {
         const isWorklistFocus = window.document.hasFocus();
         const reportPopup = window.report.get();
         const filmboxPopup = window.filmbox.get();
         const isReportPopupFocus = reportPopup ? reportPopup.document.hasFocus() : false;

         if(isWorklistFocus) {
            // 1. worklist foucs 상태 -> filmbox open
            document.dispatchEvent(new CustomEvent("callFleaderFuncEvent", {detail: {functionName: "filmbox"}}));
         } else if(isReportPopupFocus) {
            // 2. reportPopup focus 상태-> filmbox open, filmbox가 없다면 worklist open
            if(filmboxPopup) {
               // reportPopup에서 open을 안시키면 간헐적으로 filmbox가 열리지 않는 현상으로 인해 직접 open
               reportPopup.open("", "popup");

               // filmboxPopup로 filmbox open시 filmbox window의 opener가 reportPopup으로 변경되어 강제로 변경해줌
               // 현재 방법이 없어 해당방법을 사용하는데 다른 방법이 생기면 바꾸는것이 좋아보임.
               filmboxPopup.opener = window;
            } else {
               reportPopup.open("", "worklist");
            }
         } else {
            // 3. filmbox focus 상태 -> worklist open
            window.postMessage({ target: "filmbox", functionName: "worklist" }, document.location.href);
         }

         window.fulfilled();
         break;
      }
      default:
         break;
      }
   }

   // 주의: 마우스를 사용해서 특정 영역을 복사하는 것을 막게됨
   DOMElement("snackbar").onselectstart = () => false;
   DOMElement("snackbar").onclick = () => false;

   // allow hoisting for the declarations above.
   const initial_rules = [
      // Rule#0) 사용자 단축키 정의를 파싱하고 명령 실행준비가 되면 아래 신호에 의해 FLeader가 상태관리를 시작한다
      [__hb__,                      "loaded",               "ready",                   [readyToGo, cleanupHandler]],

      /* ENTER 입력처리:START ----------------------------------------------------------------------------------- */
      // WARN: worklist 화면에서 focus를 받은 DOM Element에 따라 혹은 콤포넌트에 따라 'enter' 입력에 의해 발생하는 이벤트 총 갯수와 종류가 다르다
      // WARN: worklist와 filmbox 에서오는 'enter' 키보드이벤트를 받으면 판독문으로 포커스를 옮기는 기능은 주요기능이므로 주의할 것!
      // Rule#1)
      // 제어용 신호는 "ready" 상태에서 받을 경우 큐에 쌓지 않는다! (NOTE:`ESC` 키는 "keyup" 타입이다)
      [[__fulfilled__, __canceled__, __rejected__, __failed__, __timeout__, __no_more__, __dialog_closed__, __macro_waiting__ ], "ready", "ready", Noop],
      [["esc"],                     "ready",                "ready", cleanupHandler],

      /*  ------------- ready --> on_editing 전환과정의 룰: START --------------------------------------- */
      // 마우스에 의해 포커스가 자동으로 입력되는 방식과 달리, 키보드 'enter' 키를 눌렀을 때 판독문으로 들어가는 과정
      // 1) HPACS 사용도중 'enter'를 입력한 위치에 따라 'keypress' 혹은 'keyup' 이벤트 한곳이나 양쪽다 신호가 올 수 있다.
      //    ↳ 'enter'를 입력한 시점에서 focus를 가진 콤포넌트가 해당 키의 전파를 막았는가 여부에 달려있기 때문이다
      //    ↳ 'keypress'가 우선적으로 잡히므로 중간 상태에서 대기하다가 'enter@keyup' 이벤트가 들어오거나 대기시간이 지났을 경우
      // 2) navbar에 속한 TEXTAREA로 포커스를 옮기기위해 세가지 방법으로 focus()를 옮기는 시도를 한다.
      // 3) 만ㅇ약 TEXTAREA에서 onFocus() 핸들러에 의해 발신되는 신호(on_focus_editing)를 받지 못하면 다시 "ready" 상태로 fallback해야한다!
      //    ↳ 이미 Approve되어 있거나 편집하지 못하는 케이스의 경우, on_focus_editing 신호를 받지 못한다.
      // Rule#2)
      [["enter@keypress","enter@keyup", "enter@keydown"], "ready",                 "ready.enter.waiting.on-editing-focus", [
         /* 막지않으면 TEXTAREA로 포커스가 옮겨진뒤 엔터가 입력되서 공백라인이 생긴다 */
         (...args)=>app.stopPropagation(...args),
         /* 앞서 포커스를 줬던 TEXTAREA로 다시 돌아가는 걸 시도, 객체가 없으면 taConclusion으로 돌아간다 */
         ()=>setTimeout(()=>{
            // 숏컷이나 버튼으로 판독문을 save, approve 한 뒤 mismatch 창이 나올 경우 키보드로 close시 간헐적으로 ready 상태값이 적용됨
            // if(!app.isDialog()) app.moveCursorToPreviousFocusedElement();
            app.moveCursorToPreviousFocusedElement();
         },0), /* IMPORTANT: 제어의 흐름에서 분리시키지 않으면 TEXTAREA에 개행이 입력된다!! */
         // 포커스를 옮기는 세가지 시도. 800ms 내에 TEXTAREA가 주는 신호를 기다림
         ()=>app.waitingConsecutiveKey(800, __timeout__)
      ]],

      // 타임아웃&엔터@keyup을 기다리는 동안 timeout을 제외하고 모두 무시
      [[__any__],                   "ready.enter.waiting.on-editing-focus", "ready.enter.waiting.on-editing-focus", [
         (...args)=>app.stopPropagation(...args)
      ]],

      // 판독창에서 onFocus() 핸들러에 의해 진입했다는 신호를 받음.  대기도중 받았던 이벤트를 차례로 적용한다
      // Rule#3)
      [__on_editing_focus__,        "ready.enter.waiting.on-editing-focus",        "on_editing", [
         cleanupHandler
      ]],

      // case 조건에 따라 focus를 받지 못했다면 on_editing_focus 신호를 받지 못하므로 리셋해야 한다
      [__timeout__,                 "ready.enter.waiting.on-editing-focus",        "ready", [
         ()=>app.focusOut(),
         cleanupHandler,
         ()=>app.popupNotice("This report cannot be modified.", true)
      ]],
      /*  ------------- ready --> on_editing 전환과정의 룰: END --------------------------------------- */


      /*  ------------- on_editing --> ready 전환과정의 룰: START ------------------------------------- */
      // 판독문 입력도중 ESC 키로 취소 (실제로 빠졌다는 신호를 받을 때까지 중간 단계에서 다른 키를 배제해야한다)
      ["esc",                       "on_editing",           "ready.off-pending",
         [cleanupHandler, ()=> app.await(__off_editing_focus__), ()=> app.focusOut()]], /* INPUT 또는 TEXTAREA에서 입력 취소  */

      [__any__,                     "ready.off-pending",    "ready.off-pending",       ()=>true],

      // 제어 메세지는 기억하지 않는다
      [["esc", __single_click__],   "ready.off-pending",    "ready.off-pending",       Noop],

      [__off_editing_focus__,       "ready.off-pending",    "ready",                   ()=>app.fulfilled()],
      /*  ------------- on_editing --> ready 전환과정의 룰: END --------------------------------------- */


      /*  ------------- ready      --> dialog 전환과정의 룰: START ------------------------------------ */
      // ready/on_editing 상태에서 다이얼로그 열림/닫힘
      [__dialog_opened__,           "ready",                "dialog",                  ()=>app.stackingUp()],
      [__dialog_opened__,           "on_editing",           "dialog",                  ()=>app.stackingUp()],

      // INFO: ↓  Filmbox <Canvas> 요소에서 'enter'는 'keypress' 에서 전달된다
      // INFO: ↓  워크리스트 윈도우에서 'enter'를 입력했을 때는 'keyup'에서 전달된다
      [["enter@keypress","enter@keyup"],                    "dialog",                  "dialog",                  ()=>app.focusOnDialog()],

      // WARN: Modal Dialog가 아닐 때, TEXTAREA에 포커스를 준 경우 무조건 상태를 바꿔야 한다. Otherwise, 빠져나올 수 없는 상태가 된다
      [__on_editing_focus__,        "dialog",               "on_editing", [
         // 콜백을 실행하다 포커스가 빠질 수 있으므로 main thread에서 detattch 한다
         () => queueMicrotask(()=>{
            app.rejected();
            app.stackingDown();
            cleanupHandler();
            try{ app.forceCloseDialog(); } catch(e) { }
         })
      ]],

      // IMPORTANT: 다이얼로그가 열린 상태에서는 어떤 것도 받아들이지 않는다
      [__any__,                    "dialog",                "dialog",                  Noop],

      // WARN: 모든 Polymer Dialog는 키보드입력과 마우스 click event를 외부로 전파해서는 안된다!!
      [[__single_click__],          "dialog",               "dialog",                  [
         () => {
            if(!app.ctx.is_modal) {
               app.setCurrentState(app.stackingDown());
               if(app.current_state === "on_editing") app.moveCursorToPreviousFocusedElement();
            }
         }
      ]],

      // HPACS에서 사용중인 <paper-dialog> 요소는 ESC키를 전파하기 때문에,  dialog_closed 신호와 겹쳐오게되면 오동작을 유발한다.
      // 또한 <paper-dialog modal> 속성을 주면 modal dialog의 바로아래 backdrop 레이어가 생기는데,
      // 해당  레이어는 키보드/마우스 이벤트를 전파하지 않기 때문에 사용자가 backdrop 레이어에 클릭하고 ESC를 입력해도 다이얼로그가 닫히지 않는다.
      // 위의 두가지 문제를 해결하기 위해, ESC를 강제로 active-dialog를 닫는데 사용한다.
      // 다이얼로그는 무조건 __dialog_closed__ 신호에 의해서만 닫힌다
      // WARN: 그러나 오직 __dialog_closed__ 신호에 의해서만 닫히므로, <paper-dialog>가 해당 신호를 보내주지 않으면 ESC로 "ready"에 이르지 못하는 경우가 생긴다!
      // INFO: 이 문제를 해결하기위해 80ms 이내에 ESC가 연달아오면 리셋을 하도록 강제한다
      ["esc@keyup",                 "dialog",               "dialog.pending",             ()=>app.waitingConsecutiveKey(80, __timeout__)],
      [["esc@keyup",__timeout__],   "dialog.pending",       "ready",                      [
         ()=>app.stackingDown(),
         ()=>app.forceCloseDialog(),
         cleanupHandler
         // ()=>app.popupNotice("Ready",false)
      ]],

      [__dialog_closed__,           "dialog",               "dialog", [
         ()=>app.setCurrentState(app.stackingDown()), /* 원래의 상태로 돌아간다 */
         cleanupHandler,
         ()=>{ if(app.current_state === "on_editing") app.moveCursorToPreviousFocusedElement();}
      ]],

      // dialog ---> __dialog_opened__ --> dialog.dialog
      // 첫번째 다이얼로그가 닫히지 않은 상태에서 두번째 다이얼로그가 떴을 때 처리
      // "ESC"를 무시해도 괜찮은 이유는, 다이얼로그가 닫히면서 신호를 보내오기 때문이다
      [__dialog_opened__,           "dialog",               "dialog.dialog",          Noop],
      [[__any__,__single_click__, "esc@keyup"],             "dialog.dialog",          "dialog.dialog",           Noop],

      // 두번째 다이얼로그 닫힘
      [[__dialog_closed__],         "dialog.dialog",        "dialog",                 Noop],


      /* SPACE를 누름으로써 "BasicMode" 처리:START ------------------------------------------------------------ */
      // Rule#6)
      [["space@keyup", "space@keypress"],                   "ready",                  "basic", [
         ()=>app.hideNotice(),
         ()=>app.addMenuPath("SPC"),
         ()=>elAdvancedMode.hide(),
         ()=>elBasicMode.show(),
         startBasicModePanel
      ]],


      // leader -> f -> Film Box 명령 실행모드
      // Rule#7)
      [["f","F"],                   "basic",                "filmboxFunction", [
         ()=>app.hideNotice(),
         ()=>app.addMenuPath("f"),
         (...args)=>app.warn_if_capslock_is_on(...args),
         (...args)=>app.warn_if_hangul(...args),
         pipe(getFilmBoxMenus, updateBasicModePanel)
      ]],

      // leader -> w -> Worklist 명령 실행 모드ㅡ
      // Rule#8)
      [["w", "W"],                  "basic",                "worklistFunction", [
         ()=>app.hideNotice(),
         ()=>app.addMenuPath("w"),
         (...args)=>app.warn_if_capslock_is_on(...args),
         (...args)=>app.warn_if_hangul(...args),
         pipe(getWorklistMenus, updateBasicModePanel)
      ]],


      // leader -> m -> 매크로 실행 모드
      // Rule#9)
      [["m", "M"],                  "basic",                "macroFunction", [
         ()=>app.hideNotice(),
         ()=>app.addMenuPath("m"),
         (...args)=>app.warn_if_capslock_is_on(...args),
         (...args)=>app.warn_if_hangul(...args),
         pipe(getMacroMenus, updateMacroModePanel)
      ]],

      [["c", "C"],                  "basic",                "userPreferences", [
         ()=>app.hideNotice(),
         ()=>app.addMenuPath("c"), /* WARN: 위의 trigger-key와 같이 변해야함을 잊지 말것! */
         (...args)=>app.warn_if_capslock_is_on(...args),
         (...args)=>app.warn_if_hangul(...args),
         pipe(getUserPreferencesMenus, updateUserPreferencesPanel),
         () => app.setPreviousUserPreferences()
      ]],


      // basic 모드 아래의 다른 설정 (worklist, filmbox, macro)을 부를 때는 popup 창이 곧바로 닫히지만
      // 사용자정의 기능설정은 여러항목에 대해 변경할 수 있기 때문에, 각 항목설정을 변경하더라도 popup 창은 닫히지 않게 하였다.
      // ESC 또는 Enter 키로 popup을 닫는다.
      [["enter@keypress", "enter@keyup"],                  "userPreferences",                "ready", [
         () => app.isUserPreferencesChanged("toggleTrackingFocus") && app.applyUserPreferences(),
         cleanupHandler
      ]],

      [["esc"],                     ["userPreferences"],     "ready", [
         ()=>app.restoreUserPreferences(),
         cleanupHandler
      ]],

      [["backspace", "left"],               ["userPreferences"],     "basic", [
         ()=>app.restoreUserPreferences(),
         ()=>app.popMenuPath(),
         ()=>app.back_to_basic_mode(),
         startBasicModePanel
      ]],


      // Rule#10)
      [["backspace", "esc", "left"],   "basic",                "ready",                   cleanupHandler],
      [["backspace", "left"],          ["filmboxFunction", "worklistFunction", "macroFunction"],     "basic", [
         ()=>app.popMenuPath(),
         ()=>app.back_to_basic_mode(),
         startBasicModePanel
      ]],


      // ESC를 받았을 때, 공통처리
      // Rule#11)
      ["esc@keyup",                 ["keyStacking", "filmboxFunction", "worklistFunction", "macroFunction"], "ready", cleanupHandler],

      [__on_editing_focus__,        ["ready", "basic", "advanced", "keyStacking", "filmboxFunction","worklistFunction", "macroFunction","userPreferences"], "on_editing", [
         () => queueMicrotask(()=>{
            cleanupHandler();
            try{ app.forceCloseDialog(); } catch(e) { }
         })
      ]],

      // 다른 곳으로 클릭을 했을 때, 명령팝업을 제거한다
      [__single_click__,            ["basic","filmboxFunction","worklistFunction","macroFunction","userPreferences"], "ready", [
         cleanupHandler
      ]],

      // 정의된 단축키(fkey)가 아닐경우, 해당 단축키 없다는 안내
      // Rule#12)
      [__any__,                     ["basic"],                 "basic", [
         ()=>app.popupNotice("No such shortcut(fkey) in this menu", false)
      ]],
      [__any__,                     ["worklistFunction"],      "worklistFunction", [
         ()=>app.popupNotice("No such shortcut(fkey) in this menu", false)
      ]],
      [__any__,                     ["filmboxFunction"],       "filmboxFunction", [
         ()=>app.popupNotice("No such shortcut(fkey) in this menu", false)
      ]],
      // [__any__,                     ["basic","worklistFunction","filmboxFunction"], "ready", [
      //    // cleanupHandler,
      //    ()=>app.popupNotice("No such shortcut(fkey) in this menu", false)
      // ]],
      /* SPACE를 누름으로써 "BasicMode" 처리:END ------------------------------------------------------------ */


      /* 명령입력처리:START -------------------------------------------------------------------------------- */
      // leader, esc 를 제외한 나머지 키 입력 (uncaught) -> 입력된 키를 쌓는 상태 (Enter or Shift+Enter 키를 기다림 )
      // 단독 CTRL을 trigger로 설정해둘 때, 'CTRL-a' 등의 키가 입력되면 keyup 이벤트에 이벤트 핸들러가 두번 실행되며
      // 그중 한번의 핸들러 호출에 의해 Control이 눌려졌다고 판단하게되어 "동작오류"가 일어난다
      [__lower_word__,              "ready",                "keyStacking", [
         ()=>app.hideNotice(),
         (...args) => app.TemplateAndMacroKeyStackingHandler(...args),
         () => app.updateToastBox(),
         displayKeyStack
      ]], // 매크로 or 상용구 입력 모드로 전환

      ["backspace",                 "keyStacking",          "keyStacking", [
         (...args) => app.doBackspaceFunction(...args), () => app.updateToastBox()
      ]],

      [__empty_keystack__,          "keyStacking",          "ready",      cleanupHandler],

      [__lower_word__,              "keyStacking",          "keyStacking", [
         (...args) => app.TemplateAndMacroKeyStackingHandler(...args), () => app.updateToastBox(),
      ]],

      [__tab__,                     "keyStacking",          "keyStacking",  Noop],

      [__single_click__,            "keyStacking",          "ready", [
         cleanupHandler
      ]],

      // textarea 밖에서 enter-enter 입력으로 개행을 붙여 템플릿(Template; 상용구) 넣기
      [["enter@keyup","enter@keypress"],                    "keyStacking",          "WaitingReturnKey", [
         // 다른 룰과 차별하기 위해 '__timeout__'을 쓰지 않음에 주의
         ()=>app.focusOut(),
         ()=>app.await(__fulfilled__),
         ()=>app.waitingConsecutiveKey(400, __no_more__)
      ]],

      // enter -> 50ms (선두에 공백이나 개행없이) 상용구 입력
      [__no_more__,                 "WaitingReturnKey",     "waitForFulfilled", [
         ()=>app.clearTimeout(), /* ↓ 템플릿 호출하는 시간이 걸리므로 그 사이에 타임아웃되지 않게 먼저 타이머를 리셋해줘야 한다 */
         ()=>app.handleKeyStack(handleTemplate, " "),
         cleanupHandler,
         ()=>app.waitingConsecutiveKey(2000, __timeout__),
         ()=>false /* __no_more__ 신호를 큐에 저장하지 않는다 */
      ]],

      // enter -> enter (선두에 개행 추가해서) 상용구 입력
      [["enter@keyup","enter@keypress"],                    "WaitingReturnKey",     "waitForFulfilled", [
         ()=>app.clearTimeout(), /* ↓ 템플릿 호출하는 시간이 걸리므로 그 사이에 타임아웃되지 않게 먼저 타이머를 리셋해줘야 한다 */
         ()=>app.handleKeyStack(handleTemplate, "\n"), /* 연달아 enter를 입력한후에 개행이 TEXTAREA들어가지 못하게 event를 dettach함 */
         cleanupHandler,
         ()=>app.waitingConsecutiveKey(2000, __timeout__),
         ()=> false /* 이 룰의 'enter'는 제어용이므로 비동기 이벤트 큐에 저장하지 않는다! */
      ]],

      // 아주 짧은 시간이지만, 템플릿을 빨리 입력할 때 이 부분을 설정하지 않으면 키 입력을 놓치게된다
      [__any__,                     "WaitingReturnKey",     "WaitingReturnKey",        ()=>true],
      // 템플릿 호출 후 기다리는 동안 입력하는 키 입력을 쌓아둠
      [__any__,                     "waitForFulfilled",     "waitForFulfilled",        ()=>true],
      // 예외 키 처리. 큐에 저장하지 않는다
      [__no_more__,                 "waitForFulfilled",     "waitForFulfilled",        ()=>false],

      // 기다리다가 ESC를 누르면, 입력을 지우고 ready로 리셋
      [["esc@keyup", __canceled__], "waitForFulfilled",     "ready", [
         ()=>app.rejected(),
         cleanupHandler,
         ()=>app.popupNotice("Canceled applying template", false)
      ]],

      [__timeout__,                 "waitForFulfilled",     "ready", [
         ()=>app.rejected(),
         cleanupHandler,
         ()=>app.popupNotice("Timeout(3secs) for waiting response", true)
      ]],

      // 템플릿 호출 성공신호 받음. "ready" 상태로 바뀐 뒤에 쌓았던 키를 적용하는게 중요!!!
      [__fulfilled__,               "waitForFulfilled",     "ready",                   [
         () => {[ app.linkedElements.$taFinding, app.linkedElements.$taConclusion, app.linkedElements.$taRecommendation ].forEach(textarea =>  textarea.dispatchEvent(new CustomEvent("input"))); },
         ()=>app.clearTimeout(),
         cleanupHandler,
         ()=>app.fulfilled()
      ]],

      // 템플릿이 없거나 오류
      [[__rejected__, __failed__],  ["waitForFulfilled","ready"],     "ready",                   [
         ()=>app.clearTimeout(),
         cleanupHandler,
         ()=>app.rejected()
      ]],

      // key-stacking 상태 -> Enter -> backslash -> 매크로 실행
      ["backslash",     "WaitingReturnKey",     "macro", [
         ()=>app.clearTimeout(), /* ↓ 템플릿 호출하는 시간이 걸리므로 그 사이에 타임아웃되지 않게 먼저 타이머를 리셋해줘야 한다 */
         ()=>app.handleKeyStack(handleMacro),
         cleanupHandler,
         ()=>app.setMaximumExecutionTimeLimit(),
      ]],

      // key-stacking 상태 -> Ctrl -> 매크로 실행  (각각의 매크로 명령은 buildUserShortcutRules() 함수에서 구성된다
      [["ctrl@keyup"],                                      "keyStacking",             "macro", [
         ()=>app.handleKeyStack(handleMacro),
         cleanupHandler,
         ()=>app.setMaximumExecutionTimeLimit(),
      ]],
      [["shift+enter@keyup", "shift+enter@keypress"],       "keyStacking",             "macro", [
         ()=>queueMicrotask(() => {
            app.handleKeyStack(handleMacro);
            cleanupHandler();
         }),
         (...args)=>app.stopPropagation(...args),
         ()=>app.setMaximumExecutionTimeLimit(),
      ]],

      // 아래 두가지 신호에 대해 매크로를 계속 진행한다
      [[__fulfilled__, __canceled__],                       ["macro", "macro.waiting"],"macro", [
         ()=>app.clearTimeout(),
         ()=>app.emit(__next__)
      ]],

      // 다이얼로그가 열리면 매크로 타임아웃카운트를 멈춘다
      [[__dialog_opened__],                                 "macro",                   "macro.dialog", [
         ()=>app.clearMacroTimeout(),
      ]],

      // 포커스를 backdrop에 놓고 ESC에 의해 닫을 경우, 강제로 다이얼로그를 닫도록 호출한다
      ["esc@keyup",                                         "macro.dialog",            "macro.dialog.pending", [
         ()=>app.waitingConsecutiveKey(100, __timeout__),
         ()=>app.forceCloseDialog()
      ]],

      // 강제로 닫히면 다음번 태스크를 진행한다
      [["esc@keyup",__timeout__, __dialog_closed__],        "macro.dialog.pending",    "macro", [
         ()=>app.forceCloseDialog(),
         ()=>app.setMaximumExecutionTimeLimit(),
         ()=>app.emit(__next__)
      ]],

      // 사용자가 정상적으로 버튼을 통해 닫았을 경우도 다음번 대스크를 진행한다
      [[__dialog_closed__],                                 "macro.dialog",            "macro.waiting", [
         ()=>app.clearTimeout(),
         ()=>app.setMaximumExecutionTimeLimit(),
         ()=>app.waitingConsecutiveKey(500, __macro_waiting__)
      ]],

      // 500ms를 쉬어주는 이유는 Polymer Component 가운데 비동기로 다이얼로그를 띄우고, 다이얼로그가 닫히면서
      // 원래의 명령을 재실행하는 approveOpinion 명령때문에 발생하는 순서역전 현상을 방지하기 위해서다.
      // FIXME: EventBus가 적용되면 아래코드는 필요없게될 수 있다
      [[__macro_waiting__],                                       "macro.waiting",            "macro", [
         ()=>app.emit(__next__)
      ]],

      // 두번째 다이얼로그가 열릴경우
      [[__dialog_opened__],                                 "macro.dialog",            "macro.dialog.dialog",        Noop],
      [[__any__,__single_click__, "esc@keyup"],             "macro.dialog.dialog",     "macro.dialog.dialog",        Noop],
      [[__dialog_closed__],                                 "macro.dialog.dialog",     "macro.dialog",               Noop],

      // 도중에 오류가 발생하면 모두 취소
      [[__rejected__, __failed__],                          ["macro", "macro.dialog", "macro.dialog.dialog", "macro.waiting"],        "ready", [
         ()=>app.clearMacroTimeout(),
         ()=>app.forceCloseAllDialogs(),
         ()=>app.emit(__rollback__),
         ()=>app.popupNotice("macro stopped due to some failure", true)
      ]],

      // 매크로실행 시간제한에 걸린경우
      [[__macro_timeout__],                                 ["macro", "macro.dialog", "macro.dialog.dialog"],        "ready", [
         ()=>app.forceCloseAllDialogs(),
         ()=>app.emit(__rollback__),
         ()=>app.popupNotice("exceeded maximum-macro-execution-time (4 secs)", true)
      ]],

      [[__done__, ],                                        ["macro","macro.waiting"],    "ready", [
         ()=>app.clearMacroTimeout(),
         ()=>app.popupNotice("Macro applied.", false),
         ()=>setTimeout(app.hideNotice, 1000)
      ]],

      [[__macro_abort__, ],                                 ["macro","macro.waiting"],    "ready", [
         ()=>app.clearMacroTimeout(),
         (...args)=>app.stopPropagation(...args),
         ()=>app.forceCloseAllDialogs()
      ]],
      /* 명령입력처리:END -------------------------------------------------------------------------------- */


      /* 인라인 숏컷 변경:START -------------------------------------------------------------------------- */
      [__dbl_click__,               ["filmboxFunction","worklistFunction","macroFunction"],       "changingShortcut",
         [disableStackUpTrigger, (ctx, el) => app.startChangeShortcut(el)]],

      // ↓ 새로운 숏컷을 지정하는 동안 입력되는 키 이벤트 처리
      [[__word__, __number__, __special_char__, "backspace@keyup"],              "changingShortcut", "changingShortcut",
         (...args) => whileChangingShortcut(...args)],

      ["esc",                       "changingShortcut",     "basic",                         [
         ()=> app.restorePreviousFKey(), ()=>app.clearPreviousFKey(), () => app.restorePreviousState(),
         () => setTimeout(enableStackUpTrigger,0)
      ]],

      [__on_editing_focus__,        "changingShortcut",     "on_editing",                         [
         () => queueMicrotask(()=>{
            app.restorePreviousFKey();
            app.clearPreviousFKey();
            app.restorePreviousState();
            enableStackUpTrigger();
            cleanupHandler();
         })
      ]],

      // ↓ 숏컷 재지정은 Enter 키 입력 또는 마우스 더블클릭으로 ("Enter" 키 재바인딩은 허용하지 않는다)
      // [ "shift+enter@keypress",     "changingShortcut",     "changingShortcut.wait-keyup",   () => app.waitingConsecutiveKey(200, __timeout__)],
      [ ["shift+enter@keypress", "shift+enter@keyup"],      "changingShortcut",     "checkDuplication", [
         ()=>app.clearTimeout(), () => app.applyChangedShortcut(/* force */true)
      ]],
      [__any__,                     "changingShortcut",     "changingShortcut",              Noop],
      [["enter@keyup","enter@keypress"],                    "changingShortcut",           "checkDuplication",
         () => app.applyChangedShortcut()
      ],
      [__ok__,                      "checkDuplication",     "basic",
         [() => app.clearPreviousFKey(), () => app.refreshRules(), ()=>app.restorePreviousState(),
            /* 아래 코드를 setTimeout()으로 지연시키지 않으면, app.restorePreviousState() 함수가 끝나기 전에 상태를 변경하는 비동키호출이 1-tick 늦게 실행되므로  'FKey' 변경전의 상태로 돌아가는 신호가 this.menuPathStack 값에 쌓이게 된다. (#16759) */
            ()=>setTimeout( enableStackUpTrigger, 0)
         ]
      ],

      [__duplicated__,              "checkDuplication",     "changingShortcut",        [() => app.showDuplicationWarning()]],


      // BasicMode에서 AdvancedMode 전환 (2020-07-31 #14800 -- 일시적으로 개발 중지)
      // [__leader__,                  "basic",                "advanced", [
      //    () => elBasicMode.hide(),
      //    updateAdvancedSearchPanel
      // ]],

      // AdvancedMode에서 Enter키를 누름으로써 명령실행 및 History 배열에 추가
      [["enter@keyup","enter@keypress"],                    "advanced",                   "ready",
         [(/* eslint-disable no-unused-vars */ctx, event) => {
            // add to LRU
            const selectedCommand = $elSearchResult.find("LI")[app.cursor];
            app.addToLRU( selectedCommand );
            app.resetCursor();
            app.exec( selectedCommand );
         }, cleanupHandler, () => app.focusOut()]],

      ["esc",                       "advanced",             "ready", [
         () => elAdvancedMode.hide(),
         cleanupHandler
      ]],

      ["up",                        "advanced",             "advanced",
         [(ctx, event) => app.keypressUP(ctx, event), (ctx,e)=>updateAdvancedSearchResult(e)]],

      ["down",                      "advanced",             "advanced",
         [(ctx, event) => app.keypressDOWN(ctx, event), (ctx, e)=>updateAdvancedSearchResult(e)]],

      // on_editing 상태에서 TEXTAREA에서오는 신호를 받았다면, 마우스나 탭 키등에 의해 이미 포커스가 빠진 것이다
      // ESC 등을 입력해서 빠지는 것은 강제로 빼는 것이므로 이 신호를 기다리는 중간단계가 필요하다
      [__off_editing_focus__,       "on_editing",           "ready",                   Noop],

      // 판독문입력할 때, 허용하는 키를 명시적으로 설정함. TEXTAREA쪽 KeyboardEvent Handler를 설정해서 불필요한 키 입력을 막아야 함
      // 'shift'키를 제외한 modifiers key와 조합된 키는 허용하지 않는다. '한글'은 허용한다
      // [[__word__, __number__, __special_char__, "space", "backsapce"],           "on_editing",       "on_editing",               Noop],
      [[__single_click__, __any__], "on_editing",           "on_editing",              Noop],

      // Conclusion 등의 TEXTAREA에서 입력도중 매크로|상용구 입력을 하려고 shift-shift 시퀀스의 첫번째 shift를 눌렀을 때 다음번 shift 입력을 300ms 동안 기다림
      ["shift",                     "on_editing",           "waiting_another_shift", [
         ()=> {
            return app.useDblShiftEscaping ? app.waitingConsecutiveKey(300, __timeout__) : app.emit(__timeout__);
         }
      ]],

      // 타임아웃이 되거나, shift가 아닌 임의의 키인 경우 그냥 에디팅을 진행하게 함
      [[__timeout__, __any__],      "waiting_another_shift","on_editing",              (...args)=>app.clearTimeout(...args)],

      // 판독문 입력시 shift-shift 이후의 룰들이 중복이라  ESC 와 같은 역활로 대체 (2020-06-16 Tue; 실험적)
      ["shift",                     "waiting_another_shift","ready",
         () => { app.clearTimeout(); app.clearKeyStack(); app.focusOut(); }],

      // workaround for QA #15083
      [ ["meta+shift", "alt+shift"],            "on_editing",           "on_editing", [
         () => app.toggleDblShiftEscaping()
      ]]

   ];

   app = new FLeader({leader:"space"}, "FLeader", "loaded", /* context */{}, initial_rules);

   // global Uncaught Handler
   // 각각의 상태에 대한 uncaught handler가 설정되어 있지않는 경우 호출됨
   app.UncaughtHandler = (trigger, e, /* app */{current_state}, key, modifiers=[]) => {

   };

   // app.disable_handler(["basic", "worklistFunction", "filmboxFunction", "changingShortcut"], ["keyup"]);
   // app.disable_handler(["keyStacking"], ["keyup"]);


   function whileChangingShortcut(ctx, e, app, key, modifiers) {
      [e]
         .filter(e => e instanceof KeyboardEvent) // KeyboardEvent 타입이 아닌 경우에는 무시
         .map(e => app.showNewShortcut(e)) // 숏컷 변경 중, 허용가능한 키입력을 필터링
         .filter(key => !Object.is(key, null))
         .map(key => app.isAlreadyRegistered(key) ? __duplicated__ : __ok__)
         .map((signal) => {
            return (signal === __duplicated__) ? app.showDuplicationWarning() : app.showNoDuplication();
         });
   }

   /* DOM binding:START ------------------------------------------------------------------------ */
   elBasicMode = (()=>({
      show(value="block") {
         // INFO: elBasicMode 변수가 설정될 타이밍에는 DOM 요소를 찾을 수 없기 때문에 lazy-referencing 을 해야한다
         // TODO: use lens
         DOMElement("basicMode").style.display = value;
      },
      hide() {
         DOMElement("basicMode").style.display = "none";
      }
   }))();

   elAdvancedMode = (()=>({
      show(value="block") {
         // TODO: use lens
         DOMElement("advancedMode").style.display = value;
      },
      hide() {
         DOMElement("advancedMode").style.display = "none";
      }
   }))();

   elSearchKeywords  = DOMElement("searchKeywords");
   elSnackbar        = DOMElement("snackbar");
   elDisplayInputSCP = DOMElement("displayInputSCP");
   $elSearchResult   = $(DOMElement("searchResult"));
   $functionList     = $(DOMElement("functionList"));

   // WARN: These are DOM Element, not jQuery-ed object
   $inputKey         = $(DOMElement("inputShortcut"))[0];
   $displayKey       = $(DOMElement("displayKey"))[0];
   ToastBox          = DOMElement("ToastBox");

   // TODO: 스타일 파일에 통합
   $displayKey.style.fontSize = "1.5em";

   // FIXME: why doing this????
   elSearchKeywords.addEventListener("keyup", updateAdvancedSearchResult);
   elSearchKeywords.style.color = "white";
   elSearchKeywords.style.fontWeight = "900";

   // 주의: Polymer 콤포넌트에 속한 API를 지정하고 있음
   app.DOMElement = DOMElement;

   // 마우스클릭에 대한 이벤트를 보내지 않도록 주의!
   [elSnackbar, ToastBox].forEach(el => el.addEventListener("click", e => e.stopPropagation() && false));

   // #14621 `splitter`가 변화하거나 윈도우의 geometry가 변경되면 업데이트해야하지만
   // handler를 polymer쪽에 두지않기 위해서 폭을 계산할 개체의 객체를 받아서 패널이 뜰 때마다 width를 조절하고 있다
   // FIXME: 더 나은 타이밍을 찾아 수정할 것
   const setSnackbarWidth = () => { elSnackbar.style.width = `${app.linkedElements.$worklistOldFilm.offsetWidth - 45}px`; };

   /* ---------------------------------------------------------------------- */
   /* 원격 데이터베이스에서 가져온 단축키 정보(JSON)를 FLeader 룰로 변환한다 */
   /* ---------------------------------------------------------------------- */
   // NOTE: JSON 파일자체에 문제가 있거나 숏컷정보를 변환도중 문제가 생길 경우,
   // NOTE: default json 파일을 읽어서 HPACS를 기동시키기 위해 전체 과정이 하나의 함수로 작성되야 한다
   const buildUserShortcutRules = json => app.parse(json, ({category, shortcut, functionName, displayedName, fkey})=>{
      // console.log(`buildUserShortcutRules()>> category: ${category}, shortcut:${shortcut}, functionName${functionName}, fkey:${fkey}` );

      let handler = () => console.log(`no function in category '${category}'`);
      let this_function_has_to_await_result = SyncedRules.includes(functionName);

      if(functionName === "filmbox" || functionName === "worklist") {
         handler = () => handleCommonFunction(shortcut, functionName);
      }else if( category === "worklistFunction" ) {
         handler = () => handleWorklistFunction( functionName );
      } else if (category === "filmboxFunction") {
         handler = () => handleFilmboxFunction(shortcut, functionName);
      } else if (category === "macroFunction") {
         handler = () => handleMacro(shortcut);
      } else if (category === "userPreferences") {
         handler = () => handleUserPreferences( functionName );
      }

      const cb = (ctx, event/* , app, key, modifiers */) => {
         try {
            console_log(`callback: calling ${functionName} of ${category}`);
            event.stopPropagation();
            cleanupHandler();
            handler();
         } catch(e) {
            console_log(e);
         }
      };

      // 판독문입력도중 alt+key 단축키를 입력한 경우, 명령을 수행하기 전에 TEXTAREA에 입력된 마지막 문자를 삭제한다
      const editing_cb = (ctx, event, app/* , key, modifiers */) => {
         console_log(`callback: calling ${functionName} of ${category} in Editing Area`);
         event.preventDefault();
         cleanupHandler();
         // app.remove_last_char_in_report_area_If_alt_compositedKey(event);
         queueMicrotask( handler );
      };

      // 매크로실행에 사용되는 콜백.위의 루틴과의 차이점은 cleanupHandler 호출시점이다
      const macro_cb = (ctx, event, app/* , key, modifiers */) => {
         try {
            console_log(`callback: calling Macro ${shortcut}`);
            event.stopPropagation();
            handler();
            cleanupHandler(); // WARN: handler()에서 keyStack을 사용하므로 cleaupHandler를 나중에 불러야한다
            app.setMaximumExecutionTimeLimit();
         } catch(e) {
            console_log(e);
         }
      };

      const preferences_cb = (ctx, event/* , app, key, modifiers */) => {
         try {
            console_log(`adjust user-preferences: ${functionName}`);
            // 사용자별 기능정의를 수행할 때는 FLeader popup 화면을 닫지않고 유지한다. 여러 설정을 할 수 있기 때문이다.
            // 팝업을 닫는 키는 ESC 또는 Enter!
            handler();
         } catch(e) {
            console_log(e);
         }
      };


      let rule = [];
      const globallyCallableCategory = ["filmboxFunction", "worklistFunction"];

      if(["filmboxFunction", "worklistFunction", "macroFunction", "userPreferences"].includes(category)) {

         // 시퀀스키는 다른 기능이 모두 안정화& 확인된 후 사용할 예정
         // const sequence_key = mod_key || shortcut.replace(/\+/g, " ");
         // const global_sequence_key = [sequence_key, "ready", "ready", cb];

         // 단어로 되어 있는 키 이름을 특정 소문자 단어로 변경한다: Page UP => pageup, Arrow Down => down .. 등으로 변경
         const mod_key  = normalizeSpecialKeyName(shortcut);
         // shortcut의 키 이름이 단어로 되어 있어 mod_key 값이 해당 값을 소문자 단어로 변환시킨 것이라면 shortcut 값 대신 mod_key를 사용해야 한다.
         const $shortcut = (mod_key || shortcut);

         // 사용자지정 전역단축키 (modifiers|specialkeys)+ASCII (ready ===> ready)
         if(globallyCallableCategory.includes(category)) {
            if( this_function_has_to_await_result ) {
               if($shortcut) rule = [...rule, ...RuleFactory.create({functionName, app, shortcut:$shortcut, from:"ready", to:"ready", displayedName, callback:cb, cleanupHandler})];
            } else if($shortcut) {
               rule.push([$shortcut, "ready", "ready", cb]);
            }
         }

         // 판독정보입력(Conclusion TEXTAREA)에서도 전역메뉴는 동작해야 함 (on_editing ==> ready || on_editing ==> on_editing)
         // 입력박스에서는 오직 modifiers+ascii 키 조합만 허용한다
         // (단 Conclustion 입력시 approve 되지 않은 상태에서는 Prev/Next 등이 듣지 말아야 함)
         // NOTE: ready 상태에서는 pageup/pagedown 등과 같은 키입력이 처리되지만, TEXTAREA에서는 처리되지 않음에 유의!
         if(globallyCallableCategory.includes(category)) {
            if(!mod_key && ["alt", "ctrl", "meta"].some(mkey => shortcut.indexOf(`${mkey}+`) !== -1)) {
               if( this_function_has_to_await_result ) {
                  if(shortcut) rule = [...rule, ...RuleFactory.create({functionName, app, shortcut, from:"on_editing", to:"ready", displayedName, callback:editing_cb, cleanupHandler})];
               } else if(shortcut) {
                  rule.push([shortcut, "on_editing", "on_editing", cb]);
               }
            }
         }

         // fimlBox, worklist, macroFunction를 보이는 Basic-Mode에서 사용할 FKey 전용 메뉴
         const f_key = (fkey||"").replace(/\s/g, "");
         if( f_key.length === 1) {
            if(globallyCallableCategory.includes(category)) {
               if( this_function_has_to_await_result ) {
                  rule = [...rule, ...RuleFactory.create({functionName, app, shortcut:f_key, from:category, to:"ready", displayedName, callback:cb, cleanupHandler})];
               } else  {
                  // TODO: global-shortcut 대신에 fkey 에서도 상태관리를 세분화해야한다
                  // INFO: RuleFactory.create() 함수는 alt+key 형태의 호출에 대해서 세분화 되어있음을 주의!
                  rule.push([f_key, category, "ready", cb]);
               }
            } else if(category === "macroFunction" ) {
               rule.push([f_key, category, "macro", macro_cb]);

            } else if(category === "userPreferences" ) {
               // WARN: 오직 ESC 키에 의해서만 ready 상태로 바뀐다
               rule.push([f_key, category, "userPreferences", preferences_cb]);
            }
         }
      }

      return rule;
   });

   app.reloadRules = (json) => {
      app.resetRules(initial_rules);
      buildUserShortcutRules(json);
      app.buildGroupKeys();
      console_log("shortcut/fkey information reloaded: ", json);
   };

   // NOTE: UserProfile 기능구현을 위해 임시로 해당 정보를 끼워넣는 부분
   // TODO: (DB에서 정보가 오게되면 삭제할 것)
   // eslint-disable-next-line
   if( !(shortcutObj.hasOwnProperty("userPreferences") && typeof(shortcutObj.userPreferences) === "object") ) {
      const localDb = JSON.parse(localStorage.getItem("userPreferences"));
      if(localDb) Object.assign(shortcutObj, {userPreferences: localDb});
      else Object.assign(shortcutObj, {userPreferences: defaultUserPreferences}); // FIXME: MongoDB가 연결되면 없애버릴 것
   }

   app.getStoredJSON(shortcutObj)    // 원격데이터베이스에 저장된 단축키정보를 가져옴
      .then(buildUserShortcutRules)  // 기본단축키외에 사용자 단축키로써 추가
      .catch((e) => {                // 추가도중 오류가 날 경우, 리셋후 기본 단축키설정을 적용
         console.log( e );
         app.resetRules(initial_rules);
         buildUserShortcutRules(defaultShortcutSetting());

         // eslint-disable-next-line no-alert
         // alert(`키보드/단축키 정보를 처리하는 동안 오류가 발생했습니다.\n정보파일(JSON)을 확인해주세요\n\n기본 단축키 정의를 사용합니다.`);
         console.log(app.states);
      })
      .then(() => {

         // FLeader 클래스 내에서 참조할 DOM 객체들
         app.ctx.$inputKey = $inputKey;
         app.ctx.$displayKey = $displayKey;
         app.ctx.ToastBox = ToastBox;

         // window 전역에 대해 keypress/keyup 이벤트를 잡는다
         app.bindKeyboardEventsOn( window );

         // 그룹키 정보를 구성
         app.buildGroupKeys();

         // 상태정보가 준비되었고 시작 신호를 보낸다
         app.emit(__hb__);
      });


   function callMacroFeature(functionName){
      const macroList = app.menuJSON.macroFunction;
      macroList.filter(macro => macro.functionName === functionName).map((m) => {
         // harmless 'return' to avoid ESLint warning
         return m.functionList.forEach((func, index) =>{
            setTimeout(()=>{
               if(func.type ==="function"){
                  handleWorklistFunction(func.functionName);
               } else if(func.type ==="preform"){
                  handleTemplate("", "", func.functionName);
               }
            }, 400 * index);
         });
      });
   }


   // [found:boolean), "category":string, function-info:object]
   function getCallableFunctionInfo(target) {
      let found = app.menuJSON.worklistFunction.filter(({functionName}) => functionName === target).pop();
      if(found) return [true, "worklistFunction", found];

      found = app.menuJSON.filmboxFunction.filter(({functionName}) => functionName === target).pop();
      if(found) return [true, "filmboxFunction", found];

      // macroFunction은 핫키에 의해 호출되지 않는다
      return [false, "", undefined];
   }


   function updateKeyInputStatusLine() {
      const menu = [...app.menuPathStack].slice(1).join(" ");
      elDisplayInputSCP.innerHTML = `${app.menuPathStack[0]} - <span>${menu}</span>`;
      $inputKey.value = `${app.menuPathStack.join(" ")} -`;
   }

   // TODO: app.menuJSON 의 최상위 카테고리 값을 참고로 표시해야함!
   function startBasicModePanel(state) {

      // 팝업이 지연되는 동안 상태가 변경된 경우
      // if( app.current_state !== state ) {
      //    console_log(`state changed while delaying to open BasicModePanel`);
      //    return;
      // }

      requestAnimationFrame(() => {
         const vdom =`
         <li class="class-function shortcut-input shortcut-input-color1">
            <div class="class-shortcut input disabled">w</div>
            <div class="class-arrow"></div>
            <div class="class-text-parent-list span">WorkList Functions</div>
         </li>
         <li class="class-function shortcut-input shortcut-input-color1">
            <div class="class-shortcut input disabled">f</div>
            <div class="class-arrow"></div>
            <div class="class-text-parent-list span">FilmBox Functions</div>
         </li>
         <li class="class-function shortcut-input shortcut-input-color1">
            <div class="class-shortcut input disabled">m</div>
            <div class="class-arrow"></div>
            <div class="class-text-parent-list span">Macro Functions</div>
         </li>
         <li class="class-function shortcut-input shortcut-input-color1">
            <div class="class-shortcut input disabled">c</div>
            <div class="class-arrow"></div>
            <div class="class-text-parent-list span">User Configurations</div>
         </li>`;

         $functionList[0].innerHTML = "";
         $functionList.append(vdom);
         tryCatch(setSnackbarWidth); // 해당요소가 준비되기전에 사용자가 __leader__ 키를 눌렀을 때 오류를 보이지 않기 위함
         if(!elSnackbar.classList.contains("show")) elSnackbar.classList.add("show");
         if(!$functionList[0].classList.contains("active")) $functionList[0].classList.add("active");
         updateKeyInputStatusLine();
         hideKeyStack();
         elSnackbar.focus();
      });
   }

   function updateBasicModePanel(items=[]){/* [{char:, displayedName:, shortcut: }] */

      const vdom = [];
      items.forEach(({shortcut, functionName, displayedName, fkey=""}) => {
         const sc = (shortcut ? `<span style="color: #999999">(${shortcut})</span>` : "");
         vdom.push(
            `<li class="class-function shortcut-input shortcut-input-color1"
               ondblclick="dblClickHandler(this)"
               data-function-name="${functionName}"
               data-fkey-key="${fkey}"
               data-shortcut-key="${shortcut}">
                  <div class="class-shortcut input disabled">
                     <span class="class-shortcut-key-text">${fkey}</span>
                  </div>
                  <div class="class-arrow"></div>
                  <div class="class-text-child-list span">${displayedName}
                     ${sc}
                  </div>
               </div>
            </li>`);
      });
      requestAnimationFrame(() => {

         // $functionList 객체는 Cash Object 이지만, .empty() 함수가 빠져있다.
         $functionList[0].innerHTML = "";

         // Cash.append()는 Enable 되어 있다
         $functionList.append(vdom.join(""));

         if($functionList[0].classList.contains("active"))
            $functionList[0].classList.remove("active");
         updateKeyInputStatusLine();

         hideKeyStack();
      });
   }

   function updateMacroModePanel(items=[]){
      const vdom = [];
      items.forEach(({shortcut, functionName, displayedName, fkey=""}) => {
         const sc = (shortcut ? `<span style="color: #999999">(${shortcut})</span>` : "");
         vdom.push(
            `<li class="class-function shortcut-input shortcut-input-color1"
               ondblclick="dblClickHandler(this)"
               data-function-name="${functionName}"
               data-fkey-key="${fkey}"
               data-shortcut-key="${shortcut}">
                  <div class="class-shortcut input disabled">
                     <span class="class-shortcut-key-text">${fkey}</span>
                  </div>
                  <div class="class-arrow"></div>
                  <div class="class-text-child-list">${displayedName}
                     ${sc}
                  </div>
               </div>
            </li>`);
      });

      requestAnimationFrame(() => {
         $functionList[0].innerHTML = "";
         $functionList.append(vdom.join(""));

         if($functionList[0].classList.contains("active"))
            $functionList[0].classList.remove("active");

         updateKeyInputStatusLine();
      });
   }

   function updateUserPreferencesPanel(items=[]){
      const vdom = [];
      items.forEach(({shortcut, functionName, displayedName, fkey="", printableValue=""}) => {
         const pv = (printableValue !== "" ? `<span style="color: #999999">(${printableValue})</span>` : "");
         vdom.push(
            `<li class="class-function shortcut-input shortcut-input-color1"
               ondblclick="dblClickHandler(this)"
               data-function-name="${functionName}"
               data-fkey-key="${fkey}"
               data-shortcut-key="${shortcut}">
                  <div class="class-shortcut input disabled">
                     <span class="class-shortcut-key-text">${fkey}</span>
                  </div>
                  <div class="class-arrow"></div>
                  <div class="class-text-child-list">${displayedName}
                     ${pv}
                  </div>
               </div>
            </li>`);
      });

      requestAnimationFrame(() => {
         $functionList[0].innerHTML = "";
         $functionList.append(vdom.join(""));

         if($functionList[0].classList.contains("active"))
            $functionList[0].classList.remove("active");

         updateKeyInputStatusLine();
      });
   }

   // eslint-disable-next-line no-param-reassign
   window.dblClickHandler = function __dblClickHandler(el){
      // TODO: how to hand-over 'this'???
      if(!el) throw new Error(`더블클릭핸들러 호출시 this를 넣었는지 확인해주세요`);

      // fkey를 바꾸는 중이 아니라면 신호를 보냄
      if(Object.is(app.previousFKey, null)) app.emit(__dbl_click__, el);
   };

   function changeAdvancedMode(){
      // elSnackbar.className = "show";
      if(!elSnackbar.classList.contains("show"))
         elSnackbar.classList.add("show");

      elBasicMode.hide();
      elAdvancedMode.show();
      setTimeout(()=> elSearchKeywords.focus(),0);
   }

   function changeBasicMode(){
      elAdvancedMode.hide();
      elBasicMode.show();
   }

   function displayKeyStack(){
      app.ctx.ToastBox.style.display = "";
      app.ctx.ToastBox.focus();

      // #17788 [HPACS] Report popup 창에서 Reading template 실행 이후 clear 버튼 누르고 다시 Reading Template 실행 했을때 발생되는 오류
      const reportWindow = window.report.get();
      if(reportWindow) {
         const reportAcitveElem = findActiveElement(reportWindow.document);
         reportAcitveElem.blur();
      }
   }

   function findActiveElement(root = document){
      if (root.activeElement && root.activeElement.shadowRoot && root.activeElement.shadowRoot.activeElement) {
         return findActiveElement(root.activeElement.shadowRoot);
      }
      return root.activeElement;
   }

   function hideKeyStack(){
      app.ctx.ToastBox.style.display = "none";
   }


   // hidden shortcutBar
   function hideSearchBar() {
      // changeBasicMode();
      // elSnackbar.className = elSnackbar.className.replace("show", "");
      // if(elSnackbar.classList.contains("show"))

      elBasicMode.hide();

      elSnackbar.classList.remove("show");
      //
      // $functionList.empty();

      $inputKey.value = "";
      // elDisplayInputSCP.innerHTML = "&nbsp;";
   }

   // SPACE를 누름으로써 BasicMode Popup 박스를 표시. 주어진 만큼의 시간 지연이 있음
   // app.startBasicMode()를 호출하는 또 다른 함수를 맏는 이유는, updateBasicModePanel 함수가 fleader.js 외부에 있기 때문이다
   function startBasicMode() {
      // app.startBasicMode의 호출함수로 startBasicModePanel을 직접 인수로 전달하면 되지만,
      // 굳이 여기서 한번 더 함수로 감싸는 이유는 팝업이 실연실행되는 동안 상태가 변경된 것을 감지하기 위해서다.
      // const status = app.current_state;
      // app.startBasicMode(() => startBasicModePanel(status));
      startBasicModePanel();
   }

   function clearFocus(){
      // WHY!!! 이 라인 때문에 TEXTAREA에서 포커스가 빠져버림!!
      // document.dispatchEvent(new CustomEvent("windowFocusOut"));
   }

   return app;
}
