import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/paper-tooltip/paper-tooltip";
import moment from "moment-timezone";
import store from "./redux/store";
import TechnicianUtils from "./utils/technician-utils";
/**
 * `techlist-counter`
 *
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
class TechlistCounter extends PolymerElement {

   static get template() {
      return html`
         <style>
            :host {
               display: block;
               width: 100%;
               height: 25px;
            }

            .container {
               display: flex;
               height: 100%;
               line-height: 25px;
            }

            .font{
               color: #aaaaaa;
               font-size: 13px;
               width: 320px;
               text-align: right;
               white-space: nowrap;
            }

            .filter {
               width: calc(100% - 250px);
               color: #aaaaaa;
               font-size: 13px;
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
            }

            .filter:hover {
               cursor: help;
            }

            .cnt {
               color: #e46159;
               font-weight: bold;
            }

            paper-tooltip  {
               --paper-tooltip-duration-in: 0;
               --paper-tooltip-duration-out: 0;
            }
            </style>
            <div class="container">
               <div id="filter" class="filter">Filter : [[filter]]</div>
               <paper-tooltip for="filter" position="right">[[filter]]</paper-tooltip>
                <template is="dom-if" if="{{showCount}}">
                   <!--<div class="font">Verified : <span class="cnt">[[verify]]</span> &nbsp; Merged : <span class="cnt">[[merge]]</span></div>-->
                    <div class="font">
                        Total: <span class="cnt">[[total]]</span>&nbsp;
                        Verify: <span class="cnt">[[verified]]</span>&nbsp;
                        Unverify: <span class="cnt">[[unverify]]</span>&nbsp;
                        Unmatch: <span class="cnt">[[unmatched]]</span>
                    </div>
                </template>
            </div>
         </div>
      `;
   }

   static get properties() {
      return {
         filter: {
            type: String
         },
         verify: {
            type: Number,
            value: 0
         },
         merge: {
            type: Number,
            value: 0
         },
         showCount: {
            type: Boolean,
            value: true
         },
         verified:{
            type: Number,
            value: 0
         },
         unverify: {
            type: Number,
            value: 0
         },
         unmatched: {
            type: Number,
            value: 0
         },
         total: {
            type: Number,
            value: 0
         },
         fetchController: {
            type: Object
         },
         appliedFilter: {
            type: Object,
            observer: "changeAppliedFilter"
         }
      };
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         this.appliedFilter = store.getState().techlist.appliedFilter;
      });
   }

   getTechistCount(filterModel) {
      if(Object.keys(filterModel).length === 0) {
         this.showCount = false;
         return;
      }

      // #17773 [HPACS > 전수령 원장님] 워크리스트 하단 W 값이 - 로 표기 되는 오류
      if(this.fetchController) {
         this.fetchController.abort();
      }

      this.fetchController = new AbortController();
      const { signal } = this.fetchController;
      fetch("/api/tech/count", {
         signal,
         method: "POST",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(filterModel)
      }).then((response) => {
         this.showCount = true;
         if (response.ok) {
            response.json().then((httpResponse) => {
               this.verified = httpResponse.verified;
               this.unverify = httpResponse.unverify;
               this.unmatched = httpResponse.unmatched;
               this.total = httpResponse.total;
            });
         }
      });
   }

   // conversionFilter(filterModel) {
   //    const _filterModel = filterModel;
   //    const checkList = [
   //       {filter: "isEmergency", value: "E"},
   //       {filter: "isMatch", value: "(M) Matched"},
   //       {filter: "isMerge", value: "(M) Merged"}
   //    ];
   //
   //    Object.keys(_filterModel).map((key) => {
   //       checkList.map((v) => {
   //          if(v.filter.includes(key)) {
   //             const value = _filterModel[key].values[0] === v.value ? Boolean(true) : Boolean(false);
   //             _filterModel[key].values[0] = value;
   //          }
   //          return v;
   //       });
   //       return key;
   //    });
   //    return _filterModel;
   // }

   setDisplayFilter(filterStr) {
      this.filter = filterStr;
   }

   getCurrentAllCount() {
      return Number(this.total);
   }

   displayFilter(filterModel = {}, appliedDateFilters = {}) {
      let filterStr = "";

      if (filterModel.isEmergency) {
         const {values} = filterModel.isEmergency;
         filterStr += `EM(${ values.toString() })`;
         filterStr += " / ";
      }

      if (filterModel.readingStatus) {
         const {values} = filterModel.readingStatus;
         filterStr += `RS(${  values.toString()  })`;
         filterStr += " / ";
      }

      if (filterModel.studyStatus) {
         const {values} = filterModel.studyStatus;
         filterStr += `SS(${  values.toString()  })`;
         filterStr += " / ";
      }

      if (filterModel.isMerge) {
         const {values} = filterModel.isMerge;
         let merge = "M";
         if(values.find(v => v.includes("NM"))) merge = "NM";
         filterStr += `Merge(${ merge })`;
         // filterStr += `Merge(${ values.toString() })`;
         filterStr += " / ";
      }

      if (filterModel.isMatch) {
         const {values} = filterModel.isMatch;
         let match = "M";
         if(values.find(v => v.includes("NM"))) match = "NM";
         filterStr += `Match(${ match })`;
         // filterStr += `Match(${ values.toString() })`;
         filterStr += " / ";
      }

      if (filterModel.verify) {
         filterStr += `Assignee(${ filterModel.verify.filter })`;
         filterStr += " / ";
      }

      if (filterModel.accessionNumber) {
         filterStr += `AccessionNo(${ filterModel.accessionNumber.filter })`;
         filterStr += " / ";
      }

      if (filterModel.studyInstanceUID) {
         filterStr += `StudyInstanceUID(${ filterModel.studyInstanceUID.filter })`;
         filterStr += " / ";
      }

      if (filterModel.patientID) {
         filterStr += `ID(${ filterModel.patientID.filter })`;
         filterStr += " / ";
      }

      if (filterModel.patientName) {
         filterStr += `Name(${ filterModel.patientName.filter })`;
         filterStr += " / ";
      }

      if (filterModel.patientBirthDate) {
         filterStr += `BirthDate(${ filterModel.patientBirthDate.filter })`;
         filterStr += " / ";
      }

      if (filterModel.patientSex) {
         const {values} = filterModel.patientSex;
         filterStr += `Sex(${ values.toString() })`;
         filterStr += " / ";
      }

      if (filterModel.modality) {
         const {values} = filterModel.modality;
         filterStr += `Modality(${ values.toString() })`;
         filterStr += " / ";
      }

      if (filterModel.bodypart) {
         const {values} = filterModel.bodypart;
         filterStr += `BodyPart(${ values.toString() })`;
         filterStr += " / ";
      }

      if (filterModel.studyDescription) {
         filterStr += `StudyDesc(${ filterModel.studyDescription.filter })`;
         filterStr += " / ";
      }

      if (filterModel.requestHospital) {
         const {values} = filterModel.requestHospital;
         filterStr += `ReqHosp(${ values.toString() })`;
         filterStr += " / ";
      }

      if (filterModel.requestDtime) {
         // console.log("requestDtime", filterModel.requestDtime);
         // const {dateFrom, dateTo} = filterModel.requestDtime;
         const dateFrom = moment.unix(filterModel.requestDtime.dateFrom / 1000).format("YYYY-MM-DD");
         // const {dateTo} = filterModel.requestDtime;
         if (filterModel.requestDtime.type === "inRange" || filterModel.requestDtime.type === "inRelativeRange") {
            const dateTo = moment.unix(filterModel.requestDtime.dateTo / 1000).format("YYYY-MM-DD");

            const prefix = appliedDateFilters.requestDtime ? "*" : "";
            filterStr += `${prefix}ReqDate(${ this.formatDate(dateFrom) }-${ this.formatDate(dateTo) })`;
         } else {
            const prefix = appliedDateFilters.requestDtime && appliedDateFilters.requestDtime.isRelative ? "*" : "";
            filterStr += `${prefix}ReqDate(${ this.formatDate(dateFrom) })`;
         }
         filterStr += " / ";
      }

      if (filterModel.studyDtime) {
         const {dateFrom, dateTo} = filterModel.studyDtime;
         if (filterModel.studyDtime.type === "inRange" || filterModel.studyDtime.type === "inRelativeRange") {
            const prefix = appliedDateFilters.studyDtime ? "*" : "";
            filterStr += `${prefix}StudyDate(${ this.formatDate(dateFrom) }-${ this.formatDate(dateTo) })`;
         } else {
            const prefix = appliedDateFilters.studyDtime && appliedDateFilters.studyDtime.isRelative ? "*" : "";
            filterStr += `${prefix}StudyDate(${ this.formatDate(dateFrom) })`;
         }
         filterStr += " / ";
      }

      const v = this.removeLastSeparator(filterStr);
      this.setDisplayFilter(v);
   }

   removeLastSeparator(strng) {
      const n = strng.lastIndexOf("/");
      return strng.substring(0, n);
   }

   formatDate(d, f = "YYYYMMDD") {
      if(!d || (d === "Invalid date") || (d === "null null")) return null;
      return moment(d, "YYYY-MM-DD HH:mm:ss").format(f);
   }

   changeAppliedFilter(appliedFilter = {}) {
      const { filterModel, appliedDateFilters } = appliedFilter;
      this.displayFilter(filterModel, appliedDateFilters);
      this.getTechistCount(TechnicianUtils.conversionFilter(filterModel));
   }
}

window.customElements.define("techlist-counter", TechlistCounter);
