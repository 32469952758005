/* eslint-disable max-classes-per-file */
// COMMON UTILITY FUNCTIONS
export const isFalse = v => [null, undefined, NaN, 0, ""].some(_ => Object.is(v, _));
export const isFalsy = v => [null, undefined, "undefined", "", NaN].some(_ => Object.is(v, _));
export const isEmpty = v => ["", null, undefined].some(_ => Object.is(v, _));
export const isNotFalsy = v => !isFalsy(v);
export const isFunction = (f) => {
   // eslint-disable-next-line no-var
   var type = Object.prototype.toString.call(f).toLowerCase();
   return (type.search(/\bobject\b/g) !== -1 && type.search(/\bfunction\b/g) !== -1);
};
export const identity = v => v;
export const revoke = () => undefined;
export const truth = v => !!v;
export const isUndefined = v => Object.is(v, undefined);
export const getOrElse = (value, if_undefined) => isUndefined(value) ? if_undefined : value;
export const Noop = () => {};
export const arrayfy = value => Array.isArray(value) ? value : [value];
export const if_then_else = (condition, if_true, if_false=Noop) => condition() ? if_true() : if_false();
export const hasIn = (constraints=[], target=[]) => {
   return constraints.reduce((acc, el) => {
      return (acc === true) ? true : target.includes(el);
   }, false);
};

export const tryCatch = (f, valueIfOccurException=undefined) => {
   try {
      return [true, {value: f()}];
   }catch(e) {
      return [false, {value: valueIfOccurException, error: e}];
   }
};

// LOGIC GATES
export const NOT = a => !a;
export const OR = (a, b) => a || b;
export const AND = (a, b) => a && b;
export const NAND = (a, b) => !AND(a, b);
export const NOR = (a, b) => !OR(a, b);
export const XOR = (a, b) => AND(NAND(a, b), OR(a, b));
export const XNOR = (a, b) => !NOR(a, b);

/**
 const c = curry((a, b, c, d) => console.log(a, b, c, d));
 c(1);                   //[Function:curried]
 c(1)(2);                //[Function:curried]
 c(1)(2)(3);             //[Function:curreid]
 c(1, 2, 3);             //[Function:curreid]
 c(1, 2, 3, 4);          //1 2 3 4
 c(1, 2)(3, 4);          //1 2 3 4
 c(1, 2, 3)(4);          //1 2 3 4
 */
export const curry = (function () {
   const _curry1 = function (fn /* variadic number of args */) {
      const args = Array.from(arguments).slice(1);
      return function curried() {
         return fn.apply(this, args.concat(Array.from(arguments)));
      };
   };
   return function curryN(fn, numArgs) {
      numArgs = numArgs || fn.length;
      return function curried() {
         if (arguments.length < numArgs) {
            return numArgs - arguments.length > 0 ?
               curryN(_curry1.apply(this, [fn].concat(Array.from(arguments))),
                  numArgs - arguments.length) :
               _curry1.apply(this, [fn].concat(Array.from(arguments)));
         } else {
            return fn.apply(this, arguments);
         }
      };
   };
}());

export const reduce = (function() {
   /* eslint-disable */
   const head = iter => (iter.next()).value;
   const empty = (function* __empty(){})();
   const ensure = iter => (iter && iter[Symbol.iterator]) ? iter[Symbol.iterator]() : empty;
   const branchBy = (value, f) => (value instanceof Promise) ? value.then(f) : f(value);
   const accumulate = (acc, el, f) => (el instanceof Promise) ? el.then(v => f(acc, v), e=>Promise.reject(e)) : f(acc, el);
   const Reduce = function __reduce(f, acc, iter) {
      if(arguments.length === 1) return (...iter) => Reduce(f, ...iter);
      if(arguments.length === 2) return Reduce(f, head(iter=ensure(acc)), iter);
      iter = ensure(iter);
      let el;
      return branchBy(acc, function recursively(acc) {
         while(!(el=iter.next()).done) {
            acc = accumulate(acc, el.value, f);
            if(acc instanceof Promise) {
               return acc.then(recursively);
            }
         }
         return acc;
      });
   };
   return Reduce;
})();
export const call =  (value, f) => f(value);
export const pipe = (f1, ...funcs) => (...args) => reduce(call, f1(...args), funcs);



export class Either {
   constructor(args) {
      if (!new.target || new.target === Either) {
         throw new Error(`Do not call directly or instanciate abstract class 'Either'`);
      }
      this.value = args;
   }

   inspect(f) {
      const _inspect = `${this.constructor.name}(${this.value})`;
      Either.of(f, isFunction).fold(() => f(_inspect), () => console.log(_inspect));
      return this;
   }

   * [Symbol.iterator]() {
      yield this.value;
   }

   ['try']() {
      return this;
   }

   ['throw']() {
      return this;
   }

   ['catch']() {
      return this;
   }

   ['apply']() {
      return this;
   }

   clone() {
      return this;
   }

   tap(f = console.log) {
      f(this.value);
      return this;
   }

   of() {
      return this;
   }

   take() {
      return this;
   }

   map() {
      return this;
   }

   chain() {
      return this;
   }

   filter() {
      return this;
   }

   fold() {
      return this;
   }

   done() {
      return this;
   }

   doneIf() {
      return this;
   }

   throwIf() {
      return this;
   }
};

// tern() is short term of "ternary condition" and it will lazy-evaluate the both of if_ok()/if_not()
export const tern = (condition, if_ok, if_not) => Either.filter(condition, truth).fold(if_ok, if_not);

Either.of = (v, f, if_true = Either.right, if_false = Either.left) => {
   if (!isFunction(f)) return Either.right(v);
   return f(v) ? if_true(v) : if_false(v);
};

Either.fromNullable = v => Either.of(v, truth);

Either.right = curry(v => new Right(v));

Either.left = curry(v => new Left(v));

Either.filter = (condition, validator = truth, if_true = Either.right, if_false = Either.left) => {
   const v = condition();
   return validator(v) ? if_true(v) : if_false(v);
};

/* only '.take()' or '.fold()' handles instance of 'Done' */
Either.done = (v, functor = Either.right) => new Done(functor(v));

Either.doneIf = (f, v, functor = Either.right) => {
   return f(v) ? Either.done(v, functor) : functor(v);
};

/* 'v' must be Right or Left. If it is not of Either, Right(v) will be passed. */
Either.throw = v => new Throw((v instanceof Either) ? v : Either.right(v));

/* `throw` does not mean "throwing Error" but "to carrying Right or Left" */
Either.throwIf = (f, v, if_true = Either.right, if_false = Either.left) => new Throw(f(v) ? if_true(v) : if_false(v));

/* WARNING: when 'f' contains Promise, beware to have '.catch()' on Promise.
 * Or Be sure to use 'await' to avoid the Error "Unhandled Promise Rejection" */
Either.try = (f) => {
   try {
      return Either.right(f());
   } catch (e) {
      return Either.left(e);
   }
};

Either.is = (value) => (value instanceof Either);

class Right extends Either {

   of(v, f) {
      /* use this when you want to change the instance's value */
      if (!isFunction(f)) return Either.right(v);

      /* if 'f' is given */
      return f(v) ? Either.right(v) : Either.left(v);
   }

   clone(v) {
      return Either.right(getOrElse(v, this.value));
   }

   take() {
      return this.value;
   }

   map(f) {
      return Either.right(f(this.value));
   }

   chain(f) {
      return f(this.value);
   }

   filter(f) {
      return Either.of(this.value, f);
   }

   fold( /* for Right */f = identity /* for Left, but no_use */) {
      return f(this.value);
   }

   done(v) {
      return Either.done(isUndefined(v) ? this.value : v);
   }

   doneIf(f, v) {
      return f(this.value) ? Either.done(getOrElse(v, this.value)) : this;
   }

   /* remember that we not pass 'Error' nor 'Exception', but Right or Left.
    * if 'v' is supplied, it will be thrown to '.catch()' instead of instance's value
    * this is useful when we want to pass some other value based on condition.
    */
   throwIf(f, v, if_true = Either.right) {
      return f(this.value) ? Either.throw(if_true(getOrElse(v, this.value))) : this;
   }

   ['try'](f) {
      try {
         return Either.right(
               /* if f() returns Promise, be careful to not get 'Unhandled Promise Rejection'
                * you have to attach '.catch()' on Promise when you defined inside f().
                * This is because async function returns Promise always.
                * So this 'try' definition can not be declared as 'async' function.
                */
               f(this.value)
         );
      } catch (e) {
         return Either.left(e);
      }
   }

   ['throw'](f) {
      /* optional 'f' could be used before throwing. (e.g notifying, logging..) */
      if (isFunction(f)) f(this.value);

      /* since only Right can throw, passing value must be of Right */
      return Either.throw(Either.right(this.value));
   }

   ['apply'](o) {
      return o.map(this.value);
   }

}

// Class Left
class Left extends Either {

   constructor(args) {
      super(args);
   }

   take() {
      return this.value;
   }

   fold(_, f = identity) {
      return f(this.value);
   }
}

// 'Throw' is not Error nor Exception. It is carrier for the instance of Right to '.catch()'
// and 'Throw' does ignore all but '.catch()'. ONLY Right can throw.
// NOTE: The return value must be of Right or the caller of '.throw()'
class Throw extends Either {
   constructor(args) {
      if (!(args instanceof Either)) throw new Error(`Throw(): argument must be of Either`);
      super(args);
   }

   ['catch'](handler) {
      /* WARNING: Be careful in handler. If 'handler' is omitted, there will be no problem.
       * but if it is given, it must return Right or Left. Otherwiase, chain will be broken. */
      return isFunction(handler) ? handler(this.value) : this.value;
   }

   tap(f = console.log) {
      (function recur(obj) {
         return (obj && (obj instanceof Either) && (obj.value instanceof Either)) ? recur(obj.value) : f(obj.value);
      })(this);
      return this;
   }

   inspect(f) {
      const _inspect = (function recur(obj) {
         return (obj instanceof Either) ? `${obj.constructor.name}(${recur(obj.value)})` : `${obj}`;
      })(this);
      Either.of(f, isFunction).fold(() => f(_inspect), () => console.log(_inspect));
      return this;
   }

}

/* NOTE: Done is another carrier for Right and Left.
 * Only instance of Done will ignore all method but '.take() & .fold()' */
class Done extends Either {

   constructor(args) {
      if (!(args instanceof Either)) throw new Error(`Done(): argument must be of Either`);
      super(args);

   }

   tap(f = console.log) {
      (function recur(obj) {
         return (obj && (obj instanceof Either) && (obj.value instanceof Either)) ? recur(obj.value) : f(obj.value);
      })(this);
      return this;
   }

   fold(f = identity, g = identity) {
      return (function recur(obj) {
         return (obj && (obj instanceof Either) && (obj.value instanceof Either)) ? recur(obj.value) : obj.fold(f, g);
      })(this);
   }

   inspect(f) {
      const _inspect = (function recur(obj) {
         return (obj instanceof Either) ? `${obj.constructor.name}(${recur(obj.value)})` : `${obj}`;
      })(this);
      Either.of(f, isFunction).fold(() => f(_inspect), () => console.log(_inspect));
      return this;
   }

   take() {
      return (function recur(obj) {
         return (obj && (obj instanceof Either) && (obj.value instanceof Either)) ? recur(obj.value) : obj.value;
      })(this);
   }
}

