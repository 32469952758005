/* eslint-disable    import/prefer-default-export */
/* eslint-disable    import/extensions */
/* eslint-disable    no-use-before-define */
// eslint-disable-next-line max-classes-per-file
import { __any__, __off_editing_focus__, __on_editing_focus__, __timeout__, __fulfilled__, __rejected__, __canceled__, __failed__, __single_click__, __dialog_opened__, __dialog_closed__ } from "./fleader.mjs";
import {Noop} from "./FP.mjs";

export const SyncedRules = ["logout", "opinionSave", "opinionApprove", "opinionClear", "next", "prev"];

export default class Rule {
   constructor({from, functionName}) {
      this.rules = [];
      this.transit_state = `${from}.${functionName}.pending`;
   }

   static create({functionName, app, shortcut, from, to, displayedName, callback, cleanupHandler}) {
      let type;
      switch(functionName) {
      case "logout":
         type = new logoutRule({functionName, app, shortcut, from, to, displayedName, callback, cleanupHandler});
         break;
      case "opinionSave":
      case "opinionApprove":
      case "opinionClear":
      case "prev":
      case "next":
         type = new WaitAndStackingInputs({functionName, app, shortcut, from, to, displayedName, callback, cleanupHandler});
         break;
      default:
         type = new defaultRule({functionName, app, shortcut, from, to, displayedName, callback, cleanupHandler});
      }
      return type.rules;
   }
}

// 로그아웃 처리를 공통 'dialog' 상태룰로 처리하지 않은 이유는, 아래 3)번 룰 때문이다
class logoutRule extends Rule {
   constructor({functionName, app, shortcut, from, to, callback, cleanupHandler}) {
      super({from, functionName});

      // 1) 로그아웃 명령이 호출되면 상태를 중간단계로 전환한다
      this.rules.push([shortcut, from, this.transit_state, [
         ()=>app.focusOut(),
         callback,
         ()=>app.await(__fulfilled__)
      ]]);

      // 2) 기다리는 가운데 들어오는 모든 이벤트는 저장하지 않는다!
      this.rules.push([[__any__, "esc"], this.transit_state, this.transit_state, () => false]);

      // 3) 다이얼로그가 열리면 중간단계에서 다이얼로그 상태로 전환한다
      this.rules.push([__dialog_opened__, this.transit_state, "logout.dialog", [
         ()=>app.stackingUp()
      ]]);

      // 4) 이 때 들어오는 모든 것도 저장하지 않는다
      this.rules.push([__any__, "logout.dialog", "logout.dialog", () => false]);

      // 5) 'enter' 입력은 포커스를 다이얼로그 박스로 옮길 뿐이다
      this.rules.push([["enter@keypress", "enter@keyup"], "logout.dialog", "logout.dialog", () => app.focusOnDialog()]);

      // 6) 마우스로 TEXTAREA에 클릭해서 포커스를 받은 신호가 오면, 즉시 상태를 전환한다
      this.rules.push([__on_editing_focus__, "logout.dialog", "on_editing", [
         // 콜백을 실행하다 포커스가 빠질 수 있으므로 main thread에서 detattch 한다
         () => queueMicrotask(()=>{
            app.rejected();
            app.stackingDown();
            cleanupHandler();
         })
      ]]);

      // 7) single_click 이 들어왔는데 모달 다이얼로그가 열린 것이면 무시한다
      this.rules.push([__single_click__,            "logout.dialog",               "logout.dialog",                  [
         () => {
            if(!app.ctx.is_modal) {
               app.rejected();
               app.stackingDown();
               cleanupHandler();
               app.setCurrentState(to);
               if(app.current_state === "on_editing") app.moveCursorToPreviousFocusedElement();
            }
         }
      ]]);

      // 8) 다이얼로그 닫음 ("esc"에 반응해서는 안된다. 오직 다이얼로그가 닫혔다는 신호에 반응해야한다)
      this.rules.push([[__canceled__, __dialog_closed__], "logout.dialog",  to, [
         ()=>app.rejected(),
         ()=>app.stackingDown(),
         cleanupHandler
      ]]);
   }
}

// opinionSave, opinionAprrove, next, prev
class WaitAndStackingInputs extends Rule {
   constructor({functionName, app, shortcut, from, to, /* displayedName, */callback/* ,  cleanupHandler */}) {
      super({from, functionName});

      // 1) 단축키로 호출받으면 중간단계 상태에서 기다림 (ie. "ready" --> "ready.next.pending")
      // 모든 명령 도중에 Dialog가 열릴 수 있으므로 __timeout__을 설정하면 안됨!!!
      this.rules.push([shortcut, from, to, [
         ()=>app.focusOut(),
         // ()=>app.await(__fulfilled__),
         callback /* callback 안에서 cleanupHandler()를 호출한다 */
      ]]);

      // 2) 입력받는 도중의 모든 이벤트를 큐에 저장 (fulfilled를 받았을 때 중간에 저장된 이벤트를 순서대로 적용)
      // (ie. "ready.next.pending" --> "ready.next.pending")
      // this.rules.push([__any__, this.transit_state, this.transit_state, () => true]);

      // 3) 몇가지 신호는 저장하지 않는다 (ie. "ready.next.pending" --> "ready.next.pending")
      // this.rules.push([[__on_editing_focus__, __off_editing_focus__, __single_click__], this.transit_state, this.transit_state, Noop]);

      // 4) window_closed 가 아닌 cancel 신호를 명시적으로 받음 (ie. "ready.next.pending" --> "ready")
      // this.rules.push(["esc", this.transit_state,  to, [
      //    ()=>app.rejected(),
      //    ()=>app.popupNotice(`calling ${displayedName} canceled`, true)
      // ]]);

      // (ie. "ready.next.pending" --> "ready")
      // this.rules.push([__canceled__, this.transit_state,  to, [
      //    ()=>app.rejected(),
      //    ()=>(app.ctx.canceled) && app.popupNotice(app.ctx.canceled.message, true)
      // ]]);

      // 5) fulfilled  (ie. ready.next.pending --> ready)
      // this.rules.push([__fulfilled__, this.transit_state, to, [
      //    ()=>app.fulfilled()
      // ]]);

      // 6) rejected  (ie. on_editing --> ready)
      // this.rules.push([__rejected__, this.transit_state, to, [
      //    ()=>app.rejected(),
      //    ()=>(app.ctx.rejected) && app.popupNotice(app.ctx.rejected.message, true)
      // ]]);

      // 7) failed (ie. "ready.next.pending" --> "ready")
      // this.rules.push([__failed__, this.transit_state, to, [
      //    ()=>app.rejected(),
      //    ()=>(app.ctx.failed) && app.popupNotice(app.ctx.failed.message, true)
      // ]]);

      // --------------------------------------- DIALOG:START ---------------------------------------
      // 8) 다이얼로그가 열리면 중간단계에서 다이얼로그 상태로 전환한다
      // this.rules.push([__dialog_opened__, this.transit_state, `${this.transit_state}.dialog`, [
      //    ()=>app.stackingUp() /* 돌아갈 상태를 저장 */
      // ]]);

      // 9) 다이얼로그가 열린 상태에서 들어오는 이벤트는 저장하지 않는다
      // this.rules.push([[__any__, __single_click__, "esc"], `${this.transit_state}.dialog`, `${this.transit_state}.dialog`, () => false]);

      // 10) 'enter' 입력은 포커스를 다이얼로그 박스로 옮길 뿐이다
      // (모든 다이얼로그에서 'enter' 키 이벤트에 대해 전파를 막아뒀기 때문에 'enter'가 입력된다면 필름박스윈도우에서 오는 이벤트일것이다)
      // this.rules.push([["enter@keypress", "enter@keyup"], `${this.transit_state}.dialog`,`${this.transit_state}.dialog`, () => app.focusOnDialog()]);

      // 11) 취소: 다이얼로그 닫음
      // WARN "esc"는 무시하지 않으면 __dialog_closed__ 신호후에 ESC 이벤트가 차례로 적용되는데,
      // 다이얼로그가 두개 오픈된 상황에서는 연달아 전파되는 ESC때문에 두개 모두 닫힐 것이기 때문이다
      // this.rules.push([[__canceled__, __dialog_closed__], `${this.transit_state}.dialog`, to, [
      //    ()=>app.rejected()
      // ]]);

      // 12) __dialog_closed__ 신호에 의해 다이얼로그 닫음 (태스크가 종료된 것은 아님!)
      // 오직 __fulfilled__ 신호에 의해서만 닫혀야 한다.
      // this.rules.push([__dialog_closed__, `${this.transit_state}.dialog`, this.transit_state, () => false]);

      // 13) 두번째 다이얼로그가 열리는 것을 대비
      // this.rules.push([__dialog_opened__, `${this.transit_state}.dialog`, `${this.transit_state}.dialog.dialog`, ()=>false]);
      // this.rules.push([__dialog_closed__, `${this.transit_state}.dialog.dialog`, `${this.transit_state}.dialog`, ()=>false]);
   }
}


class defaultRule extends Rule {
   constructor({functionName, app, shortcut, from, to, displayedName, callback/* , cleanupHandler */}) {
      super({from, functionName});

      // 1) 단축키로 호출받으면 중간단계 상태에서 기다림 (3초)
      this.rules.push([shortcut, from, this.transit_state, [
         ()=>app.focusOut(),
         callback,
         ()=>app.await(__fulfilled__),
         ()=>app.waitingConsecutiveKey(/* default timeout */3000, __timeout__)
      ]]);

      // 2) 입력받는 도중의 모든 이벤트를 큐에 하지 않는다
      this.rules.push([[__on_editing_focus__, __off_editing_focus__], this.transit_state, this.transit_state, Noop]);
      this.rules.push([__any__, this.transit_state, this.transit_state, () => false]);

      // 3) timeout
      this.rules.push([__timeout__, this.transit_state,  to, [
         ()=>app.rejected(),
         ()=>app.popupNotice("Timeout (3 secs) for waiting response", true),
      ]]);

      // 4) cancel
      this.rules.push(["esc", this.transit_state,  to, [
         ()=>app.rejected(),
         ()=>app.popupNotice(`calling '${displayedName}' canceled`, true) /* 명시적으로 취소한 것이므로 지정된 문자열을 표시한다 */
      ]]);
      this.rules.push([__canceled__, this.transit_state,  to, [
         ()=>app.rejected(),
         ()=>(app.ctx.canceled) && app.popupNotice(app.ctx.canceled.message, true)
      ]]);

      // 5) fulfilled  (ie. on_editing --> ready)
      this.rules.push([__fulfilled__, this.transit_state, to, [
         ()=>app.fulfilled(),
      ]]);

      // 6) rejected  (ie. on_editing --> ready)
      this.rules.push([__rejected__, this.transit_state, to, [
         ()=>app.rejected(),
         ()=>(app.ctx.rejected) && app.popupNotice(app.ctx.rejected.message, true)
      ]]);

      // 7) failed
      this.rules.push([__failed__, this.transit_state, to, [
         ()=>app.rejected(),
         ()=>(app.ctx.failed) && app.popupNotice(app.ctx.failed.message, true)
      ]]);
   }
}


