import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/paper-tooltip/paper-tooltip";
import moment from "moment-timezone";
import GridUtils from "./utils/grid-utils";
import mixinCommons from "./common-mixin";
import store from "./redux/store";
import RadiologyUtils from "./utils/radiology-utils";
import {QueryKey} from "./redux/reducers/query";
/**
 * `worklist-counter`
 *
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
class WorklistCounter extends mixinCommons(PolymerElement) {
   static get template() {
      return html`
         <style>
            :host {
               display: block;
               width: 100%;
               height: 25px;
            }

            .container {
               display: flex;
               height: 100%;
            }

            .font{
               color: #aaaaaa;
               width: 250px;
               display: flex;
               justify-content: flex-end;
               font-size: 13px;
            }

            .filter {
               width: calc(100% - 150px);
               color: #aaaaaa;
               font-size: 13px;
               overflow: hidden;
               text-overflow: ellipsis;
               white-space: nowrap;
            }

            .filter:hover {
               cursor: help;
            }

            .cnt {
               color: #e46159;
               font-weight: bold;
            }

            paper-tooltip  {
                --paper-tooltip-duration-in: 0;
                --paper-tooltip-duration-out: 0;
            }
         </style>

         <div class="container" id="container">
            <div id="filter" class="filter">
               {{t("label.filter")}}[[filter]]
            </div>
            <paper-tooltip for="filter" position="right">[[filter]]</paper-tooltip>
            <template is="dom-if" if="{{showCount}}">
               <div class="font">{{t("label.waitCount")}}<span class="cnt">[[WAIT]]</span> &nbsp; {{t("label.approveCount")}}<span class="cnt">[[APPROVE]]</span> &nbsp; {{t("label.tempCount")}} <span class="cnt">[[TEMP]]</span> &nbsp; {{t("label.transcribeCount")}}<span class="cnt">[[TRANSCRIBE]]</span></div>
            </template>
         </div>
      `;
   }

   static get properties() {
      return {
         filter: {
            type: String
         },
         WAIT: {
            type: Number,
            value: 0
         },
         APPROVE: {
            type: Number,
            value: 0
         },
         TRANSCRIBE: {
            type : Number,
            value: 0
         },
         TEMP: {
            type : Number,
            value: 0
         },
         showCount: {
            type: Boolean,
            value: true
         },
         fetchController: {
            type: Object
         },
         appliedFilter: {
            type: Object,
            observer: "changeAppliedFilter"
         },
         count: {
            type: Array,
            value: [],
            observer: "changeCount"
         }
      };
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         this.appliedFilter = store.getState().worklist.appliedFilter;
         const countResult = store.getState().query[QueryKey.GET_COUNT];
         if(countResult?.result) this.count = countResult?.result;
      });

      // // NOTE: 2020/09/04 By Jacob - 판독문 approve 완료되면 approve count를 증가시킨다.
      // window.addEventListener("addApproveCountEvent",({detail}) => {
      //    this.APPROVE = Number(this.APPROVE) + Number(detail);
      // });
      //
      // // NOTE: 2020/09/04 By Jacob - 판독문 save 완료되면 temp(H,O) count를 증가시킨다.
      // window.addEventListener("addTempCountEvent",({detail}) => {
      //    this.TEMP = Number(this.TEMP) + Number(detail);
      // });
      //
      // window.addEventListener("addTranscribeCountEvent",({detail}) => {
      //    this.TRANSCRIBE = Number(this.TRANSCRIBE) + Number(detail);
      // });

      // window.addEventListener("recalculateCountEvent",({detail}) => {
      //    this.WAIT = Number(this.WAIT) + Number(detail["1W"]);
      //    this.TEMP = Number(this.TEMP) + Number(detail["2T"]);
      //    this.TRANSCRIBE = Number(this.TRANSCRIBE) + Number(detail["2DT"]);
      //    this.APPROVE = Number(this.APPROVE) + Number(detail["3A"]);
      //
      //    window.postMessage({event: "POPUP_CHANGE_WORKLIST_COUNT"} , document.location.href);
      // });
   }

   getWorklistCount(filterModel) {
      return new Promise((resolve) => {
         if(Object.keys(filterModel).length === 0) {
            this.showCount = false;
            // window.postMessage({ event: "POPUP_IS_HIDE_WORKLIST_COUNT" }, document.location.href);
            // resolve();
         } else {
            const _filterModel = filterModel;

            if (_filterModel.isEmergency) {
               const sortedArray = _filterModel.isEmergency.values.sort();
               if(GridUtils.compareArray(sortedArray, ["N"])) {
                  _filterModel.isEmergency.values = ["false"];
                  _filterModel.isCVR = {filterType: "text", type: "contains", filter: Boolean(false)};
               }
               else if(GridUtils.compareArray(sortedArray, ["E"])) {
                  _filterModel.isEmergency.values = ["true"];
                  _filterModel.isCVR = {filterType: "text", type: "contains", filter: Boolean(false)};
               }
               else if(GridUtils.compareArray(sortedArray, ["C"])) {
                  _filterModel.isEmergency = null;
                  _filterModel.isCVR = {filterType: "text", type: "contains", filter: Boolean(true)};
               }
               else if(GridUtils.compareArray(sortedArray, ["N", "E"])) {
                  _filterModel.isEmergency = null;
                  _filterModel.isCVR = {filterType: "text", type: "contains", filter: Boolean(false)};
               }
               else if(GridUtils.compareArray(sortedArray, ["E", "C"])) {
                  _filterModel.isEmergency.values = ["true"];
                  _filterModel.isCVR = {filterType: "text", type: "contains", filter: Boolean(true)};
               }
               else if(GridUtils.compareArray(sortedArray, ["N", "C"])) {
                  _filterModel.isEmergency.values = ["false"];
                  _filterModel.isCVR = {filterType: "text", type: "contains", filter: Boolean(true)};
               }
               else if(GridUtils.compareArray(sortedArray, ["N", "E", "C"])) {
                  _filterModel.isEmergency = null;
                  _filterModel.isCVR = {filterType: "text", type: "contains", filter: Boolean(true)};
               }
            }

            // #17773 [HPACS > 전수령 원장님] 워크리스트 하단 W 값이 - 로 표기 되는 오류
            if(this.fetchController) {
               this.fetchController.abort();
            }

            this.fetchController = new AbortController();
            const { signal } = this.fetchController;
            fetch("/api/exchange/worklist/count", {
               signal,
               method: "POST",
               headers: {
                  "Authorization": localStorage.getItem("jwt"),
                  "Content-Type": "application/json"
               },
               body: JSON.stringify(_filterModel)
            }).then((response) => {
               this.showCount = true;
               // window.postMessage({ event: "POPUP_IS_HIDE_WORKLIST_COUNT" }, document.location.href);
               if (response.ok) {
                  response.json().then((httpResponse) => {
                     store.dispatch({ type: QueryKey.GET_COUNT, payload: {result: httpResponse} });

                     if(httpResponse.length > 0){
                        resolve(httpResponse);
                     }
                     resolve();
                  });
               } else {
                  resolve();
               }
            });
         }
      });
   }

   setCount(count){
      return new Promise((resolve) => {
         this.WAIT = 0;
         this.APPROVE = 0;
         this.TEMP = 0;
         this.TRANSCRIBE = 0;
         if (count) {
            count.map(({readingStatus, totalCnt}) => {
               if (readingStatus === "1W") this.WAIT = totalCnt;
               if (readingStatus === "3A") this.APPROVE = totalCnt;
               if (readingStatus === "2T") this.TEMP = totalCnt;
               if (readingStatus === "2DT") this.TRANSCRIBE = totalCnt;
               return totalCnt;
            });
         }
         resolve();
      });
   }

   getCurrentAllCount() {
      return Number(this.WAIT) + Number(this.APPROVE) + Number(this.TEMP) + Number(this.TRANSCRIBE);
   }

   setDisplayFilter(filterStr) {
      this.filter = filterStr;
   }

   displayFilter(filterModel = {}, appliedDateFilters = {}) {
      let filterStr = "";

      if (filterModel.isEmergency) {
         const {values} = filterModel.isEmergency;
         filterStr += `${ this.t("label.gridHeader.name.em") }(${  values.toString()  })`;
         filterStr += " / ";
      }

      if (filterModel.readingStatus) {
         const {values} = filterModel.readingStatus;
         filterStr += `${ this.t("label.gridHeader.name.rs") }(${  values.toString()  })`;
         filterStr += " / ";
      }

      if (filterModel.accessionNumber) {
         filterStr += `${ this.t("label.gridHeader.name.accessionNo") }(${ filterModel.accessionNumber.filter })`;
         filterStr += " / ";
      }

      if (filterModel.studyStatus) {
         const {values} = filterModel.studyStatus;
         filterStr += `${ this.t("label.gridHeader.name.ss") }(${  values.toString()  })`;
         filterStr += " / ";
      }

      if (filterModel.teleStatus) {
         const {values} = filterModel.teleStatus;
         filterStr += `${ this.t("label.gridHeader.name.ts") }(${  values.toString()  })`;
         filterStr += " / ";
      }

      if (filterModel.patientID) {
         filterStr += `${ this.t("label.gridHeader.name.id") }(${  filterModel.patientID.filter  })`;
         filterStr += " / ";
      }

      if (filterModel.patientName) {
         filterStr += `${ this.t("label.gridHeader.name.name") }(${  filterModel.patientName.filter  })`;
         filterStr += " / ";
      }

      if (filterModel.patientBirthDate) {
         filterStr += `${ this.t("label.gridHeader.name.birthDate") }(${  filterModel.patientBirthDate.filter  })`;
         filterStr += " / ";
      }

      if (filterModel.patientSex) {
         const {values} = filterModel.patientSex;
         filterStr += `${ this.t("label.gridHeader.name.sex") }(${  values.toString()  })`;
         filterStr += " / ";
      }

      if (filterModel.modality) {
         const {values} = filterModel.modality;
         filterStr += `${ this.t("label.gridHeader.name.modality") }(${values.toString()})`;
         filterStr += " / ";
      }

      if (filterModel.bodypart) {
         const {values} = filterModel.bodypart;
         filterStr += `${ this.t("label.gridHeader.name.bodyPart") }(${values.toString()})`;
         filterStr += " / ";
      }

      if (filterModel.studyDescription) {
         filterStr += `${ this.t("label.gridHeader.name.studyDesc") }(${filterModel.studyDescription.filter})`;
         filterStr += " / ";
      }

      if (filterModel.requestHospital) {
         const {values} = filterModel.requestHospital;
         filterStr += `${ this.t("label.gridHeader.name.reqHosp") }(${  values.toString()  })`;
         filterStr += " / ";
      }

      if (filterModel.readingDoctor) {
         filterStr += `${ this.t("label.gridHeader.name.repDoc") }(${  filterModel.readingDoctor.filter  })`;
         filterStr += " / ";
      }

      if (filterModel.requestDtime) {
         const {dateFrom} = filterModel.requestDtime;
         const {dateTo} = filterModel.requestDtime;
         if (filterModel.requestDtime.type === "inRange" || filterModel.requestDtime.type === "inRelativeRange") {
            const prefix = appliedDateFilters.requestDtime ? "*" : "";
            filterStr += `${prefix}${ this.t("label.gridHeader.name.reqDate") }(${this.formatDate(dateFrom)}-${this.formatDate(dateTo)})`;
         } else {
            const prefix = appliedDateFilters.requestDtime && appliedDateFilters.requestDtime.isRelative ? "*" : "";
            filterStr += `${prefix}${ this.t("label.gridHeader.name.reqDate") }(${this.formatDate(dateFrom)})`;
         }
         filterStr += " / ";
      }

      if (filterModel.studyDtime) {
         const {dateFrom, dateTo} = filterModel.studyDtime;
         if (filterModel.studyDtime.type === "inRange" || filterModel.studyDtime.type === "inRelativeRange") {
            const prefix = appliedDateFilters.studyDtime ? "*" : "";
            filterStr += `${prefix}${ this.t("label.gridHeader.name.studyDate") }(${this.formatDate(dateFrom)}-${this.formatDate(dateTo)})`;
         } else {
            const prefix = appliedDateFilters.studyDtime && appliedDateFilters.studyDtime.isRelative ? "*" : "";
            filterStr += `${ this.t("label.gridHeader.name.studyDate") }(${this.formatDate(dateFrom)})`;
         }
         filterStr += " / ";
      }

      if (filterModel.checkupHospital) {
         const {values} = filterModel.checkupHospital;
         filterStr += `${ this.t("label.gridHeader.name.acqHosp") }(${  values.toString()  })`;
         filterStr += " / ";
      }

      if (filterModel.checkupDepartment) {
         const {values} = filterModel.checkupDepartment;
         filterStr += `${ this.t("label.gridHeader.name.reqDept") }(${  values.toString()  })`;
         filterStr += " / ";
      }

      if (filterModel.checkupDoctor) {
         filterStr += `${ this.t("label.gridHeader.name.reqDoc") }(${  filterModel.checkupDoctor.filter  })`;
         filterStr += " / ";
      }

      if (filterModel.checkupTechnician) {
         filterStr += `${ this.t("label.gridHeader.name.tech") }(${  filterModel.checkupTechnician.filter  })`;
         filterStr += " / ";
      }

      if (filterModel.verify) {
         filterStr += `${ this.t("label.gridHeader.name.verifyTo") }(${  filterModel.verify.filter  })`;
         filterStr += " / ";
      }

      if (filterModel.serviceName) {
         const {values} = filterModel.serviceName;
         filterStr += `${ this.t("label.gridHeader.name.service") }(${  values.toString()  })`;
         filterStr += " / ";
      }

      const v = this.removeLastSeparator(filterStr);
      this.setDisplayFilter(v);
   }

   removeLastSeparator(strng) {
      const n = strng.lastIndexOf("/");
      const a = strng.substring(0, n);
      return a;
   }

   formatDate(d, f = "YYYYMMDD") {
      if(!d || (d === "Invalid date") || (d === "null null")) return null;
      return moment(d, "YYYY-MM-DD HH:mm:ss").format(f);
   }

   changeAppliedFilter(appliedFilter = {}) {
      const { filterModel, appliedDateFilters } = appliedFilter;
      this.displayFilter(filterModel, appliedDateFilters);
      this.setWorklistCount(RadiologyUtils.convertParmas(filterModel));
   }

   setWorklistCount(filterModel) {
      Promise.resolve()
         .then(() => this.getWorklistCount(filterModel))
         // .then(r => this.setCount(r))
         // .then(() => window.postMessage({event: "POPUP_CHANGE_WORKLIST_COUNT"} , document.location.href))
         .catch(() => this.setCount());
   }

   changeCount(newVal) {
      this.setCount(newVal);
   }

}

window.customElements.define("worklist-counter", WorklistCounter);
