export default class FilmboxUtils {

   /**
    * Filmbox 호출시 사용되는 Related tab code
    * @param relatedTabCode
    */
   static convertRelatedTabCode(relatedTabCode) {
      switch (relatedTabCode) {
      case 0:
         return "A"; // All
      case 1:
         return "M"; // Modality
      case 2:
         return "B"; // BodyPart
      case 3:
         return "MB"; // Modality & BodyPart
      case 4:
         return "SD"; // Study Description
      default:
         return relatedTabCode;
      }
   }
}
