/* eslint-disable import/extensions,no-param-reassign */
/* eslint-disable    no-useless-escape */
/* eslint-disable    no-restricted-syntax */
import $ from "cash-dom";
import Pathway from "./pathway.mjs";
import {AND, isUndefined, arrayfy, Either, if_then_else, identity, curry, pipe, Noop, hasIn, isFalse, isFalsy, tryCatch} from "./FP.mjs";
import {KeyboardEventTriggerObject, makeKeySymbolFromString, stopPropagation, preventDefault, getKeyInfoFromEvent, isWord, isLowerWord, isUpperWord, isNumber, isSpecialChar, isStackableKey } from "./keyboardevent.mjs";
import KBDCommon from "./kbdevent-common.mjs";
import {hasSequenceKey, isSequenceKey} from "./sequence-key.mjs";
import { useTransducer, useSharable } from "./transducer.mjs";

export const __hb__                = Symbol.for("__hb__");
export const __backspace__         = "backspace";
export const __space__             = "space";
export const __esc__               = "esc";
export const __tab__               = "tab";
export const __keypress_f__        = "f";
export const __keypress_w__        = "w";
export const __enter__             = "enter";
export const __arrow_down__        = "down";
export const __arrow_up__          = "up";
export const __arrow_left__        = "left";
export const __arrow_right__       = "right";
export const __shift__             = "shift";
export const __ctrl__              = "ctrl";
export const __option__            = "alt";
export const __meta__              = "meta";
export const __not_found__         = Symbol.for("__not_found__");
export const __single_click__      = Symbol.for("__single_click__");
export const __dbl_click__         = Symbol.for("__dbl_click__");
export const __ok__                = Symbol.for("__ok__");
export const __duplicated__        = Symbol.for("__duplicated__");
export const __leader__            = __space__;
export const __uncaught__          = Symbol.for("__uncaught__");
export const __any__               = __uncaught__;
export const __on_editing_focus__  = Symbol.for("on-editing-focus");
export const __off_editing_focus__ = Symbol.for("off-editing-focus");
export const __word__              = Symbol.for("alphabet word");
export const __lower_word__        = Symbol.for("alphabet word lowercase");
export const __upper_word__        = Symbol.for("alphabet word UPPERCASE");
export const __number__            = Symbol.for("0-9 number");
export const __hangul__            = Symbol.for("한글");
export const __special_char__      = Symbol.for("!(\w | \W | \d)");
export const __modifier_key__      = Symbol.for("ctrl|alt|meta|shift");
export const __timeout__           = Symbol.for("timeout");
export const __no_more__           = Symbol.for("__no_more__");
export const __flush__             = Symbol.for("__flush__");
export const __abandon__           = Symbol.for("__abandon__");
export const __fulfilled__         = Symbol.for("fulfilled");
export const __rejected__          = Symbol.for("rejected");
export const __canceled__          = Symbol.for("canceled");
export const __failed__            = Symbol.for("failed");
export const __dialog_opened__     = Symbol.for("dialog-opened");
export const __dialog_closed__     = Symbol.for("dialog-closed");
export const backspace_symbol      = Symbol.for("{key:backspace,modifiers:[],action:keyup}");
export const __empty_keystack__    = Symbol.for("empty-keystack");
export const __begin__             = Symbol.for("__begin__");
export const __commit__            = Symbol.for("__commit__");
export const __rollback__          = Symbol.for("__rollback__");
export const __next__              = Symbol.for("__next__");
export const __done__              = Symbol.for("__done__");
export const __macro__             = Symbol.for("__macro__");
export const __macro_timeout__     = Symbol.for("__macro_timeout__");
export const __macro_abort__       = Symbol.for("__macro_abort__");
export const __macro_waiting__     = Symbol.for("__macro_waiting__");
export const enter_at_keydown_symbol   = Symbol.for("{key:enter,modifiers:[],action:keydown}");
export const enter_at_keyup_symbol     = Symbol.for("{key:enter,modifiers:[],action:keyup}");
export const enter_at_keypress_symbol  = Symbol.for("{key:enter,modifiers:[],action:keypress}");

const console_log = (...args) => window.__debug__ && console.log(...args);
const isModifier =  key => (key === "shift" || key === "ctrl" || key === "alt" || key === "meta");

// __any__ 보다 앞서 검사해야할 그룹검색 키
// ↓ 호출 순서를 바꾸지 말 것!
const checklist_keys = [
   {pattern:__modifier_key__, filter:isModifier},
   {pattern:__lower_word__,   filter:isLowerWord},
   {pattern:__upper_word__,   filter:isUpperWord},
   {pattern:__word__,         filter:isWord},
   {pattern:__number__,       filter:isNumber},
   {pattern:__hangul__,       filter:KBDCommon.isHangul},
   {pattern:__special_char__, filter:isSpecialChar}
];

const abortIf = (condition, message="Abort!") => {
   if (condition === true) {
      throw new Error(message);
   }
};

const isHTTPResponseReady = o => AND(o.readyState === 4, o.status === 200);
const loadJSON = (url) => {
   return new Promise((resolve) => {
      if (isUndefined(url)) throw new Error("required url to call loadJSON()");
      const o = new XMLHttpRequest();
      o.overrideMimeType("application/json");
      o.timeout = 10000; /* 10 seconds */
      o.open("GET", url, true);
      o.ontimeout = () => { throw new Error(`loadJSON()@FLeader -- Timeout: could not fetch ${url}`); };
      o.onreadystatechange = () => {
         if (isHTTPResponseReady(o)) {
            resolve(o.response);
         }
      };
      o.send(null);
   });
};

const _addCSSClass = ({className=""}, token) => {
   const c = String(className).split(" ");
   return if_then_else(() => c.find(cls => cls === token), () => className, () => `${className} ${token}`);
};

const _removeCSSClass = ({className=""}, token) => {
   return String(className).replace(token, "").replace(/\s+/, " ");
};

// <div id="selected-command-history-container" class="class-advance-history">
//    <li class="class-function class-function-selected">
//       <div class="class-advance-function-title" data-displayed-name="Setting">Setting</div>
//       <div class="class-advance-function-shortcut" style="">&nbsp;(t)</div>
//    </li>
// </div>
const _getAttributeName = curry((name, el) => el.getAttribute(name));
const _getDisplayedName = _getAttributeName("data-displayed-name");
const _getFunctionName  = _getAttributeName("data-function-name");
const _getShortcutKey   = _getAttributeName("data-shortcut-key");
const _query = (el, qry) => el.querySelector(qry);

// for Advanced Mode
const _getFunctionTitleElement = curry(li => _query(li, ".class-advance-function-title"));
const _getAttributeValue_DisplayedName = pipe(_getFunctionTitleElement, _getDisplayedName);
const _getAttributeValue_FunctionName = pipe(_getFunctionTitleElement, _getFunctionName);

// for Basic Mode
const _getShortcutElement = curry(li => _query(li, ".class-shortcut"));
const _getShortcutKeyTextElement = curry(li => _query(li, ".class-shortcut-key-text"));
const _getNewShortcutInputElement = curry(li => _query(li, ".class-function-shortcut-input"));

const default_property = {
   version: "1.6.37",
   leader: __space__,  // leader key (default:space)
   delayId:null,
   keyStack: [],
   searchStack: [], // 사용자가 입력한 검색어를 담아둘 공간
   popupDelay: 0,
   maxCommands: 0, // total displayed commands in AdvancedMode
   cursor: 0, // cursor pointer in AdvancedMode
   selectedEl: "", // selected <li> to change shortcut-key in BasicMode
   selectedCategory: "", // selected category when to start changing shortcut
   LRU:[], // latest selected commands (5개)
   menuJSON: null, // 테스트할 때의 JSON 혹은 데이터베이스로부터의 응답객체
   backStack: [], // basic --> filmbox|worklist --> changingShortcut --> [ESC] --> 돌아갈 직전 상태저장용
   addStateHandler: Noop, // JSON 데이터를 읽어 룰(Rule)로 추가하는 사용자 지정 함수
   previousFKey: null, // BasicMode에서 fkey 변경전, 원래의 값을 저장하는 용도. 변경과정이 끝나면 반드시 null 이어야 한다.
   seqKeyTimeoutId: null, // SequenceKey 입력 도중 3초 이상 쉬면 ESC를 눌러서 취소 시킴
   groupKeys: new Map(), // 런타임에서 그룹키 처리에 시간을 최대한 줄이기 위한 캐쉬
   groupKeysForCurrentState: [], // 상태가 바뀐 직후 onChangeState() 함수에 의해 업데이트 된다
   blocker: null,  // 설정될 경우 FLeader에 전달되는 이벤트/신호가 모두 deferred_q에 쌓인다
   deferred_Q: [],
   remoteCtx: [], // 함수의 인자로 전달하지 못해 객체를 통해 전달: i.e) window.emit(Symbol.for("rejected"), /* remoteCtx */ {error: true, message, ... })
   linkedElements: {}, // HPACS 내의 HTML Elements에 직접 접근하기 힘들므로 각 클래스 ready()에서 필요한 요소를 미리 받아두는 용도
   reportPopup: null, // ReportPopup의 window를 담아두는 용도
   reportPopupElements: {}, // ReportPopup의 Elements 받아두는 용도 열렸을때 저장 닫았을때 제거
   do_not_stackup_trigger: false, // 특정 상태 사이에서 들어오는 키는 쌓지 않게하기위해
   disabled_event_type: {}, // object-of-object ie { "state": { "keyup": true, .. }} 각상태에서 금지하는 event-type
   dialog_objects: [], // dialog_opened 신호를 보내올 때 쌓을 버퍼 (하나 이상의 다이얼로그가 열릴 수 있기 때문)
   macro_timerId: null,
   macro_maximum_execution_time_limit: 5000,
   macro_commands: [], // 매크로안에 주어진 함수를 담을 버퍼
   menuPathStack: [], // 메뉴를 호출한 순서를 표시
   useDblShiftEscaping: 0, // 판독문 이력시 Shift-Shift 키 조합을 ESC 처럼 사용하기
   previousUserPreferences: [], // 사용자 설정값이 변경됐을 경우에만 DB refresh를 하기위해 이전 값을 저장할 목적
};

export default class FLeader extends Pathway {
   constructor({leader}, ...args) {
      super(...args);
      Object.assign(this, default_property, {leader});

      this.push_on_event_reducer = useTransducer("event-reducer",
         this.kbd_event_reducer.bind(this),
         this.hand_over_to_event_processors.bind(this)
      ).feeder;

      this.push_on_event_processors = useTransducer("event-processing-pipes",
         this.tap_at_the_front_door.bind(this),
         this.break_if_no_trigger_is_given.bind(this),
         this.handle_macro_tasks.bind(this),
         this.break_if_event_came_from_non_controllerable_elements.bind(this),
         this.apply_deferred_Q.bind(this),
         /* this.break_if_disabled_event.bind(this), */
         this.break_if_state_has_given_rule.bind(this),
         /* this.break_if_not_keyboard_event_should_be_handled.bind(this), */
         this.break_if_has_groupkey.bind(this),
         this.process__any__rule.bind(this),
         this.process_no_suitable_rule.bind(this)
      ).feeder;
   }

   // __leader__ 메세지를 받고 팝업을 표시하는 함수. 단순히 CSS display 값을 변경해도 되지만
   // 일정 시간동안 기다렸다가 팝업을 띄우기 위해 아래 함수가 필요함
   startBasicMode(renderFunc) {
      // this.delayId = setTimeout(() => requestAnimationFrame(renderFunc), this.popupDelay);
      // TODO: AdvancedMode를 붙이고 나서는 다시 위의 상태로 돌려야 한다
      // this.delayId = queueMicrotask(() => requestAnimationFrame(renderFunc));
      this.delayId = queueMicrotask(() => requestAnimationFrame(renderFunc));
   }

   getStoredJSON(json) {
      return Promise.resolve(json);
   }

   // 저장된 사용자의 숏컷정보를 가져오기 (Fake API)
   getStoredShortcuts (url) {
      return loadJSON(url);
   }

   // 가져온 사용자의 숏컷정보를 FLeader에서 사용할 수 있게 변환 & 룰로 변환/적용
   parse (json, addState, remember_addState_handler=true) {
      // 추가할 상태 규칙을 담을 배열
      const commands = [];

      if(remember_addState_handler) this.addStateHandler = addState;

      // 전체 숏컷 정보를 JSON형태로 가지고 있음
      // "고급모드" 상태에서 사용자의 입력을 받아 필터링된 결과를 표시할 때 사용.
      // 사용자가 인라인바인딩으로 숏컷을 변경한 경우, 이 정보는 반드시 업데이트 되야함
      if(!json) {
         throw new Error("No JSON data of menu structure. Abort");
      }
      this.menuJSON = json;

      // 불필요한 키이름 삭제: TODO --> 다른 함수로 분리할 것(ex: preprocessing)
      try {
         ["id", "userId"].forEach(id => delete this.menuJSON[id]);
      } catch(e) {}

      // 각각의 분류에 대해서, 순회하면서 상태머신의 규칙(Rule)으로 등록한다
      (Object.keys(this.menuJSON)).forEach( (category) => {

         // 분류내의 실제 명령항목에 대해 처리
         json[category].forEach( ({displayedName, functionName, shortcut, fkey}) => {

            // 단축키도 없고 fkey도 없으면 상태테이블에서 제외
            if((Object.is(shortcut, undefined) || Object.is(shortcut, "")) && (Object.is(fkey, undefined) || Object.is(fkey, ""))) return;

            // fleader-app.js 에서 리턴되는 룰을 받아 최종 명령 배열에 추가한다
            addState({category, shortcut, functionName, displayedName, fkey})
               .filter(cmd => !!cmd.length)
               .forEach(cmd => commands.push(cmd));
         });
      });

      // 디버깅용: 전체 단축키 정보
      // TODO: 안정화 후 반드시 제거!
      window.__fleader__ = this;

      this.add(commands);
   }

   bindKeyboardEventsOn(el=window) {
      abortIf( !el, `키보드이벤트를 얻기위한 DOM 객체가 필요합니다!`);
      const app = this;
      ["keydown"].forEach(action => el.addEventListener(action, e=>app.RepeatKeyboardEventFeeder(e)));
      ["keypress", "keyup"].forEach(action => el.addEventListener(action, e=>app.KeyboardEventFeeder(e)));

      // HPACS 시스템에서 FLeader쪽으로 신호를 보내오는 용도
      window.emit = (trigger, ctx) => {
         if(ctx) app.remoteCtx.push(ctx);
         app.emit(trigger);
      };

      window.fulfilled = (args) => { app.ctx.fulfilled = args; app.emit(__fulfilled__); };
      window.rejected  = (args) => { app.ctx.rejected  = args; app.emit(__rejected__); };
      window.failed    = (args) => { app.ctx.failed    = args; app.emit(__failed__); };
      window.canceled  = (args) => { app.ctx.canceled  = args; app.emit(__canceled__); };
      window.dialog_opened = (info={}) => {
         if(info && info.dialog) {
            app.dialog_objects.push(info);
            app.ctx.dialog = info.dialog;
            app.ctx.is_modal = !!info.is_modal;
            app.ctx.is_dialog = false; // mismatch dialog 상태값
         }
         app.emit(__dialog_opened__);
      };
      // closed with ok meaning
      window.dialog_closed = (info) => {
         if(info && !Object.is(info.selected, undefined)) app.ctx.dialog_selected = info.selected;
         else app.ctx.dialog_selected = "";

         // 다이얼로그가 중첩됐을 경우를 처리함
         const saved = app.dialog_objects.pop();
         if(saved) {
            app.ctx.dialog = saved.dialog;
            app.ctx.is_modal = saved.is_modal;
         } else {
            app.ctx.dialog = null;
            app.ctx.is_modal = false;
         }

         app.ctx.is_dialog = true;

         // #17812 dialog enter로 닫으면 닫히고 난후 keyup 이벤트가 적용되어 막음.
         app.ctx.dialog_closed_flag = true;
         setTimeout(() => {
            app.ctx.dialog_closed_flag = false;
         }, 500);
         app.emit(__dialog_closed__);
      };

      // 판독중인 케이스가 변경될 경우, 'Enter' 입력으로 커서를 옮기는 위치를 사용자 설정 값으로 초기화 한다.
      window.selected_rows_changed = () => {
         app.ctx.textObject = null;
         app.ctx.is_dialog = false; // mismatch dialog 상태값
      };

      // hh-report.js 내의 TEXTAREA에서 입력도중 전역 단축키를 눌렀을 때 해당 문자를 표시하지 않도록 boolean을 리턴한다
      window.is_global_shortcut_key = (() => {
         const on_editing = app.states.get("on_editing");
         return (e) => {
            const {normalized, modkeys:modifiers} = getKeyInfoFromEvent(e);
            const trigger   = KeyboardEventTriggerObject(normalized, modifiers, "keyup");
            const symbol    = trigger.getSymbol();
            return on_editing.has(symbol);
         };
      })();

      window.addEventListener("click", (event) => {
         // agGrid Cell DOM 요소를 잡아서 PageUp/PageDown을 보낼 용도로 저장한다
         try {
            if(!app.linkedElements.$agGridCell) {
               const path = [...event.path];
               if(path.some(node => String(node.tagName).toLowerCase() === "ag-grid-polymer")) {
                  app.linkedElements.$agGridCell = path.filter(node => String(node.className).indexOf("ag-") !== -1).shift();
                  app.linkedElements.$agGridEventPath = event.path;
                  app.linkedElements.$agGridEventTarget = event.target;
                  // console_log(`found! agGridCell:`, app.linkedElements.$agGridCell);
               }
            }
         }  catch(e) {
            console_log(e);
         }

         const exact_matches = ["basic","filmboxFunction","worklistFunction","macroFunction", "keyStacking"];
         const partial_matches = ["dialog", "pending"];
         if( partial_matches.some(st => app.current_state.indexOf(st) !== -1)
            || exact_matches.includes(app.current_state)) app.emit(__single_click__, event);
         return false;
      });

      // exception: keypress 이벤트는 처리하지 않지만 필름박스에서 오는 것은 허용하라는 신호 ('enter' 입력해서 TEXTAREA 들어가기용)
      // window.KeyboardEventRepeater = (e, exception=false) => queueMicrotask(()=>app.KeyboardEventFeeder(e, "filmbox", exception));

      window.KeyboardEventRepeater = e => queueMicrotask(()=>app.KeyboardEventFeeder(e, "filmbox"));
      window.KeydownEventRepeater = e => queueMicrotask(()=>app.RepeatKeyboardEventFeeder(e, "filmbox"));

      // NOTE: emit() 함수는 src/commponent/hh-report.js 파일내의 판독입력용 세개의 TEXTAREA 요소에서 아래와 같이 호출된다:
      // NOTE: onBlur=window.document.emit(app, 0), onFocus=window.document.emnit(app, 1)

      window.ElementEventRepeater= (textObject, /* 0:exit, 1:enter */ action) => {
         const sig = {
            0: __off_editing_focus__,
            1: __on_editing_focus__
         };

         // NOTE: 다시 포커스를 주기위해 TEXTAREA 요소를 기억함.
         // NOTE: ccInfo 객체를 기억해두면 [Radilogy]탭에서 'enter'를 입력하면 의 보이지않는 ccInfo로 포커스가 가버릴 것이기 때문이다
         // NOTE: TEXTAREA of Addendum also should not be saved

         if(textObject && ["ccInfo"].every(id => textObject.id !== id)) app.ctx.textObject = textObject;
         app.emit(sig[action]);
      };

      window.filmbox = (function filmbox(){
         let filmbox_window = null;
         return {
            set(filmbox){
               if(Object.is(filmbox, null) || (typeof child === "object")) filmbox_window = filmbox;
               return this;
            },
            get() {
               return filmbox_window;
            },
            reset() {
               filmbox_window = null;
               return this;
            },
            focus() {
               if(filmbox_window) queueMicrotask(() => filmbox_window.focus());
               else console.log(`no filmbox object yet.`);
               return this;
            },
            map (f){
               f(filmbox_window);
               return this;
            }
         };
      })();

      // filmbox에서 enter키 입력시 focus를 주기위한 용도 및 report가 열려있는지 확인
      // filmbox에서 enter키 입력시 fleader로 넘어와서 report로 focus를 주게되면 간헐적으로 안되는 현상이 있어 filmbox에서 focus를 주는 방식으로 사용
      window.report = (function report(){
         let reportWindow = null;
         let hhReport = null;
         return {
            set(window, report){
               reportWindow = window;
               hhReport = report;
               return this;
            },
            get() {
               return reportWindow;
            },
            getReport() {
               // ReadingTemplate 적용에 사용 ( setCopyOpinion )
               return hhReport;
            },
            reset() {
               reportWindow = null;
               hhReport = null;
               return this;
            }
         };
      })();

      // FLeader의 초기화보다 빨리 설정되는 요소를 가져옴 (필요에 따라 2초 이상 딜레이를 줘야함)
      // infoArray: 객체의 배열 [{id:"id", value:object}, {id:"id", value:object}, {id:"id", value:object}, ... ]

      app.import_hpacs_symbols(window);

      // report-container ready시 호출하여 필요한값 세팅
      window.report_popup_opened = (popupWindow, report) => {
         window.report.set(popupWindow, report);
         app.ctx.textObject = null; // Enter키 진입시 사용되는 textarea 초기화

         // 1. element 가져오기
         app.import_hpacs_symbols(popupWindow);

         // 2. hh-report에서 사용되는 함수연결
         popupWindow.fulfilled = window.fulfilled;
         popupWindow.rejected = window.rejected;
         popupWindow.failed = window.failed;
         popupWindow.ElementEventRepeater = window.ElementEventRepeater;
         popupWindow.is_global_shortcut_key = window.is_global_shortcut_key;
         popupWindow.addEventListener("keydown", e=>app.RepeatKeyboardEventFeeder(e));
         ["keypress", "keyup"].forEach(action => popupWindow.addEventListener(action, e=>app.KeyboardEventFeeder(e)));

         // 3. report popup의 setting-dialog에서 사용되는 함수연결
         popupWindow.dialog_opened = window.dialog_opened;
         popupWindow.dialog_closed = window.dialog_closed;
      };

      window.report_popup_closed = () => {
         // NOTE: 2021/04/29 By Evan.kim
         // report popup의 textarea에 focus가 되어있는 상태로 close 처리되면 close될때 blur됨.
         // app.ctx.textObject의 값이 세팅되어 초기화가 안되는 문제가 생겨 닫히고난후 처리하는 방식으로 변경함.
         const popupTick = setInterval(()=>{
            const reportWindow = window.report.get();
            if(!reportWindow || reportWindow.closed) {
               window.report.reset();
               app.reportPopupElements = {};
               app.ctx.textObject = null; // Enter키 진입시 사용되는 textarea 초기화
               clearInterval(popupTick);
            }
         }, 500);

      };

      window.set_hpacs_symbol = (window, id, symbol) => {
         app.set_hpacs_symbol(window, id, symbol);
      }
   }

   /** 'keydown', 'keypress', '두가지 키보드이벤트에 대한 핸들러
    *
    * ① 키보드이벤트가 전달되면 타입에 따라 보정한다
    * ② 보정된 KeyboardEvent 객체에 기반해서 키입력정보를 나타내는 객체를 만든다. 이때 "action"은 키조합에 따라 자동으로 지정된다
    *   예1: alt+s ==> {key: 's', modifieres:['alt'], action: 'keydown'}
    *   예2: S ==> {key: 's', modifieres:['shift'], action: 'keypress'}
    *   예3: alt+C ==> {key: 's', modifieres:['shift', 'alt'], action: 'keydown'}
    * ③ 객체에 대한 Symbol을 생성한다.
    * ④ 상태테이블에 Symbol을 trigger로 전송한다
    */
   KeyboardEventFeeder(event, src="worklist") {
      if(event.which === 8) return; // NOTE: backspace 처리는 keydown에서만 한다
      if(this.ctx.dialog_closed_flag) return; // #17812 dialog enter로 닫으면 닫히고 난후 keyup 이벤트가 적용되어 막음.

      // key_info: {"printable":"a","normalized":"a","keycode":97,"action":"keyup|keypress","is_hangul":false,"is_control":true,"modkeys":["ctrl"]}
      const key_info = getKeyInfoFromEvent(event);

      // 한글상태 또는 CapsLock을 켜둔 상태에서 alt 조합키 등.. 처리할 수 없거나 예외적인 상태인 경우
      if (key_info.has_exception) return;

      // 기존에 작성했던 변수때문에 호환성을 유지하기 위해 이름을 변경
      const {printable, normalized, modkeys:modifiers, action} = key_info;

      // making trigger symbol based on states of event ...
      const trigger   = KeyboardEventTriggerObject(normalized, modifiers, event.type);
      const symbol    = trigger.getSymbol();

      // 모든 키보드 이벤트는 reducer를 거쳐 하나의 이벤트로 걸려지고, push_on_event_processors 파이프로 넘겨진다
      this.push_on_event_reducer({trigger: symbol, event, key:normalized, printable, modifiers, action, at_state: this.current_state, event_src: src});
   }

   RepeatKeyboardEventFeeder(event) {
      // Ag-Grid 영역에 click 한뒤 엔터를 입력하면 keydown 만 전파되는 경우에도 판독문입력영역(TEXTAREA)으로 포커스를 옮길 수 있어야 한다
      // if(event.which === 13 && this.current_state === "ready") {
      //    const path = [...event.path];
      //    if(path.some(node => String(node.tagName).toLowerCase() === "ag-grid-polymer")) {
      //       console_log(` ↳ got enter@keydown`);
      //       this.emit(enter_at_keydown_symbol);
      //    }
      //    return false;
      //
      //    // NOTE: 'backspace@keydown' 아래 두 상태에서만 사용하고 있다
      //    // eslint-disable-next-line
      // } else
      if( !["basic", "filmboxFunction", "worklistFunction", "macroFunction", "keyStacking", "changingShortcut", "userPreferences"].includes(this.current_state)) return false;

      if(event.which === 8 && !(event.altKey || event.ctrlKey || event.shiftKey || event.metaKey)) this.emit(backspace_symbol, event);
      event.stopPropagation();
      return false;
   }

   pushOnKeyStack(key) {
      if(this.do_not_stackup_trigger) return;
      this.keyStack.push(key);
   }

   handleKeyStack(f, prepend="") {
      const input = this.keyStack.map(key => key.replace(/(enter|shift|ctrl|alt)/i, "")).join("");
      if(input.length) f(input, prepend);
      else this.popupNotice("Warning: Empty", /* non-error */false);
   }

   clearKeyStack () {
      // delete all contents so other references to this.keyStack array will be deleted also.
      this.keyStack = [];
   }

   pushOnSearchStack(key) {
      this.searchStack.push(key);
   }

   clearSearchKeywords (dom) {
      // delete all contents so other references to this.keyStack array will be deleted also.
      this.searchStack.length = 0;

      // WARNING: side-effect
      // eslint-disable-next-line no-param-reassign
      dom.value = "";
   }

   // eslint-disable-next-line class-methods-use-this
   applyAdvancedSearch(e, /* jQuery Object */container) {
      if(e && e.which === 27) {
         this.emit(makeKeySymbolFromString("esc@keyup"));
         return;
      }

      // ① clear previous content
      container.empty();

      // ② get the generated content by keyword from user
      const content = Either
         .fromNullable(e) // 최초
         .map(({target:{value}}) => value)
         .map(value => isFalse(value) ? "" : String(value).trim())
         .fold(
            value => this.showSearchResult(String(value), false),
            () => this.showSearchResult(String(""), true)
         );

      // ③ build DOM
      // container.append( history  );
      container.append(content);

      // ④ 'history' 섹션에 기존에 선택된 명령 5개에 대한 DOM elements를 넣어 표시
      // 'enter' 카를 누를 때마다 this.LRU에 포함된다
      // TODO: 따라서 'enter'키에 대한 핸들러는 FLeader 클래스의 메소드여야 한다
      $(this.DOMElement("selected-command-history-container")).append(this.LRU);

      // ④ display highlighted LI element
      this._markCursorOnList(container) ;
   }

   _markCursorOnList(/* jQuery object for id#:'searchResult' DOM Element */container) {
      // ① 검색결과를 표시하는 블록에서, 모든 <li> 태그 요소를 얻는다
      const LI = container.find("LI");

      // ② <li> 요소 갯수를 미리 설정함으로써 <DOWN> 키입력이 있을 때 더 넘어가지 못하게 한다
      this.maxCommands = LI.length;

      // ③ 검색 결과 때문에 이전 결과로 인한 커서 위치가 변경되었을 때, 커서를 목록 상위로 옮긴다
      if(this.cursor >= this.maxCommands) this.cursor = 0;

      // ④ 현재 커서가 가리키는 <li> 요소를 얻어와서 강조표시를 위한 CSS className을 추가한다
      const selectedCommand = LI[this.cursor];
      Object.assign(selectedCommand, {className: _addCSSClass(selectedCommand, "class-function-selected")});
   }


   /** Pathway::parseRule()을 호출하기 전에 trigger를 Symbol 형태로 변환한다
    * trigger에 공백문자가 포함된 sequence-type 인경우는 sequence-key 간의 상태전환을 자동으로 해주는 룰을 추가한다
    */
   parseRules (_rules=[]) {
      const rules = [];
      // rules = [[[Symbol,Symbol...], "from", "to", function(){}],[[Symbol],"from", "to", callback],...]
      _rules.forEach(([_trigger, from, to, _cb]) => {
         // 1) trigger를 일관된 방식으로 다루기 위해 배열로 변환
         const triggers = arrayfy(_trigger);

         // 2) 스페이스로 구분되는 연속된 키를 가지고 있다면 sequence-key로 처리
         if(hasSequenceKey(triggers)) {
            this.buildSequenceRules(triggers, from, to, _cb).forEach(rule => rules.push(rule));
            // 3) 그외는 단일 키로 처리
         } else rules.push([ triggers.map(makeKeySymbolFromString), from, to, _cb]);
      });

      /** rules의 최종 형태는 이중배열이다:
       * [
       *   [Symbol, "from", "to", function(){}],
       *   [Symbol, "from", "to", function(){}],
       *     ...
       * ]
       */

      return super.parseRules(rules);
   }

   // 키보드이벤트에서 오는 trigger인 경우, keyboard_event_should_be_handled 플래그가 설정되지만
   // 그외 trigger (Symbol, Number, String...)는 기본적으로 중복 호출이 없으므로 'true' 설정이 적용된다
   // 즉 이 플래그는 하나의 입력에 대해 여러번 발생하는 콜백 호출을 한번만 실행시키기 위해 반드시 필요하다
   // KeyboardEvent 타입 외, 다른 종류의 trigger 수납 API
   // (useTransducer() 사용이전의 API 사용형태에서 수정을 최소화 하기 위해 이름을 맞추기 위함)

   emit(trigger, event, key="", modifiers=[] /* , keyboard_event_should_be_handled=true */) {
      console_log(`\n\nemit(`,trigger,`) @<${this.current_state}>`);
      this.push_on_event_processors({trigger, event, key, modifiers /* , keyboard_event_should_be_handled */});
   }

   async * kbd_event_reducer(iter) {
      const pressMods = [];
      const upMods = [];

      let accept_this_event;
      let last_keypress_key;

      const condition_checkers = {
         keypress({key, event, modifiers, action}) {
            console_log(`keypress>> key: ${key}, modifiers:${JSON.stringify(modifiers)}`);

            last_keypress_key = null;

            // i.e; shift+ctrl+a ==> "shift", "ctrl" will be canceled in consecutive keyup processes
            if(action === event.type) {
               accept_this_event = true;
               last_keypress_key = key;
            } else {
               accept_this_event = false;
            }

            if(accept_this_event && modifiers.length) {
               modifiers.map(mod => pressMods.push(mod));
            }
         },
         keyup({key, event, modifiers, action}) {
            console_log(`keyup>> key: ${key}, modifiers:${JSON.stringify(modifiers)}`);

            accept_this_event = false;

            // FIXME: capslock 누른상태와 shift 키를 떼지않고 계속 쳤을 때를 어떻게 구분할 것인?
            if(pressMods.length && pressMods.includes(key)){
               pressMods.splice(pressMods.indexOf(key), 1);

            } else if(upMods.length && upMods.includes(key)){
               upMods.splice(upMods.indexOf(key), 1);

            } else if(action === event.type) {
               accept_this_event = true;

            } else if(last_keypress_key !== key && KBDCommon.WordKeys.includes(key)) {
               // keyup에서 받아들일 키가 아니지만 WordKeys 에 속한 문자가 keyup 에만 들어온 경, 예외적으로 허용한다
               accept_this_event = true;
            }

            if(accept_this_event && modifiers.length) {
               modifiers.map(mod => upMods.push(mod));
            }
         }
      };

      for await (const input of useSharable(iter)) {
         const {event} = input;
         accept_this_event = true;

         // 1) 조합키가 눌려졌을때 event.key를 제외하고 mod-key는 출력되지 않게함
         // 2) keypress 에서 출력된 문자는 keyup에서 다시 출력하지 않게함
         condition_checkers[event.type](input);


         if(accept_this_event) {
            console_log(`\n»» 0) reducer: `, input.trigger, ` @<${input.at_state}>`);
            yield input;
         // } else {
         //    tryCatch(()=>event.stopPropagation());
         }

      }
   }

   // eslint-disable-next-line
   async * hand_over_to_event_processors(iter) {
      for await (const input of useSharable(iter)) {
         // 이 pipe로 전달되는 모든 값은 이벤트 처리프로세스 파이프로 넘겨진다
         this.push_on_event_processors( input );
      }
   }

   // 0) 이벤트처리에 앞서 대상을 표시
   async * tap_at_the_front_door(iter) {
      for await (const input of useSharable(iter)) {
         console_log(` ↳ 1) trigger: `, input.trigger, ` @<${this.current_state}>`, input);
         yield input;
      }
   }

   // 1) trigger는 반드시 필요하다. 없는 경우 API 호출문제로써 반드시 바로 잡아야 한다
   async * break_if_no_trigger_is_given(iter) {
      for await (const input of useSharable(iter)) {
         const {trigger} = input; // console_log(`2) break_if_no_trigger_is_given`);
         if(Object.is(trigger, undefined)) {
            console_log(`  ↳ --------------------------------------------------------`);
            console_log(`  ↳ Abnormal Calling of FLeader.emit() -- no trigger given`);
            console_log(`  ↳ --------------------------------------------------------`);
            if(window.alert) {
               // eslint-disable-next-line   no-alert
               window.alert("Abnormal Calling of FLeader.emit() -- no trigger given");
            }
         } else  {
            yield input;
         }
      }
   }

   // 2)
   async * handle_macro_tasks(iter) {
      for await (const input of useSharable(iter)) {
         if( input.trigger === __begin__ ) {
            console_log(" ↳ 3) handle_macro_task. flushing events...");

            for await (const input of useSharable(iter)) {
               const {trigger, event} = input;

               // INFO: __macro__ 호출할 때는 두번째 인자가 실행할 명령을 담은 배열이다!
               if( trigger === __macro__ ) {
                  this.macro_commands = [...event];
               }

               // 취소 통보를 받으면 명령버퍼를 비우고 inner for-of를 종료한다
               else if( trigger === __rollback__ ) { this.macro_commands = []; break; }

               // 오직 __next__ 신호에 의해서만 매크로내 함수를 수행한다
               // __fulfilled__ 신호받는 것을 여기서 바로 처리할 수 있도있지만 일반 룰테이블에 넣는 것이 더 활용도가 높다
               // ie. 진행바를 표시하는 등의 일을 할 수 있다
               else if( trigger === __next__ ) {
                  const cmd = this.macro_commands.shift();
                  if(cmd) {
                     setTimeout(()=>tryCatch(cmd),0);
                  } else {
                     // 모두 실행했으면 종료신호를 보낸다
                     input.trigger = __done__; yield input; break;
                  }

               } else {
                  yield input;
               }
            } /* inner loop */

            // console_log(`step out from macro loop`);

         } else {
            // 매크로 모드가 시작되기 전에는 다음 pipe로 모두 전달한다
            yield input;
         }
      } /* outer loop */
   }

   // 2) 필름박스 화면의 <INPUT> 태그에서 온 키보드 이벤트에 대해서는 처리하지 않는다
   async * break_if_event_came_from_non_controllerable_elements(iter) {
      for await (const input of useSharable(iter)) {
         // console_log(`3) break_if_event_came_from_non_controllerable_elements`);
         const {event} = input;
         if(event && this.occurEvent_while_in_InputTag(event)) {
            // Worklist 화면에서 입력가능한 INPUT, TEXTAREA 요소에서 입력을 하게되면
            // Uncaughted 상태가 되는데, 이때 INPUT 요소에서 입력되는 키에 대해서는
            // 상태변경 메세지로 인식하지 않도록 함
            // 중요!: 모든 HTML INPUT 요소에 포커스가 들어가는 것을 일일이 처리할 수 없다.
            // 단, fkey를 변경할 때의 이벤트만 허용한다
            console_log( `  ↳ This KeyboardEvent on <INPUT> elements is not under control by FLeader` );
         } else {
            yield input;
         }
      }
   }

   // 4) await()으로 blocker가 설정되어 있다면, __fulfilled__ 트리거를 만날때까지 큐에 저장한다
   // NOTE: 상태테이블에서 blocker 외에 __any__ ==> Noop 설정을 반드시 해야함을 잊지말것!
   async * apply_deferred_Q(iter) {
      // eslint-disable-next-line   no-restricted-syntax
      for await (const input of useSharable(iter)) {
         const {trigger} = input;
         if(this.blocker) {
            console_log(` ↳ 4) apply_deferred_Q`);
            // blocker가 설정되었고 성공/실패 중 하나이면 적절히 처리, 아니면 그냥 다음 pipe 함수로 전달
            if(trigger === __flush__) {
               while(this.deferred_Q.length) {
                  console_log(`    ↳ yield this.deferred_Q.shift()`);
                  yield this.deferred_Q.shift();
               }
               this.blocker = null;
               console_log("    ↳ fulfilled");
            } else if(trigger === __abandon__) {
               this.deferred_Q = [];
               this.blocker = null;
               console_log("    ↳ rejected");
            } else {
               // 다음 pipe 함수로 전달
               yield input;
            }
         } else {
            // blocker가 설정되지 않았다면 그냥 다음 pipe 함수로 입력을 전달
            yield input;
         }
      }
   }

   // async * break_if_disabled_event(iter) {
   //    for await (const input of useSharable(iter)) {
   //       const {trigger, event} = input;
   //       if(event && this.is_disabled_handler(event.type) && !this.state.has(trigger)) {
   //          console_log("  ↳ ", `${event.type} is disabled` );
   //       } else {
   //          yield input;
   //       }
   //    }
   // }

   // 5) trigger 전달여부 검사
   // WARN: 특정 trigger 에 대한 룰이 존재할 경우, __word__ 등과같은 groupkey 나 __uncaught__ 처리에 앞선다.
   // WARN: 종료조건 ③  보다 앞서 점검해야한다.
   // NOTE: "enter@keyup" 설정과 같이 기본적으로 keypress로 처리되는 핸들러를 다른 타입의 이벤트에서 변경할수도 있기 때문이다.
   // NOTE: 룰에 지정하는 것은 곧 키스택에 쌓이게 하는 것이다!!
   async * break_if_state_has_given_rule(iter) {
      for await (const input of useSharable(iter)) {
         const {trigger, event, key, printable, modifiers} = input;
         if(this.state.has(trigger)) {
            console_log(" ↳ 5) has_rule");
            if(!isFalsy(printable) && isStackableKey(key)) this.pushOnKeyStack((KBDCommon.isHangul(printable) ? KBDCommon.getEnglishFromHangul(printable)[1] : printable));
            const [,results] = super.emit(trigger, event||{}, this, key, modifiers);
            this.defer_in_Q_if(results.pop(), input);
         } else {
            yield input;
         }
      }
   }

   // 6) KeyboardEvent 이고 'key'를 처리하는 적절한 Handler 호출이 아니면 처리하지 않는다
   // 적절한 Handler 인지 여부는 KeyboardEventFeeder() 함수의 pickBestActrionFromEvent() 함수가 결정한다
   // async * break_if_not_keyboard_event_should_be_handled(iter) {
   //    for await (const input of useSharable(iter)) {
   //       const {event, trigger,keyboard_event_should_be_handled} = input;
   //       if(event && !keyboard_event_should_be_handled) {
   //          console_log(`6) break_if_not_keyboard_event_should_be_handled`);
   //          console_log("  ↳ ", trigger);
   //       } else {
   //          yield input;
   //       }
   //    }
   // }

   // 7) trigger에 대한 지정된 룰은 없지만 그룹키(패턴. ex: __word__, __lower_word__ ...) 영역에 적용될 수 있는지 검사
   // WARN: \w | \W | \d 등의 처리는 __any__ 보다 우선한다.
   async * break_if_has_groupkey(iter) {
      for await (const input of useSharable(iter)) {
         const {event, key, modifiers} = input;
         const [hasGroupKey, groupKey] = this.groupKeysForCurrentState.reduce(([hasGroupKey, groupKey], {pattern, filter})=>{
            if(hasGroupKey) return [hasGroupKey, groupKey];
            return [this.state.has(pattern) && filter(key, modifiers), pattern];
         }, [false, ""]);

         if(hasGroupKey) {
            console_log(` ↳ 7) break_if_has_groupkey`);
            // 현재의 상태에서 패턴에 해당되는 trigger
            const [,results] = super.emit(groupKey, event, this, key, modifiers);
            this.defer_in_Q_if(results.pop(), input);
         } else {
            yield input;
         }
      }
   }

   // 8) WARN: WildCard에 해당하는 처리. __uncaught__는 __any__ 와 같다 (alias)
   async * process__any__rule(iter) {
      for await (const input of useSharable(iter)) {
         const {event, key, modifiers} = input;
         if(this.state.has(__uncaught__)) {
            console_log(` ↳ 8) process__any__rule`);
            const [,results] = super.emit(__uncaught__, event, this, key, modifiers);
            this.defer_in_Q_if(results.pop(), input);
         }
         else {
            yield input;
         }
      }
   }

   // 9) 조건에 맞는 룰이 없음
   // eslint-disable-next-line    require-yield
   async * process_no_suitable_rule(iter) {
      // eslint-disable-next-line
      for await (const input of useSharable(iter)) {
         console_log(" ↳ 9) process_no_suitable_rule");
         // WARN: super.emit()을 통한 콜백호출시 첫번째 인수는 this.ctx 이지만, 아래의 경우는 ctx가 전송되지 않음에 주의!!!
         // FIXME: API 설계상의 실수. super.emit()에서 콜백호출에 사용하는 인수의 갯수와 순서에 맞출 것
         // const {trigger, event, key, modifiers} = input;
         // this.__uncaughthandler(trigger, event, this, key, modifiers);
      }
   }

   updateToastBox() {
      const app = this;
      requestAnimationFrame(() => {
         // "basic" 과 그 하부 상태에서 입력된 키를 보여주기 위함
         app.ctx.$displayKey.value = app.keyStack.join("");
      });
   }

   // 'ready' 상태에서 모든 키입력을 받는 'keyStacking' 상태에서 호출 됨.
   // 'AdvancedMode'에서 검색결과를 표시
   // eslint-disable-next-line import/prefer-default-export
   showSearchResult (keyword, showAll=false) {
      const {worklistFunction, filmboxFunction, macroFunction}  = this.menuJSON;
      const _keyword = keyword.toLowerCase();
      const filterCondition = showAll ? ()=>true : (({displayedName}) => displayedName.toLowerCase().includes(_keyword));
      return `
         <div class="advanced-search-result-function-group">
            <div class="class-advance-title">Recently Used</div>
            <div id="selected-command-history-container" class="class-advance-history">
            </div>
         </div>

         <div class="advanced-search-result-function-group">
            <div class="class-advance-title">Worklist functions</div>
            <div class="class-advance-functionList">
               ${worklistFunction.filter( filterCondition ).map(({displayedName, functionName, shortcut}) => `
               <li class='class-function shortcut-input-color' style='list-style:none;'>
                  <div class="class-advance-function-title" data-displayed-name="${displayedName}" data-function-name="${functionName}">
                     ${displayedName} ${ shortcut ? `<span>(${shortcut})</span>` : "" }
                  </div>
               </li>`).join("\n")}
            </div>
         </div>

         <div class="advanced-search-result-function-group">
            <div class="class-advance-title">Macro functions</div>
            <div class="class-advance-functionList">
               ${macroFunction.filter( filterCondition ).map(({displayedName, functionName, shortcut}) => `
               <li class='class-function shortcut-input-color' style='list-style:none;'>
                  <div class="class-advance-function-title" data-displayed-name="${displayedName}" data-function-name="${functionName}">
                     ${displayedName} ${ shortcut ? `<span>(${shortcut})</span>` : "" }
                  </div>
               </li>`).join("\n")}
            </div>
         </div>

         <div class="advanced-search-result-function-group">
            <div class="class-advance-title">FilmBox functions</div>
            <div class="class-advance-functionList" style="max-height: 300px; overflow:auto;">
               ${filmboxFunction.filter( filterCondition ).map(({displayedName, functionName, shortcut}) => `
               <li class='class-function shortcut-input-color' style='list-style:none;'>
                  <div class="class-advance-function-title" data-displayed-name="${displayedName}" data-function-name="${functionName}">
                     ${displayedName} ${ shortcut ? `<span>(${shortcut})</span>` : "" }
                  </div>
               </li>`).join("\n")}
            </div>
         </div>
      `;
   }

   // AdvancedMode에서 사용자의 Enter 키에 의해 선택된 DOM 노드를 History 기능 구현을 위한 this.LRU 배열에 넣는다
   addToLRU(/* DOM Element */command) {
      // DOMNode(command)를 LRU 배열의 시작위치에 넣는다.

      this.LRU = Either.of(this.LRU.filter(li => _getAttributeValue_DisplayedName(li) !== _getAttributeValue_DisplayedName(command)))
      // eslint-disable-next-line no-sequences
         .map(cache => (cache.unshift(command), cache))
         .map(cache => cache.splice(0, 5))
         .map(
            cache => cache.map(el => Object.assign(el, {className: _removeCSSClass(el, "class-function-selected")}))
         )
         .fold(identity, ()=>[]);
   }

   resetCursor() {
      this.cursor = 0;
   }

   // AdvancedMode 에서 Enter 키를 눌렀을 때 처리: 실제 명령 실행
   exec(/* DOM Element */command) {
      const funcName = _getAttributeValue_FunctionName(command);
      console.log(`exec function: ${funcName}`);
   }

   _removeHightlightOnCursor() {
      this.LRU.map( el => Object.assign(el, {className: _removeCSSClass(el, "class-function-selected")}));
   }

   /**
    * "고급모드" 상태에서 방향키(UP) 핸들
    */
   keypressUP(ctx, event) {
      preventDefault(event);
      stopPropagation(event);

      // cursor를 위로 이동.
      this._removeHightlightOnCursor();
      this.cursor -= if_then_else(() => this.cursor > 0, () => 1, () => 0);
   }

   /**
    * "고급모드" 상태에서 방향키(DOWN) 핸들
    */
   keypressDOWN(ctx, event) {
      preventDefault(event);
      stopPropagation(event);

      // History + TopCategoryItems 갯수보다 적을 때, 위치를 증가시킴으로써 아래로 내려가는 걸 표시
      // maxCommands 계산은 applyAdvancedSearch() -> _markCursorOnList() 호출에서 결정된다
      this._removeHightlightOnCursor();
      this.cursor += if_then_else(() => this.cursor < this.maxCommands,  () => 1, () => 0);
   }

   // 숏컷수정 모드로 전환하기전의 상태를 저장
   stackingUp() {
      this.backStack.push( this.previous_state );
   }

   // 숏컷수정 모드가 취소되면 돌아가야할 이전 상태를 얻음
   stackingDown() {
      if(!this.backStack.length) throw new Error("ESC키에 의한 상태 복구 호출의 짝이 맞지 않습니다");
      return this.backStack.pop();
   }

   setCurrentState( st ) {
      this.current_state = st;
      this.state = this.states.get(this.current_state);
      console_log("forced to transit to ", this.current_state);
   }

   // TODO: 상위필드의 이름이 this.menuJSON 에서 받아 자동으로 처리하게 해야함
   restorePreviousState() {
      const signal = Either.of( this.stackingDown() )
         .map(previous_state => ({"filmboxFunction": "f", "worklistFunction": "w", "macroFunction": "m", "userPreferences": "p"}[previous_state]))
         .map(makeKeySymbolFromString)
         .take();

      // IMPORTANT!!! this.emit() 재진입을 막기위해 반드시 필요! queueMicrotask() vs. setTimeout() 호출 차이를 반드시 이해해야함!!!!
      // 만약 변경할 상태가 하나이고 정해져 있다면 직접적으로 this.forceTransitionTo("상태") 함수를 사용해서 상태를 바꿀 수 있겠지만,
      // restorePreviousState()함수는 위의 signal 값에 따라 상태 분기를 해야하고 그 결과에 따라 콜백함수가 다르므로
      // 아래와 같이 상태변이를 유발하는 신호를 줌으로써 signal 값에 따라 상태테이블에서 지정한 룰을 따르게 한다.
      queueMicrotask(()=> this.emit(signal));
   }

   // TODO: 상위필드의 이름이 this.menuJSON 에서 받아 자동으로 처리하게 해야함
   applyChangingShortcut() {
      const signal = Either.of( this.stackingDown() )
         .map(previous_state => ({"filmboxFunction": "f", "worklistFunction": "w", "macroFunction":"m", "userPreferences": "p"}[previous_state]))
         .map(makeKeySymbolFromString)
         .take();

      queueMicrotask(()=> this.emit(signal));
   }

   // `basic` 상태에서 fkey 설정을 변경한다
   startChangeShortcut(LI) {

      // 숏컷수정 모드로 전환하기전의 상태를 저장
      this.stackingUp();

      // 새로운 숏컷 입력을 받을 때 작업대상인 <LI>를 다시 찾지않기 위해 저장
      this.selectedEl = LI;

      // NOTE: 상태값이 JSON 데이터에서 분류이름과 같다
      this.selectedCategory = this.menuJSON[this.previous_state];

      const elShortcut = _getShortcutElement(LI); // NOTE: 실제로는 `fkey` 값이다. 혼동하지 말것
      const elText = _getShortcutKeyTextElement(LI);
      this.previousFKey = _getShortcutKey(LI);
      // const functionName = _getFunctionName(LI);

      // 기존 키 표시 OFF
      elText.style.display = "none";

      $(elShortcut).append(`<input type="text" class="class-function-shortcut-input" value="${elText.innerText}" onKeyDown="return preventTab(e)" disabled>`);
   }

   restorePreviousFKey() {
      try {
         const inputEl = _getNewShortcutInputElement(this.selectedEl);
         inputEl.setAttribute("value", this.previousKey);
      }catch(e) {
         console.log(e);
      }
   }

   clearPreviousFKey() {
      this.previousFKey = null;
   }

   showNewShortcut(e) {
      // 한글 자모는 허용하지 않는다
      if(KBDCommon.isHangul(e.key)) return null;

      // 키보드 입력 정보를 바탕으로 대소문자구분과 기능키를 파악
      const {printable, normalized:key} = getKeyInfoFromEvent(e);

      // single-modifiers 입력은 허용하지 않는다
      if(["alt", "control", "meta", "shift", "enter", "capslock", "hangul", "hanja", "contextmenu", "tab", "space"].includes(key)) return null;

      // backslash | doblue-qoute 는 허가하지 않는다
      if([92, 34].includes(e.which)) return null;

      // backspace를 누르면 fkey 지움
      const _key = (key === "backspace") ?  "" : printable;

      const inputEl = _getNewShortcutInputElement(this.selectedEl);
      inputEl.setAttribute("value", _key);
      return _key;
   }

   // 키 입력받고 있는 값을 제외하고 다른 단축키와 비교
   isAlreadyRegistered(_fkey, _functionName) {
      if(Object.is(_functionName, undefined)) {
         // const inputEl = _getNewShortcutInputElement(this.selectedEl);
         // eslint-disable-next-line no-param-reassign
         _functionName = _getFunctionName(this.selectedEl);
      }
      // selectedEl 예: <li class="class-function" ondblclick="dblClickHandler(this)" data-function-name="logout" data-shortcut-key="q">
      // TODO: 충돌한 쪽을 표시해줄 것
      console_log(this.selectedCategory, _fkey, _functionName);

      // ↓ 변경하는 가운데 fkey 삭제를 지원
      if( _fkey === "" ) return false;

      return !!this.selectedCategory.filter(({fkey, functionName}) => (_functionName !== functionName) && (_fkey === fkey)).length;
   }

   // 중복된 키 정의가 있을 때 설정과정을 간편하게 제공하기 위해 기존 설정값을 없애버리고 새로운 설정값을 강제로 지정한다
   deleteExistingFKey(fkey, functionName) {
      for(let i=0; i<this.selectedCategory.length; i++) {
         const rule = this.selectedCategory[i];
         // 같은 기능이 이름이 아닌데 fkey 값이 같은 항목을 찾아
         if((rule.functionName !== functionName) && (rule.fkey === fkey)) {
            // 충돌하는 쪽의 키 값을 없앰
            this.selectedCategory[i].fkey = "";
            return;
         }
      }
   }

   // BasicMode에서 fkey를 변경할 때 조건에 맞는지 검사
   applyChangedShortcut(force=false) {
      const inputEl = _getNewShortcutInputElement(this.selectedEl);
      const functionName = _getFunctionName(this.selectedEl);
      let signal = this.isAlreadyRegistered(inputEl.value, functionName, this.selectedCategory) ? __duplicated__ : __ok__;
      if(force) {
         this.deleteExistingFKey(inputEl.value, functionName);
         signal = __ok__;
      }

      // 저장
      if(signal === __ok__) this.setDBChangedShortcut(this.selectedEl);

      // 상태 전환
      queueMicrotask(()=> this.emit(signal));
   }

   setDBChangedShortcut(selectedEl){
      const inputEl = _getNewShortcutInputElement(this.selectedEl);
      const param = {
         detail: {
            fkey: inputEl.value,
            functionName: selectedEl.dataset.functionName,
            category:(this.backStack[0])
         }
      };
      document.dispatchEvent(new CustomEvent("callShortcutUpdateEvent",param));
   }

   refreshRules() {
      // 1) 바뀐 숏킷의 정보를 JSON 데이터에 적용한다
      const inputEl = _getNewShortcutInputElement(this.selectedEl);
      const functionName = _getFunctionName(this.selectedEl);
      const commands = this.selectedCategory;
      for(let i=0; i<commands.length; i++) {
         if(commands[i].functionName === functionName) {
            commands[i].fkey = inputEl.value;
            break;
         }
      }

      // fkey 변경에 따른 룰 적용과정
      // 1) 상태 정보와 그룹키 전체를 리셋한다: this.states.clear(), this.groupKeys.clear();
      // 2) 사용자별 설정이 아닌 FLeader 시스템 초기 룰을 적용한다: this.parseRules(initial_rules);
      // 3) 변경된 룰 (this.menuJSON)을 적용한다.
      this.reloadRules( this.menuJSON );
   }

   showDuplicationWarning() {
      [_getNewShortcutInputElement(this.selectedEl)]
         .map((element) => {
            this.popupNotice("The Shortcut you entered is already existed.");
            return [element, _addCSSClass(element, "warning-duplicated-shortcut")];
         })
         .map(([element, className]) => Object.assign(element, {className}));
   }

   showNoDuplication() {
      [_getNewShortcutInputElement(this.selectedEl)]
         .map(element => [element, _removeCSSClass(element, "warning-duplicated-shortcut")])
         .map(([element, className]) => Object.assign(element, {className}));
   }

   doBackspaceFunction(ctx, e, app/* ,  _key/, modifiers=[] */) {
      // if(app.keyStack[app.keyStack.length - 1] === "backspace") app.keyStack.pop();
      app.keyStack.pop();
      if(app.keyStack.length === 0) this.emit(__empty_keystack__);
   }

   TemplateAndMacroKeyStackingHandler (ctx, e, app, _key, modifiers=[]) {

      let key = _key;

      // __lower_word__ or __upper_word__ 에서는 잡히지 않는다!
      if(_key === "backspace" ) {
         if(app.keyStack[app.keyStack.length - 1] === "backspace") app.keyStack.pop();
         app.keyStack.pop();
         return;
      }

      // console.log(e, `key ==> ${_key}`, (["ctrl", "alt", "meta", "shift", "enter"].includes(_key)));
      // modifiers에 "ctrl", "alt", "meta" 키조합이 있다면 무시
      if ( hasIn(["ctrl", "alt", "meta"], modifiers) || (["ctrl", "alt", "meta", "shift", "enter"].includes(_key)) ) {
         console.log(`ignore`, _key);
         return;
      }

      // 일반 문자인데 SHIFT 플래그가 있는 경우, 대문자로 변경해서 쌓는다
      if(hasIn(["shift"], modifiers)) key = _key.toUpperCase();
      else if(_key === "space") key = " ";
      else if(KBDCommon.isHangul(_key)) {
         const [isValid, eng] = KBDCommon.getEnglishFromHangul(_key);
         key = isValid ? eng : "";
      }
      app.pushOnKeyStack(key);
      // console.log( `stacking: ${app.keyStack.join("")}` );
   }

   hasSequenceKey(triggers=[]) {
      return triggers.reduce((acc, tr) => {
         if(acc===true) return true;
         return String(tr).trim().split(" ").length > 1;
      }, false);
   }

   // sequence-key를 입력할 때 3초 쉬면 취소하는 함수
   clearSequenceKeyInputTimeout() {
      if(!Object.is(this.seqKeyTimeoutId, null)) clearTimeout(this.seqKeyTimeoutId);
      this.seqKeyTimeoutId = null;
   }

   // sequence-key 입력 취소 타이머 시작
   refreshSequenceKeyInputTimeout () {
      this.clearSequenceKeyInputTimeout();
      this.seqKeyTimeoutId = setTimeout(() => this.emit(makeKeySymbolFromString("esc")), 3000);
   }


   // triggers 는 배열이며 다양한 타입의 값을 가질 수 있다: String, Symbol, Number
   // ex: ==> [["shift shift"], "ready", "basic", cb]
   // [
   //    [[Symbol.for("{key:shift,modifiers:[],action:keyup}")], `ready`      ,`ready.shift`, (ctx, e, app, key) => this.pushOnKeyStack(key)],
   //    [[Symbol.for("{key:shift,modifiers:[],action:keyup}")], `ready.shift`,"basic", cb],
   //    [[Symbol.for("{key:esc,modifiers:[],action:keyup}")],   `ready.shift`,"ready", [() => this.clearKeyStack()]],
   // ];
   buildSequenceRules(triggers, from, to, cb) {
      const rules = [];

      // sequence-key 입력 도중에 ToastBox를 이용해 입력중임을 알리는 용도
      // const notice = () => this.popupNotice(`Waiting sequence key...(${this.keyStack.join("-")})`, false);
      const canceled = () => this.popupNotice(`Canceled sequence key`, false);

      triggers.forEach((trigger) => {
         if(isSequenceKey(trigger)) {
            const keys = trigger.split(" ");
            let _from; let _to = from;
            keys.forEach((key, index) => {
               _from = _to;
               _to = `${_to}.${key}`; // key1.key2.key3 와 같은 형태로 이어간다
               if(index < (keys.length-1)) {
                  // 단계별 상태 이전
                  rules.push([[makeKeySymbolFromString(key)], _from, _to, [() => this.refreshSequenceKeyInputTimeout(), (ctx, e, app, key) => this.pushOnKeyStack(key)]]);

                  // 각 단계에서 `ESC` 키를 받으면 시작 상태로 돌아가게 해야함: 모든 상태는 상호간 연결된 상태여야 하기 때문
                  rules.push(
                     [[makeKeySymbolFromString("esc")], _to, from, [() => this.clearSequenceKeyInputTimeout(), canceled, () => this.clearKeyStack()]]
                  );
               } else {
                  // 시퀀스의 마지막 키는 `to`로 이어줘야 한다
                  rules.push([[makeKeySymbolFromString(key)], _from, to, [()=> this.clearSequenceKeyInputTimeout(), (ctx, e, app, key) => this.pushOnKeyStack(key), cb]]);
               }
            });
         } else {
            rules.push([[ makeKeySymbolFromString(trigger)], from, to, cb]);
         }
      });

      return rules;
   }

   focusOut() {
      if(this.ctx.textObject) {
         queueMicrotask(() => this.ctx.textObject.blur());
      // } else if(this.linkedElements.$taConclusion) {
      //    queueMicrotask(() => this.linkedElements.$taConclusion.blur());
      } else {
         this.sendEvent("focusOutFromReportArea");
      }
   }

   // 'ready' 상태에서 'enter'를 입력했을 때, 직전에 작업했던 영역으로 이동시킴.
   // 직전 작업영역이 설정되지 않았다면 'Finding'으로 이동시킴
   moveCursorToPreviousFocusedElement() {
      const preference = this.findUserPreference("toggleTrackingFocus");
      const ta = `$ta${preference.printableValue}`;
      const reportPopup = window.report.get();
      const link = reportPopup ? this.reportPopupElements : this.linkedElements;

      if(reportPopup) window.open("", "reportWindow");

      if(this.ctx.textObject) {
         queueMicrotask(() => {
            this.ctx.textObject.focus();
         });
      } else if(link[ta]){
         queueMicrotask(() => {
            link[ta].focus();
         });
      } else {
         this.sendEvent("focusOnReportArea");
      }
   }

   // ToastBox를 사용자에게 알림을 주는 용도로 사용
   popupNotice(msg="Notice!", isErr=true) {
      document.dispatchEvent(new CustomEvent("toastEvent", {
         bubbles: true, composed: true, detail:{msg, isErr}
      }));
   }

   hideNotice() {
      window.dispatchEvent(new CustomEvent("hideToastEvent"));
   }

   sendEvent(msg="focusOnReportArea") {
      const reportPopup = window.report.get();
      let targetDocument = document;
      if(reportPopup) targetDocument = reportPopup.document;
      targetDocument.dispatchEvent(new CustomEvent(msg));
   }

   waitingConsecutiveKey(ms=300, key=__timeout__) {
      this.timeoutId = setTimeout(()=> {this.timeoutId = null; this.emit(key);}, ms);
   }

   setMaximumExecutionTimeLimit(ms, key=__macro_timeout__) {
      const limit = ms || this.macro_maximum_execution_time_limit;
      this.macro_timerId = setTimeout(()=> {this.macro_timerId = null; this.emit(key);}, limit);
   }

   clearTimeout() {
      if(this.timeoutId) clearTimeout(this.timeoutId);
      this.timeoutId = null;
   }

   clearMacroTimeout() {
      if(this.macro_timerId) clearTimeout(this.macro_timerId);
      this.macro_timerId = null;
   }

   // FLeader 제작 끝 부분에 와서야 비로소 드러난 문제점: 모든 키보드 이벤트를 다 조절하기 위해서는
   // INPUT/TEXTAREA 요소 안에서도 키보드 이벤트를 조절해야 한다. 그러나 Workslist에는
   // 3rd-party 콤포넌트를 가져다 쓰는 부분이 많고, 그 모든 INPUT 요소에 onFocus(), onBlur() 콜백을 설정해서
   // 상태를 관리 할 수 없다는 점이 발견되었다.
   // 아래 함수는 fkey를 변경하는 INPUT 요소안에서 이벤트를 허용한다.
   occurEvent_while_in_InputTag(e) {
      let ignore = false;
      try {
         const path = e.path || e.composedPath && e.composedPath();
         const el = path[0];
         // FLeader의 단축키(fkey)를 바꾸는 INPUT 태그가 아니고 템플릿|매크로 이름표시에 사용되는 INPUT 태그인 경우가 아니면 무시
         if((el.tagName).toLocaleLowerCase() === "input" && (el.className.indexOf("class-function-shortcut-input") === -1 && el.id !== "displayKey" ) ) ignore = true;
      } catch(e) {
      }
      return ignore;
   }

   resetRules( json ) {
      this.states.clear();
      this.groupKeys.clear();
      return this.parseRules(json, this.addStateHandler);
   }

   // 런타임에서 키 이벤트에 대한 처리속도를 올리기 위해, 각 상태에 예약된 '그룹키'를 미리 구성해둔다
   buildGroupKeys() {

      // 런타임에서 점검할 그룹키 순서대로 가져온다.
      // 형식: Map("ready") => Map("key", {from, to, cb})
      const group_keys = checklist_keys.map(({pattern}) => pattern);

      // 상태테이블에 등록된 모든 상태를 순회한다
      [...this.states.keys()].forEach( (st) => { // st: 문자열

         // 현재 상태에 예약된 모든 키를 받는다
         const signals /* Map Object */ = this.states.get(st);

         // 그룹키인 것만 중복없도록 분리한다
         const foundGroupKeys= [...new Set([...signals.keys()].filter(signal => group_keys.includes(signal)))];

         // 패턴:필터의 배열 형태로 모은다 [{pattern, filter}, {pattern, filter}, ...]
         const pattern_filters = foundGroupKeys.map((key) => {
            const [{filter}] = checklist_keys.filter( ({pattern}) => pattern === key);
            return {pattern:key, filter};
         });

         // 최종형태 ex: "ready::groupKeys" => [{pattern, filter}, {pattern, filter}, ...]
         this.groupKeys.set(st, pattern_filters);
      });
   }

   // 상태가 바뀐 직후에 처리 (Pathway의 onChangeState를 Override)
   onChangeState() {
      this.groupKeysForCurrentState = this.groupKeys.get(this.current_state) || [];
   }

   ["await"](trigger=null) {
      this.blocker = trigger;
   }

   // 특정 조건의 trigger가 들어왔다면 큐에 쌓여진 것을 진행시키기 위해 신호를 주는 역활
   fulfilled() {
      this.clearTimeout();
      this.emit(__flush__);  // __fulfilled__ 를 사용하지 않는 건 상태 룰에서 __fulfilled__ 사용을 허용하기 위해서다
   }

   rejected() {
      this.clearTimeout();
      this.emit(__abandon__);
      if(this.ctx.rejected) {
         this.popupNotice(this.ctx.rejected.message, true);
         this.ctx.rejected = null;
      }
   }

   defer_in_Q_if(cond, value) {
      if(this.blocker && cond) this.deferred_Q.push(value);
   }

   // FP.js 모듈에서 제공하는 Noop과 다른 점은, KeyboardEvent 전파를 막기위한 코드가 추가된 것이다
   // NOTE: TEXTAREA 혹은 INPUT 요소에 대해 아래 함수를 쓰지 않도록 주의할 것! (키보드 입력 전달을 멈추기 때문)

   stopPropagation(ctx, event) {
      tryCatch(()=>event.stopPropagation() && event.preventDefault());
   }

   focusOnDialog() {

      // document.activeElement를 사용해서 현재 포커스를 받은 요소가 어디에 있는지 검사할 수도 있으나
      // window.dialog_opened()에 의해 현재 "dialog" 상태에서 'enter'가 입력되면 무조건 해당 다이얼로그로 포커스를 옮긴다
      // 해당 다이얼로그 컨테이너에서 모든 키 입력을 상위로 전파하지 않기 때문에 "dailog" 상태에서 'enter'가 입력된 것은
      // FilmBox 혹은 다른 윈도우에서 입력한 것인줄 안다. 다이얼로그에 이미 포커스가 있는 경우, 'enter'는 Accept의 의미로 사용될 것이다.
      // WARN: 필름박스윈도우에 포커스가 있는 상태에서 다이얼로그 객체에 .focus() 명령을 호출해도 소용없고
      // WARN: 워크리스트 윈도우로 포커스를 옮긴뒤에야 해당 다이얼로그로 포커스를 이동할 수 있다

      if(this.ctx.dialog) {
         try { this.ctx.dialog.focus(); } catch(e) { console_log("focusOnDialog() failed >> ", e); }
      }
   }

   enableStackUpTrigger(sw=true) {
      this.do_not_stackup_trigger = !sw;
   }

   import_hpacs_symbols(symbol_set) {
      const app = this;
      let count = 10;
      const { name } = symbol_set;
      const check_timer = setInterval(() => {
         if( symbol_set.__element_refers__) {
            clearInterval(check_timer);
            symbol_set.__element_refers__.forEach((item)=> {
               if(name === "reportWindow") {
                  app.reportPopupElements[item.id] = item.value;
               } else {
                  app.linkedElements[item.id] = item.value;
               }
            });
            console_log("found hpacs symbols: ", symbol_set.__element_refers__);
         } else if(count === 0) {
            clearInterval(check_timer);
            throw new Error("can not import HPACS symbols during 10 seconds");
         } else {
            count -= 1;
         }
      },1000);
   }

   set_hpacs_symbol(window, id, symbol) {
      const app = this;
      const { name } = window;

      const setSymbol = (win, key, el) => {
         if (win === "reportWindow") {
            app.reportPopupElements[key] = el;
         } else {
            app.linkedElements[key] = el;
         }
      }

      if (symbol !== undefined) {
         setSymbol(name, id, symbol);
      } else if (window.__element_refers__) {
         const item = window.__element_refers__.find((item) => item?.id === id);
         if (item) setSymbol(name, item.id, item.value);
      }
   }

   disable_handler(states=[], types=[]) {
      const app = this;
      states.forEach((state) => {
         if(Object.is(this.disabled_event_type[state], undefined)) this.disabled_event_type[state] = {};
         types.forEach(function set(type) { app.disabled_event_type[state][type] = true; });
      });
   }

   is_disabled_handler(type, state) {
      return this.disabled_event_type[state||this.current_state] && this.disabled_event_type[state||this.current_state][type];
   }

   forceCloseAllDialogs() {
      let dialog = this.dialog_objects.pop();
      while(dialog) {
         if (dialog.close) dialog.close();
         dialog = this.dialog_objects.pop();
      }
   }

   forceCloseDialog() {
      if(this.ctx.dialog) {
         if (this.ctx.dialog) this.ctx.dialog.close();
      }
   }

   isDialog() {
      return this.ctx.is_dialog;
   }

   clearDialogObjects() {
      this.dialog_objects = [];
   }

   warn_if_capslock_is_on(ctx, event) {
      Either.of(event)
         .filter((e) => e instanceof KeyboardEvent)
         .doneIf((e) => e.getModifierState('CapsLock'))  // CapsLock을 사용하는 경우 .fold()로 JUMP
         .filter(({key}) => !KBDCommon.isHangul(key))    // 한글은 대소문자가 없으므로, 한글상태에 있으면 전체 루틴은 무시한다
         .filter(({key, shiftKey}) => (String(key).toUpperCase() === key) !== shiftKey)
         .fold(()=> {
            this.map_on_top_keyStack(path=> String(path).toLowerCase());
            this.popupNotice("CapsLock is On !", true);
         });
   }

   warn_if_hangul(ctx, event) {
      Either.of(event)
         .filter((e) => e instanceof KeyboardEvent)
         .filter(({key}) => KBDCommon.isHangul(key) )
         .fold(() => this.popupNotice("Hangul mode!", true));
   }

   back_to_basic_mode() {
      this.keyStack = ["space"];

   }

   map_on_top_keyStack(f) {
      if(this.keyStack.length) {
         this.keyStack.push(f(this.keyStack.pop()));
      }
   }

   addMenuPath(key) {
      if(! this.do_not_stackup_trigger ) this.menuPathStack.push(key);
   }

   getMenuPath() {
      return `${this.menuPathStack.join("-")} - `;
   }

   clearMenuPathStack() {
      this.menuPathStack = [];
   }

   popMenuPath() {
      return this.menuPathStack.pop();
   }

   // 판독문 입력도중 alt+key 를 눌러서 명령을 수행한 경우,확장문자가 입력된 경우 삭제함
   // FLeader 구조적인 이유로 해당 키가 들어왔을 때 막거나 즉시 삭제할 수 없다
   remove_last_char_in_report_area_If_alt_compositedKey(event) {

      if(event.ctrlKey || event.metaKey) {
         // 이 경우에는 문자가 출력되지 않는다
         return;
      }

      const ta = this.ctx.textObject;
      if(!ta) {
         console_log(`FLeader.removeLastCharInReportAreaIfAltCompositedKey() -- 직전의 판독문이 입력되던 TEXTAREA 객체가 FLeader.ctx.textObject 에 등록되지 않아 작업을 진행할 수 없습니다 `);
      } else {
         ta.value = ta.value.substr(0, ta.value.length - 1);
      }
   }

   // workaround for QA #15083
   toggleDblShiftEscaping() {
      const app = this;
      const dbkey = "useDblShiftEscaping";
      const useDblShiftEscaping = parseInt(localStorage.getItem(dbkey), 10);
      this.useDblShiftEscaping = [0,1].some(allowed_value => useDblShiftEscaping === allowed_value) ? useDblShiftEscaping : 0;
      this.useDblShiftEscaping = 1 - this.useDblShiftEscaping;
      localStorage.setItem(dbkey, this.useDblShiftEscaping);

      this.popupNotice(  `Shift-Shift escaping is ${ app.useDblShiftEscaping ? "enabled" : "disabled"}`, false);
   }

   findUserPreference(functionName) {
      return (this.menuJSON["userPreferences"] || [])
         .filter( preference => preference.functionName === functionName )
         .shift();
   }

   setPreviousUserPreferences() {
      this.previousUserPreferences = JSON.parse(JSON.stringify(this.menuJSON.userPreferences));
   }

   findPreviousUserPreferences( functionName ) {
      return (this.previousUserPreferences || [])
         .filter( preference => preference.functionName === functionName )
         .shift();
   }

   isUserPreferencesChanged( functionName, property="value" ) {
      const prev = this.findPreviousUserPreferences( functionName );
      const curr = this.findUserPreference( functionName );
      console_log(functionName, "prev:", prev);
      console_log(functionName, "curr:", curr);
      return tryCatch(() => prev[property] !== curr[property])[0]; // [boolean, value]
   }

   applyUserPreferences() {
      const param = {
         detail: {
            value: this.menuJSON["userPreferences"],
            category:"userPreferences"
         }
      };
      document.dispatchEvent(new CustomEvent("callShortcutUpdateEvent",param));

      // userPreferences에서는 fkey를 변경할 수 없다! (Temporary)
      // this.parse(this.menuJSON, this.addStateHandler, /* Don't override previous addState callback */false);
   }

   restoreUserPreferences() {
      this.menuJSON.userPreferences = this.previousUserPreferences;
   }
}
