import React, { useState } from "react";
import {
   Stack,
   Divider,
   Popper,
   Paper,
   MenuList,
   Button,
   Icon,
   Grow,
   ClickAwayListener, styled,
} from "@mui/material";
// import { styled } from "@mui/styles";
import MenuItem from "./MenuItem";

type Item = {
    id?: string,
    name?: string,
    icon?: string,
    size?: number,
    childNode?: Item[],
    separator: boolean
}

type PopperState = {
   open: boolean,
   anchorEl: HTMLButtonElement | null,
   item: Item | undefined,
};

const StyledContainer = styled("div")({
   display: "inline-block",
});

const StyledButton = styled(Button)(props => ({
   color: "#ccc",
   fontSize: "15px",
   fontWeight: "lighter",
   textTransform: "none",
   borderRadius: "unset",
   padding: "0 10px",
   "&:hover": {
      color: "#fff",
      backgroundColor: "#0087cb",
   },
   "& .MuiSvgIcon-root": {
      color: "#ccc",
   },
}));

const StyledPaper = styled(Paper)({
   color: "#ccc",
   borderBottomLeftRadius: "7px",
   borderBottomRightRadius: "7px",
   backgroundColor: "#1d1e25",
   border: "1px solid #4C5667",
   height: "auto",
});

const StyledDivider = styled(Divider)({
   borderBottom: "1px solid #4C5667",
});

function Menu(props: { menuItems: Item[], handleMenuClick: Function }) {
   const { menuItems, handleMenuClick } = props;

   const [state, setState] = useState<PopperState | null>(null);

   const handleToggle = (event: { currentTarget: HTMLButtonElement; }) => {
      const anchorEl = event.currentTarget;
      setState({ open: true, anchorEl, item: (menuItems ?? []).find((item: Item) => item.id === anchorEl?.dataset.key) });
   };

   const handleClose = () => {
      if (state) setState(null);
   };

   const handleClick = (event: any) => {
      event.stopPropagation();
      if (handleMenuClick) handleMenuClick(event);

      const { dataset } = event.currentTarget;
      if ((dataset?.children ?? []).length === 0) {
         handleClose();
      }
   };

   // function handleListKeyDown(event: React.KeyboardEvent) {
   //    if (event.key === "Tab") {
   //       event.preventDefault();
   //       setAnchorEl(null);
   //    } else if (event.key === "Escape") {
   //       setAnchorEl(null);
   //    }
   // }

   const onClickAway = () => {
      handleClose();
   };

   const buttonIconProps = (icon: string | undefined) => (icon ? { startIcon: <Icon>{icon}</Icon> } : {});

   return (
      <>
         <ClickAwayListener onClickAway={onClickAway}>
            <Stack direction="row" spacing={0} onMouseLeave={handleClose}>
               {menuItems.map((item: Item, idx: number) =>
                  <StyledContainer key={idx}>
                     <StyledButton variant="text" data-key={item.id} data-children={item.childNode} onMouseEnter={handleToggle} onClick={handleClick} { ...buttonIconProps(item.icon) }>
                        {item.name}
                     </StyledButton>
                  </StyledContainer>)}
               {(state?.item?.childNode ?? []).length > 0
               && <Popper open={state?.open ?? false}
                  anchorEl={state?.anchorEl ?? null}
                  role={undefined}
                  placement="bottom-start"
                  transition
               >
                  {({ TransitionProps, placement }) => (
                     <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom-start" ? "left top" : "left bottom" }}>
                        <StyledPaper>
                           <MenuList autoFocusItem={state?.open ?? false}>
                              {(state?.item?.childNode ?? [])
                                 .map((child: Item, idx: number) => (child.id ? (
                                    <MenuItem key={idx} item={child} data-children={child.childNode} onClick={handleClick}>
                                       <MenuItem/>
                                    </MenuItem>
                                 ) : (<StyledDivider key={idx} />)))}
                           </MenuList>
                        </StyledPaper>
                     </Grow>
                  )}
               </Popper>}
            </Stack>
         </ClickAwayListener>
      </>
   );
}
export default Menu;
