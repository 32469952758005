/* eslint-disable no-else-return */
// eslint-disable-next-line
import kbd_common from "./kbdevent-common.mjs";

function is_mod_or_workey(e) {
   return kbd_common.isWordKey(e) || kbd_common.isModKey(e);
}

function get_keyinfo_from_wordkey(e) {
   const normalized = kbd_common.getWordKey(e);
   const printable  = kbd_common.getPrintableWordKey(e);
   const modkeys    = kbd_common.get_computed_mod_keys(e);
   const action     = kbd_common.pickBestActrionFromKeyInfo(normalized, [...modkeys]);
   return {
      printable,
      normalized,
      keycode:             e.which,
      action,
      is_alt_composed_key: modkeys.has("alt"),
      is_hangul:           false,
      is_non_prinable:     false,
      is_word_key:         true,
      is_mod_key:          false,
      modkeys:             [...modkeys]
   };
}

// WARN: 오직 keyrepss + normal ASCII 의 경우에만 호출될 수 있다!
// INFO: 아래 함수는 ASCII Code value: 32~126 사이의 코드에 대해서 처리한다
// @keypress 상태전용
function get_keyinfo_from_normal_ascii(e) {
   const modkeys        = kbd_common.get_computed_mod_keys(e); // 설정된다해도 "shift" 만 있어야 한다
   const printable      = e.key;
   const lowercase_key  = kbd_common.normal_ascii_shift_map[e.key];
   const normalized     = lowercase_key || e.key;

   // INFO: 이 함수 내에서는 ASCII 에 대해서만 고려하면 된다
   // WARN: capslock이 눌려져 있다해도 "shift"를 누른 것과 동일한 키로 처리해서는 안된다
   const is_capslock_on = (/* UpperCase -> lowercase */lowercase_key) ? !e.shiftKey : false;
   const action         = kbd_common.pickBestActrionFromKeyInfo(normalized, [...modkeys]);

   return {
      printable,
      normalized,
      action,
      is_hangul:           false, // 이 함수는 오직 32~126 ASCII Code 값의 문자에 대해서만 처리하기 때문이다
      is_capslock_on,
      is_non_prinable:     false,
      is_alt_composed_key: false,
      is_mod_key:          false,
      modkeys:             [...modkeys]
   };
}

function get_keyinfo_from_alt_composited(e) {
   const index_in_normal_map = kbd_common.alted_keys.indexOf(e.key);
   const index_in_shift_map  = kbd_common.shift_altedkeys.indexOf(e.key);
   const modkeys             = kbd_common.get_computed_mod_keys(e); // 설정된다해도 "alt 또는 shift" 만 있어야 한다
   let printable             = e.key;
   let normalized;

   if (kbd_common.isDeadKey(e)) {
      normalized = kbd_common.deadkey_to_ascii_map[e.which];
      printable = kbd_common.alted_keys[kbd_common.alted_keys_ascii.indexOf(normalized)];

   } else if (kbd_common.ascii_key_pattern.test(e.key)) {
      // keypress 핸들러에서도 한글상태에서 alt 조합키가 눌려지면 e.key는 한글이 아니라 ASCII 값을 가진다.
      // kbd_common.isHangul() 함수는 altKey가 눌려지지 않은 상태만을 점검하며 KeyboardEvent 객체의 값만으로는 한글이 눌려진 것을 알 수 없다
      const lowercaseKey = kbd_common.normal_ascii_shift_map[e.key];
      if (lowercaseKey) {
         normalized = lowercaseKey;
         printable = lowercaseKey;
      } else {
         normalized = e.key;
         printable = e.key;
      }

   } else if(index_in_normal_map !== -1) {
      normalized = kbd_common.alted_keys_ascii[index_in_normal_map];

   } else if(index_in_shift_map !== -1) {
      normalized = kbd_common.shift_altedkeys_ascii[index_in_shift_map];

   } else {
      console.log(`could not handle this case in <get_keyinfo_from_alt_composited<keypress>()`);
      console.trace(e);

      document.dispatchEvent(new CustomEvent("toastEvent", {
         bubbles: true, composed: true, detail: { msg: "Please check you are in Hangul Mode or CapsLock pressed", isErr: true }
      }));
      return {has_exception: true };
   }

   const is_capslock_on = kbd_common.shift_altedkey_to_ascii_map[e.key] ?  !e.shiftKey : false;
   const action         = kbd_common.pickBestActrionFromKeyInfo(normalized, [...modkeys]);

   return {
      printable,
      normalized,
      action,
      is_hangul:           false,    // altKey 설정이 되어 있으면 한글은 들어올 수 없다
      is_capslock_on,
      is_non_prinable:     false,
      is_alt_composed_key: true,
      is_mod_key:          false,   // modkey 검사와 처리는 다른 함수에서 이미 진행했다
      modkeys:             [...modkeys]
   };
}

export function get_keyinfo_from_keypress_event(e) {

   // console.log(e.key, e.which, e.code, kbd_common.isWordKey(e));

   if(kbd_common.isWordKey(e)) {
      // "space", "enter", "tab", "backspace", "doublequote"
      return get_keyinfo_from_wordkey(e);

   } else if(kbd_common.isModKey(e)) {
      // "esc", "shift", "ctrl", "alt", "meta", "caplock", "backspace", "pageup", ...
      // keypress 핸들러는 호출되지 않는다
      return kbd_common.get_keyinfo_from_modkey(e);

   } else if(kbd_common.isHangul(e.key)) {
      // WARN: 'altKey'가 설정되어 있을 경우, e.key 값으로는 한글여부를 알 수 없다!
      // This must be preceed to "hasNonASCIIDomainKey()" check, for its internal check routine.
      // If this routine is behinded to it, get_keyinfo_from_normal_ascii_keypress() routine have to deal with Hangul.
      // It will be ok even if ctrlKey|metaKey composited event
      // meta composited key could not be here
      return kbd_common.get_keyinfo_from_hangul(e);

   } else if(!kbd_common.hasNonASCIIDomainKey(e)) {
      // For the case of having no altKey|ctrlKey|metaKey.
      // Only for [a-zA-Z0-9] & special characters (`~!@#$%^&*()-_=+[{]}\|;:'"/?.>,<)
      return get_keyinfo_from_normal_ascii(e);

   } else if(kbd_common.is_alt_composed_key(e)) {
      // alt+key ==> extended character (IMPORTANT: if ctrlKey or metaKey, then the character is not extended one any more. So we have to exclude that conditions)
      return get_keyinfo_from_alt_composited(e);

   } else { /* ctrlKey || metaKey */
      // then, this block should be the rest of all: i.e) contextMenu,
      // ASCII [a-zA-Z0-9] 문자역시 들어올 수 있다
      console.log(e);
      return kbd_common.get_keyinfo_from_ctrl_meta(e);
   }
}

export default {
   is_mod_or_workey,
   get_keyinfo_from_wordkey,
   get_keyinfo_from_normal_ascii,
   get_keyinfo_from_alt_composited,
   get_keyinfo_from_keypress_event,
};
