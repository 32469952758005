/* eslint-disable no-lonely-if */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/paper-tabs";
import "@polymer/iron-pages";
import "@vaadin/vaadin-icons";
import "./hh-clinical-info";
import "./hh-thumbnail";
import "./hh-tech-report";
import "@vaadin/vaadin-split-layout";
import moment from "moment-timezone";
import CommonUtils from "../../public/resource/js/utils/common";
import mixinCommons from "../common-mixin";
import store from "../redux/store";
import { QueryKey } from "../redux/reducers/query.ts";

/**
 * `hh-tech-window`
 *
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
class HhTechWindow extends mixinCommons(PolymerElement) {
   static get template() {
      return html`
         <style>
         :host {
            display: block;
            width: 100%;
            height: 100%;
            background-color: #2d333e;
         }

         .container {
            display: flex;
            flex: 1 1;
            flex-direction: column;
            width: 100%;
            height: 100%;
         }

         paper-tabs {
            --paper-tabs-selection-bar: {
               border-bottom: 3px solid #0087cb;
               color: #2baab1;
            };
            --paper-tabs-content: {
               font-weight: bold;
               color: #aaaaaa;
               font-size: 13px;
            };
            background-color: #252934;
            box-shadow: 0px 3px 9px -2px #000000;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            height: 30px;
         }

         paper-tabs paper-tab.iron-selected {
            color: #2baab1;
         }

         paper-tabs paper-tab[disabled] {
            color: #424242;
         }

         iron-pages {
            width: 100%;
            height : calc(100% - 30px);
            background-color: rgba(0, 0, 0, 0.10);
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
         }

         .container-header{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 10px;
            padding-right: 10px;
            background-color: #252934;
            height: 25px;
            font-weight: bold;
            font-size: 12px;
            box-shadow: 0px 3px 9px -2px #000000;
         }

         input{
            color: #aaa;
            height: 12px;
            font-weight: bold;
            font-size: 12px;
            background-color: #252934;
            border: 0px;
            align-items: center;
            text-align: -webkit-center;
         }

         .class-title-window{
            color: gray;
            width: 122px;
         }

         .class-title{
            height: 27px;
            width: 100%;
            align-self: center;
            background-color: #252934;
            box-shadow: 0px 3px 9px -2px #000000;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            font-weight: bold;
            color: #2baab1;
            font-size: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 3px solid #0087cb;
            font-family: var(--paper-font-common-base_-_font-family);
         }

         .class-title-patientInfo {
            color: #aaa;
            width: calc(100% - 90px);
            text-align: center;

            -webkit-user-select: text;   /* Safari */
            -khtml-user-select: text;    /* Konqueror HTML */
            -moz-user-select: text;      /* Firefox */
            -ms-user-select: text;       /* Internet Explorer/Edge */
            user-select: text;           /* Non-prefixed version, currently supported by Chrome and Opera */
         }

         .class-div-thumbnail{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            background-color: rgba(255,0,0,0);
         }

         .class-div-clinicalInfo{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            background-color: rgba(255,0,0,0);
         }

         .class-div-functionPanel{
            height: 110px;
            width: 100%;
            background-color: rgba(255,0,0,0);
         }

         .class-content{
            height: calc(100% - 30px);
            width: 100%;
            background-color: rgba(0, 0, 0, 0.10);
            display: flex;
            justify-content: center;
            align-items: center;
         }

         .class-tab-content{
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.10);
            display: flex;
            justify-content: center;
            align-items: center;
         }

         .container-body {
            height:calc(100% - 25px);
            width: 100%;
         }

         .wrap-component {
            height: calc(100% - 10px);
            width: calc(100% - 10px);
         }

         .container-body-split {
            height: 100%;
            width: 100%;
         }

         .body-top {
            height: 300px;
            width: 100%;
            min-height: 210px;
         }

         .body-bottom {
            height: calc(100% - 300px);
            width: 100%;
            min-height: 237px;
         }
         </style>

         <div class="container">
            <div class="container-header">
               <div class="class-title-window">
                  {{t("label.techWindow")}}
               </div>

               <div class="class-title-patientInfo">
                  <template is="dom-if" if="{{patient}}">
                     {{patient.id}}&nbsp{{patient.name}}&nbsp{{patient.sex}}&nbsp{{patient.age}}
                  </template>
               </div>
            </div>


            <div class="container-body" id="windowReport">
               <vaadin-split-layout id="containerBodySplit" class="container-body-split" orientation="vertical" theme="minimal">
                  <div id="splitBodyTop" class="body-top">

                     <div class="class-div-thumbnail" >
                        <div class="wrap-component">
                           <div class="class-title">
                              {{t("label.thumbnail")}}
                           </div>
                           <div class="class-content">
                              <div class="wrap-component">
                                 <hh-thumbnail id="techThumbnail" log="{{log}}"></hh-thumbnail>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div id="splitBodyBottom" class="body-bottom">
                     <paper-tabs selected="{{tabIdx}}" noink>
                        <paper-tab id="cTab">{{t("label.clinicalInfo")}}</paper-tab>
                        <paper-tab>{{t("label.report")}}</paper-tab>
                     </paper-tabs>

                     <iron-pages selected="{{tabIdx}}">
                        <div class="class-div-clinicalInfo">
                           <div class="wrap-component">
                              <div class="class-tab-content">
                                 <div class="wrap-component">
                                    <hh-clinical-info id="techClinicalInfo" selected-row="[[reportRow.id]]"></hh-clinical-info>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="class-div-clinicalInfo">
                           <div class="wrap-component">
                              <div class="class-tab-content">
                                 <div class="wrap-component">
                                    <hh-tech-report id="techReport"></hh-tech-report>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </iron-pages>
                  </div>

               </vaadin-split-layout>
            </div>
         </div>
      `;
   }

   static get is() {
      return "hh-tech-window";
   }

   static get properties() {
      return {
         reportRow: {
            type: Object,
            observer: "onSelectReport",
         },
         category: {
            type: Number
         },
         g_selectedRow: {
            type: Object,
            observer: "onSelectTechRow",
         },
         patient: {
            type: Object
         },
         tabIdx: {
            type: Number,
            value: 0
         },
         splitSize: {
            type: Object,
            value: {
               "splitBodyTopTech": "300"
            }
         },
         log: {
            type: String,
            value: "hh-tech-window"
         }
      };
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         this.category = store.getState().common.category;
         this.g_selectedRow = store.getState().techlist.row?.detail;
         this.reportRow = store.getState().techlistReport.reportRow?.detail;
      });

      this.clinicalInfoReadOnly(false);

      this.getUserStyle().then((result) => {
         if(result.layout) {
            const {splitBodyTopTech} = result.layout;
            if(splitBodyTopTech) this.splitSize.splitBodyTopTech = splitBodyTopTech;
         } else {
            this.updateSplitLayout(this.splitSize);
         }
      }).catch((err) => {
         console.info(err);
      });

      this.$.containerBodySplit.addEventListener("iron-resize", () => {
         this.resize();
      });

      this.$.containerBodySplit.addEventListener("splitter-dragend", (e) => {
         const splitBodyTopTech = e.target.querySelector("#splitBodyTop").offsetHeight;
         this.updateSplitLayout({
            splitBodyTopTech
         });
      });

      // tab title에 style 적용이 되지 않아서 tabIdx를 변경함(이유 모름)
      setTimeout(() => { this.tabIdx = 1;});
   }

   // isEmpty(str) {
   //    if(str === undefined || str === null || str === "")
   //       return true;
   //    return false;
   // }

   onSelectTechRow(row) {
      // console.log(`-> [${this.log}] onSelectTechRow`, row, this.category);
      if (this.category !== 1) return;
      if (!row) return;

      this.setPatientInfo(row);
      // this.setThumbnail(row.id);
      // this.setClinicalInfo(row.id);
      // this.setTechReport(row);
   }

   onSelectReport(row) {
      // console.log(`-> [${this.log}] onSelectReport`, id, this.category);

      if (!row) {
         this.clearTechWindow();
      } else {
         this.setThumbnail(row.id);
         this.setClinicalInfo(row.id);
         this.setTechReport(row);
      }
   }

   nvl(str, defaultStr) {
      let reStr = str;
      if(CommonUtils.isEmptyValue(reStr)) reStr = defaultStr;

      return reStr;
   }

   setPatientInfo(row) {

      this.patient = null;    // 기존 patinet 초기화
      if(!row) return;

      const {patientID, patientName, patientSex, patientAge} = row;
      const patient = {
         id: this.nvl(patientID, "N/A"),
         name: this.nvl(patientName, "N/A"),
         sex: this.nvl(patientSex, "N/A"),
         age: this.nvl(patientAge, "N/A")
      };
      this.patient = patient;
   }

   setSelectedRow(row){
      this.g_selectedRow = row;
   }

   clinicalInfoReadOnly(flag){
      if(flag || null){
         // readyOnly
         this.$.techClinicalInfo.hiddenClinicalInfo(true);
      }else{
         // modify
         this.$.techClinicalInfo.hiddenClinicalInfo(false);
      }
   }// cc change

   setClinicalInfo(id) {
      this.$.techClinicalInfo.fetchClinicalInfo(id);
   }

   isChangeCcInfo() {
      return this.$.techClinicalInfo.isChangeCcInfo();
   }

   setThumbnail(id) {
      this.$.techThumbnail.fetchThumbnail(id);
   }

   clearThumbnail() {
      /* TODO : Thumbnail 오류 수정후 주석 제거 */
      this.$.techThumbnail.clearThumbnail();
   }

   clearClinicalInfo() {
      this.$.techClinicalInfo.clearClinicalInfo();
   }

   clearPatientInfo(){
      this.patient = null;    // 기존 patinet 초기화
      const patient = {};
      patient.id = null;
      patient.name = null;
      patient.sex = null;
      patient.age = null;
      this.patient = patient;
   }

   clearReport() {
      this.$.techReport.initTextArea();
   }

   initTabIdx() {
      this.tabIdx = 0;
   }

   diabledThumbnail(){
      this.$.techThumbnail.style.display = "none";
   }

   abledThumbnail(){
      this.$.techThumbnail.style.display = "block";
   }

   setThumbnailInit(){
      this.$.techThumbnail.clearThumbnail();
   }

   setOpinion(opinion) {
      this.$.techReport.setOpinion(opinion);
   }

   enableAddendumList(){
      this.$.techReport.enableAddendumList();
   }

   disableAddendumList(){
      this.$.techReport.disableAddendumList();
   }

   disabledAddendumAccordionList(){
      this.$.techReport.disabledAddendumAccordionList();
   }

   ableAddendumAccordionList(){
      this.$.techReport.ableAddendumAccordionList();
   }

   updateSplitLayout(layout) {
      fetch(`/api/user/option/style/layout`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(layout)
      }).then((response) => {
         if (!response.ok) {
            console.debug(new Error(`${response.status} ${response.statusText}`));
         }
      });
   }

   getUserStyle() {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/style`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   setSize() {
      const {splitBodyTopTech} = this.splitSize;

      this.$.splitBodyTop.style.height = `${splitBodyTopTech}px`;
      this.$.splitBodyBottom.style.height = `calc(100% - ${splitBodyTopTech}px)`;
   }

   resize() {
      if (this.category === 1 && this.g_selectedRow && !CommonUtils.isEmptyObject(this.g_selectedRow)) {
         this.$.techThumbnail.resizeCanvas();
         this.$.techThumbnail.redrawCellThumbnail();
      }
   }

   setTechReport(row) {
      const user = JSON.parse(localStorage.getItem("user"));

      // 판독문이 존재할 경우(RS : 2T, 3A)
      switch (row?.readingStatus){
      case "3A":
         this.getOpinionByWeever(row.id).then((opinionResult) => {
            this.getAllAddendumByWeever(row.id).then((addendumList) =>{
               const elipseTime = this.elapsedTimeCheck(opinionResult.approveTime); // min
               if(elipseTime <= 4320 && user.id === opinionResult.userId && addendumList == null){
                  this.disableAddendumList();
                  this.setOpinion({finding:opinionResult.finding, conclusion:opinionResult.conclusion, recommendation:opinionResult.recommendation});
               }else{
                  this.enableAddendumList();

                  const opinion = {};
                  opinion.finding = opinionResult.finding;
                  opinion.conclusion = opinionResult.conclusion;
                  opinion.recommendation = opinionResult.recommendation;
                  if(opinionResult.approver){
                     opinion.approver = opinionResult.approver;

                     if(opinionResult.approveTime) {
                        const approveDtime = new Date(parseInt(opinionResult.approveTime, 10));
                        opinion.approveDtime = this.getMilisecToDate(approveDtime);
                     }
                  }else{
                     if(opinionResult.owner){
                        opinion.approver = opinionResult.owner;
                     }else{
                        opinion.approver = "";
                     }

                     if(opinionResult.approveTime) {
                        const approveDtime = new Date(parseInt(opinionResult.approveTime, 10));
                        opinion.approveDtime = this.getMilisecToDate(approveDtime);
                     }
                  }

                  if(addendumList && addendumList.length > 0) {
                     this.ableAddendumAccordionList();
                     const tempList = [];
                     for (let i = 0; i < addendumList.length; i++) {
                        const addendum = {};
                        if (addendumList[i].finding) {
                           if (addendumList[i].finding) {
                              addendum.addendum = addendumList[i].finding;
                           }

                           if (addendumList[i].conclusion) {
                              addendum.addendum += ` ${  addendumList[i].conclusion}`;
                           }

                           if (addendumList[i].recommendation) {
                              addendum.addendum += ` ${  addendumList[i].recommendation}`;
                           }
                        } else if (addendumList[i].conclusion) {
                           addendum.addendum = addendumList[i].conclusion;
                        }

                        // console.log("addendum list descending")
                        const addendumDate = new Date(parseInt(addendumList[i].approveTime, 10));
                        addendum.addendumDtime = this.getMilisecToDate(addendumDate );
                        addendum.addemdumer = addendumList[i].approver;
                        tempList.push(addendum);
                     }
                     opinion.addendumList = tempList;
                  }else{
                     this.disabledAddendumAccordionList();
                  }
                  // this.$.hhTechWindow.setOpinion(opinion);
                  this.setOpinion(Object.assign(opinion, {study: row}));
               }
            });
         });
         break;
      case "2T":
         // 선택한 케이스가 판독이 되지 않았을때
         this.disableAddendumList();

         this.getOpinionByWeever(row.id).then((result) => {
            if(result.userId === user.id){
               // 저장된 임시 판독문을 내가  다시 확인할때
               // save (Hold)
               this.setOpinion({finding:result.finding, conclusion:result.conclusion, recommendation:result.recommendation});
            }else{
               // Save (Open)
               this.clearReport();
            }
         });
         break;
      case "2DT":
         // 선택한 케이스가 판독이 되지 않았을때
         this.disableAddendumList();

         this.getOpinionByWeever(row.id).then((result) => {
            // transcribe 열람 가능 조건
            if( (result.transcribeReadRoles === "User" && result.userId === user.id)
                  || (result.transcribeReadRoles === "Group" && result.isContainsMembers)
                  || (result.transcribeReadRoles === "All" && (result.userId === user.id || result.isContainsMembers || result.isContainsFacilityMembers)) ) {
               this.setOpinion({finding:result.finding, conclusion:result.conclusion, recommendation:result.recommendation});
            }else{
               this.clearReport();
            }
         });
         break;
      default:
         this.disableAddendumList();
         this.clearReport();
         break;
      }
   }

   elapsedTimeCheck(date){
      const approveTime = new Date(parseInt(date, 10));
      const nowTime = new Date();
      return moment(nowTime)
         .diff(approveTime, "minutes");
   }

   /**
    * 입력된 mlisecond 값을 Date 형식으로 변환
    *
    * Create by BohyunJang on 2019-09-06 오후 3:11
    * */
   getMilisecToDate(milisec){
      const year = milisec.getFullYear();
      let month = milisec.getMonth()+1;
      if(month < 10) month = `0${month}`;
      let date = milisec.getDate();
      if(date < 10) date = `0${date}`;
      let hour = milisec.getHours();
      if(hour < 10) hour = `0${hour}`;
      let min = milisec.getMinutes();
      if(min < 10) min = `0${min}`;
      let sec = milisec.getSeconds();
      if(sec < 10) sec = `0${sec}`;
      return `${year}-${month}-${date} ${hour}:${min}:${sec}`;
   }

   getOpinionByWeever(id) {
      return new Promise((resolve) => {
         fetch(`/api/opinion/weever/${id}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               resolve("failed");
            }
         });
      });
   }

   getAllAddendumByWeever(id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/opinion/addendums/${id}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            }
            else if(response.status === 204){
               resolve(null);
            }else{
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   clearTechWindow() {
      // clearTechTabFunctionEvent
      this.clearPatientInfo();
      // clearTechTabThumbnailEvent
      this.clearThumbnail();
      // clearTechTabFunctionEvent
      this.clearClinicalInfo();
      // clearTechTabReportEvent
      this.disableAddendumList();
      this.clearReport();
   }

}

window.customElements.define(HhTechWindow.is, HhTechWindow);
