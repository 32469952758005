/* eslint-disable import/extensions */
import kbd_common from "./kbdevent-common.mjs";
import { get_keyinfo_from_keypress_event } from "./keypress.mjs";
import { get_keyinfo_from_keyup_event} from "./keyup.mjs";
import { isFalsy } from "./FP.mjs";

// const console_log = (...args) => window.__debug__ && console.log(...args);

/* WARN: NOT keypress event.which, BUT keyup event.which value !!!!!!! */
const _MODKEY_MAP = {
   0: "null", 1: "soh", 2: "stx", 3: "etx", 4: "eot", 5: "enq", 6: "ack", 7: "bel", 8: "backspace", 9: "tab", 10: "lf",
   11: "vt", 12: "ff", 13: "enter", 14: "so", 15: "si", 16: "shift", 17: "ctrl", 18: "alt", 19: "dc3", 20: "capslock",
   21: "nak", 22: "syn", 23: "etb", 24: "cancel", 25: "em", 26: "sub", 27: "esc", 28: "fs", 29: "gs", 30: "rs",
   31: "us", 32: "space", 33: "pageup", 34: "pagedown", 35: "end", 36: "home", 37: "left", 38: "up", 39: "right", 40: "down",
   41: "rparen", 45: "help", 46: "delete", 92:"backslash", 224: "meta"
};

// const _MODKEY_REVERSE_MAP = Object.values(_MODKEY_MAP);


// eslint-disable-next-line
const DEADKEY_MAP = {
   69:"´",
   73:"ˆ",
   78:"˜",
   85:"¨",
};

const _SHIFT_MAP = {
   "~": "`", "!": "1", "@": "2", "#": "3", "$": "4", "%": "5",
   "^": "6", "&": "7", "*": "8", "(": "9", ")": "0", "_": "-",
   "+": "=", "{": "[", "}": "]", "|":"\\", ":": ";",
   "–":"-", "≠": "=", "“": "[", "‘": "]","…":";", "æ":"'", "≤":",", "≥":".", "÷":"/", "«":"backslash",
   // eslint-disable-next-line
   '"': "'", "<": ",", ">": ".", "?": "/",
   "A": "a", "B": "b", "C": "c", "D": "d", "E": "e", "F": "f",
   "G": "g", "H": "h", "I": "i", "J": "j", "K": "k", "L": "l",
   "M": "m", "N": "n", "O": "o", "P": "p", "Q": "q", "R": "r",
   "S": "s", "T": "t", "U": "u", "V": "v", "W": "w", "X": "x",
   "Y": "y", "Z": "z",
   "Å": "a", "ı": "b", "Ç": "c", "Î": "d", "Ï": "f",
   "˝": "g", "Ó": "h", "Ô": "j", "": "k", "Ò": "l",
   "Â": "m", "Ø": "o", "∏": "p", "Œ": "q", "‰": "r",
   "Í": "s", "ˇ": "t", "¨": "u", "◊": "v", "„": "w", "˛": "x", "Á":"y", "¸": "z",
   "‚" : "0", "⁄":"1", "€":"2", "›":"4", "ﬁ":"5", "ﬂ":"6", "‡":"7", "°":"8", "·":"9",
   "—":"-", "±":"+","”":"[", "’":"]", "»":"backslash", "Ú":";", "Æ":"'", "¯":",", "˘":".", "¿":"/"
};

// const non_stackable_keys = (function get_non_stackable_keys() {
//    const keys = new Set([...kbd_common.MOD_KEYS]);
//    keys.delete("space"); keys.delete("+");
//    return keys;
// })(kbd_common.SPECIAL_ALIASES);


// '이름' => 코드
let _REVERSE_MAP = {};

// --------------------------------------------------------------------------------------------------------------------------


export const normalizeSpecialKeyName = key => kbd_common.SPECIAL_ALIASES[key] ? kbd_common.SPECIAL_ALIASES[key] : "";


// i.e; "alt+A" ==> {key:a, modifiers:[alt,shift], action: keyup}
// eslint-disable-next-line
export function  getKeyInfoFromEvent(e) {
   // console.log(e.type, e);
   return (e.type === "keypress") ?  get_keyinfo_from_keypress_event(e) :  get_keyinfo_from_keyup_event(e);
}

function _isModifier(key) {
   return key === "shift" || key === "ctrl" || key === "alt" || key === "meta";
}

export function preventDefault(e) {
   if (e.preventDefault) {
      e.preventDefault();
      return;
   }
   e.returnValue = false;
}

export function stopPropagation(e) {
   if (e.stopPropagation) {
      e.stopPropagation();
      return;
   }

   e.cancelBubble = true;
}

// eslint-disable-next-line
function _getReverseMap() {
   /* keypress 에서는 받아들일 수 없는 키인지 여부를 알아낸다 */
   if (!_REVERSE_MAP) {
      _REVERSE_MAP = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const key in _MODKEY_MAP) {
         // pull out the numeric keypad from here cause keypress should be able to detect the keys from the character
         if (key > 95 && key < 112) { // eslint-disable-next-line no-continue
            continue;
         }
         // eslint-disable-next-line no-prototype-builtins
         if (_MODKEY_MAP.hasOwnProperty(key)) {
            // event.which --> modkey name
            _REVERSE_MAP[_MODKEY_MAP[key]] = key;
         }
      }
   }
   return _REVERSE_MAP;
}

function _keysFromString(_combination) {
   // '+' 문자 하나일 경우 그냥 '+' 리턴`b
   if (_combination === "+") {
      return ["+"];
   }

   // '++' --> '+plus' 로 치환
   const combination = _combination.replace(/\+{2}/g, "+plus");

   // '+' 를 기준으로 토큰 분리
   return combination.split("+");
}



export function normalizeKeyboardEventValue(e) {
   // @see http://stackoverflow.com/questions/4285627/javascript-keycode-vs-charcode-utter-confusion
   if (typeof e.which !== "number") {
      e.which = e.keyCode;
   }

   if(e.type === "keypress" && kbd_common.hasNonASCIIDomainKey(e)) {
      e.which = String(e.key).charCodeAt(0);
   }
   return e;
}

export function KeyboardEventTriggerObject(key, modifiers, action) {
   return {
      key,
      modifiers,
      action,
      getSymbol() {
         return Symbol.for(`{key:${key},modifiers:[${modifiers.join(",")}],action:${action}}`);
      },
      getKey() {
         // sort()를 호출하는 이유: modifiers의 출현순서를 항상 일정하게 유지하기 위해! --> 키로 쓰이기 때문
         // 키는 반드시 가장 오른쪽에 위치해야 한다
         return modifiers.sort().join("+") + key;
      }
   };
}

/** @param _key: String (WARN: KeyboardEvent 타입이 아니다!)
  * --- makeKeySymbolFromString() 에서 호출된다
  * -- 대소문자|숫자|특수문자|확장문자|한글 등... 표시하는 그대로의 문자를 인수로 받고 key+modKeys 로 분해한 값을 리턴한다
  * -- modKeys인 경우 대소문자가 섞인 그대로의 값으로 주어져야 한다 (i.e; Page Up, Enter, Arrow Right ..)
  * @returns ["key" [modkeys]]
 */
function getNormalizedKey(_key) {
   if(isFalsy(_key)) {
      console.trace();
      throw new Error(`Falsy key value is given which is not allowed and could not proceed. Abort.`);
   }
   // 주어진 키에 대해,
   //  ①  약자를 정자로 변경 & 두단어를 한단어로 짧게 변경 (소문자로 변경하기 전에 처리한다)
   //  ②  대문자 키는 "shift" + "소문자"로 변경 소문자로 변경
   //  ③  modifier 키역시 ["modifier", ["modifier"]] 형태로 변환
   //  ④  리턴형식: ["key", ["modifier", "modifier", ..]]
   const modifiers = new Set();
   let key = _key;

   // 예: "spc" --> "space", "ArrowUp" --> "up"
   if (kbd_common.SPECIAL_ALIASES[key]) { key = kbd_common.SPECIAL_ALIASES[key]; }

   // SHIFT를 눌러야 하는 키의 경우, 소문자로 치환하고 modifiers에 "shift"를 추가한다
   // "&" --> "7" + "shift"
   if (_SHIFT_MAP[key]) {
      key = _SHIFT_MAP[key];
      modifiers.add("shift");
   }

   // ALT 조합키의 경우 "alt"를 추가하고 대체되는 영문으로 변경한다
   if(kbd_common.altedkey_to_ascii_map[key]) {
      key = kbd_common.altedkey_to_ascii_map[key];
      modifiers.add("alt");
   }

   // shift,ctrl,alt,meta 등의 키이름은 그대로 modifiers에 추가
   if (_isModifier(key)) modifiers.add(key);

   return [key, [...modifiers]];
}

// 트리거로 지정된 값을 Symbol로 변환한다.
export function makeKeySymbolFromString(/* must be string */trigger) {

   if(typeof trigger === "symbol") return trigger;

   // 먼저 "+" 기호를 구분자로삼아 단어를 분리한다 (가장 오른쪽 요소가 "키"가 된다)
   // ex: ctrl+alt+meta+A ==> ["ctrl", "alt", "meta", "A"]
   // ex: shift+enter@keyup ==> ["shift", "enter@keyup"]
   const keys = _keysFromString(trigger);

   // action을 분리한다. ex: enter@keyup ==> keyup
   const req_action = (function req_action() {

      // ①  앞서 분리된 배열의 최상위 요소가 key에 해당한다. 'key@action' 포맷인경우, '@action'을 분리한뒤 'key'만 다시 복구한다
      const key = keys.pop();

      // ②  발견되지 않으면 단순 "key" 스타일. action은 디폴트로 설정한다 (WARN: key==="@")
      const at_position = Object.is(key, undefined) ? -1 : key.indexOf("@");
      if( key === "@" || at_position === -1) {
         keys.push(key);
         return; // undefined
      }
      // ③  '@' 마크가 발견됐다면 "key@action" 형태에서 "key", "action"으로 나눈다. 이때 '@@action' 포맷도 처리한다
      const tokens = key.split("@");

      if(tokens.length === 3) { // "@@action" --> ["", "", "action"]
         if( tokens[1] === "" && (tokens[2] === "keyup" || tokens[2] === "keypress" || tokens[2] === "keydown")) {
            keys.push(tokens[0]);
            // eslint-disable-next-line    consistent-return
            return tokens[2];
         }
         throw new Error("unsupported action type -- ", tokens[2]);
      } else if( tokens.length === 2) { // key@action --> ["key", "action"]
         if(tokens[1] === "keyup" || tokens[1] === "keypress" || tokens[1] === "keydown") {
            keys.push(tokens[0]);
            // eslint-disable-next-line    consistent-return
            return tokens[1];
         }
         if(tokens[0] === "") throw new Error("no key specified -- ", key);
      } else {
         // 첫번째와 두번째 조건외에는 모두 오류!
         const error_message = `\nFLeader rules has wrong key@action format: ${trigger}\n\naction part should be one of '@keydown', '@keypress', '@keyup'.\n\nAbort!!`;
         // eslint-disable-next-line no-alert
         if(window && window.alert) window.alert( error_message );
         else throw new Error( error_message );
      }
   })();

   // 단어를 적절하게 변환한 뒤 modifiers를 묶는다
   const [key, _modifiers] = keys.map(getNormalizedKey) // ex: ["ctrl","alt","meta","A"] ==(getNormalizedKey)==> [["ctrl",["ctrl"]],["meta",["meta"]],["alt",["alt"]],["a",[shift]]]
      .reduce(
         ([, modifiers], [key, mod]) => {
            // getNormalizedKey()로 넘어온 마지막 요소가 리턴되는 'key' 값이 된다
            // modifiers키를 배열에 모은다
            return [key, modifiers.concat(mod)];
         },["", []]
      );


   // key 이름과 같은 modifiers 값을 제거하고 정렬
   const modifiers = _modifiers.filter(mod => key !==mod ).sort();

   // modifiers에 따라 keyup 혹은 keypress를 결정한다
   const action = kbd_common.pickBestActrionFromKeyInfo(/* WARN:lowercase only */key, modifiers, req_action);

   // console.log(`${action} = kbd_common.pickBestActrionFromKeyInfo(${key}, [${modifiers}], req:${req_action})`);

   // key, modifiers, action 3가지를 묶어 Symbol 키를 만든다
   // 예: alt+Z ==> shift+alt+z ==> Symbol.for({key:"z", modifiers:["shift","alt"],action:"keyup"})
   return KeyboardEventTriggerObject(key, modifiers, action).getSymbol();
}

export function hasAnyModifierIn(mod=[], search_keys=["alt","ctrl","meta"]) {
   return search_keys.some(key => mod.indexOf(key) !== -1);
}

export function isWord(key, mod) {
   return isLowerWord(key, mod) || isUpperWord(key, mod);
}

export function isLowerWord(key="", mod=[]) {
   return (typeof(key) === "string" && key.length === 1 && mod.length === 0) && /[a-z]/.test(key);
}

export function isUpperWord(key="", mod=[]) {
   // WARN: 대문자는 "소문자 + ['shift']" 로 전달됨을 유의!
   const modkey = mod.pop();
   return (typeof(key) === "string" && mod.length === 0 && modkey==="shift") && /[a-z]/.test(key);
}

// eslint-disable-next-line    no-unused-vars
export function isNumber(key="", mod=[]) {
   return (mod.length === 0 && !Number.isNaN(key));
}

export function isSpecialChar(key="", mod=[]) {
   const pattern = "!@#$%^&*()-_=+[{]};:'\\|\",<.>/?`~";
   return (typeof(key) === "string" && key.length === 1 && !hasAnyModifierIn(mod, ["alt","ctrl","meta"]) && pattern.indexOf(key) !== -1);
}

// FIXME: 목적에 따라 스택에 쌓는 시점이 다르므로 이렇게 하나의 함수로 조건을 계속 추가해서는 안된다
// FIXME: state rule 에서 스택에 넣을지 말지를 결정해야한다
export function isStackableKey(key) {
   return (String(key).length === 1) && !(kbd_common.WordKeys.includes(key) || kbd_common.MOD_KEYS.has(key));
}
