/* eslint-disable no-unused-vars */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/paper-icon-button/paper-icon-button";
import "@polymer/iron-icons/iron-icons";
import "@polymer/paper-dialog/paper-dialog";
import "@polymer/paper-button/paper-button";
import "@polymer/paper-input/paper-input";
import "@polymer/paper-tooltip";

import "@hpacs/healthhub-icons/healthhub-icons";

import moment from "moment-timezone";
// eslint-disable-next-line import/no-extraneous-dependencies
import {IronResizableBehavior} from "@polymer/iron-resizable-behavior/iron-resizable-behavior";
import {mixinBehaviors} from "@polymer/polymer/lib/legacy/class";
import CommonUtils from "../../public/resource/js/utils/common";
import DateFilterUtils from "../../public/resource/js/utils/date-filter-utils";
import mixinCommons from "../common-mixin";
import store from "../redux/store";
import {QueryKey} from "../redux/reducers/query";
import {WorklistActionType} from "../redux/reducers/worklist";
import {TechlistActionType} from "../redux/reducers/techlist";
/**
 * `worklist-filter`
 *
 * @customElement
 * @polymer
 */
class WorklistFilter extends mixinBehaviors([IronResizableBehavior], mixinCommons(PolymerElement)) {
   static get template() {
      return html`
      <style>
         :host {
            display: block;
            width: 100%;
            height: 40px;
            background-color: #252934;
            border-radius: 2px;
         }

         paper-icon-button {
            color: #aaaaaa;
            height: 33px;
            width: 33px;
         }

         .pinFilter {
            position: relative;
            height: 27px;
            right: 81px;
            margin-right: -14px;
            /*z-index: 1;*/
         }

         .pinFilter iron-icon {
            height: 16px;
            width: 16px;
            cursor: pointer;
            --iron-icon-fill-color: #aaaaaa;
         }

         .removeFilter {
            position: relative;
            height: 27px;
            right: 10px;
            margin-right: -8px;
         }

         .removeFilter iron-icon {
            height: 16px;
            width: 16px;
            cursor: pointer;
            --iron-icon-fill-color: #aaaaaa;
         }

         .container {
            display: flex;
            /*flex-wrap: wrap-reverse;*/
            overflow-x: hidden;
            flex-direction: row;
            height: 100%;
            width: 100%;
         }

         .fixed-back {
            align-self: center;
            /*padding-left: 5px;*/
            /*z-index: 2;*/
         }

         .flex-child {
            flex: 1;
            white-space: nowrap;
            overflow-x: hidden;
            align-self: center;
            scroll-behavior: smooth;
         }

         .fixed-forward {
            align-self: center;
            /*padding-right: 5px;*/
         }

         .inner-container {
            display: flex;
            flex-direction: row;
            width: 1px;
         }

         /*동적 생성 필터 CSS*/
         paper-button.filter {
            font-size: 11px;
            font-family: NotoSansCJKLight, NotoSansCJK, NotoSansCJKLight !important;
            background-color: #252934;
            border: 1px solid #4c5667;
            border-radius: 10px;
            width: 75px;
            height: 27px;
            color: #aaaaaa;
            margin: 0;
            --paper-button-ink-color: #0087cd;
         }

         paper-button.openInputFilterName {
            --paper-button-ink-color: #0087cd;
            color: #ffffff;
            font-size: 11px;
            font-family: NotoSansCJKDemi, NotoSansCJK, NotoSansCJKLight;
            background-color: #1d1e25;
            text-transform: none;
            border-radius: 10px;
            /*width: 90px;*/
            height: 25px;
            min-width: 0;
         }

         paper-button.saveFilter {
            --paper-button-ink-color: #aaaaaa;
            font-family: NotoSansCJKDemi, NotoSansCJK, NotoSansCJKLight !important;
            font-size: 12px;
            background-color: #0087cb;
            color: #ffffff;
            border-radius: 2px;
            width: 60px;
            height: 25px;
            margin: 0;
            padding: 0;
            text-transform: none;
         }

         paper-dialog {
            background-color: #2d333f;
            border-radius: 2px;
            margin: 0px;
            border: 1px solid #4c5667;
            width: 200px;
            height: 130px;

         }

         .edit-filter-dialog {
            background-color: #2d333f;
            border-radius: 2px;
            margin: 0px;
            border: 1px solid #4c5667;
            width: 450px;
            height: 580px;
            margin-top: 11px;
         }

         .edit-filter-input {
            --paper-input-container-input-color: #fff;
            --paper-input-container-focus-color: #0087cb;
            --paper-input-container-label: {
               font-size: 13px;
            };
            margin: auto;
         }

         .dialog-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            margin: 0px;
            padding: 0px;
            justify-content: center;
            align-items: center;
         }

         .dialog-container-row {
            display: flex;
            flex-direction: row;
            /*height: 100%;*/
            /*width: 100%;*/
            margin: 0px;
            padding: 0px;
            justify-content: center;
            align-items: center;
         }

         .dialog-column-child {
            width: 400px;
         }

         .dialog-child-input {

         }

         .dialog-child-checkbox {
            margin-bottom: 10px;
         }

         .dialog-child-input input {
            width: 168px;
            height: 25px;
            background-color: #2d333f;
            border: 1px solid #4c5667;
            padding: 0;
            font-size: 12px;
            font-family: NotoSansCJK, NotoSansCJKLight !important;
            color: #aaaaaa;
         }

         .dialog-child-save {
            text-align: center;
            margin-bottom: 15px;
         }

         .wrap-filter {
            display: flex;
            flex-direction: row;
            padding-right: 5px;
            padding-left: 10px;
         }

         .filterConfig {
            display: flex;
            align-items: center;
            /*width: 320px;*/
            width: 220px;
            /*margin-right: 10px;*/
            justify-content: space-evenly;
         }

         .filter {
            display: flex;
         }

         .filter-selected {
            background-image: linear-gradient(45deg, #666 25%, transparent 25%), linear-gradient(-45deg, #666 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #666 75%), linear-gradient(-45deg, transparent 75%, #666 75%);
            -webkit-background-size: 2px 2.1px;
            background-position: 0 0, 1px 0, 1px -1px, 0px 1px;
            font-weight: 900;
            /*visibility: visible !important;*/
         }

         paper-checkbox {
            font-size: 13px;
            color: #aaaaaa;
            --paper-checkbox-checked-color: #0087cb;
            --paper-checkbox-label-color: #aaaaaa;
            --paper-checkbox-unchecked-color: #4c5667;
            padding: 3px;
         }

         paper-tooltip  {
             --paper-tooltip-duration-in: 0;
             --paper-tooltip-duration-out: 0;
         }

         paper-menu-button {
            padding: 2px 0 2px 5px;
            align-self: center;
         }

         paper-listbox {
            position: fixed;
            top: 40px;
            border-style: solid;
            border-color: #3d444c;
            border-width: 0 1px 1px 0;
            /*--paper-input-container-focus-color: #0087cb;*/
            /*--paper-input-container-label: {*/
            /*   text-align: center;*/
            /*   color: #aaaaaa;*/
            /*   font-size: 13px;*/
            /*};*/
            /*--paper-input-container-input: {*/
            /*   text-align: center;*/
            /*   color: #aaaaaa;*/
            /*   font-size: 13px;*/
            /*};*/
            color: #aaaaaa;
            background: var(--ag-background-color, #242834);
         }

         paper-item {
            --paper-item-min-height: 30px;
            /*--paper-item-focused: {*/
            /*   color: #0087cb;*/
            /*};*/
            font-size: 13px;
            cursor: pointer;
         }

         paper-item.iron-selected {
            color: #0087cb;
         }
      </style>

      <div class="container">
         <div id="filter" class="filter" style$="width: {{filterWidth}}">
            <paper-menu-button vertical-align="top" horizontal-align="left">
               <paper-icon-button icon="menu" slot="dropdown-trigger" alt="menu"></paper-icon-button>
               <paper-listbox slot="dropdown-content" attr-for-selected="data-value" selected="{{selected}}">
                  <template is="dom-repeat" items="{{_userFilterList}}" as="item">
                     <paper-item data-value$="{{item.id}}" style="text-transform: uppercase;" on-click="retrieveWorklistByMenu">
                        {{item.userFilterModel.name}}
                     </paper-item>
                  </template>
               </paper-listbox>
            </paper-menu-button>
            <div class="fixed-back">
               <paper-icon-button id="backFilter" icon="healthhub:filter-left" on-click="backFilter"></paper-icon-button>
               <paper-tooltip for="backFilter" animation-delay="0">{{ t("label.back") }}</paper-tooltip>
            </div>
            <div class="flex-child" id="wrapFilterList">
               <div class="inner-container" id="filterList"></div>
            </div>
            <div class="fixed-forward">
               <paper-icon-button id="forwardFilter" icon="healthhub:filter-right" on-click="forwardFilter"></paper-icon-button>
               <paper-tooltip for="forwardFilter" animation-delay="0">{{ t("label.forward") }}</paper-tooltip>
            </div>
         </div>
         <div class="filterConfig" style$="width: {{configWidth}}">
            <paper-button class="openInputFilterName" style$="width: {{btnWidth}}" on-click="reloadFilter">
            <template is="dom-if" if="{{isExpand}}">{{ t("button.refresh") }}</template>
            <template is="dom-if" if="{{!isExpand}}">{{ t("button.rfsh") }}</template>
            </paper-button>
            <paper-button id="saveFilterButton" class="openInputFilterName" style$="width: {{btnWidth}}" on-click="openSaveFilterDialog">
            <template is="dom-if" if="{{isExpand}}">{{ t("button.saveFilter") }}</template>
            <template is="dom-if" if="{{!isExpand}}">{{ t("button.save") }}</template>
            </paper-button>
            <paper-button class="openInputFilterName" style$="width: {{btnWidth}}" on-click="clearFilter">
            <template is="dom-if" if="{{isExpand}}">{{ t("button.clear") }}</template>
            <template is="dom-if" if="{{!isExpand}}">{{ t("button.clr") }}</template>
            </paper-button>
         </div>
      </div>

      <paper-dialog class="save-filter-dialog" id="saveFilterDialog" no-overlap
                     vertical-align="top">
         <div class="dialog-container">
            <div class="dialog-child dialog-child-input">
               <paper-input autofocus=true class="edit-filter-input" id="inputFilterName" label='{{ t("label.name") }}'
                           required="true" maxlength="10"></paper-input>
            </div>
            <div class="dialog-child dialog-child-checkbox">
                <paper-checkbox id="checkDefaultFilter">{{ t("label.setDefaultFilter") }}</paper-checkbox>
            </div>
            <div class="dialog-child dialog-child-save">
               <paper-button class="saveFilter" on-click="saveFilter">{{ t("button.save") }}</paper-button>
            </div>
         </div>
      </paper-dialog>

      <paper-dialog class="edit-filter-dialog" id="editFilterDialog" no-overlap vertical-align="top">
         <div class="dialog-container">
            <div class="dialog-column-child dialog-child-input">
               <input type="hidden" id="pin" value="{{userFilterModel.pin}}">
               <div class="dialog-container-row">
                  <paper-input class="edit-filter-input" label='{{ t("label.name") }}' required="true"
                                 value="{{userFilterModel.name}}" maxlength="10"></paper-input>
                  <paper-input class="edit-filter-input" label='{{ t("label.emergencyStatus") }}' disabled
                                 value="{{userFilterModel.filterModel.isEmergency.values}}"></paper-input>
               </div>
               <div class="dialog-container-row">
                  <paper-input class="edit-filter-input" label='{{ t("label.readingStatus") }}' disabled
                                 value="{{userFilterModel.filterModel.readingStatus.values}}"></paper-input>
                  <paper-input class="edit-filter-input" label='{{ t("label.patientID") }}' disabled
                                 value="{{userFilterModel.filterModel.patientID.filter}}"></paper-input>
               </div>
               <div class="dialog-container-row">
                  <paper-input class="edit-filter-input" label='{{ t("label.patientName") }}' disabled
                                 value="{{userFilterModel.filterModel.patientName.filter}}"></paper-input>
                  <paper-input class="edit-filter-input" label='{{ t("label.patientBirthDate") }}' disabled
                                 value="{{userFilterModel.filterModel.patientBirthDate.filter}}"></paper-input>
               </div>
               <div class="dialog-container-row">
                  <paper-input class="edit-filter-input" label='{{ t("label.patientSex") }}' disabled
                                 value="{{userFilterModel.filterModel.patientSex.values}}"></paper-input>
                  <paper-input class="edit-filter-input" label='{{ t("label.modality") }}' disabled
                                 value="{{userFilterModel.filterModel.modality.values}}"></paper-input>
               </div>
               <div class="dialog-container-row">
                  <paper-input class="edit-filter-input" label='{{ t("label.bodypart") }}' disabled
                                 value="{{userFilterModel.filterModel.bodypart.values}}"></paper-input>
                  <paper-input class="edit-filter-input" label='{{ t("label.studyDescription") }}' disabled
                                 value="{{userFilterModel.filterModel.studyDescription.filter}}"></paper-input>
               </div>
               <div class="dialog-container-row">
                  <paper-input class="edit-filter-input" label='{{ t("label.reportingDoctor") }}' disabled
                                 value="{{userFilterModel.filterModel.readingDoctor.filter}}"></paper-input>
                  <paper-input class="edit-filter-input" label='{{ t("label.requestDoctor") }}' disabled
                                 value="{{userFilterModel.filterModel.requestDoctor.filter}}"></paper-input>
               </div>
               <div class="dialog-container-row">
                  <paper-input class="edit-filter-input" label='{{ t("label.acquiredHospital") }}' disabled
                                 value="{{userFilterModel.filterModel.checkupHospital.values}}"></paper-input>
                  <paper-input class="edit-filter-input" label='{{ t("label.requestDepartment") }}' disabled
                                 value="{{userFilterModel.filterModel.checkupDepartment.filter}}"></paper-input>
               </div>
               <div class="dialog-container-row">
                  <paper-input class="edit-filter-input" label='{{ t("label.technician") }}' disabled
                                 value="{{userFilterModel.filterModel.checkupTechnician.filter}}"></paper-input>
               </div>
            </div>
            <div class="dialog-column-child dialog-child-save">
               <paper-button class="saveFilter" on-click="updateFilter">{{ t("button.update") }}</paper-button>
            </div>
         </div>
      </paper-dialog>
      <hh-confirm-dialog id="deleteConfirmDialog"></hh-confirm-dialog>
      `;
   }

   static get is() {
      return "worklist-filter";
   }

   connectedCallback() {
      super.connectedCallback();
      this.addEventListener("iron-resize", this.onIronResize.bind(this));
   }

   onIronResize() {
      if (this.offsetWidth > 0) {
         if (this.offsetWidth < 400) {
            this.isExpand = false;
            this.btnWidth = "50px";
            this.filterWidth = "calc(100% - 170px)";
            this.configWidth = "170px";
         } else {
            this.isExpand = true;
            this.btnWidth = "90px";
            this.filterWidth = "calc(100% - 220px)";
            this.configWidth = "220px";
         }
      }
   }

   static get properties() {
      return {
         pin: {
            type: Boolean,
         },
         isExpand: {
            type: Boolean,
            value: true
         },
         btnWidth: {
            type: String,
            value: "90px"
         },
         filterWidth: {
            type: String,
            value: "calc(100% - 220px)"
         },
         configWidth: {
            type: String,
            value: "220px"
         },
         filterName: {
            type: String,
            notify: true
         },
         changeFilterName: {
            type: String
         },
         filterModel: {
            type: Object,
            value: {},
         },
         _targetFilterElement: {
            type: Object,
            value: {}
         },
         _userFilterList: {
            type: Array,
            value: []
         },
         dateFilter: {
            type: String
         },
         sortModel: {
            type: Object,
            value: {}
         },
         dragFilter: {
            type: Object,
            value: {
               startIndex: null,
               selectedIndex: null,
               scrolling: true
            }
         },
         selected: {
            type: String
         },
         _category: {
            type: Number,
            value: 0,
         },
         getCustomFilterList: {
            type: Array,
            value: [],
            observer: "changedFilterList",
         },
         appliedFilter: {
            type: Object,
            observer: "changeAppliedFilter"
         },
         log: {
            type: String,
            value: "worklist-filter"
         },
         worklistSortModel: {
            type: Object,
         },
         techlistSortModel: {
            type: Object,
         }
      };
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         this._category = store.getState().common.category;
         this.getCustomFilterList = store.getState().query[QueryKey.GET_CUSTOM_FILTER_LIST];
         this.worklistSortModel = store.getState().worklist.appliedSortModel;
         this.techlistSortModel = store.getState().techlist.appliedSortModel;
      });

      this.fetchGetUserFiltersByUserId().then((result) => {
         if(result) {
            store.dispatch({ type: QueryKey.GET_CUSTOM_FILTER_LIST, payload: result });
         }
      }).catch((err) => {
         console.log(err);
      });

      this.$.inputFilterName.addEventListener("keyup", (e) => {
         if (e.keyCode === 13) this.saveFilter();
      });

      this.$.deleteConfirmDialog.addEventListener("confirm", (e) => {
         if(!e.detail) return;
         const { params } = e.detail;
         this.removeFilter(params);
      });

      this.$.filterList.addEventListener("dragstart", (e) => {
         const path = (e.composedPath && e.composedPath()) || e.path;
         const el = path.find(el => el.className === "wrap-filter");
         if(el) {
            // start index, selected index 설정
            for(let i = 0; i < this.$.filterList.children.length; i++) {
               if(el.id === this.$.filterList.children[i].id) {
                  this.dragFilter.startIndex = i;
                  this.dragFilter.selectedIndex = i;
               }
            }
         }
      });

      this.$.filterList.addEventListener("dragover", (e) => {
         e.preventDefault();
      });

      this.$.filterList.addEventListener("dragenter", (e) => {
         const path = (e.composedPath && e.composedPath()) || e.path;
         const el = path.find(el => el.className === "wrap-filter");
         if(el) {
            // selected index 설정
            for(let i = 0; i < this.$.filterList.children.length; i++) {
               if(el.id === this.$.filterList.children[i].id) {
                  this.dragFilter.selectedIndex = i;
               }
            }
            // target element style 설정
            if(this.dragFilter.startIndex < this.dragFilter.selectedIndex) {
               el.style.borderRight = "2px solid #aaa";
            } else if(this.dragFilter.startIndex > this.dragFilter.selectedIndex) {
               el.style.borderLeft = "2px solid #aaa";
            }
         }
         // 나머지 element style 초기화
         for(let i = 0; i < this.$.filterList.children.length; i++) {
            if(i !== this.dragFilter.selectedIndex)
               this.$.filterList.children[i].style.borderStyle = "";
         }
      });

      this.$.filterList.addEventListener("dragend", (e) => {
         this.moveFilter(this.dragFilter.startIndex, this.dragFilter.selectedIndex);

         // 모든 element style 초기화
         for(let i = 0; i < this.$.filterList.children.length; i++) {
            this.$.filterList.children[i].style.borderStyle = "";
         }
      });

      this.$.filter.addEventListener("dragover", (e) => {
         e.preventDefault();
      });

      this.$.filter.addEventListener("dragenter", (e) => {
         const path = (e.composedPath && e.composedPath()) || e.path;
         const el = path.find(el => el.id === "backFilter" || el.id === "forwardFilter");
         if(el) {
            this.dragFilter.scrolling = true;
            const interval = setInterval(() => {
               if(!this.dragFilter.scrolling)
                  clearInterval(interval);
               el.click();
            }, 500);

         }
      });

      this.$.filter.addEventListener("dragleave", (e) => {
         const path = (e.composedPath && e.composedPath()) || e.path;
         const el = path.find(el => el.id === "backFilter" || el.id === "forwardFilter");
         if(el) {
            this.dragFilter.scrolling = false;
         }
      });

      this.$.filter.addEventListener("dragend", (e) => {
         this.dragFilter.scrolling = false;
      });
   }

   /**
    * Move Filter
    * @param from startIndex
    * @param to selectedIndex
    */
   moveFilter(from, to) {
      if(from === to) return;
      // move element
      if(from < to) { // 오른쪽으로 이동한 경우
         for(let i = from; i < to; i++) {
            const ref = this.$.filterList.children[Number(i)];
            const el = this.$.filterList.children[Number(i)+1];
            // swap index
            // const temp = ref.dataset.idx;
            // ref.dataset.idx = el.dataset.idx;
            // el.dataset.idx = temp;
            // swap element
            this.$.filterList.insertBefore(el, ref);
         }
      } else if(from > to) { // 왼쪽으로 이동한 경우
         for(let i = from; i > to; i--) {
            const ref = this.$.filterList.children[Number(i)-1];
            const el = this.$.filterList.children[Number(i)];
            // swap index
            // const temp = ref.dataset.idx;
            // ref.dataset.idx = el.dataset.idx;
            // el.dataset.idx = temp;
            // swap element
            this.$.filterList.insertBefore(el, ref);
         }
      }
      // update filter index
      this.updateFilterIndex();
   }

   /**
    * Update Filter Index, 화면에 표시된 순서대로 필터 인덱스 업데이트
    */
   updateFilterIndex() {
      const arr = [];
      for(let idx = 0; idx < this.$.filterList.children.length; idx++) {
         const {id} = this.$.filterList.children[idx];
         arr.push({id, idx});
      }
      fetch("/api/user/option/filter/index", {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(arr)
      }).then((response) => {
         if(response.ok) {
            response.json().then((result) => {
               store.dispatch({ type: QueryKey.GET_CUSTOM_FILTER_LIST, payload: result });
            });
         } else {
            console.debug(new Error(`${response.status} ${response.statusText}`));
         }
      });
   }

   backFilter() {
      this.$.wrapFilterList.scrollLeft -= 100;
   }

   forwardFilter() {
      this.$.wrapFilterList.scrollLeft += 100;
   }

   openSaveFilterDialog() {
      this.$.saveFilterDialog.positionTarget = this.$.saveFilterButton;
      this.$.saveFilterDialog.open();
   }

   clearFilter() {
      this.deselectedFilter();
      // this.dispatchEvent(new CustomEvent("stopWorkerEvent"));
      this.dispatchEvent(new CustomEvent("stopWorkerEvent", {bubbles: true, composed: true }));
      // this.dispatchEvent(new CustomEvent("clearFilterEvent"));
      const filterModel = {};
      filterModel.studyDtime = DateFilterUtils.getDateFilterModel(2, "months");
      if (this._category === 0){
         store.dispatch({ type: WorklistActionType.SET_FILTER, payload: {detail: {filterModel}} });
      }else if (this._category === 1){
         store.dispatch({ type: TechlistActionType.SET_FILTER, payload: {detail: {filterModel}} });
      }
   }

   getToday() {
      return moment().format("YYYY-MM-DD");
   }

   lastTwoMonths() {
      return moment().subtract(2, "months").add(1, "days").format("YYYY-MM-DD");
   }

   /* *
    * 필터 핀 설정(리로드시 핀이 설정된 필터로 로딩)
    *
    * Create by BohyunJang on 2018-11-07 오후 7:24
    * */
   pinFilter(e, isCheck, id) {
      let userFilterModel = {};
      const userFilterList = JSON.parse(JSON.stringify(this.getCustomFilterList));

      for (let i = 0; i < userFilterList.length; i++) {
         if (id === userFilterList[i].id) {
            userFilterModel = userFilterList[i].userFilterModel;
         }
      }
      if (isCheck === false) {
         userFilterModel.pin = true;
      } else {
         userFilterModel.pin = false;
      }
      this.fetchUpdateUserFilter(id, userFilterModel).then(() => {
         this.fetchGetUserFiltersByUserId().then((result) => {
            if(result) {
               store.dispatch({ type: QueryKey.GET_CUSTOM_FILTER_LIST, payload: result });
            }
         }).catch((err) => {
            console.log(err);
         });
      }).catch((err) => {
         console.log(err);
      });
   }

   removeFilterConfirm(e) {
      const id = e.target.getAttribute("removeid");
      const el = e.target.parentNode.parentNode;
      if(!id || !el) return;

      this.$.deleteConfirmDialog.doCustomSize(180,410);
      this.$.deleteConfirmDialog.doOpen({
         title: "Delete Filter",
         contents: ["Are you sure you want to permanently delete this filter?"],
         ok: "OK",
         cancel: "CANCEL",
         params: { id, el }
      });
   }

   /* *
    * 필터 삭제
    */
   removeFilter({ id, el }) {
      if(!id || !el) return;
      this.fetchDeleteUserFilterById(id).then(() => {
         this.fetchGetUserFiltersByUserId().then((result) => {
            if(result) {
               store.dispatch({ type: QueryKey.GET_CUSTOM_FILTER_LIST, payload: result });
            }
         }).catch((err) => {
            console.log(err);
         });
      }).catch((err) => {
         console.log(err);
      });
   }


   /* *
    * 필터 삭제 (Ray API 호출)
    */
   fetchDeleteUserFilterById(id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/filter/${id}`, {
            method: "DELETE",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
            },
         }).then((response) => {
            if (response.ok) {
               resolve();
            }
            else {
               // TODO 예외처리 방안
               reject(response.status);
            }
         });
      });
   }

   /* *
    * 필터 저장
    */
   saveFilter() {
      this.dispatchEvent(new CustomEvent("getFilterEvent"));

      const userFilterModel = {};
      let name = this.$.inputFilterName.value;
      const pin = this.$.checkDefaultFilter.checked;

      // TODO: #17446 사용자 필터 저장시 퀵필터 여부 체크, 추후 체크 방법 변경 필요
      if(this.filterModel.patientID && this.filterModel.patientName) {
         if(this.filterModel.patientName.filter === this.filterModel.patientID.filter
            && this.filterModel.patientName.filterType === this.filterModel.patientID.filterType) {
            userFilterModel.isQuickFilter = true;
         }
      }

      this.dispatchEvent(new CustomEvent("getDateToggleEvent"));
      if(this.dateFilter) {
         userFilterModel.dateFilter = this.dateFilter;
      }

      if (this.sortModel) {
         userFilterModel.sortModel = Object.values(this.sortModel);
      }

      if (name) {
         // remove white space
         name = name.replace(/^\s+/, "").replace(/\s+$/, "");
         if (name !== "") {
            if(pin) {
               for (let i = 0; i < this.$.filterList.childElementCount; i++) {
                  this.$.filterList.children[i].children[1].children[0].checked = false;
                  this.$.filterList.children[i].children[1].children[0].setAttribute("icon", "icons:bookmark-border"); // bookmark check
               }
            }
            userFilterModel.name = name;
            userFilterModel.pin = pin;
            userFilterModel.filterModel = this.filterModel;

            this.fetchCreateUserFilter(userFilterModel).then((result) => {
               this.$.inputFilterName.value = "";
               this.$.checkDefaultFilter.checked = false;
               this.$.saveFilterDialog.close();

               this.fetchGetUserFiltersByUserId().then((result) => {
                  if(result) {
                     store.dispatch({ type: QueryKey.GET_CUSTOM_FILTER_LIST, payload: result });
                  }
               }).catch((err) => {
                  console.log(err);
               });
            }).catch((err) => {
               console.log(err);
            });
         }
      }
   }

   updateFilter() {
      this.fetchUpdateUserFilter(this._targetFilterElement.id, this.userFilterModel).then(() => {
         this.$.editFilterDialog.close();

         this.fetchGetUserFiltersByUserId().then((result) => {
            if(result) {
               store.dispatch({ type: QueryKey.GET_CUSTOM_FILTER_LIST, payload: result });
            }
         }).catch((err) => {
            console.log(err);
         });
      }).catch((err) => {
         console.log(err);
      });
   }

   /* *
    * 필터 변경 (Ray API 호출)
    */
   fetchUpdateUserFilter(id, userFilterModel) {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/filter/${id}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(userFilterModel)
         }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            }
            else {
               reject(new Error("filter update fail"));
            }
         });
      });

   }

   /* *
    * 필터 생성 (Ray API 호출)
    */
   fetchCreateUserFilter(userFilterModel) {
      return new Promise((resolve, reject) => {
         fetch("/api/user/option/filter", {
            method: "POST",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(userFilterModel)
         }).then((response) => {
            if (response.ok) {
               response.json().then((result) => {
                  // TODO 필터가 한개 생성될때의 라이프사이클을 다시 생각해봐야함.
                  resolve(result);
               });
            }
            // TODO 예외처리 방안
            else {
               reject(response.status);
            }
         });
      });
   }

   /* *
    * 동적 필터 엘리먼트 생성
    */
   renderFilter(id, filterName, pin) {
      const filterWrap = document.createElement("div");
      filterWrap.setAttribute("class", "wrap-filter");
      filterWrap.setAttribute("id", id);

      const _filterButton = document.createElement("paper-button");
      // _filterButton.setAttribute("noink", "");
      _filterButton.setAttribute("toggles", "");
      _filterButton.setAttribute("class", "filter");
      _filterButton.setAttribute("id", id);
      _filterButton.setAttribute("draggable", true);
      _filterButton.textContent = filterName;

      _filterButton.addEventListener("click", e => this.retrieveWorklistByFilter(id));
      _filterButton.addEventListener("contextmenu", e => this.editFilter(e));
      filterWrap.appendChild(_filterButton);

      const pinIconWrap = document.createElement("div");
      pinIconWrap.setAttribute("class", "pinFilter");
      const pinIcon = document.createElement("iron-icon");
      pinIcon.setAttribute("pinId", id);
      if (pin) {
         pinIcon.checked = true;
         pinIcon.setAttribute("icon", "icons:bookmark");
      } else {
         pinIcon.checked = false;
         pinIcon.setAttribute("icon", "icons:bookmark-border");
      }
      pinIcon.addEventListener("click", (e) => {
         const event = e;
         const isCheck = e.target.checked;
         const id = event.target.getAttribute("pinId");
         this.pinFilter(event, isCheck, id);
      });

      pinIconWrap.appendChild(pinIcon);
      filterWrap.appendChild(pinIconWrap);
      this.$.filterList.appendChild(filterWrap);

      const removeIconWrap = document.createElement("div");
      removeIconWrap.setAttribute("class", "removeFilter");
      const removeIcon = document.createElement("iron-icon");
      removeIcon.setAttribute("removeId", id);
      removeIcon.setAttribute("icon", "healthhub:report-close");
      removeIcon.addEventListener("click", e => this.removeFilterConfirm(e));
      removeIconWrap.appendChild(removeIcon);
      filterWrap.appendChild(removeIconWrap);

      this.$.filterList.appendChild(filterWrap);
   }

   changedFilterList(result, oldValue) {
      if(JSON.stringify(result) !== JSON.stringify(oldValue)) {
         const filterList = this.shadowRoot.getElementById("filterList");
         while ( filterList.hasChildNodes() ) {
            filterList.removeChild( filterList.firstChild );
         }
         this._userFilterList = result;
         (result || []).forEach((filter) => {
            this.renderFilter(filter.id, filter.userFilterModel.name, filter.userFilterModel.pin);
         });
      }
   }

   retrieveWorklistByMenu(e) {
      const el = e.target;
      if(el) this.retrieveWorklistByFilter(el.dataset.value);
   }

   /* *
    * 필터 적용된 worklist 조회
    */
   retrieveWorklistByFilter(id) {
      this.dispatchEvent(new CustomEvent("stopWorkerEvent", {bubbles: true, composed: true }));
      if(id) {
         this.fetchGetUserFilter(id).then((result) => {
            // this.dispatchEvent(new CustomEvent("stopWorkerEvent"));
            const message = {};
            const detail = {};
            if(result.userFilterModel.isQuickFilter) detail.isQuickFilter = true;
            if(result.userFilterModel.dateFilter) detail.dateFilter = result.userFilterModel.dateFilter;
            detail.filterModel = result.userFilterModel.filterModel;
            this.filterModel =  result.userFilterModel.filterModel;
            detail.sortModel = result.userFilterModel.sortModel;
            this.sortModel = result.userFilterModel.sortModel;
            message.detail = detail;
            if(this._category === 0){
               store.dispatch({ type: WorklistActionType.SET_FILTER, payload: message });
            } else if (this._category === 1){
               store.dispatch({ type: TechlistActionType.SET_FILTER, payload: message });
            }
            this.selectFilterButton(id);
         }).catch((err) => {
            console.log(err);
         });
      }
   }

   selectFilterButton(id) {
      let selected = null;
      this.$.filterList.querySelectorAll(".filter").forEach((el) => {
         if(el.id === id) {
            if (!el.classList.contains("filter-selected")) el.classList.add("filter-selected");
            selected = id;
         } else {
            el.classList.remove("filter-selected");
         }
      });
      this.selected = selected;
   }

   deselectedFilter() {
      this.$.filterList.querySelectorAll(".filter").forEach((el) => {
         el.classList.remove("filter-selected");
      });
      this.selected = null;
   }

   clearFilterButton(appliedFilterModel = {}) {
      const el = this.$.filterList.querySelector(".filter-selected");
      if(el) {
         const wrapFilter = el.parentNode;
         this.fetchGetUserFilter(wrapFilter.id).then((result) => {
            if(result) {
               const {filterModel} = result.userFilterModel;
               delete filterModel.isEmptyFilter;
               // eslint-disable-next-line no-param-reassign
               delete appliedFilterModel.isQuickFilter;

               // 1. length 비교
               if(Object.keys(appliedFilterModel).length !== Object.keys(filterModel).length) {
                  el.classList.remove("filter-selected");
                  this.selected = null;
                  return;
               }
               // 2. filter 비교
               Object.entries(appliedFilterModel).forEach(([columnId, appliedModel]) => {
                  // 같은 컬럼이 있는지 체크
                  const model = filterModel[columnId];
                  if(!model) {
                     el.classList.remove("filter-selected");
                     this.selected = null;
                     return;
                  }
                  // date filter 체크
                  if(appliedModel.filterType === "date") {
                     if(appliedModel.filterType !== model.filterType
                        || appliedModel.type !== model.type) {
                        el.classList.remove("filter-selected");
                        this.selected = null;
                        return;
                     }
                     const {type, isRelative, amount, unit} = model;
                     let {dateFrom, dateTo} = model;
                     if(isRelative) {
                        // relative range 이면 날짜를 다시 계산해서 비교해줌
                        if(type === "inRelativeRange")
                           dateFrom = DateFilterUtils.getRelativeDate(amount, unit);
                        else
                           dateFrom = DateFilterUtils.getToday();
                        dateTo = DateFilterUtils.getToday();
                        if(!moment(appliedModel.dateFrom).isSame(moment(dateFrom))
                           || !moment(appliedModel.dateTo).isSame(moment(dateTo))) {
                           el.classList.remove("filter-selected");
                           this.selected = null;
                        }
                     } else if(!moment(appliedModel.dateFrom).isSame(moment(dateFrom))
                        || !moment(appliedModel.dateTo).isSame(moment(dateTo))) {
                        el.classList.remove("filter-selected");
                        this.selected = null;
                     }
                  // date filter 아닌 경우 체크
                  } else {
                     Object.entries(appliedModel).forEach(([key, value]) => {
                        const compareValue = model[key];

                        if(compareValue){
                           if(Array.isArray(value)) {
                              if(!CommonUtils.arrayEquals(value, compareValue)) {
                                 el.classList.remove("filter-selected");
                                 this.selected = null;
                              }
                           } else if(value !== compareValue) {
                              el.classList.remove("filter-selected");
                              this.selected = null;
                           }
                        }
                     });
                  }
               });
            }
         }).catch((err) => {
            console.log(err);
         });
      }
   }

   editFilter(evt) {
      evt.preventDefault();
      this.$.editFilterDialog.close();
      this._targetFilterElement = evt.currentTarget;
      const key = evt.currentTarget.id;

      this.fetchGetUserFilter(key).then((result) => {
         this.userFilterModel = result.userFilterModel;
         this.$.editFilterDialog.positionTarget = this._targetFilterElement;
         this.$.editFilterDialog.open();
      }).catch((err) => {
         console.log(err);
      });
   }

   reloadFilter() {
      // 데이터를 조회하던 중(prefetch Queue가 실행되던 중)에 필터 검색시 남아있던 Queue 제거
      this.dispatchEvent(new CustomEvent("stopWorkerEvent", {bubbles: true, composed: true }));
      this.dispatchEvent(new CustomEvent("reloadFilterEvent", {bubbles: true, composed: true }));
      if (this.appliedFilter) {
         const { filterModel, appliedDateFilters } = this.appliedFilter??{};
         Object.keys(appliedDateFilters).forEach((key) => {
            if(filterModel[key])
               filterModel[key] = appliedDateFilters[key];
         });
         const detail = {};
         detail.filterModel = filterModel;
         if (this._category === 0) {
            if(this.worklistSortModel){
               const { appliedSortModel } = this.worklistSortModel;
               detail.sortModel = appliedSortModel;
            }
            store.dispatch({ type: WorklistActionType.SET_FILTER, payload: {detail} });
         } else if (this._category === 1){
            if(this.techlistSortModel){
               const { appliedSortModel } = this.techlistSortModel;
               detail.sortModel = appliedSortModel;
            }
            store.dispatch({ type: TechlistActionType.SET_FILTER, payload: {detail} });
         }
      }
   }

   /* *
    * 필터 리스트 조회 (Ray API 호출)
    */
   fetchGetUserFiltersByUserId() {
      return new Promise((resolve, reject) => {
         fetch("/api/user/option/filter", {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            }
         }).then((response) => {
            if (response.ok) {
               if(response.status === 200) {
                  response.json().then((data) => {
                     resolve(data);
                  });
               }
               else {
                  resolve([]);
               }
            } else {
               reject(new Error(`Message: ${response.status}`));
            }
         });
      });
   }

   fetchGetUserFilter(id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/filter/${id}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok) {
               if(response.status === 200) {
                  response.json().then((data) => {
                     resolve(data);
                  });
               } else {
                  resolve({});
               }
            } else {
               reject(new Error(`Message: ${response.status}`));
            }
         });
      });
   }

   changeAppliedFilter(appliedFilter) {
      // console.log(`[${this.log}] changeAppliedFilter`, appliedFilter);
      if (!appliedFilter) return;
      const { filterModel } = appliedFilter;

      // checkUserFilterButtonEvent
      // 적용된 필터와 선택된 사용자 필터가 같은지 비교 후 다르면 deselect 처리
      this.clearFilterButton(filterModel);
   }
}

window.customElements.define(WorklistFilter.is, WorklistFilter);
