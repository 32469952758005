// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
const SPECIAL_ALIASES = {
   "Arrow Up": "up", "Arrow Down": "down", "Arrow Right": "right", "Arrow Left": "left",
   "ArrowUp": "up", "ArrowDown": "down", "ArrowRight": "right", "ArrowLeft": "left", "AltRight": "alt", "AltLeft": "alt",
   "Accept": "accept",
   "Up": "up", "Down": "down", "Dead": "dead", "Right": "right", "Left": "left",
   "Alt": "alt", "AltGraph": "alt", "Backspace": "backspace",
   "BrightnessDown": "brightnessdown", "BrightnessUp": "brightnessup",
   "Backqoute": "backqoute",
   "CapsLock": "capslock",
   "Control": "ctrl", "ControlLeft": "ctrl", "ControlRight": "ctrl",
   "ContextMenu": "contextmenu",
   "command": "meta", "Command": "meta", "Clear": "clear",
   "Copy": "copy",
   "CrSel": "crsel",
   "Compose": "compose",
   "Cut": "cut",
   "Delete": "delete",
   "Eject": "eject",
   "EraseEof": "erase",
   "ExSel": "exsel",
   "Enter": "enter",
   "Escape": "esc", "ESC": "esc", "Esc": "esc",
   "Execute": "execute",
   "End": "end",
   "Find": "find",
   "Finish": "finish",
   "F1": "f1", "F2": "f2", "F3": "f3", "F4": "f4", "F5": "f5", "F6": "f6", "F7": "f7", "F8": "f8", "F9": "f9", "F10": "f10",
   "F11": "f11", "F12": "f12", "F13": "f13", "F14": "f14", "F15": "f15", "F16": "f16", "F17": "f17", "F18": "f18", "F19": "f19", "F20": "f20",
   "Help": "insert",
   "HangulMode": "hangulmode", "HanjaMode": "hanjamode", "JunjaMode": "junjamode",
   "LogOff": "logoff",
   "Power": "power",
   "PowerOff": "poweroff",
   "PrintScreen": "printscreen",
   "Hibernate": "hibernate",
   "Standby": "standby",
   "WakeUp": "wakeup",
   "Pause": "pause",
   "Play": "play",
   "Props": "props",
   "Select": "select",
   "ShiftLeft": "shift",
   "ShiftRight": "shift",
   "Shift": "shift",
   "Home": "home",
   "Insert": "insert",
   "Paste": "paste",
   "Redo": "redo",
   "Undo": "undo",
   "Fn": "fn",
   "Hyper": "hyper",
   "Meta": "meta", "MetaLeft": "meta", "MetaRight": "meta", "Windows": "meta", "Win": "meta",
   "NumLock": "numlock",
   "option": "alt", "Option": "alt", "Page Up": "pageup", "Page Down": "pagedown", "Page Right": "pageright", "Page Left": "pageleft",
   "PageUp": "pageup", "PageDown": "pagedown", "PageRight": "pageright", "PageLeft": "pageleft",
   "return": "enter",
   "plus": "+",
   "Scroll": "scrolllock",
   "ScrollLock": "scrolllock",
   "Super": "alt", "OS": "alt",
   "Symbol": "symbol",
   "SymbolLock": "symbollock",
   "Space Bar": "space", "spc": "space", "Space": "space", " ": "space",
   "Soft1": "soft1", "Soft2": "soft2", "Soft3": "soft3", "Soft4": "soft4",
   "Tab": "tab",
   "mod": /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? "meta" : "ctrl",
   "ZoomIn": "zoomin", "ZoomOut": "zoomout",
};

const normal_ascii_shift_map = {
   "A": "a", "B": "b", "C": "c", "D": "d", "E": "e", "F": "f",
   "G": "g", "H": "h", "I": "i", "J": "j", "K": "k", "L": "l",
   "M": "m", "N": "n", "O": "o", "P": "p", "Q": "q", "R": "r",
   "S": "s", "T": "t", "U": "u", "V": "v", "W": "w", "X": "x",
   "Y": "y", "Z": "z",
   "~": "`", "!": "1", "@": "2", "#": "3", "$": "4", "%": "5","^": "6", "&": "7", "*": "8", "(": "9", ")": "0", "_": "-", "+": "=",
   "{": "[", "}": "]", "|":String.fromCharCode(92), ":": ";", "'": String.fromCharCode(34),
   "<":",", ">":".", "?":"/"
};

const altedkey_to_ascii_map = {
   "å": "a", "∫": "b", "ç": "c", "∂": "d", "´": "e", "ƒ": "f", "©": "g",
   "˙": "h", "ˆ": "i", "∆": "j", "˚": "k", "¬": "l", "µ": "m", "˜": "n",
   "ø": "o", "π": "p", "œ": "q", "®": "r", "ß": "s", "†": "t", "¨": "u",
   "√": "v", "∑": "w", "≈": "x", "¥": "y", "Ω": "z",
   "º": "0", "¡": "1", "™":"2", "£":"3", "¢":"4", "∞":"5","§":"6", "¶":"7", "•":"8", "ª":"9",
   "–":"-", "≠": "=", "“": "[", "‘": "]", "…":";", "æ":"'", "≤":",", "≥":".", "÷":"/", "«": String.fromCharCode(92),
};

const shift_altedkey_to_ascii_map = {
   "Å": "a", "ı": "b", "Ç": "c", "Î": "d", "´": "e", "Ï": "f",
   "˝": "g", "Ó": "h", "Ô": "j", "": "k", "Ò": "l",
   "Â": "m", "Ø": "o", "∏": "p", "Œ": "q", "‰": "r",
   "Í": "s", "ˇ": "t", "¨": "u", "◊": "v", "„": "w", "˛": "x",
   "¸": "z",
   "‚" : "0", "⁄":"1", "€":"2", "›":"4", "ﬁ":"5", "ﬂ":"6", "‡":"7", "°":"8", "·":"9",
   "—":"-", "±":"+","”":"[", "’":"]", "»":String.fromCharCode(92), "Ú":";", "Æ":"'", "¯":",", "˘":".", "¿":"/"
};

// total 47 characters
const alted_keys_ascii      = "abcdefghijklmnopqrstuvwxyz0123456789-=[];',./\\`";
const alted_keys            = "å∫ç∂´ƒ©˙ˆ∆˚¬µ˜øπœ®ß†¨√∑≈¥Ωº¡™£¢∞§¶•ª–≠“‘…æ≤≥÷«`";
const shift_altedkeys_ascii = "abcdefghijklmnopqrstuvwxyz0123456789-=[];',./\\`";
const shift_altedkeys       = "ÅıÇÎ´Ï˝ÓˆÔÒÂ˜Ø∏Œ‰Íˇ¨◊„˛Á¸‚⁄€‹›ﬁﬂ‡°·—±”’ÚÆ¯˘¿»`" ;
const ascii_key_pattern     = /[a-z0-9A-Z`~!@#$%^&*()_+-=\[\]\{\};:'",<.>/\?]/;

const HangulPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
const English = "rRseEfaqQtTdwWczxvgASDFGZXCVkoiOjpuPhynbmlYUIHJKLBNM";
const Hangul = "ㄱㄲㄴㄷㄸㄹㅁㅂㅃㅅㅆㅇㅈㅉㅊㅋㅌㅍㅎㅁㄴㅇㄹㅎㅋㅌㅊㅍㅏㅐㅑㅒㅓㅔㅕㅖㅗㅛㅜㅠㅡㅣㅛㅕㅑㅗㅓㅏㅣㅠㅜㅡ";
const isHangul = char => HangulPattern.test(char);
const getEnglishFromHangul = char => [""," ", null,undefined].includes(char) ? [false, ""] : [true, English.charAt(Hangul.indexOf(char))];

const WordKeys              = ["tab", "enter", "space", "doubleqouote", "backslash", "backquote"];
const wordkey_map           = {9: "tab", 13: "enter", 28:"backslash", 32:"space", 92: "backslash", 96: "backqoute", 124:"backslash", 171: "backslash", 192:"backqoute", 220:"backslash"};
const wordkey_printable_map = {"tab":"\t", "enter":"\n", "space":" ", "doublequote": String.fromCharCode(34), "backslash":String.fromCharCode(92), "backqoute": "`"};

const deadkey_to_ascii_map = { /* in keyup ==> */ 69: "e", 73: "i", 78: "n", 85: "u", /* in keypress => */101:"e", 105: "i", 110:"n", 117:"u"};

const isDeadKey = ({ which }) => deadkey_to_ascii_map[which];

// Mixed Case word --> lowercase ( "Enter" --> "enter" )
function isModKey({key}) { return !!SPECIAL_ALIASES[key]; }
function getModKey({key}) { return SPECIAL_ALIASES[key]; }
function getPrintableModKey(e) {
   const printable = getModKey(e);
   return wordkey_printable_map[printable] || printable;
}

// code --> char (i.e: 13 --> "enter")
function isWordKey({which}) { return !!wordkey_map[which]; }
function getWordKey({which}) { return wordkey_map[which]; }
function getPrintableWordKey(e) {
   const printable = getWordKey(e);
   return wordkey_printable_map[printable] || e.key;
}

/* WARN: WordKeys 목록에 속한 문자는 일반 ASCII 처리한다 (keypress)  */
const MOD_KEYS = new Set([...Object.values(SPECIAL_ALIASES)]); ["+", ...WordKeys].forEach(key=> MOD_KEYS.delete(key));
const get_aliased_name = name =>  wordkey_map[SPECIAL_ALIASES[name]];

function pickBestActrionFromKeyInfo(/* normalized-lower-case-only */key, modifiers=[], action=null) {
   if (modifiers.length && (modifiers.includes("alt") || modifiers.includes("ctrl")|| modifiers.includes("meta"))) return action || "keyup";
   if (MOD_KEYS.has(key)/* && !(key==="enter" || key==="space") */) return action || "keyup";

   return action || "keypress";
}

// i.e) event ==> ["alt", "shift"]
function get_computed_mod_keys(e) {
   const modkeys        = new Set();
   // i.e "ctrl+alt+a" ==> ["ctrl", "alt"]
   const mods  = ["alt", "ctrl", "shift", "meta"];
   // NOTE: 처리속도를 올리기 위해 for..next 루프를 사용
   for(let i=0; i<mods.length; i++) { if(e[`${mods[i]}Key`]) modkeys.add( mods[i] ); }
   return modkeys;
}

// keypress event 안에서는 normalized ASCII 코드에 대한 정보가 없기 때문에 reverse lookup table을 사용한다
// WARN: keyup event 에서는 다른 방법을 써야한다
function getNormalizedkeyInfoIfHangul(key) {
   const modkeys    = new Set();
   const [, eng]    = getEnglishFromHangul(key);
   const lowercase  = String(eng).toLowerCase();
   const normalized = (eng !== lowercase) ? (modkeys.add("shift"),lowercase) : eng;

   return {normalized, printable: key, modkeys:[...modkeys]};
}

// 0-9, a-z, A-Z
function hasNonASCIIDomainKey(e) {
   return !!(["alt", "ctrl", "meta"].filter(type => e[`${type}Key`] === true).length) || (e.which < 32 || e.which > 126);
}

function get_keyinfo_from_modkey(e) {
   const normalized = getModKey(e);
   const printable  = getPrintableModKey(e);
   const modkeys    = get_computed_mod_keys(e);
   const action     = pickBestActrionFromKeyInfo(normalized, [...modkeys]);

   return {
      printable,
      normalized,
      keycode:             e.which,
      action,
      is_alt_composed_key: modkeys.has("alt"),
      is_hangul:           false,
      is_non_prinable:     true,
      is_word_key:         false,
      is_mod_key:          true,
      modkeys:             [...modkeys]
   };
}

// WARN: use In @keypress Handler ONLY
function get_keyinfo_from_hangul(e) {
   const {normalized, printable, modkeys} = getNormalizedkeyInfoIfHangul(e.key);
   const action                           = pickBestActrionFromKeyInfo(normalized, [...modkeys]);
   const computed_modkeys                 = new Set([...get_computed_mod_keys(e), ...modkeys]);

   return {
      printable,
      normalized,
      action,
      is_hangul:true,
      is_capslock_on: false, /* No proper way to get the status of capslock key when Hangul-mode. */
      is_non_prinable: false,
      is_alt_composed_key: false,
      is_mod_key: false,
      modkeys: [...computed_modkeys]
   };
}

// 한글은 고려할 필요없음
function get_keyinfo_from_ctrl_meta(e) {
   const modkeys    = get_computed_mod_keys(e); // 설정된다해도 "alt 또는 shift" 만 있어야 한다
   const printable  = e.key;
   const normalized   = normal_ascii_shift_map[e.key] || e.key;
   const action     = pickBestActrionFromKeyInfo(normalized, [...modkeys]);
   const is_capslock_on = (normalized === printable) ? !e.shiftkey : false;

   return {
      printable,
      normalized,
      action,
      is_hangul: false,
      is_capslock_on,
      is_non_prinable: true,
      is_alt_composed_key: modkeys.has("alt"),
      is_mod_key: false,
      modkeys: [...modkeys]
   };
}

function is_alt_composed_key({altKey, ctrlKey, metaKey}) {
   return altKey && !(ctrlKey || metaKey);
}

export default {
   SPECIAL_ALIASES,
   MOD_KEYS,
   WordKeys,
   normal_ascii_shift_map,
   altedkey_to_ascii_map,
   shift_altedkey_to_ascii_map,
   wordkey_map,
   wordkey_printable_map,
   ascii_key_pattern,
   isWordKey,
   isModKey,
   isDeadKey,
   getWordKey,
   getModKey,
   pickBestActrionFromKeyInfo,
   getPrintableModKey,
   getPrintableWordKey,
   get_computed_mod_keys,
   isHangul,
   getEnglishFromHangul,
   getNormalizedkeyInfoIfHangul,
   hasNonASCIIDomainKey,
   get_keyinfo_from_modkey,
   get_keyinfo_from_hangul,
   get_keyinfo_from_ctrl_meta,
   is_alt_composed_key,
   alted_keys_ascii,
   alted_keys,
   shift_altedkeys_ascii,
   shift_altedkeys,
   get_aliased_name,
   deadkey_to_ascii_map,
};

