/* eslint-disable no-undef, no-bitwise, no-param-reassign, object-shorthand,one-var,prefer-object-spread,prefer-destructuring,import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies,no-unused-vars */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/paper-dialog";

// import "./worklist-navbar";
// import "./worklist-toolbar";
// import "./worklist-content";
import "./components/hh-report-window";
// import "./dialog/techtab-new-exam-dialog";
// import "./dialog/techtab-modified-exam-dialog";
// import "./dialog/techtab-split-dialog";
// import "./dialog/setting-dialog-container";
import "./components/hh-tech-window";

import "@vaadin/vaadin-split-layout";

import {mixinBehaviors} from "@polymer/polymer/lib/legacy/class";
import {IronResizableBehavior} from "@polymer/iron-resizable-behavior/iron-resizable-behavior";
// import * as Sentry from "@sentry/browser";
// import i18next from "i18next";
// import CommonUtils from "../public/resource/js/utils/common";
// import store from "./redux/store";
// import {CommonActionType, DialogType} from "./redux/reducers/common";
// import {TechlistActionType} from "./redux/reducers/techlist";


class HHWorklist extends mixinBehaviors([IronResizableBehavior], PolymerElement) {
   static get template() {
      return html`
         <style>
            :host {
               display: block;
               height: 100%;
               width: 100%;
            }

            .container-column {
               display: flex;
               flex-direction: column;
               height: 100%;
               width: 100%;
            }

            .container-row {
               display: flex;
               flex-direction: row;
               width: 100%;
               height: 100%;
            }

            .flex-child {
               flex: 1;
            }

            .class-worklist-toolbar{
               height: 100%;
               /*width: 40px;*/
               width: 0px;
            }

            .class-worklist-content{
               height: 100%;
               min-width: 323px;
               width: calc(100% - 395px);
               margin: 0 8px 0 0;

               /* #17213 [HPACS] 테크니션이 Modify exam 하고 있는 검사를 판독의가 같은 검사를 열었을 경우 노출되는 팝업 메세지 UI 이슈 */
               overflow: visible;
               z-index: 1;
            }

            .class-worklist-report{
               height: 100%;
               /*width:470px;*/
               min-width: 395px;
            }

            paper-dialog#blocker {
               /* --paper-dialog-background-color: transparent !important; */
               --paper-dialog-background-color: #414449 !important;
            }
            .sign-text {
               background: transparent !important;
               font-size: 16px;
               color: #FFF;
               font-weight: 600;
            }

            #loading {
               display: inline-block;
               background-image: url("/crosscert/unisignweb/rsrc/img/processing-img.gif");
               background-repeat: no-repeat;
               background-size: 20px 20px;
               /* background-position-y: 2rem; */
               width: 20px;
               height: 20px;
            }

            .worklist-content-split {
                width: 100%;
                height: 100%;
                --lumo-size-m : 5px;
            }

         </style>
<!--         <hh-report-confirm-dialog id="reportConfirm"></hh-report-confirm-dialog>-->

<!--         <div class="container-column">-->
<!--            <div>-->
<!--               <worklist-navbar id="navbar"></worklist-navbar>-->
<!--            </div>-->
<!--            <div class="flex-child" style="height: -webkit-calc(100% - 61px); overflow: hidden;">-->
<!--               <div class="container-row">-->
<!--                &lt;!&ndash;  <worklist-toolbar class="class-worklist-toolbar" id="toolbar"></worklist-toolbar>&ndash;&gt;-->
<!--                  <setting-dialog-container id="settingDialogContainer"></setting-dialog-container>-->
<!--                  <vaadin-split-layout id="worklistContentSplit" class="worklist-content-split" theme="minimal">-->
<!--                     <div class="class-worklist-content" id="divWorklistContent">-->
<!--                        <worklist-content id="content" style="width: 100%; height: 100%"></worklist-content>-->
<!--                     </div>-->
<!--                     <div class="class-worklist-report" id="divReportWindow">-->
<!--                        &lt;!&ndash; radiology &ndash;&gt;-->
<!--                        <hh-report-window id="hhReportWindow" style="width: 100%; height: 100%"></hh-report-window>-->
<!--                        &lt;!&ndash; technician &ndash;&gt;-->
<!--                        <hh-tech-window id="hhTechWindow" style="width: 100%; height: 100%; display: none;"></hh-tech-window>-->
<!--                     </div>-->
<!--                  </vaadin-split-layout>-->
<!--               </div>-->
<!--            </div>-->
<!--         </div>-->
<!--         <hh-confirm-dialog id="worklistConfirm"></hh-confirm-dialog>-->
<!--         <techtab-modified-exam-dialog id="techtabModifiedExamDialog"></techtab-modified-exam-dialog>-->
<!--         <techtab-split-dialog id="techtabSplitDialog"></techtab-split-dialog>-->
<!--         <techtab-new-exam-dialog id="techtabNewExamDialog"></techtab-new-exam-dialog>-->
<!--         <paper-dialog id="blocker" entry-animation="scale-up-animation"-->
<!--               exit-animation="fade-out-animation" modal alwaysOnTop>-->
<!--            <div class="sign-text"><div id="loading"></div> {{signText}}</div>-->
<!--         </paper-dialog>-->
<!--         <techtab-verify-dialog id="verifyDialog"></techtab-verify-dialog>-->
      `;
   }

   static get properties() {
      return {
         _ready: {
            type: Boolean,
            value: false
         },
         approveOpinionInfo: {
            type: Object
         },
         contentWidth: {
            type: Number,
            value: 0
         },
         hhReportWindowWidth: {
            type: Number,
            value: 0
         },
         _styleObj: {
            type: Object,
            value: {}
         },
         message: {
            type: Object,
            value: {
               msg: "",
               isErr: false
            }
         },
         _category: {
            type: Number,
            value: 0,
            observer: "onSelectCategory",
         },
         g_selectedRow: {
            type: Object,
            observer: "onSelectRow"
         },
         g_selectedTechRow: {
            type: Object,
            observer: "onSelectTechRow"
         },
         _divideStandard: {
            type: Number,
            value: 700
         },
         signText: {
            type: String
         },
         filmboxName: {
            type: String,
            value: "popup",
            readOnly: true
         },
         mismatchCancel: {
            type: Boolean,
            value: false
         },
         dialogState: {
            type: Object,
            observer: "changeDialogState"
         },
         popupReport: {
            type: Boolean,
            value: false,
         },
      };
   }

   ready() {
      // super.ready();
      //
      // // init dialog
      // // initWorklistConfirmDialog(this.$.worklistConfirm);
      // // initVerifyDialog(this.$.verifyDialog);
      // // init user configuration
      // this.getStyleObj().then(({ layout, certAtLogin }) => {
      //    store.dispatch({ type: CommonActionType.SET_USER_CONFIG, payload: { layout, certAtLogin } });
      // });
      //
      // // console.log(i18next.t("msg.cvr.success"));
      // // console.log(i18next.t("msg.cvr", { returnObjects: true }));
      //
      // store.subscribe(() => {
      //    // this._objId = store.getState().report.thumbnailRow;
      //    this._category = store.getState().common.category;
      //    if (this._category === 0) {
      //       this.g_selectedRow = store.getState().worklist.row?.detail;
      //    } else if (this._category === 1) {
      //       this.g_selectedTechRow = store.getState().techlist.row?.detail;
      //    }
      //    this.dialogState = store.getState().common.dialog;
      //    const { popupReport } = store.getState().common;
      //    this.popupReport = popupReport?.open;
      // });
      //
      // this.contentWidth = this.$.content.offsetWidth;
      // this.hhReportWindowWidth = this.$.hhReportWindow.offsetWidth;
      // this.hhTechWindowWidth = this.$.hhTechWindow.offsetWidth;
      //
      // // set split layout
      // this.getUserStyle().then((result) => {
      //
      //    if(result) {
      //       const userStyle = result;
      //
      //       if(!userStyle.tabIndex) userStyle.tabIndex = this.getDefaultTabIndex();
      //       else userStyle.tabIndex = Object.assign({}, this.getDefaultTabIndex(), userStyle.tabIndex);
      //
      //       this.updateWorkListTabIndex(userStyle.id, userStyle.tabIndex);
      //
      //       if(!userStyle.layout) userStyle.layout = this.getDefaultLayout();
      //       else {
      //          userStyle.layout = Object.assign({}, this.getDefaultLayout(), userStyle.layout);
      //       }
      //
      //       this.updateWorkListStyle(userStyle.layout);
      //
      //       this.$.hhReportWindow.setTabIndex(userStyle.tabIndex.hhReportWindowExpand, 3);
      //       this.$.hhReportWindow.setTabIndex(userStyle.tabIndex.hhReportWindowNonExpand, 4);
      //       this.setStyleFilmOpinion(userStyle);
      //    }
      //    // else {
      //    //    this._styleObj.layout = this.getDefaultLayout();
      //    //    this._styleObj.tabIndex = this.getDefaultTabIndex();
      //    // }
      // }).catch((err) => {
      //    console.info(err);
      //    this._styleObj.layout = this.getDefaultLayout();
      //    this._styleObj.tabIndex = this.getDefaultTabIndex();
      // });
      //
      // this.$.content.addEventListener("changeFilterTechlistEvent",() => {
      //    /* TODO : Thumbnail 오류 수정후 주석 해제 */
      //    // this.$.hhTechWindow.setThumbnailInit();
      //    this.$.hhTechWindow.disableAddendumList();
      //    this.$.hhTechWindow.clearClinicalInfo();
      //    this.$.hhTechWindow.clearReport();
      // });
      //
      // // show toast err message
      // this.$.content.addEventListener("openToastEvent",(e) => {
      //    const {msg, isErr} = (e.detail.message||{});
      //    this.openToast(msg, isErr);
      // });
      //
      // this.$.content.addEventListener("stopWorkerEvent", () => {
      //    this.dispatchEvent(new CustomEvent("stopWorkerEvent"));
      // });
      //
      // this.$.settingDialogContainer.addEventListener("displayEvent", (e) => {
      //    const {direction} = e.detail;
      //    if (!direction) {
      //       console.error(`wrong direction : ${direction}`);
      //    }
      // });
      //
      // this.$.content.addEventListener("downloadEvent", (e) => {
      //    this.dispatchEvent(new CustomEvent("downloadEvent", e));
      // });
      //
      // this.$.content.addEventListener("downloadedEvent", (e) => {
      //    this.dispatchEvent(new CustomEvent("downloadedEvent", e));
      // });
      //
      // this.$.content.addEventListener("initStructEvent", (e) => {
      //    this.dispatchEvent(new CustomEvent("initStructEvent", e));
      // });
      //
      // this.$.content.addEventListener("popupEvent", (e) => {
      //    this.$.navbar.setPopup(e.detail);
      // });
      //
      // this.$.hhTechWindow.addEventListener("matchedClickEvent",() => {
      //    this.$.content.techMatchEvent();
      // });
      //
      // this.$.hhTechWindow.addEventListener("mergedClickEvent",() => {
      //    console.log("merged event");
      // });
      //
      // this.$.hhTechWindow.addEventListener("verifyClickEvent",() => {
      //    console.log("verify event");
      // });
      //
      // // this.$.worklistConfirm.addEventListener("confirmCancel", (e) => {
      // //    if(this.popupReport) window.open("", "reportWindow");
      // // });
      //
      // this.$.hhReportWindow.addEventListener("hhReportWindowTabIndexChangeEvent",(e) =>{
      //    let styleObj = {};
      //    this.getUserStyle().then((result) => {
      //       if(result){
      //          styleObj = result;
      //          if(e.path[1].offsetWidth > this._divideStandard){
      //             styleObj.tabIndex.hhReportWindowExpand = e.detail;
      //             this.$.hhReportWindow.setTabIndex(e.detail, 3);
      //          }else{
      //             styleObj.tabIndex.hhReportWindowNonExpand = e.detail;
      //             this.$.hhReportWindow.setTabIndex(e.detail, 4);
      //          }
      //          this.updateWorkListTabIndex(styleObj.id, styleObj.tabIndex);
      //       }
      //    });
      // });
      //
      // this.$.hhReportWindow.addEventListener("toastEvent", (e) => {
      //    this.dispatchEvent(new CustomEvent("toastEvent", e));
      // });
      //
      // this.$.hhReportWindow.addEventListener("setImageReportEvent",(e) =>{
      //    this.setImageReport(e);
      // });
      //
      // this.$.settingDialogContainer.addEventListener("toastEvent", (e) => {
      //    this.dispatchEvent(new CustomEvent("toastEvent", e));
      // });
      //
      // this.$.settingDialogContainer.addEventListener("getListSelectHotkey", () => {
      //    this.dispatchEvent(new CustomEvent("getListSelectHotkey"));
      //    this.$.navbar.getListSelectHotkey();
      // });
      //
      // this.$.navbar.addEventListener("displayNextEvent", () => {
      //    if(this.mismatchCancel) {
      //       window.rejected({message: "Macro was canceled due to mismatch"});
      //       return;
      //    }
      //    this.$.content.displayNext();
      // });
      //
      // this.$.navbar.addEventListener("displayPrevEvent", () => {
      //    if(this.mismatchCancel) {
      //       window.rejected({message: "Macro was canceled due to mismatch"});
      //       return;
      //    }
      //    this.$.content.displayPrev();
      // });
      //
      // this.$.navbar.addEventListener("displayPageUpEvent", () => {
      //    this.$.content.displayPageUp();
      // });
      //
      // this.$.navbar.addEventListener("displayPageDownEvent", () => {
      //    this.$.content.displayPageDown();
      // });
      //
      // this.$.navbar.addEventListener("hotkeyOpinionPaste", (e) => {
      //    this.$.hhReportWindow.setCopyOpinion(e.detail);
      // });
      //
      // this.$.worklistContentSplit.addEventListener("splitter-dragend", () => {
      //    if(this._category === 0) {
      //       if(this.contentWidth !== this.$.content.offsetWidth && this.hhReportWindowWidth !== this.$.hhReportWindow.offsetWidth) {
      //          this.contentWidth = this.$.content.offsetWidth;
      //          this.hhReportWindowWidth = this.$.hhReportWindow.offsetWidth;
      //          if(this._styleObj.layout) {
      //             this.updateSplitFilmOpinion(parseFloat(this.$.divReportWindow.offsetWidth));
      //          }
      //       }
      //    }else if(this._category === 1) {
      //       if(this.contentWidth !== this.$.content.offsetWidth && this.hhTechWindowWidth !== this.$.hhTechWindow.offsetWidth) {
      //          this.contentWidth = this.$.content.offsetWidth;
      //          this.hhTechWindowWidth = this.$.hhTechWindow.offsetWidth;
      //
      //          if(this._styleObj.layout) {
      //             this.updateSplitFilmOpinionTech(parseFloat(this.$.divReportWindow.offsetWidth));
      //          }
      //       }
      //
      //    }
      // });
      //
      // this.$.worklistContentSplit.addEventListener("iron-resize", () =>{
      //    if(this._category === 0) {
      //       this.changehhReportWindowSize();
      //       this.getUserStyle().then((result) => {
      //          if(!result) return;
      //
      //          if(this.$.divReportWindow.offsetWidth > this._divideStandard) {
      //             this.$.hhReportWindow.setTabIndex(result.tabIndex, 0);
      //          } else {
      //             this.$.hhReportWindow.setTabIndex(result.tabIndex, 1);
      //          }
      //       });
      //    }
      // });
      //
      // this.$.navbar.addEventListener("shortcutSetting", (e) => {
      //    this.popupSettingDialog(e);
      // });
      //
      // // PREFETCH 삭제 EVENT
      // this.$.navbar.addEventListener("deletedRawCaseIDEvent",(e) => {
      //    this.prefetcStatushMark(e.detail);
      // });
      //
      // this.$.hhReportWindow.addEventListener("updateExpandEvent", (e) =>{
      //    this.updateExpandForReportWindow(e.detail).then(() => {
      //       // console.log(result);
      //    });
      // });
      //
      // window.addEventListener("message", (event) => {
      //    // if(__SENTRY_DSN__ && __SENTRY_DSN__.trim() !== "") {
      //    //    let username, eventName = null;
      //    //    if(localStorage && localStorage.getItem) username = JSON.parse(localStorage.getItem("user")).name;
      //    //    if(event.data) eventName = event.data.event;
      //    //    Sentry.captureException(new Error(`worklist message event: ${username}, eventName: ${eventName}`), {
      //    //       tags: {
      //    //          section: "mismatch",
      //    //       },
      //    //    });
      //    // }
      //
      //    if(event.data.type === "updateKeyImage") {
      //       this.$.hhReportWindow.setThumbnail(event.data.id);
      //    }
      //    if(event.data.filmboxFocus) {
      //       window.open("", this.filmboxName);
      //    }
      // });
      //
      // this.$.navbar.addEventListener("menuItemClickReloadEvent", () => {
      //    this.$.content.menuItemClickReload();
      // });
      //
      // this.$.navbar.addEventListener("dialogOpenEvent", (e) => {
      //    this.popupSettingDialog(e);
      // });
      //
      // window.addEventListener("beforeunload", () => {
      //    // #17034 토큰이 만료된 후 웹페이지를 닫았을 경우 viewing 정보 update
      //    const nowDate = new Date().getTime();
      //    const expTime = JSON.parse(localStorage.getItem("jwtExpTime"));
      //    if((!expTime) || (expTime && nowDate >= expTime)) this.updateFilmboxOFFStatusByObjectIdUser(this.g_selectedRow?.id);
      //
      //    // #17789 modify exam edit 정보 update
      //    const modifyDialog = this.$.techtabModifiedExamDialog;
      //    if(modifyDialog && modifyDialog.caseObjectId) {
      //       this.caseUnlock({caseIdList: [modifyDialog.caseObjectId]});
      //    }
      // });
      //
      // this._ready = true;
   } // end ready

   // onSelectRow(row) {
   //    // if (row && row.id) {
   //    //    this.getRelatedReports(row.id);
   //    // }
   // }
   //
   // onSelectTechRow(row) {
   //    this.setTechReport();
   // }
   //
   // onSelectCategory(newValue) {
   //    if (this._ready) {
   //       this.$.navbar.sseItemsInit();
   //
   //       this.dispatchEvent(new CustomEvent("stopWorkerEvent"));
   //
   //       // ui
   //       switch (newValue) {
   //       case 0:
   //          this.$.divReportWindow.style.minWidth = "395px";
   //          this.$.divReportWindow.style.maxWidth = null;
   //          this.$.hhReportWindow.style.display = "block";
   //          this.$.hhTechWindow.style.display = "none";
   //          this.$.divReportWindow.style.flex = `1 1 ${parseFloat(this._styleObj.layout.splitFilmOpinion)}px`;
   //          this.$.divWorklistContent.style.flex = `1 1 calc(100% - ${parseFloat(this._styleObj.layout.splitFilmOpinion) + 8}px)`;
   //          this.changehhReportWindowSize();
   //          if (parseFloat(this._styleObj.layout.splitFilmOpinion) > this._divideStandard) {
   //             this.$.hhReportWindow.setTabIndex(this._styleObj.tabIndex, 0);
   //          } else {
   //             this.$.hhReportWindow.setTabIndex(this._styleObj.tabIndex, 1);
   //          }
   //          this.$.hhReportWindow.clearThumbnail();
   //          this.$.hhReportWindow.adjustTextareaHeights();
   //          // this.notifyResize();
   //          break;
   //       case 1:
   //          this.$.divReportWindow.style.minWidth = "395px";
   //          this.$.divReportWindow.style.maxWidth = null;
   //          this.$.hhReportWindow.style.display = "none";
   //          this.$.hhTechWindow.style.display = "block";
   //          if(this._styleObj.layout){
   //             this.$.divReportWindow.style.flex = `1 1 ${parseFloat(this._styleObj.layout.splitFilmOpinionTech)}px`;
   //             this.$.divWorklistContent.style.flex = `1 1 calc(100% - ${parseFloat(this._styleObj.layout.splitFilmOpinionTech) + 8}px)`;
   //          }
   //          this.changehhTechWindowSize();
   //          this.$.hhTechWindow.initTabIdx();
   //          break;
   //       default:
   //       }
   //    }
   // }
   //
   // caseUnlock(params) {
   //    fetch(`/api//tech/studylist/caseUnlock`, {
   //       method: "PATCH",
   //       headers: {
   //          "Authorization": localStorage.getItem("jwt"),
   //          "Content-Type": "application/json"
   //       },
   //       body: JSON.stringify(params)
   //    }).then((response) => {
   //       if (response.ok) {
   //          const params = { detail : { message : { msg : i18next.t("msg.caseLockFail"), isErr : true } } };
   //          this.dispatchEvent(new CustomEvent("openToastEvent", params));
   //       } else {
   //          store.dispatch({ type: TechlistActionType.REDRAW_SELECTED_ROWS, payload: true });
   //       }
   //    });
   // }
   //
   // getDefaultLayout() {
   //    const layout = {};
   //    layout.splitNewFilmOldFilm = "839";
   //    layout.splitFilmOpinion = "395";
   //    layout.splitClinicalReport = "140";
   //    // layout.splitFindingConclusion = "271";
   //    // layout.splitConclusionRecommend = "104";
   //    layout.splitBodyTop = "300";
   //    layout.splitBodyTopRight = "500";
   //    layout.splitBodyTopRightFirst = "200";
   //    layout.splitBodyBottomLeft = "330";
   //
   //    layout.isIndividualScroll = false;
   //    layout.isReportScroll = false;
   //    layout.splitFindings = null;
   //    layout.splitConclusion = null;
   //    layout.isPopupPin = true;
   //
   //    layout.splitFilmOpinionTech = "395";
   //    layout.splitBodyTopTech = "300";
   //
   //    return layout;
   // }
   //
   // getDefaultTabIndex() {
   //    const tabIndex = {};
   //    tabIndex.worklistNewFilm = 0;
   //    tabIndex.hhReportWindowExpand = 0;
   //    tabIndex.hhReportWindowNonExpand = 0;
   //    tabIndex.worklistRelated = 0;
   //    tabIndex.techlistBottom = 0;
   //    tabIndex.techlistRelated = 0;
   //    return tabIndex;
   // }
   //
   // setTechReport() {
   //    const user = JSON.parse(localStorage.user);
   //
   //    // 판독문이 존재할 경우(RS : 2T, 3A)
   //    switch (this.g_selectedTechRow?.readingStatus){
   //    case "3A":
   //       this.getOpinionByWeever(this.g_selectedTechRow.id).then((opinionResult) => {
   //          this.getAllAddendumByWeever(this.g_selectedTechRow.id).then((addendumList) =>{
   //             const elipseTime = CommonUtils.elapsedTimeCheck(opinionResult.approveTime); // min
   //             if(elipseTime <= 4320 && user.id === opinionResult.userId && addendumList == null){
   //                this.$.hhTechWindow.disableAddendumList();
   //                this.$.hhTechWindow.setOpinion({finding:opinionResult.finding, conclusion:opinionResult.conclusion, recommendation:opinionResult.recommendation});
   //             }else{
   //                this.$.hhTechWindow.enableAddendumList();
   //
   //                const opinion = {};
   //                opinion.finding = opinionResult.finding;
   //                opinion.conclusion = opinionResult.conclusion;
   //                opinion.recommendation = opinionResult.recommendation;
   //                if(opinionResult.approver){
   //                   opinion.approver = opinionResult.approver;
   //
   //                   if(opinionResult.approveTime) {
   //                      const approveDtime = new Date(parseInt(opinionResult.approveTime, 10));
   //                      opinion.approveDtime = CommonUtils.getMilisecToDate(approveDtime);
   //                   }
   //                }else{
   //                   if(opinionResult.owner){
   //                      opinion.approver = opinionResult.owner;
   //                   }else{
   //                      opinion.approver = "";
   //                   }
   //
   //                   if(opinionResult.approveTime) {
   //                      const approveDtime = new Date(parseInt(opinionResult.approveTime, 10));
   //                      opinion.approveDtime = CommonUtils.getMilisecToDate(approveDtime);
   //                   }
   //                }
   //
   //                if(addendumList && addendumList.length > 0) {
   //                   this.$.hhTechWindow.ableAddendumAccordionList();
   //                   const tempList = [];
   //                   for (let i = 0; i < addendumList.length; i++) {
   //                      const addendum = {};
   //                      if (addendumList[i].finding) {
   //                         if (addendumList[i].finding) {
   //                            addendum.addendum = addendumList[i].finding;
   //                         }
   //
   //                         if (addendumList[i].conclusion) {
   //                            addendum.addendum += ` ${  addendumList[i].conclusion}`;
   //                         }
   //
   //                         if (addendumList[i].recommendation) {
   //                            addendum.addendum += ` ${  addendumList[i].recommendation}`;
   //                         }
   //                      } else if (addendumList[i].conclusion) {
   //                         addendum.addendum = addendumList[i].conclusion;
   //                      }
   //
   //                      // console.log("addendum list descending")
   //                      const addendumDate = new Date(parseInt(addendumList[i].approveTime, 10));
   //                      addendum.addendumDtime = CommonUtils.getMilisecToDate(addendumDate );
   //                      addendum.addemdumer = addendumList[i].approver;
   //                      tempList.push(addendum);
   //                   }
   //                   opinion.addendumList = tempList;
   //                }else{
   //                   this.$.hhTechWindow.disabledAddendumAccordionList();
   //                }
   //                // this.$.hhTechWindow.setOpinion(opinion);
   //                this.$.hhTechWindow.setOpinion(Object.assign(opinion, {study: this.g_selectedTechRow}));
   //             }
   //          });
   //       });
   //       break;
   //    case "2T":
   //       // 선택한 케이스가 판독이 되지 않았을때
   //       this.$.hhTechWindow.disableAddendumList();
   //
   //       this.getOpinionByWeever(this.g_selectedTechRow.id).then((result) => {
   //          if(result.userId === user.id){
   //             // 저장된 임시 판독문을 내가  다시 확인할때
   //             // save (Hold)
   //             this.$.hhTechWindow.setOpinion({finding:result.finding, conclusion:result.conclusion, recommendation:result.recommendation});
   //          }else{
   //             // Save (Open)
   //             this.$.hhTechWindow.clearReport();
   //          }
   //       });
   //       break;
   //    case "2DT":
   //       // 선택한 케이스가 판독이 되지 않았을때
   //       this.$.hhTechWindow.disableAddendumList();
   //
   //       this.getOpinionByWeever(this.g_selectedTechRow.id).then((result) => {
   //          // transcribe 열람 가능 조건
   //          if( (result.transcribeReadRoles === "User" && result.userId === user.id)
   //             || (result.transcribeReadRoles === "Group" && result.isContainsMembers)
   //             || (result.transcribeReadRoles === "All" && (result.userId === user.id || result.isContainsMembers || result.isContainsFacilityMembers)) ) {
   //             this.$.hhTechWindow.setOpinion({finding:result.finding, conclusion:result.conclusion, recommendation:result.recommendation});
   //          }else{
   //             this.$.hhTechWindow.clearReport();
   //          }
   //       });
   //       break;
   //    default:
   //       this.$.hhTechWindow.disableAddendumList();
   //       this.$.hhTechWindow.clearReport();
   //       break;
   //    }
   // }
   //
   // setImageReport(e) {
   //    if(this.g_selectedRow){
   //       const obj = {};
   //       obj.id = this.g_selectedRow.id;
   //       obj.imageReport = e.detail;
   //       this.$.navbar.getSelectedRowsImageReport(obj);
   //    }
   //    else{
   //       this.openToast(i18next.t("msg.notSelectedRow"), true);
   //    }
   // }
   //
   // setStyleFilmOpinion(result) {
   //    this._styleObj = result;
   //    if(this._styleObj.tabIndex.worklistNewFilm === 0) {
   //       const readingWidth = parseFloat(this._styleObj.layout.splitFilmOpinion);
   //       this.$.divReportWindow.style.width = `${readingWidth}px`;
   //       this.$.divWorklistContent.style.width = `calc(100% - ${readingWidth + 8}px)`;
   //
   //       this.notifyResize();
   //
   //       this.changehhReportWindowSize();
   //       if(readingWidth > this._divideStandard){
   //          this.$.hhReportWindow.setTabIndex(result.tabIndex, 0);
   //       }else{
   //          this.$.hhReportWindow.setTabIndex(result.tabIndex, 1);
   //       }
   //    }else if(this._styleObj.tabIndex.worklistNewFilm === 1){
   //       const readingWidthTech = parseFloat(this._styleObj.layout.splitFilmOpinionTech);
   //       this.$.divReportWindow.style.width = `${readingWidthTech}px`;
   //       this.$.divWorklistContent.style.width = `calc(100% - ${readingWidthTech + 8}px)`;
   //       this.changehhTechWindowSize();
   //    }
   // }
   //
   // updateSplitFilmOpinion(hhReportWindowWidth) {
   //    if(this._styleObj.layout.splitFilmOpinion && this._styleObj.layout.splitFilmOpinion !== hhReportWindowWidth){
   //       this.getUserStyle().then((result) => {
   //          if(result) {
   //             const userStyle = result;
   //
   //             if(!userStyle.layout) userStyle.layout = this.getDefaultLayout();
   //             else userStyle.layout = Object.assign({}, this.getDefaultLayout(), userStyle.layout);
   //
   //             this._styleObj = userStyle;
   //             this._styleObj.layout.splitFilmOpinion = hhReportWindowWidth;
   //             const {splitFilmOpinion} = this._styleObj.layout;
   //             this.updateWorkListStyle( {splitFilmOpinion});
   //          }
   //          // else {
   //          //    this._styleObj.layout.splitFilmOpinion = hhReportWindowWidth;
   //          //    this.createStyle();
   //          // }
   //       }).catch((err) => {
   //          console.error(err);
   //       });
   //    }
   // };
   //
   // updateSplitFilmOpinionTech(hhReportWindowWidth) {
   //    if(this._styleObj.layout.splitFilmOpinionTech && this._styleObj.layout.splitFilmOpinionTech !== hhReportWindowWidth){
   //       this.getUserStyle().then((result) => {
   //          if(result) {
   //             const userStyle = result;
   //
   //             if(!userStyle.layout) userStyle.layout = this.getDefaultLayout();
   //             else userStyle.layout = Object.assign({}, this.getDefaultLayout(), userStyle.layout);
   //
   //             this._styleObj = userStyle;
   //             this._styleObj.layout.splitFilmOpinionTech = hhReportWindowWidth;
   //             const {splitFilmOpinionTech} = this._styleObj.layout;
   //             this.updateWorkListStyle( {splitFilmOpinionTech});
   //          }
   //       }).catch((err) => {
   //          console.error(err);
   //       });
   //    }
   // }
   //
   // changehhReportWindowSize(){
   //    if(this.$.hhReportWindow.offsetWidth >= this._divideStandard){
   //       this.$.hhReportWindow.setExpand();
   //    }else{
   //       this.$.hhReportWindow.setNonExpand();
   //    }
   // }
   //
   // changehhTechWindowSize(){
   //    this.$.hhTechWindow.setSize();
   //    this.$.content.setTechWidth();
   // }
   //
   // completedPrefetchMark(message) {
   //    this.$.navbar.currentUsageFileSystem(message);
   //    this.$.content.completedPrefetchMark(message);
   // }
   //
   // prefetcStatushMark(message) {
   //    this.$.content.prefetcStatushMark(message);
   // }
   //
   // hideOverlay() {
   //    this.$.content.hideOverlay();
   // }
   //
   // getUserStyle() {
   //    return new Promise((resolve, reject) => {
   //       fetch(`/api/user/option/style`, {
   //          method: "GET",
   //          headers: {
   //             "Authorization": localStorage.getItem("jwt")
   //          }
   //       }).then((response) => {
   //          if (response.ok && response.status === 200) {
   //             response.json().then((rows) => {
   //                resolve(rows);
   //             });
   //          } else {
   //             reject(new Error(`status: ${response.status}, message: ${response.statusText}`));
   //          }
   //       });
   //    });
   // }
   //
   // updateWorkListStyle(layout) {
   //    fetch(`/api/user/option/style/layout`, {
   //       method: "PATCH",
   //       headers: {
   //          "Authorization": localStorage.getItem("jwt"),
   //          "Content-Type": "application/json"
   //       },
   //       body: JSON.stringify(layout)
   //    }).then((response) => {
   //       if (!response.ok) {
   //          console.debug(new Error(`${response.status} ${response.statusText}`));
   //       }
   //    });
   // }
   //
   // updateWorkListTabIndex(id, tabIndex) {
   //    const param = { request : tabIndex };
   //    fetch(`/api/user/option/tabIndex/${id}`, {
   //       method: "PATCH",
   //       headers: {
   //          "Authorization": localStorage.getItem("jwt"),
   //          "Content-Type": "application/json"
   //       },
   //       body: JSON.stringify(param.request)
   //    }).then((response) => {
   //       if (!response.ok) {
   //          console.log("Response Layout Failed");
   //       }
   //    });
   // }
   //
   // updateExpandForReportWindow(param) {
   //    return new Promise((resolve, reject) => {
   //       const {id} = JSON.parse(localStorage.getItem("user"));
   //       fetch(`/api/user/${id}/config/worklist`, {
   //          method: "PATCH",
   //          headers: {
   //             "Authorization": localStorage.getItem("jwt"),
   //             "Content-Type": "application/json"
   //          },
   //          body: JSON.stringify(param)
   //       }).then((response) => {
   //          if (!response.ok) {
   //             reject(new Error(`${response.status} ${response.statusText}`));
   //          }
   //       });
   //    });
   // }
   //
   // getOpinionByWeever(id) {
   //    return new Promise((resolve) => {
   //       fetch(`/api/opinion/weever/${id}`, {
   //          method: "GET",
   //          headers: {
   //             "Authorization": localStorage.getItem("jwt")
   //          }
   //       }).then((response) => {
   //          if (response.ok && response.status === 200) {
   //             response.json().then((httpResponse) => {
   //                resolve(httpResponse);
   //             });
   //          } else {
   //             resolve("failed");
   //          }
   //       });
   //    });
   // }
   //
   // getAllAddendumByWeever(id) {
   //    return new Promise((resolve, reject) => {
   //       fetch(`/api/opinion/addendums/${id}`, {
   //          method: "GET",
   //          headers: {
   //             "Authorization": localStorage.getItem("jwt")
   //          }
   //       }).then((response) => {
   //          if (response.ok && response.status === 200) {
   //             response.json().then((httpResponse) => {
   //                resolve(httpResponse);
   //             });
   //          }
   //          else if(response.status === 204){
   //             resolve(null);
   //          }else{
   //             reject(new Error(`${response.status} ${response.statusText}`));
   //          }
   //       });
   //    });
   // }
   //
   // openToast(msg, isErr) {
   //    // console.log("'fireEvent'", {bubbles: true, composed: true, detail});
   //    const detail = {msg, isErr};
   //    this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
   // }
   //
   // changeCacheUsage() {
   //    this.dispatchEvent(new CustomEvent("changeCacheUsageEvent", {bubbles: true, composed: true}));
   // }
   //
   // onvertHashCode(str) {
   //    return Array.from(str).reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0);
   // }
   //
   // updateFilmboxOFFStatusByObjectIdUser(requestObjectId) {
   //    const user = JSON.parse(localStorage.getItem("user"));
   //    if(!user || !requestObjectId) return;
   //    return new Promise((resolve) => {
   //       fetch(`/api/exchange/worklist/filmboxStatus/close/${requestObjectId}/user?userId=${user.id}`, {
   //          method: "PATCH",
   //          headers: {
   //             "Content-Type": "application/json"
   //          }
   //       }).then((response) => {
   //          if (!response.ok) {
   //             console.error(`Message : `, response.status);
   //          }
   //       }).finally(() => resolve());
   //    });
   // }
   //
   // sinkViewing(request) {
   //    this.$.content.sinkViewing(request);
   // }
   //
   // sinkVerify(requestList) {
   //    this.$.content.sinkVerify(requestList);
   // }
   //
   // sinkApprove(requestList) {
   //    this.$.content.sinkApprove(requestList);
   // }
   //
   // sinkAiState(aiAppProgress) {
   //    this.$.content.sinkAiState(aiAppProgress);
   // }
   //
   // sinkTeleRequest(params) {
   //    this.$.content.sinkTeleRequest(params);
   // }
   //
   // sinkTeleStatusVerify(request) {
   //    this.$.content.sinkTeleStatusVerify(request);
   // }
   //
   // popupSettingDialog(e){
   //    this.$.settingDialogContainer.callSettingDialog(e);
   // }
   //
   // filmboxInit(){
   //    this.$.settingDialogContainer.filmboxInit();
   // }
   //
   // getStyleObj() {
   //    return new Promise((resolve, reject) => {
   //       fetch(`/api/user/option/style`, {
   //          method: "GET",
   //          headers: {
   //             "Authorization": localStorage.getItem("jwt")
   //          }
   //       }).then((response) => {
   //          if (response.ok && response.status === 200) {
   //             response.json().then((rows) => {
   //                resolve(rows);
   //             });
   //          } else {
   //             reject(new Error(`status: ${response.status}, message: ${response.statusText}`));
   //          }
   //       });
   //    });
   // }
   //
   // changeDialogState(dialogState) {
   //    if (dialogState) {
   //       const { type, actionType, message, open } = dialogState;
   //       switch (type) {
   //       case DialogType.REPORT_CONFIRM_DIALOG: {
   //          if (this.popupReport) {
   //             // popupReport가 있는 경우 그 창에서 dialog 상태 처리됨
   //             // dialog 관련 action은 redux sync로 동기화 되지 않도록 처리했기 때문에 강제 종료 처리
   //             store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   //             return;
   //          }
   //          message.actionType = actionType;
   //          this.$.reportConfirm.doOpen(message);
   //          break;
   //       }
   //       case DialogType.CONFIRM_DIALOG: {
   //          if (this.popupReport) {
   //             // popupReport가 있는 경우 그 창에서 dialog 상태 처리됨
   //             // dialog 관련 action은 redux sync로 동기화 되지 않도록 처리했기 때문에 강제 종료 처리
   //             store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   //             return;
   //          }
   //          message.actionType = actionType;
   //          this.$.worklistConfirm.doOpen(message);
   //          break;
   //       }
   //       case DialogType.VERIFY_DIALOG: {
   //          // this.$.verifyDialog.setSelectedRows(message.params);
   //          this.$.verifyDialog.doOpen(message);
   //          break;
   //       }
   //       case DialogType.MODIFY_EXAM_DIALOG: {
   //          this.$.techtabModifiedExamDialog.doOpen(message.detail);
   //          break;
   //       }
   //       case DialogType.NEW_EXAM_DIALOG: {
   //          this.$.techtabNewExamDialog.doOpen(message.detail);
   //          break;
   //       }
   //       case DialogType.SPLIT_EXAM_DIALOG: {
   //          this.$.techtabSplitDialog.doOpen(message.detail);
   //          break;
   //       }
   //       default:
   //          break;
   //       }
   //    }
   // }
}

window.customElements.define("hh-worklist", HHWorklist);
