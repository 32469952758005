import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { LicenseManager } from  "ag-grid-enterprise";
import { ThemeProvider } from "@mui/material";
import $ from "cash-dom";
import detectOs from "../src/platformDetection/detectOs";
import "../public/resource/js/localization";
import "../public/style/css.css";
import MainApp from "../src/layout/main/MainApp";
import "../src/hh-login";
import store from "../src/redux/store";
import { ToastProvider } from "../src/context/ToastContext";
import { globalTheme } from "../src/components/global/styles/globalTheme";

LicenseManager.setLicenseKey(__AG_LICENSE__);
let isCreated = false;

const createScript = (src : string) => {
   const el = document.createElement("script");
   el.setAttribute("type", "text/javascript");
   if (src) el.setAttribute("src", src);
   return el;
};

const createMainApp = (bol : boolean) => {
   // const mainApp = document.createElement("main-app");
   // const login = document.createElement("hh-login");
   window.addEventListener("checkExpTime", () => {
      // eslint-disable-next-line no-use-before-define
      checkExpTime();

      // const mainApp = document.createElement("main-app");
      // mainApp.startServerSentEvent();
   });
   // mainApp.setAttribute("unresolved", "");
   const body = document.querySelector("body");

   if (bol) {
      const css = document.createElement("link");
      css.setAttribute("rel", "stylesheet");
      css.setAttribute("type", "text/css");
      css.setAttribute("href", "/crosscert/unisignweb/rsrc/css/certcommon.css?v=1");
      document.querySelector("head")?.appendChild(css);

      const ifrm = document.createElement("iframe");
      ifrm.setAttribute("src", "https://127.0.0.1:15018/");
      ifrm.setAttribute("name", "hsmiframe");
      ifrm.setAttribute("id", "hsmiframe");
      body?.appendChild(ifrm);

      // UniSignWeb_Multi_Init_Nim.js에서 unisignwebclient.js의 객체를 가져다 쓰기 때문에
      // unisignwebclient.js의 로딩이 되기 전에 UniSignWeb_Multi_Init_Nim.js에서 먼저 호출 되면서 발생하는 오류 방지
      const script = createScript("/crosscert/unisignweb/js/unisignwebclient.js?v=1");
      script.onload = () => setTimeout(() => body?.appendChild(createScript("/crosscert/UniSignWeb_Multi_Init_Nim.js?v=1")));
      body?.appendChild(script);
   }

   isCreated = true;
   // todo: toastProvider 위치 변경 필요 => main-app
   ReactDOM.createRoot(document.getElementById("root")!).render(
      <Provider store={store}>
         <ThemeProvider theme={globalTheme}>
            <ToastProvider>
               <MainApp/>
               <hh-login id="hhLogin"/>
            </ToastProvider>
         </ThemeProvider>
      </Provider>,
   );
};

const checkExpTime = () => {
   // console.log("checkExpTime", __CERT_STORAGE__, localStorage.getItem("jwt"));
   if (!localStorage.getItem("jwt")) {
      window.location.replace("/home");
   } else {
      fetch(`/api/auth/login/v1/check/expTime`, {
         method: "GET",
         headers: {
            "Authorization": localStorage.getItem("jwt") as string,
            "Content-Type": "application/json;charset=utf-8",
         },
      }).then((response) => {
         if (response.status === 200) {
            return response.text();
         }
         throw new Error(`[${response.status}] ${response.statusText}`);
      }).then((result) => {
         if (result) {
            const { verify, expTime } = JSON.parse(result);
            if (verify) {
               // Link login or 우리메디컬 login
               if (localStorage.getItem("link")) { // || localStorage.getItem("accessionNumber")) {
                  window.location.replace("/linkMain");
               } else {
                  if (!isCreated) initPage();

                  const interval = setInterval(() => {
                     const nowTime = new Date().getTime();
                     if (nowTime >= expTime) {
                        console.log(`${nowTime} (${new Date(nowTime)})`, `${expTime} ( ${new Date(expTime)})`, (nowTime >= expTime));
                        clearInterval(interval);

                        // todo : 어떤 경우에 사용하는지 확인 필요
                        // const mainApp = document.createElement("main-app");
                        // mainApp.stopServerSentEvent();

                        // const login = document.querySelector("hh-login");
                        $("#hhLogin")[0].open(true);
                        window.postMessage({ event: "READING_OFF" }, document.location.href);
                     }
                  }, 5000);
               }
            }
            else {
               window.location.replace("/home");
            }
         }
      }).catch((error) => {
         console.error(error);
         window.location.replace("/home");
      });
   }
};

const scriptList = ["/crosscert/unisignweb/framework/json2.js", "/crosscert/install/ObjectInstall.js"];
const appendScript = () => new Promise((resolve) => {
   const body = document.querySelector("body");
   scriptList.forEach((src, idx) => {
      const el = createScript(src);
      if (idx === scriptList.length - 1) {
         el.onload = () => {
            resolve(true);
         };
      }
      body?.appendChild(el);
   });
});

function initPage() {
   if (JSON.parse(localStorage.getItem(__CERT_STORAGE__) as string) === true) {
      // TODO: Windows가 아니면 어떻게 할 것인가?
      if (!detectOs.isWindows()) {
         localStorage.setItem(__CERT_STORAGE__, JSON.stringify(false));
         createMainApp(false);
      } else {
         appendScript().then(() => {
            // TODO : window 객체 global 타입으로 잡을지 확인 필요
            // @ts-ignore
            if (!window.uniCRSChecker) {
               // @ts-ignore
               window.uniCRSChecker = UniCRSInstall({
                  options: {
                     callerName: "window.uniCRSChecker", // 선언 변수명과 반드시 동일 해야 함!
                  },
                  success: () => {
                     // sessionStorage.removeItem(__CERT_STORAGE__);
                     document.querySelectorAll("script").forEach((el) => {
                        const src = el.getAttribute("src") || "";
                        // console.log("src", src, "https://127.0.0.1:15018/VER?window.uniCRSChecker.UniCRSVersion");
                        if (scriptList.includes(src) || src.startsWith("https://127.0.0.1:15018/")) {
                           el.parentNode?.removeChild(el);
                        }
                     });
                     createMainApp(true);
                  },
                  fail: () => {
                     window.location.replace("/cert");
                  },
                  progress: () => {},
               });
            }
         });
      }
   }
   else {
      createMainApp(false);
   }
}

checkExpTime();

window.name = "worklist";
