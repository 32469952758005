import React, { useEffect, useState } from "react";
// @ts-ignore
import Prefetch from "hh-prefetch";

import { useSelector } from "react-redux";
import { useEventListener, useIsFirstRender } from "usehooks-ts";
import $ from "cash-dom";
// https://vitejs-kr.github.io/guide/features.html#web-workers
// eslint-disable-next-line import/no-unresolved
import WorklistWorker from "../../worker/worklist.worker?worker&inline";
import { RootState } from "../../redux/reducers";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import commonUtils from "../../../public/resource/js/utils/common";
import store from "../../redux/store";

export default function usePrefetch() {
   const [m_worker, setM_worker] = useState<Worker | null>(null);
   const [m_prefetch, setM_prefetch] = useState<Prefetch | null>(null);
   const [m_workerQueue, setM_workerQueue] = useState(Array<object>);
   const [m_worklist, setM_worklist] = useState(new Map());
   const [flag, setFlag] = useState(true);   // 프리페치가 진행되는 시점을 잡기 위한 state
   // const m_workerMax : number = 5;
   const prefetchState = useSelector((state : RootState) => state.common.prefetch);
   const [startPrefetch, setStartPrefetch] = useState((window.caches && !JSON.parse(localStorage.getItem("stopPrefetch")!)));

   // 이벤트 리스너 중 사용하는 state
   const [requestList, setRequestList] = useState<Array<object>>();

   // fecth url
   const m_url : string = `${window.location.protocol}//${window.location.host}`;

   const queueSortAdd = (value:any) => {
      setM_workerQueue(prev => [...value, ...prev].sort(
         (a, b) => a.no - b.no,
      ));
   };

   const queueRemove = (value:any) => {
      setM_workerQueue(m_workerQueue.filter((row:any) => row.id !== value.id));
   };

   // worker, prefetch 초기 Init
   useEffect(() => {
      initPrefetch();
   }, []);

   useEffect(() => {
      if (m_prefetch) initWorker();
   }, [m_prefetch]);

   // prefetch on/off에 따라 prefetch 실행 여부 달라짐
   useDidMountEffect(() => {
      changePrefetchState();
   }, [prefetchState]);

   // "initStructEvent" 이벤트를 통해서 queue가 생성이 되어지고, 시작여부가 true이면 fetch를 시작함
   useDidMountEffect(() => {
      if (window.caches && startPrefetch) {
         if (flag) fetchWorklist();
      } else if (!commonUtils.isEmptyArr(requestList)) {
         const result = requestList?.map((row:any) => row.id);
         completedPrefetchMark({ msgType: "completedPrefetch", data: (result || []), target: "newFilm" });
      }
   }, [m_workerQueue, requestList, flag]);

   // todo: grid-newfilm "initStructEvent" 이벤트 detail type 수정 필수
   useEventListener("initStructEvent", (e:any) => {
      // 하나의 필터에서 한번만 조회 하도록 수정 하여, reset 로직 제거
      // if (e.detail.type === "filter" && (startRowIdx < e.detail.startRow)) {
      //    resetWorker();
      // }

      const idList: string[] = [];
      (e.detail.data.requestObjectIdList || []).forEach((row: any) => {
         if (!m_worklist.has(row.id)) {
            const map = new Map();
            map.set("total", 0);
            map.set("cnt", 0);
            map.set("error", 0);
            m_worklist.set(row.id, map);
            idList.push(row);
         }
      });

      queueSortAdd(idList);
      setFlag(true);
      setRequestList(e.detail.data.requestObjectIdList);
   });

   useEventListener("message", (evt) => {
      const { data } = evt;
      if (data.event === "FILMBOX_PREFETCH_DONE") {
         if (m_prefetch) m_prefetch.removeByStudy(data.studyId);
         if (m_worker) m_worklist.delete(data.studyId);
         completedPrefetchMark({ msgType: "completedPrefetch", data: [data.studyId], target: "newFilm" });
      }
   });

   useEventListener("beforeunload", () => {
      destoryWorker();
   });

   useEventListener("stopWorkerEvent", () => {
      setFlag(false);
      // filter Clear, User filter, Tab 이동시 이벤트 발생
      resetWorker(true);
   });

   const workerListener = (evt: any) => {
      const { data } = evt;
      const { success } = data || {};
      if (success) {
         if (data.done) {
            // next();
         } else if (startPrefetch) {
            // const channel = data.channel || 5;
            const images = data.images || [];
            // const { first } = data;

            const rawImages = images.map((m: any) => {
               const numberOfFrames = m.numberOfFrames || 1;
               if (numberOfFrames === 1) return [{ id: m.id, path: `/prefetch/download/image/${m.id}/dcm`, series: m.series, study: m.study, type: "dicom" }];

               return Array.from(Array(numberOfFrames).keys())
                  .map(idx => ({ id: `${m.id}${idx + 1}`, path: `/content/${m.id}/${idx + 1}`, series: m.series, study: m.study, type: "dicom" }))
                  .reduce((c:any, a:any) => {
                     c.push(a);
                     return c;
                  }, []);
            }).flat();

            // if (first) m_prefetch.add(rawImages);
            // else m_prefetch.add(rawImages);
            m_prefetch.add(rawImages);

            if (m_worklist.has(data.study)) {
               m_worklist.get(data.study).set("total", data.total);
            }
         }
      } else {
         // next();
      }
   };

   function initWorker(clear: boolean = true) {
      // reset시 다시 초기값으로 돌려줌
      // if (clear) {
      m_worklist.clear();
      setM_workerQueue([]);
      // setM_workerCount(0);
      // }
      const worker = new WorklistWorker();
      worker.onmessage = workerListener;
      setM_worker(worker);
   }

   function initPrefetch() {
      const workerCountSetting = localStorage.getItem("worker-count");
      const numImageWorkers = workerCountSetting == null
         ? (Math.max(window.navigator.hardwareConcurrency - 1, 1) || 1)
         : parseInt(workerCountSetting, 10);

      const config = {
         url: __CDN_URL__,
         method: "GET",
         headers: { "Authorization": localStorage.getItem("jwt") },
         numImageWorkers,
         priority: 1,
         deleteBatch: true,
      };

      const prefetch = Prefetch.create(config, startPrefetch);
      prefetch.on(Prefetch.EVENTS.IMAGE_ADDED, (image: any) => {
         // setFlag(true);
         // {msgType: "startPrefetch", data: "5b63d6efe4b099a4cf8e3713", target: "newFilm"}
         const map = m_worklist.get(image.study);
         if (map) {
            if (!map.get("status")) {
               map.set("status", "start");
               prefetcStatushMark({ msgType: "startPrefetch", data: image.study, target: "newFilm" });
            }
         }
      });

      prefetch.on(Prefetch.EVENTS.IMAGE_DONE, (image : any) => {
         setFlag(true);
         // {msgType: "completedPrefetch", data: data: ["5b63d6efe4b099a4cf8e3713"]}
         const map = m_worklist.get(image.study);
         if (map) {
            const cnt = parseInt(map.get("cnt"), 10) + 1;
            const total = parseInt(map.get("total"), 10);
            if (cnt === total) {
               m_worklist.delete(image.study);
               completedPrefetchMark({ msgType: "completedPrefetch", data: [image.study], target: "newFilm" });
            }
            else {
               map.set("cnt", cnt);
            }
         }

         onChangeCacheUsage();
      });

      prefetch.on(Prefetch.EVENTS.IMAGE_NOT_EXISTS, (image: any) => {
         setFlag(true);
         console.error("image not exists", image);
         prefetch.removeByStudy(image.study);

         const map = m_worklist.get(image.study);
         if (map) {
            m_worklist.delete(image.study);
            prefetcStatushMark({ msgType: "emptyPrefetch", data: image.study, target: "newFilm" });
         }
      });

      prefetch.on(Prefetch.EVENTS.IMAGE_ERROR, (image: any, message: any, error: any) => {
         setFlag(true);
         console.error("image error", image, message, error);
         prefetch.removeByStudy(image.study);

         const map = m_worklist.get(image.study);
         if (map) {
            m_worklist.delete(image.study);
            prefetcStatushMark({ msgType: "failPrefetch", data: image.study, target: "newFilm" });
         }
      });
      setM_prefetch(prefetch);
   }

   function resetWorker(clear = true) {
      m_worker?.terminate();
      m_prefetch?.reset(startPrefetch);
      // m_thumbPrefetch.reset(startPrefetch);
      initWorker(clear);
   }

   function destoryWorker() {
      m_worker?.terminate();
      // m_prefetch.destory();
      // m_thumbPrefetch.destory();
   }

   function fetchWorklist() {
      // console.log("m_workerQueue : ", m_workerQueue);
      if (!m_workerQueue || m_workerQueue.length === 0) return;
      // if (m_workerCount < m_workerMax) {
      // const size = Math.min(m_workerMax - m_workerCount, m_workerQueue.length);
      const list = m_workerQueue.slice(0, 1);
      // 이벤트 리스너에서 state 업데이트가 반영이 안되는 현상으로 fetch에서 계산
      // setM_workerCount(m_workerCount - 1 + list.length);

      list.forEach((row: any) => {
         queueRemove(row);
         m_worker?.postMessage({ url: m_url, id: row.id, token: localStorage.getItem("jwt") });
      });
      // }
      setFlag(false);
   }

   function changePrefetchState() {
      setStartPrefetch(prefetchState);

      if (!prefetchState && m_prefetch) {
         resetWorker();
      }
   }

   function completedPrefetchMark(message) {
      if ($("#content")[0]) {
         $("#content")[0].completedPrefetchMark(message);
         return false;
      }
      if (message.target === "oldFilm") {
         // $("#worklistOldFilm")[0].batchCompletedPrefetchMark(message.data);
      } else {
         /* @TODO WorklistContainer */
         $("#worklistNewFilm")[0].completedPrefetchMark(message.data);
      }
   }

   function prefetcStatushMark(message) {
      if ($("#content")[0]) {
         $("#content")[0].prefetcStatushMark(message);
         return false;
      }
      if (message.target === "newFilm") {
         $("#worklistNewFilm")[0].prefetcStatushMark(message);
      }
   }

   function onChangeCacheUsage() {
      // $.worklist.changeCacheUsage();
      dispatchEvent(new CustomEvent("changeCacheUsageEvent", { bubbles: true, composed: true }));
   }
}
