import React, { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import i18next from "i18next";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import i18n from "../../utils/i18n-utils";
import Menu from "../global/Menu";
import { CommonActionType, DialogType } from "../../redux/reducers/common";
import "../../filesystem/hh-filesystem";
import "../../hh-component-logout";
import ComponentLogout from "../logout/ComponentLogout";

const DivContainer = styled.div`
   display: flex;
   align-items: center;
   height: 32px;
`;

const LogoutContainer = styled.div`
   margin-left: auto;
`;

const WorklistConfigMenu = () => {
   const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);
   const [menuItems, setMenuItems] = useState<any>([]);
   const [hospital, setHospital] = useState(JSON.parse(localStorage.getItem("user")!).facilityName);
   const dispatch = useDispatch();

   useEffect(() => {
      getUser();
   }, []);

   function getUser () {
      if (localStorage.getItem("user")) {
         const userName = JSON.parse(localStorage.getItem("user")!).name;
         const userData = [{
            ..._defineItem("userInfo", userName.replace(/(\s*)/g, ""), "person", 150),
            childNode: [
               {
                  "id": "userInfo",
                  "name": i18n("menu.userInfo"),
                  "icon": "person",
               },
               {
                  "id": "readingList",
                  "name": i18n("menu.readingList"),
               },
            ],
         }, { ..._defineItem("hospital", hospital.replace(/(\s*)/g, ""), <ApartmentIcon/>, 150) }];
         setMenuItems(userData);
      }
   }

   function _defineItem(id: any, name: any, icon?: any, size?: number) {
      return {
         "id": id,
         "name": name,
         "icon": icon,
         "size": size,
      };
   }

   function handleMenuClick(event: any) {
      const { key } = event.currentTarget.dataset ?? {};
      switch (key) {
      case "userInfo":
         popupPasswordDialog();
         break;
      case "readingList":
         openAdminPopup();
         break;
      default:
         break;
      }
   }

   function popupPasswordDialog() {
      dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.PASSWORD_DIALOG, open: true } });
   }

   function openAdminPopup() {
      window.open(`${__ADMIN_URL__}/#/login?redirect=%2FreadingReportPopup`, "HPACS-ADMIN", "top=10, left=10, width=1100, height=750, status=no, menubar=no, toolbar=no, resizable=no");
   }

   const handleChange = (event: SelectChangeEvent) => {
      setSelectedLanguage(event.target.value);
      localStorage.setItem("i18nextLng", event.target.value);
      window.location.reload();
   };

   return (
      <DivContainer>
         <div>
            <hh-filesystem id="hhFilesystem"/>
         </div>
         <Menu menuItems={menuItems} handleMenuClick={handleMenuClick} />
         <div>
            <FormControl sx={{ m: 1, minWidth: 75 }} variant={"standard"}>
               <Select
                  disableUnderline={true}
                  value={selectedLanguage}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx= {{ color: "#ccc",
                     fontSize: 15,
                     paddingTop: "3px",
                     ".MuiSelect-icon": {
                        color: "#ccc",
                     } } }
                  MenuProps={{
                     PaperProps: {
                        sx: {
                           backgroundColor: "#1d1e25",
                           "& .MuiMenuItem-root": {
                              color: "#ccc",
                           },
                        },
                     },
                  }}
               >
                  <MenuItem value="en">
                     <em style={{ fontStyle: "normal" }}>English</em>
                  </MenuItem>
                  <MenuItem value="es">Español</MenuItem>
               </Select>
            </FormControl>
         </div>
         <LogoutContainer>
            {/* TODO: WorklistNavbar에서 id로 직접 기능 호출하고 있음. React 변환 후 함께 처리 필요 */}
            <hh-component-logout id="logout"/>
            {/* <ComponentLogout /> */}
         </LogoutContainer>
      </DivContainer>
   );
};

export default WorklistConfigMenu;
