import { html, PolymerElement } from "@polymer/polymer/polymer-element";
import mixinCommons from "../common-mixin";
import store from "../redux/store";
/**
 *
 * @customElement
 * @polymer
 */

class HHAiReport extends mixinCommons(PolymerElement) {

   static get template() {
      return html`
       <style>
           :host {
              display: block;
              width: 100%;
              height: 100%;
           }

           .container {
               width: 100%;
               height: 100%;
           }

           .report-result-wrap {
                padding: 20px;
                min-height: 100px;
            }

           .report-text-wrap {
               width: 100%;
               display: flex;
               flex-direction: row;
               align-items: flex-start;
               justify-content: space-between;
               color: white;
               word-break: break-all;

               font-size: 14px;
               white-space: pre-wrap;
           }

           .report-icon {
              display: flex;
           }

           .report-content-wrap {
                display: flex;
                flex-direction: column;

                margin-top: 20px;
           }

           .report-category {
               color: white;
               font-weight: 700;
           }


           .ai-name {
               color: white;
               margin-bottom: 20px;
               font-size: 1.5rem;
           }

           .text-copy-button {
               width: 25px;
               height: 25px;
               cursor: pointer;
           }
       </style>

        <div class="container">
           <template id="aiReportResultTemplate" is="dom-repeat" items="{{aiReportResults}}">
                <div class="report-result-wrap">
                    <div class="ai-name">
                        {{item.aiName}}
                    </div>
                    <template is="dom-if" if="{{item.finding}}" restamp>
                        <div class="report-content-wrap">
                            <div class="report-category">
                                {{t("label.findings")}}
                            </div>
                            <div class="report-text-wrap">{{item.finding}}
                               <div class="report-icon">
                                  <iron-icon class="text-copy-button"
                                                on-click="copyAndPasteText"
                                                data-text$="{{item.finding}}"
                                                data-args="finding"
                                                icon="icons:content-paste">
                                  </iron-icon>
                               </div>
                            </div>
                        </div>
                    </template>

                    <template is="dom-if" if="{{item.conclusion}}" restamp>
                        <div class="report-content-wrap">
                            <div class="report-category">
                               {{t("label.conclusion")}}
                            </div>
                            <div class="report-text-wrap">{{item.conclusion}}
                               <div class="report-icon">
                                  <iron-icon class="text-copy-button"
                                               on-click="copyAndPasteText"
                                               data-text$="{{item.conclusion}}"
                                               data-args="conclusion"
                                               icon="icons:content-paste">
                                  </iron-icon>
                               </div>
                            </div>
                        </div>
                    </template>

                    <template is="dom-if" if="{{item.recommendation}}" restamp>
                        <div class="report-content-wrap">
                            <div class="report-category">
                                {{t("label.recommendation")}}
                            </div>
                            <div class="report-text-wrap">{{item.recommendation}}
                               <div class="report-icon">
                                  <iron-icon class="text-copy-button"
                                            on-click="copyAndPasteText"
                                            data-text$="{{item.recommendation}}"
                                            data-args="recommendation"
                                            icon="icons:content-paste">
                                  </iron-icon>
                               </div>
                            </div>
                        </div>
                    </template>
                </div>
           </template>
        </div>
      `;
   }

   static get properties() {
      return {
         aiReportResults: {
            type: Array,
            value: [],
            // observer: "_changeMenuItems"
         },
         popupReport: {
            type: Boolean,
            value: false
         },
      };
   }

   // eslint-disable-next-line no-useless-constructor
   constructor() {
      super();
   }

   static get is() {
      return "hh-ai-report";
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         const { popupReport } = store.getState().common;
         this.popupReport = popupReport?.open;
      });
      // this.$.aiReportResultTemplate;
   }

   // 서버에서 받아온 ai finding text 값을 복사 한후 report findings에 붙여 넣기는 이벤트를 불러오는 함수 (이벤트는 hh-report에 등록 되어있음)
   copyAndPasteText(event) {
      const { text } = event.target.dataset;
      const type =  event.target.getAttribute("data-args");

      const param = {};

      param.detail = {
         text,
         type,
      };

      // if (this.popupReport) {
      //    window.postMessage({event: "POPUP_AI_REPORT", detail: param.detail}, document.location.href);
      // } else {
      dispatchEvent(new CustomEvent("copyAndPasteAiReport", param));
      // }
   }
}

window.customElements.define(HHAiReport.is, HHAiReport);
