import React, { useEffect, useState } from "react";
import $ from "cash-dom";
import useDidMountEffect from "./useDidMountEffect";

export default function useServerSentEvent() {
   const userId = JSON.parse(localStorage.getItem("user")!).id;
   // const source = new EventSource(`${__API_URL__}/sse/subscribe/${userId}`);
   const [source, setSource] = useState<EventSource | null>(null);
   const [retryCount, setRetryCount] = useState(0);

   useEffect(() => {
      startServerSentEvent();
   }, []);

   useDidMountEffect(() => {
      if (source) createEventSource(source);
   }, [source]);

   function createEventSource(source: EventSource) {
      console.log("source : ", source);

      console.log("Create EventSource! ");

      // Event
      source.addEventListener("sink-viewing-event", (e) => {
         const data = JSON.parse(e.data);
         if (data) {
            if ($("#content")[0]) {
               $("#content")[0].sinkViewing(data);
            } else {
               $("#worklistNewFilm")[0].sinkViewing(data);
            }
         }
      });

      source.addEventListener("sink-verify-event", (e) => {
         const data = JSON.parse(e.data);
         if (data) {
            if ($("#content")[0]) {
               $("#content")[0].sinkViewing(data);
            } else {
               $("#worklistNewFilm")[0].sinkViewing(data);
            }
         }
      });

      source.addEventListener("sink-modify-event", (e) => {
         const data = JSON.parse(e.data);
         if (data) {
            const { isModifiedImage } = data;
            // modify exam data
            if ((window.filmbox && window.filmbox.get()) && isModifiedImage) {
               window.postMessage({ event: "CHANGE_FILMBOX_IMAGE" }, document.location.href);
            }
         }
      });

      source.addEventListener("sink-save-event", (e) => {
         const data = JSON.parse(e.data);
         if (data) {
            if ($("#content")[0]) {
               $("#content")[0].sinkApprove(data);
            } else {
               $("#worklistNewFilm")[0].sinkApprove(data);
            }
         }
      });

      source.addEventListener("sink-transcribe-event", (e) => {
         const data = JSON.parse(e.data);
         if (data) {
            if ($("#content")[0]) {
               $("#content")[0].sinkApprove(data);
            } else {
               $("#worklistNewFilm")[0].sinkApprove(data);
            }
         }
      });

      source.addEventListener("sink-approve-event", (e) => {
         const data = JSON.parse(e.data);
         if (data) {
            if ($("#content")[0]) {
               $("#content")[0].sinkApprove(data);
            } else {
               $("#worklistNewFilm")[0].sinkApprove(data);
            }
         }
      });

      source.addEventListener("sink-ai-request-event", (e) => {
         const data = JSON.parse(e.data);
         if (data) {
            if ($("#content")[0]) {
               $("#content")[0].sinkAiState(data);
            } else {
               $("#worklistNewFilm")[0].sinkAiState(data);
            }
         }
      });

      source.addEventListener("sink-tele-request-event", (e) => {
         const data = JSON.parse(e.data);
         if (data) {
            if ($("#content")[0]) {
               $("#content")[0].sinkTeleRequest(data);
            } else {
               $("#worklistNewFilm")[0].sinkTeleRequest(data);
            }
         }
      });

      source.addEventListener("sink-tele-status-verify-event", (e) => {
         const data = JSON.parse(e.data);
         if (data) {
            if ($("#content")[0]) {
               $("#content")[0].sinkTeleStatusVerify(data);
            } else {
               $("#worklistNewFilm")[0].sinkTeleStatusVerify(data);
            }
         }
      });

      source.addEventListener("sink-add-contents-event", (e) => {
         const data = JSON.parse(e.data);
         if (data) window.dispatchEvent(new CustomEvent("sink-add-contents", { detail: data, bubbles: true, composed: true }));
      });

      // Ping
      // eslint-disable-next-line no-param-reassign
      source.onmessage = (e) => {
         // const data = JSON.parse(e.data);
         // console.log("message: ", e, data);
      };

      // eslint-disable-next-line no-param-reassign
      source.onopen = () => {
         console.log("Open EventSource! ");
         setRetryCount(0);
         // retryCount = 0;
      };

      // eslint-disable-next-line no-param-reassign
      source.onerror = (err) => {
         console.log("Error EventSource! ", err);
         stopServerSentEvent();

         // restart
         setTimeout(() => {
            if (retryCount < 10) {
               startServerSentEvent();
               setRetryCount(prev => prev + 1);
               // retryCount++;
            }
         }, 5000);
      };
   }

   function startServerSentEvent() {
      if (!window.EventSource) {
         // IE or an old browser
         console.log("The browser doesn't support EventSource.");
         return;
      }
      if (source && source.readyState !== EventSource.CLOSED) return;

      setSource(new EventSource(`${__API_URL__}/sse/subscribe/${userId}`));
   }

   function stopServerSentEvent() {
      if (source) {
         source.close();
         console.log("close EventSource! ");
         setSource(null);
      }
   }

   return [startServerSentEvent, stopServerSentEvent];
}
