import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/paper-icon-button/paper-icon-button";
import "@vaadin/vaadin-icons/vaadin-icons";
import store from "../redux/store";
import {CommonActionType, CustomContextMenuType, DialogActionType, DialogType} from "../redux/reducers/common";
import mixinCommons from "../common-mixin";
import i18n from "../utils/i18n-utils";

/**
 * `hh-clinical-info`
 *
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
class HhClinicalInfo extends mixinCommons(PolymerElement) {
   static get is() {
      return "hh-clinical-info";
   }

   static get template() {
      return html`
         <style>
            :host {
            display: block;
            width: 100%;
            height: 100%;
         }

         .textarea-container {
            position: relative;
            display: flex;
            width: 100%;
            height: 100%;
         }

         .icon-container {
            background-color: rgba(0, 0, 0, 0.10); /* 투명 (opacity: 0) */
         }

         .areaCCInfo {
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            background-color: rgba(0, 0, 0, 0.1); /* 투명 (opacity: 0) */
            color: #aaa;
            padding: 5px;
            border: 0px;
            resize: none;
            outline: none !important;
            font-size: 14px;
            line-height: 1.5em;
         }

         .areaBtn {
            width: 30px;
            height: 30px;
            color: #aaaaaa;
         }

         .areaBtn:hover {
            color: #2baab1;
         }
         .ag-theme-balham-dark [class^=ag-],
         .ag-theme-balham-dark [class^=ag-]:after,
         .ag-theme-balham-dark [class^=ag-]:before,
         .ag-theme-balham-dark [class^=ag-]:focus {
            box-sizing: border-box;
            outline: none;
         }

         .ag-theme-balham-dark .ag-popup-child:not(.ag-tooltip-custom) {
            box-shadow: 5px 5px 10px rgba(0,0,0,.3);
         }

         .ag-theme-balham-dark .ag-menu {
            border: 1px solid;
            border-color: #424242;
            background: #2d3436;
            border-radius: 2px;
            box-shadow: none;
            padding: 4px;
         }

         iron-icon, div[suffix] {
            color: #f5f5f5;
            margin: 5px 8px 5px 0px;
            width: 14px;
            height: 14px;
         }

         .ag-theme-balham-dark {
            -webkit-font-smoothing: antialiased;
            color: #f5f5f5;
            font-size: 12px;
            line-height: normal;
         }

         .hideContextMenu {
            display: none;
         }

         .ag-menu-item-hide {
            display: none;
         }

         .ag-theme-balham-dark .ag-menu-option-disabled {
            opacity: .5;
            cursor: default;
         }

         .ag-menu-option:not(.ag-menu-option-disabled):hover {
            background: #3d4749;
         }

         .ag-menu-option {
            cursor: pointer;
         }

         #contextMenu {
            position: absolute;
            z-index: 5;
         }


         </style>

         <div class="textarea-container context-menus">
            <textarea id="ccInfo" class="areaCCInfo" value="{{ccInfoData}}" readonly="readonly" onFocus="(function(win,el){queueMicrotask(()=>win.ElementEventRepeater(el,1));})(window, this)" onBlur="(function(win,el){queueMicrotask(()=>win.ElementEventRepeater(el,0));})(window, this)" class="report-area"></textarea>
            <div id="contextMenu" class="contextMenu hideContextMenu">
               <div class="ag-theme-balham-dark ag-popup">
                  <div class="ag-menu ag-ltr ag-popup-child ag-keyboard-focus" style="min-width: 125px;">
                     <div class="ag-menu-list" tabindex="-1">
                        <div class="ag-menu-option do-copy" tabindex="-1" on-click="handleCopy">
                           <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:content-copy"></iron-icon></span>
                           <span class="ag-menu-option-text ag-menu-option-part">Copy</span>
                           <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                           <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                        </div>
                        <div class="ag-menu-option do-select-all" tabindex="-1" on-click="handleSelectAll">
                           <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:select-all"></iron-icon></span>
                           <span class="ag-menu-option-text ag-menu-option-part">Select All‎</span>
                           <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                           <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                        </div>
                        <div class="ag-menu-option do-cut ag-menu-item-hide" tabindex="-1" on-click="handleCut">
                           <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:content-cut"></iron-icon></span>
                           <span class="ag-menu-option-text ag-menu-option-part">Cut‎</span>
                           <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                           <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                        </div>
                        <div class="ag-menu-option do-paste ag-menu-item-hide" tabindex="-1" on-click="handlePaste">
                           <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:content-paste"></iron-icon></span>
                           <span class="ag-menu-option-text ag-menu-option-part">Paste‎</span>
                           <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                           <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="icon-container">
                <paper-icon-button id="saveBtn" class="areaBtn" icon="vaadin:pencil" hidden></paper-icon-button>
            </div>
         </div>
      `;
   }

   static get properties() {
      return {
         prop1: {
            type: String,
            value: "hh-clinical-info",
         },
         ccInfoData: {
            type: String
         },
         selectedId: {
            type: String
         },
         elemEditor: {
            type: Object
         },
         contextWidth: {
            type: Number,
            value: 125
         },
         contextHeight: {
            type: Number,
            value: 58
         },
         customContextMenuState: {
            type: Number,
            observer: "changeCustomContextMenuState"
         },
         customUniqueKey: {
            type: String,
            readonly: true,
            computed: "computeCustomUniqueKey()"
         },
         selectedRow: {
            type: String,
         },
      };
   }

   computeCustomUniqueKey() {
      return CustomContextMenuType.CLINICAL_INFO + Math.random();
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         this.customContextMenuState = store.getState().common.customContextMenu;
      });

      this.$.saveBtn.addEventListener("click", () => {
         if (this.isEmpty(this.selectedId)) {
            const detail = {msg: "Please select study list", isErr: true};
            this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
            return;
         }

         const {icon} = this.$.saveBtn;

         this.getCaseInfo(this.selectedId).then((result) => {
            if (result && result.readingStatus === "3A") {
               const detail = {msg: "This case already approved.", isErr: true};
               this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
            } else {
               // eslint-disable-next-line no-lonely-if
               if (icon === "vaadin:pencil") {
                  this.saveMode();
               }
               else {
                  this.saveClinicalInfoById({ caseId: this.selectedId, ccInfo: this.$.ccInfo.value });
                  this.initMode();
               }
            }
         });

         // 저장후 비교데이터를 같게 만들어준다.
         this.ccInfoData = this.$.ccInfo.value;
      });

      this.shadowRoot.querySelectorAll(".context-menus").forEach(el => this.setContextMenu(el));
      document.addEventListener("click", () => {
         // if(!this.$.contextMenu.classList.contains("hideContextMenu")) {
         //    this.$.contextMenu.classList.add("hideContextMenu");
         //    window.postMessage({event: "closeOtherContextMenu"}, document.location.href);
         //    window.postMessage({event: "closeClinicContextMenu"}, document.location.href);
         // }
         // console.log("[clinical-info] document click", (this.customContextMenuState))
         if (this.customContextMenuState !== undefined) store.dispatch({ type: CommonActionType.HIDE_CONTEXT_MENU });
      });

      this.disableContextMenuItem(this.$.ccInfo);
   }

   getCaseInfo(id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/case/${id}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json",
            }
         }).then((response) => {
            if (response.ok) {
               if (response.status === 200) {
                  response.json().then((httpResponse) => {
                     resolve(httpResponse);
                  });
               } else if(response.status === 204) {
                  resolve();
               }
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   setContextMenu(el) {
      el.addEventListener("contextmenu", e => this.showContextMenu(e, el));
   }

   showContextMenu(e, target) {
      e.preventDefault();

      // if (this.otherContextMenuIsOpen) {
      //    window.postMessage({event: "onCloseNewfilmSearchInputContextMenu"},  document.location.href);
      //    window.postMessage({event: "onCloseReportContextMenu"},  document.location.href);
      // }

      const menus = this.$.contextMenu;

      if(target.classList.contains("textarea-container")) {
         this.elemEditor = target.querySelector("textarea");
      }

      const rect = this.getBoundingClientRect();
      const {x, y} = rect;
      const {pageX, pageY} = e;
      const cw = this.contextWidth;
      const ch = this.contextHeight;

      const l = pageX - x;
      const t = pageY - y;

      let left = `${l}px`;
      if((pageX + cw) >= window.innerWidth) {
         left = `calc(100% - ${cw + 5}px)`;
      }

      let top  = `${t}px`;
      if((pageY + ch) >= window.innerHeight) {
         top = `calc(100% - ${ch + 5}px)`;
      }

      menus.style.left = left;
      menus.style.top = top;

      if(menus.classList.contains("hideContextMenu")) {
         menus.classList.remove("hideContextMenu"); // SHOW
         store.dispatch({ type: CommonActionType.SHOW_CONTEXT_MENU, payload: this.customUniqueKey });
      }

      // window.postMessage({event: "openOtherContextMenu"},  document.location.href);
      // window.postMessage({event: "openClinicContextMenu"},  document.location.href);

      this.disableContextMenuItem(this.elemEditor);
   }

   disableContextMenuItem(target) {
      if(!target) return;

      const menus = this.$.contextMenu;
      const list = [".do-cut", ".do-paste"];

      const start = target.selectionStart;
      const end   = target.selectionEnd;
      if(target.hasAttribute("readonly")) {
         list.forEach(name => this.addDisableContextMenuItem(menus.querySelector(name)));
      } else {
         list.forEach(name => this.removeDisableContextMenuItem(menus.querySelector(name)));
         if(start === end) {
            this.addDisableContextMenuItem(menus.querySelector(".do-cut"));
         } else {
            this.removeDisableContextMenuItem(menus.querySelector(".do-cut"));
         }
      }

      if(start === end) {
         this.addDisableContextMenuItem(menus.querySelector(".do-copy"));
      } else {
         this.removeDisableContextMenuItem(menus.querySelector(".do-copy"));
      }

   }

   showMenuItem(el) {
      if(!el) return;

      if(el.classList.contains("ag-menu-item-hide"))
         el.classList.remove("ag-menu-item-hide");
   }

   addDisableContextMenuItem(el) {
      if(!el) return;

      if(!el.classList.contains("ag-menu-option-disabled"))
         el.classList.add("ag-menu-option-disabled");
   }

   removeDisableContextMenuItem(el) {
      if(!el) return;

      if(el.classList.contains("ag-menu-option-disabled"))
         el.classList.remove("ag-menu-option-disabled");
   }

   handleCopy() {
      if(!this.elemEditor || (this.$.contextMenu.querySelector(".do-copy").classList.contains("ag-menu-option-disabled"))) return;
      const v = this.elemEditor.value;
      const start = this.elemEditor.selectionStart;
      const end   = this.elemEditor.selectionEnd;


      navigator.clipboard.writeText(v.substring(start, end)).then(() => {
         const params = {
            isErr: false,
            msg: "Copied text",
         };

         this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail: params}));
      });
   }

   handleSelectAll() {
      const ta = this.elemEditor;
      this.setSelectionRange(ta, 0, this.elemEditor.value.length);
      ta.select();
      ta.focus();
   }

   handleCut() {
      if((this.$.contextMenu.querySelector(".do-cut").classList.contains("ag-menu-option-disabled"))) return;

      const v = this.elemEditor.value;
      const start = this.elemEditor.selectionStart;
      const end   = this.elemEditor.selectionEnd;

      navigator.clipboard.writeText(v.substring(start, end)).then(() => {
         const newVal = v.substring(0, start) + v.substring(end);
         this.elemEditor.value = newVal;
      });
   }

   handlePaste() {
      if((this.$.contextMenu.querySelector(".do-paste").classList.contains("ag-menu-option-disabled"))) return;
      const v = this.elemEditor.value;
      navigator.clipboard.readText().then((text) => {
         this.elemEditor.value = v + text;

         this.elemEditor.focus();
      });
   }

   setSelectionRange(input, selectionStart, selectionEnd) {
      if (input.setSelectionRange) {
         input.focus();
         input.setSelectionRange(selectionStart, selectionEnd);
      }
   }

   isChangeCcInfo() {
      if (!this.ccInfoData) this.ccInfoData = "";

      if (this.convertHashCode(this.$.ccInfo.value) !== this.convertHashCode(this.ccInfoData.replace(/\r/g, ""))) {
         const data = {isChanged: true, ccInfo: this.$.ccInfo.value};
         this.$.ccInfo.value = null;
         return data;
      }
      return {isChanged: false};
   }

   convertHashCode(str) {
      // eslint-disable-next-line no-bitwise
      return Array.from(str).reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0);
   }

   initMode() {
      this.$.saveBtn.icon = "vaadin:pencil";
      this.$.ccInfo.readOnly = true;
      this.$.saveBtn.display = "none";
      this.$.saveBtn.style.color = "#aaaaaa";
   }

   saveMode() {
      this.$.saveBtn.icon = "vaadin:download";
      this.$.saveBtn.display = "block";
      this.$.ccInfo.readOnly = false;
      this.$.saveBtn.style.color = "red";
   }

   setClinicalInfo(info, currentRow) {
      if(this.selectedRow !== currentRow.id) return;

      this.checkChangeClinicalInfo(this.selectedId);
      this.initMode();
      this.ccInfoData = info;
      if (currentRow) {
         this.selectedId = currentRow.id;
      }
   }

   /**
    * 이전 CC 정보가 변경되었는지 체크 후 저장할 수 있도록 처리
    * @param oldId
    */
   checkChangeClinicalInfo(oldId) {
      if (!oldId) return;

      const { isChanged, ccInfo } = this.isChangeCcInfo();
      if (isChanged) {
         const message = {
            title: i18n("label.clinicalInfo"),
            contents: i18n("msg.clinicalInfo.confirm", { returnObjects: true }),
            ok: i18n("button.ok"),
            cancel: i18n("button.cancel"),
            onOk: () => {
               this.saveClinicalInfoById({ caseId: oldId, ccInfo });
            }
         };
         store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.CHANGE_CLINICAL_INFO, message, open: true } });
      }
   }

   clearClinicalInfo() {
      this.ccInfoData = null;
      this.selectedId = null;
      this.initMode();
   }

   // techtab에서 사용
   hiddenClinicalInfo(flag){
      const menus = this.$.contextMenu;
      const list = [".do-cut", ".do-paste"];
      if(flag){
         this.$.saveBtn.hidden = true;
      }else{
         this.$.saveBtn.hidden = false;
         list.forEach(name => this.showMenuItem(menus.querySelector(name)));
      }
   }

   isEmpty(str) {
      if(typeof  str === "undefined" || str == null || str === "")
         return true;
      return false;
   }

   fetchClinicalInfo(id) {
      this.getClinicalInfoById(id).then((ccInfo) => {
         this.setClinicalInfo(ccInfo, { id });
      });
   }

   getClinicalInfoById(id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/case/${id}/clinicalinfo`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse.requestDesc);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   changeCustomContextMenuState(customContextMenuState) {
      if (this.customUniqueKey !== customContextMenuState) {
         if (!this.$.contextMenu.classList.contains("hideContextMenu"))
            this.$.contextMenu.classList.add("hideContextMenu"); // HIDE
      }
   }

   saveClinicalInfoById({caseId, ccInfo}) {
      const param = {};
      param.request = ccInfo;
      fetch(`/api/exchange/worklist/clinicalInfo/${caseId}`, {
         method: "PATCH",
         headers: {
            "Content-Type": "application/json"
         },
         body: JSON.stringify({requestDesc: ccInfo})
      }).then((response) => {
         if (response.ok && response.status === 200) {
            const detail = { msg: this.t("msg.clinicalInfo.success"), isErr: false };
            this.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail }));
         } else if (response.status === 204) {
            const detail = { msg: this.t("msg.clinicalInfo.noContent"), isErr: false };
            this.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail }));
         } else {
            const detail = { msg: this.t("msg.clinicalInfo.fail"), isErr: true };
            this.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail }));
         }
      });
   }
}

window.customElements.define(HhClinicalInfo.is, HhClinicalInfo);
