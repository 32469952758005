/**
 * 단축키 기능 스크립트
 *
 * Create by BohyunJang on 2018-08-27 오후 2:24
 * */
// defaultShortcutSetting
const defaultShortcutSetting = () => {
   const shortcut = {
      "worklistFunction": [
         {
            "displayedName": "Logout",
            "functionName": "logout",
            "shortcut": "alt+l",
            "fkey": "l"
         },
         {
            "displayedName": "Setting",
            "functionName": "setting",
            "shortcut": "alt+t",
            "fkey": "t"
         },
         {
            "displayedName": "Open User Infomation Panel",
            "functionName": "userInfo",
            "shortcut": "",
            "fkey": "i"
         },
         {
            "displayedName": "Approve Opinion",
            "functionName": "opinionApprove",
            "shortcut": "",
            "fkey": "a"
         },
         {
            "displayedName": "Addendum opinion",
            "functionName": "opinionAddendum",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Save Opinion",
            "functionName": "opinionSave",
            "shortcut": "alt+s",
            "fkey": "s"
         },
         {
            "displayedName": "Clear Opinion",
            "functionName": "opinionClear",
            "shortcut": "",
            "fkey": "c"
         },
         {
            "displayedName": "Open Previous Exam Filmbox Panel",
            "functionName": "prev",
            "shortcut": "alt+p",
            "fkey": "p"
         },
         {
            "displayedName": "Open Next Exam Filmbox Panel",
            "functionName": "next",
            "shortcut": "alt+n",
            "fkey": "n"
         },
         {
            "displayedName": "Worklist to Filmbox",
            "functionName": "filmbox",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Open/Close Report Window",
            "functionName": "openCloseReportWindow",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Copy/Paste Related Report",
            "functionName": "copyAndPasteRelatedReport",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Copy Report",
            "functionName": "copyReport",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Paste Report",
            "functionName": "pasteReport",
            "shortcut": "",
            "fkey": ""
         }
      ],
      "filmboxFunction": [
         {
            "displayedName": "Go to Next Page *",
            "functionName": "pageDown",
            "shortcut": "Page Down",
            "fkey": "n"
         },
         {
            "displayedName": "Go to Previous Page *",
            "functionName": "pageUp",
            "shortcut": "Page Up",
            "fkey": "p"
         },
         {
            "displayedName": "Go to Next Image *",
            "functionName": "imageDown",
            "shortcut": "Arrow Down",
            "fkey": "e"
         },
         {
            "displayedName": "Go to Previous Image *",
            "functionName": "imageUp",
            "shortcut": "Arrow Up",
            "fkey": "r"
         },
         {
            "displayedName": "Go to First Image *",
            "functionName": "firstImage",
            "shortcut": "Home",
            "fkey": ""
         },
         {
            "displayedName": "Go to Last Image *",
            "functionName": "lastImage",
            "shortcut": "End",
            "fkey": ""
         },
         {
            "displayedName": "Go to Next Series *",
            "functionName": "seriesNext",
            "shortcut": "Arrow Right",
            "fkey": "h"
         },
         {
            "displayedName": "Go to Previous Series *",
            "functionName": "seriesPrev",
            "shortcut": "Arrow Left",
            "fkey": "a"
         },
         {
            "displayedName": "Turn on/off Stack View",
            "functionName": "stackView",
            "shortcut": "",
            "fkey": "s"
         },
         {
            "displayedName": "Turn on/off Overlay",
            "functionName": "overlay",
            "shortcut": "",
            "fkey": "v"
         },
         {
            "displayedName": "Turn on/off Meta",
            "functionName": "overlayMeta",
            "shortcut": "",
            "fkey": "l"
         },
         {
            "displayedName": "Rotate Image to Left",
            "functionName": "rotationLeft",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Rotate Image to Right",
            "functionName": "rotationRight",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Flip Image to Vertical way",
            "functionName": "filpVertical",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Flip Image to Horizontal way",
            "functionName": "filpHorizontal",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Reorder Images",
            "functionName": "saveReorderImages",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Invert Image",
            "functionName": "invert",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Set Panning for Image",
            "functionName": "panning",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Set Zoom for Image",
            "functionName": "zoom",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Reset Zoom for Image",
            "functionName": "zoomFitting",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Magnify",
            "functionName": "magnify",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Magnify Config",
            "functionName": "magnifyConfig",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Image Pixel",
            "functionName": "imagePixel",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "View Dicom Tag",
            "functionName": "viewDicomTag",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Pick for Annotation",
            "functionName": "pick",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Reset about Annotation",
            "functionName": "reset",
            "shortcut": "",
            "fkey": "q"
         },
         {
            "displayedName": "Set WWL",
            "functionName": "setWWL",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "WWL Preset",
            "functionName": "settingWWL",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Reset WWL",
            "functionName": "resetWWL",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Reset about Text",
            "functionName": "resetAboutText",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Line",
            "functionName": "nonTextLine",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Pen",
            "functionName": "nonTextPen",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Circle",
            "functionName": "nonTextCircle",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Ellipse",
            "functionName": "nonTextEllipse",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Rectangle",
            "functionName": "nonTextRectangle",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Fill-Rectangle",
            "functionName": "fillRectangle",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Round-Rectangle",
            "functionName": "roundRectangle",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Polygon",
            "functionName": "polygon",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Arrow",
            "functionName": "arrow",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Ruler",
            "functionName": "ruler",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Cobb Angle",
            "functionName": "cobbAngle",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Ellipse with area",
            "functionName": "textEllipse",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Rectangle with ROI",
            "functionName": "roiRectangle",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Circle with ROI",
            "functionName": "roiCircle",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Polygon with ROI",
            "functionName": "roiPolygon",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Diameter",
            "functionName": "addDiameter",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Line Distance",
            "functionName": "addLineDistance",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Pen with length",
            "functionName": "addPenWithLength",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add angle",
            "functionName": "addAngle",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Pen with ROI",
            "functionName": "addPenWithROI",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Set Cross Reference",
            "functionName": "setCrossReference",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Set Cross Reference (Auto Sync)",
            "functionName": "setCrossReferenceAutoSync",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Text",
            "functionName": "addText",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Text Mark Config",
            "functionName": "addTextMarkConfig",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Add Text Mark Custom",
            "functionName": "addTextMarkCustom",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Set Undo",
            "functionName": "setUndo",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Set Redo",
            "functionName": "setRedo",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Open View Setting Menu Panel",
            "functionName": "menuAdd1",
            "shortcut": "alt+z",
            "fkey": "z"
         },
         {
            "displayedName": "Open Image Setting Menu Panel",
            "functionName": "menuAdd2",
            "shortcut": "alt+x",
            "fkey": "x"
         },
         {
            "displayedName": "Open Annotation Setting Menu Panel",
            "functionName": "menuAdd3",
            "shortcut": "alt+c",
            "fkey": "c"
         },
         {
            "displayedName": "Mark Key Image",
            "functionName": "markKeyImage",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Unmark Key Image",
            "functionName": "unmarkKeyImage",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Filmbox to Worklist",
            "functionName": "worklist",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Toggle Maximize / Restore",
            "functionName": "compressLayout",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Toggle Manual / Auto",
            "functionName": "saveOption",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Save Filmbox",
            "functionName": "saveFilmbox",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Layout (1x1)",
            "functionName": "layoutOneByOne",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Layout (1x2)",
            "functionName": "layoutOneByTwo",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Layout (2x1)",
            "functionName": "layoutTwoByOne",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Layout (2x2)",
            "functionName": "layoutTwoByTwo",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Custom Layout",
            "functionName": "customLayout",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Config Layout",
            "functionName": "configLayout",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Save Layout",
            "functionName": "saveLayout",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Turn on/off Thumbnail Scroll",
            "functionName": "thumbnailScroll",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Go to Next Tab",
            "functionName": "goToNextTab",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Go to Previous Tab",
            "functionName": "goToPreviousTab",
            "shortcut": "",
            "fkey": ""
         },
         {
            "displayedName": "Save Config",
            "functionName": "saveConfig",
            "shortcut": "",
            "fkey": ""
         }
      ],
      "userPreferences": [
         {
            "displayedName" : "Toggle Focusing Report Area",
            "functionName" : "toggleTrackingFocus",
            "shortcut": "",
            "fkey" : "r",
            "value" : 0,
            "printableValue" : "Finding",
            "description" : "Focusing last visited report area"
         }
      ],
   };
   return shortcut;
};

export { defaultShortcutSetting };
