/* eslint-disable no-unused-vars */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/paper-tabs";
import "@polymer/iron-pages";
import "@polymer/iron-icon";
import "@polymer/paper-button";
import "./grid-newfilm";
import "./grid-study";
import "./components/healthhub-confirm-dialog";
import "./dialog/techtab-verify-dialog";
import moment from "moment-timezone";
import {mixinBehaviors} from "@polymer/polymer/lib/legacy/class";
// eslint-disable-next-line import/no-extraneous-dependencies
import {IronResizableBehavior} from "@polymer/iron-resizable-behavior/iron-resizable-behavior";
import mixinCommons from "./common-mixin";
import store from "./redux/store";
import {CommonActionType, CustomContextMenuType} from "./redux/reducers/common";
import { WorklistActionType } from "./redux/reducers/worklist.ts";
import { TechlistActionType } from "./redux/reducers/techlist.ts";

class WorklistNewfilm extends mixinBehaviors([IronResizableBehavior], mixinCommons(PolymerElement)) {
   static get template() {
      return html`
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-grid.min.css">
         <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-theme-balham-dark.min.css">

         <style>
            :host {
               display: block;
               width: 100%;
               height: 100%;
            }

            .container {
               display: flex;
               flex-direction: row;
               width: 100%;
               background-color: #252934;
               flex-wrap: wrap-reverse;
               align-items: center;
            }

            paper-tabs {
               --paper-tabs-selection-bar: {
                  border-bottom: 3px solid #0087cb;
               };
               --paper-tabs-content: {
                  font-family: NotoSansCJKMedium, NotoSansCJK, NotoSansCJKLight !important;
                  color: #aaaaaa;
                  background-color: #252934;
                  font-size: 14px;
               };
               height: 40px;
            }

            paper-tabs paper-tab.iron-selected {
               color: #0087cb;
            }

            paper-button {
               font-family: NotoSansCJK, NotoSansCJKLight;
               font-weight: normal;
               font-size: 11px;
               -webkit-font-smoothing: antialiased;
               background-color: var(--paper-blue-grey-900);
               color: white;
               --paper-button-ink-color: var(--paper-cyan-a700);
               border-radius: 10px;
               padding: 0px;
               min-width: 30px;
            }

            iron-pages {
               /*height: calc(100% - 80px);*/
               /*min-height: calc(100% - 80px);*/
               width: 100%;
            }

            iron-pages div {
               height: 100%;
               width: 100%;
            }

            iron-icon {
               --iron-icon-fill-color: #757575;
            }

            iron-icon, div[suffix] {
               color: hsl(0, 0%, 50%);
               margin-right: 2px;
               width: 14px;
               height: 14px;
            }

            .openInputFilterName {
               --paper-button-ink-color: #0087cd;
               color: #ffffff;
               font-size: 11px;
               font-family: NotoSansCJKDemi, NotoSansCJK, NotoSansCJKLight;
               background-color: #1d1e25;
               text-transform: none;
               height: 25px;
            }

            paper-button.openInputFilterName[active] {
               background-color: #1d4764;
            }

            .pIdpName {
               color: #ffffff;
               font-size: 11px;
               font-family: NotoSansCJKDemi, NotoSansCJK, NotoSansCJKLight;
               text-align: center;
               width: 100px;
               height: 27px;
               margin-right: 0px;
            }

            .InputPIdPName {
               color: white;
               height: 19px;
               width: 90px;
               text-align: center;
               background-color: #262c2e;
               font-size: 11px;
               border-radius: 10px;
            }

            input {
               margin-right: 5px;
               border: 1px solid #4c5667 !important
            }

            .tabsDiv {
               width: calc(100% - 500px);
               min-width: 150px;
            }

            .searchDiv {
               display: flex;
               align-items: center;
               justify-content: space-evenly;
               width: 500px;
               height: 40px;
            }


            .hideContextMenu {
               display: none;
            }

            .ag-menu-option:not(.ag-menu-option-disabled):hover {
               background: #3d4749;
            }

            .ag-theme-balham-dark .ag-menu-option-disabled {
               opacity: .5;
               cursor: default;
            }


         </style>
         <div class="container">
            <div id="tabsDiv" class="tabsDiv">
               <paper-tabs id="categoryTab" selected="{{selected}}" noink>
                  <paper-tab id="radTab">{{ t("label.radiology") }}</paper-tab>
                  <paper-tab id="techTab">{{ t("label.technician") }}</paper-tab>
               </paper-tabs>
            </div>
            <div id="searchDiv" class="searchDiv">
               <iron-icon icon="healthhub:search"></iron-icon>
               <input class="InputPIdPName context-menus" id="inputQueicSearch" placeholder='{{ t("label.idOrName") }}'>
               <div id="contextMenu" class="contextMenu hideContextMenu">
                  <div class="ag-theme-balham-dark ag-popup">
                     <div class="ag-menu ag-ltr ag-popup-child ag-keyboard-focus" style="min-width: 125px;">
                        <div class="ag-menu-list" tabindex="-1">
                           <div class="ag-menu-option do-cut" tabindex="-1" on-click="handleCut">
                              <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:content-cut"></iron-icon></span>
                              <span class="ag-menu-option-text ag-menu-option-part">{{ t("button.contextMenu.cut") }}</span>
                              <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                              <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                           </div>
                           <div class="ag-menu-option do-copy" tabindex="-1" on-click="handleCopy">
                              <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:content-copy"></iron-icon></span>
                              <span class="ag-menu-option-text ag-menu-option-part">{{ t("button.contextMenu.copy") }}</span>
                              <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                              <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                           </div>
                           <div class="ag-menu-option do-paste" tabindex="-1" on-click="handlePaste">
                              <span class="ag-menu-option-icon ag-menu-option-part"><iron-icon icon="icons:content-paste"></iron-icon></span>
                              <span class="ag-menu-option-text ag-menu-option-part">{{ t("button.contextMenu.paste") }}</span>
                              <span class="ag-menu-option-shortcut ag-menu-option-part"></span>
                              <span class="ag-menu-option-popup-pointer ag-menu-option-part">&nbsp;</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <paper-button id="todayFilterButton" data-value="today" style$="width: {{btnWidth}}" class="openInputFilterName" toggles on-click="dateFilterSearch">
                  <template is="dom-if" if="{{!isExpand}}">{{ t("button.t") }}</template>
                  <template is="dom-if" if="{{isExpand}}">{{ t("button.today") }}</template>
               </paper-button>
               <paper-button id="threeDaysFilterButton" data-value="threeDays" style$="width: {{btnWidth}}" class="openInputFilterName" toggles on-click="dateFilterSearch">
                  <template is="dom-if" if="{{!isExpand}}">{{ t("button.3d") }}</template>
                  <template is="dom-if" if="{{isExpand}}">{{ t("button.3days") }}</template>
               </paper-button>
               <paper-button id="weekFilterButton" data-value="week" style$="width: {{btnWidth}}" class="openInputFilterName" toggles on-click="dateFilterSearch">
                  <template is="dom-if" if="{{!isExpand}}">{{ t("button.w") }}</template>
                  <template is="dom-if" if="{{isExpand}}">{{ t("button.week") }}</template>
               </paper-button>
               <paper-button id="oneMonthsFilterButton" data-value="oneMonths" style$="width: {{btnWidth}}" class="openInputFilterName" toggles on-click="dateFilterSearch">
                  <template is="dom-if" if="{{!isExpand}}">{{ t("button.1m") }}</template>
                  <template is="dom-if" if="{{isExpand}}">{{ t("button.1month") }}</template>
               </paper-button>
               <paper-button id="twoMonthsFilterButton" data-value="twoMonths" style$="width: {{btnWidth}}" class="openInputFilterName" toggles on-click="dateFilterSearch">
                  <template is="dom-if" if="{{!isExpand}}">{{ t("button.2m") }}</template>
                  <template is="dom-if" if="{{isExpand}}">{{ t("button.2months") }}</template>
               </paper-button>

            </div>
         </div>
         <iron-pages style$="height: {{ironPagesHeight}}; min-height: {{ironPagesMinHeight}}" selected="{{selected}}">
            <div>
               <grid-newfilm id="newFilm" active-date-toggle="[[activeDateToggle]]" selected-tab="[[selected]]"></grid-newfilm>
            </div>
            <div>
               <grid-study id="study" active-date-toggle="[[activeDateToggle]]" selected-tab="[[selected]]"></grid-study>
            </div>
         </iron-pages>
         <hh-confirm-dialog id="confirmDialog"></hh-confirm-dialog>
      `;
   }

   static get properties() {
      return {
         selected: {
            type: Number,
            value: 0,
            observer: "_selectTabChanged"
         },
         isExpand: {
            type: Boolean,
            value: true
         },
         elemEditor: {
            type: Object
         },
         ironPagesHeight: {
            type: String,
            value: "calc(100% - 40px)"

         },
         ironPagesMinHeight: {
            type: String,
            value: "calc(100% - 40px)"
         },
         btnWidth: {
            type: String,
            value: "80px"
         },
         _category: {
            type: Number,
            value: 0,
         },
         worklistFilterState: {
            type: Object,
            value: {},
            observer: "setWorklistFilter"
         },
         techlistFilterState: {
            type: Object,
            value: {},
            observer: "setTechlistFilter"
         },
         customContextMenuState: {
            type: Number,
            observer: "changeCustomContextMenuState"
         },
         customUniqueKey: {
            type: String,
            readonly: true,
            computed: "computeCustomUniqueKey()"
         },
         appliedWorklistFilter: {
            type: Object,
            observer: "changeAppliedWorklistFilter"
         },
         appliedTechlistFilter: {
            type: Object,
            observer: "changeAppliedTechlistFilter"
         }
      };
   }

   computeCustomUniqueKey() {
      return `${CustomContextMenuType.QUICK_SEARCH}_${Math.random()}`;
   }

   connectedCallback() {
      super.connectedCallback();
      this.addEventListener("iron-resize", this.onIronResize.bind(this));
   }

   onIronResize() {
      if (this.offsetWidth > 0) {
         let height = "calc(100% - 40px)";
         if (this.offsetWidth < 650) {
            height = "calc(100% - 80px)";
            if (this.offsetWidth < 500) {
               this.isExpand = false;
               this.btnWidth = "33px";
            } else {
               this.isExpand = true;
               this.btnWidth = "80px";
            }
         } else {
            this.isExpand = true;
            this.btnWidth = "80px";
            height = "calc(100% - 40px)";
         }
         this.ironPagesHeight = height;
         this.ironPagesMinHeight = height;
      }
   }

   ready() {
      super.ready();
      const user = JSON.parse(localStorage.getItem("user"));

      store.subscribe(() => {
         this.worklistFilterState = store.getState().worklist.filter;
         this.techlistFilterState = store.getState().techlist.filter;
         this.customContextMenuState = store.getState().common.customContextMenu;
         this.appliedWorklistFilter = store.getState().worklist.appliedFilter;
         this.appliedTechlistFilter = store.getState().techlist.appliedFilter;
      });

      // 유저 권한에 따라 techtab 접근 제어
      // user role "technician" : scp 이면서... ????
      // if(!(user.roles.includes("editor") && user.roles.includes("technician"))){

      // 사용자 정보가 없거나 roles 아이템이 없거나 technician role이 없으면 Technician탭을 숨긴다. #16568
      if (user === null || user.roles === undefined || !user.roles.includes("technician")) {
         this.$.techTab.style.display = "none";
      }

      // study event
      this.$.study.addEventListener("openToastEvent", (e) => {
         this.dispatchEvent(new CustomEvent("openToastEvent", e));
      });

      this.$.study.addEventListener("downloadEvent", (e) => {
         this.dispatchEvent(new CustomEvent("downloadEvent", e));
      });

      this.$.study.addEventListener("downloadedEvent", (e) => {
         this.dispatchEvent(new CustomEvent("downloadedEvent", e));
      });

      // this.$.study.addEventListener("newSelectedRowsEvent", (e) => {
      //    this.dispatchEvent(new CustomEvent("newSelectedRowsEvent", e));
      // });

      // this.$.study.addEventListener("orderCountClearEvent", () => {
      //    this.dispatchEvent(new CustomEvent("orderCountClearEvent"));
      // });

      // this.$.study.addEventListener("openMofieidExamDialogEvent", (e) => {
      //    this.dispatchEvent(new CustomEvent("openMofieidExamDialogEvent", e));
      // });

      this.$.study.addEventListener("selectUserFilterButtonEvent", (e) => {
         this.dispatchEvent(new CustomEvent("selectUserFilterButtonEvent", e));
      });

      this.$.study.addEventListener("setDateToggleEvent", (e) => {
         this.setActiveDateToggle(e.detail);
      });

      this.$.study.addEventListener("getActiveDateToggleEvent", (e) => {
         this.activeDateToggle = this.getActiveDateToggle();
      });

      // /** study -> order, order grid refresh */
      // this.$.study.addEventListener("reloadOrderGridEvent", () => {
      //    this.dispatchEvent(new CustomEvent("reloadOrderGridEvent"));
      // });


      this.$.study.addEventListener("syncCountEvent", (e) => {
         this.dispatchEvent(new CustomEvent("syncCountEvent", e));
      });

      // / newfilm event
      this.$.newFilm.addEventListener("downloadEvent", (e) => {
         this.dispatchEvent(new CustomEvent("downloadEvent", e));
      });

      this.$.newFilm.addEventListener("downloadedEvent", (e) => {
         this.dispatchEvent(new CustomEvent("downloadedEvent", e));
      });

      this.$.newFilm.addEventListener("clearWindowReportEvent", () => {
         this.dispatchEvent(new CustomEvent("clearWindowReportEvent"));
      });

      this.$.newFilm.addEventListener("setDateToggleEvent", (e) => {
         this.setActiveDateToggle(e.detail);
      });

      this.$.newFilm.addEventListener("getActiveDateToggleEvent", (e) => {
         this.activeDateToggle = this.getActiveDateToggle();
      });

      this.$.newFilm.addEventListener("selectUserFilterButtonEvent", (e) => {
         this.dispatchEvent(new CustomEvent("selectUserFilterButtonEvent", e));
      });

      // grid, count sync
      this.$.newFilm.addEventListener("syncCountEvent", (e) => {
         this.dispatchEvent(new CustomEvent("syncCountEvent", e));
      });

      this.$.inputQueicSearch.addEventListener("keyup", (e) => {
         if (e.key === "Enter") this.pIdpNameSearch();
      });

      this.shadowRoot.querySelectorAll(".context-menus").forEach(el => this.setContextMenu(el));
      document.addEventListener("click", () => {
         // if (!this.$.contextMenu.classList.contains("hideContextMenu"))
         //    this.$.contextMenu.classList.add("hideContextMenu");
         // window.postMessage({event: "closeNewfilmSearchInputContextMenu"},  document.location.href);

         // console.log("[worklist-newfilm] document click", (this.customContextMenuState))
         if (this.customContextMenuState !== undefined) store.dispatch({ type: CommonActionType.HIDE_CONTEXT_MENU });
      });

      window.addEventListener("navbarDisplayPrevEvent", () => {
         this.displayPrev();
      });

      window.addEventListener("navbarDisplayNextEvent", () => {
         this.displayNext();
      });

   }

   setWorklistFilter(e = {}) {
      if(e.detail) {
         if(e.detail.isQuickFilter) {
            this.pIdpNameClear();
         } else {
            this.setFilterNSort(e.detail);
         }
      }
   }

   setTechlistFilter() {
      // this.dispatchEvent(new CustomEvent("setFilterSortClearEvent", {bubbles: true, composed: true}));
   }

   setContextMenu(el) {
      el.addEventListener("contextmenu", e => this.showContextMenu(e, el));
   }

   showContextMenu(e) {
      e.preventDefault();
      // if (this.otherContextMenuIsOpen) { // 다른 컨텍스트 메뉴 열려있으면 닫고
      //    window.postMessage({event: "onCloseClinicContextMenu"},  document.location.href);
      //    window.postMessage({event: "onCloseReportContextMenu"},  document.location.href);
      // }

      const menus = this.$.contextMenu;
      if (menus.classList.contains("hideContextMenu")) {
         menus.classList.remove("hideContextMenu"); // SHOW
         store.dispatch({ type: CommonActionType.SHOW_CONTEXT_MENU, payload: this.customUniqueKey });
      }

      // window.postMessage({event: "openNewfilmSearchInputContextMenu"},  document.location.href); // 내 컨텍스트가 오픈되었다고 알려줌
      this.elemEditor = this.$.inputQueicSearch;
      this.disableContextMenuItem(this.elemEditor);
   };

   _selectTabChanged(newValue, oldValue) {
      if (typeof oldValue !== "undefined") {
         const e = {};
         e.detail = {newValue, oldValue};
         // _this.dispatchEvent(new CustomEvent('changeCategory', e));
         this.rememberDateToggle(oldValue); // 이전 탭의 date toggle 기억
         this.clearDateToggles(); // date toggle clear
         this.$.study.clearSelectedRow();
         switch (newValue) {
         case 0:
            if (!this.$.newFilm.initDefaultFilter) {
               this.$.newFilm.initWorklist();
            } else {
               this.setActiveDateToggle(this.$.newFilm.getDateFilter()); // 선택한 탭의 date toggle setting.
               this.$.newFilm.purgeEnterpriseCache();
            }
            break;
         case 1:
            if (!this.$.study.initDefaultFilter) {
               this.$.study.initStudyList();
            } else {
               this.setActiveDateToggle(this.$.study.getDateFilter()); // 선택한 탭의 date toggle setting.
               this.$.study.purgeEnterpriseCache();
            }
            break;
         default:
            // console.log("Transcription");
         }

         store.dispatch({ type: CommonActionType.SET_CATEGORY, payload: newValue });

         // NOTE: 2020/07/24 탭 변경시에 더블클릭 했던 selected id를 초기화 한다. 해당 탭의 related tab변경시에만 필름박스가 열리기 위함.
         this.$.newFilm.dblClickedId = null;
         this.$.study.dblClickedId = null;
      }
   }

   disableContextMenuItem(target) {
      if (!target) return;

      const menus = this.$.contextMenu;
      const list = [".do-cut", ".do-delete", ".do-paste"];

      const start = target.selectionStart;
      const end = target.selectionEnd;

      if (target.hasAttribute("readonly")) {
         list.forEach(name => this.addDisableContextMenuItem(menus.querySelector(name)));
      } else {
         list.forEach(name => this.removeDisableContextMenuItem(menus.querySelector(name)));
         if (start === end) {
            this.addDisableContextMenuItem(menus.querySelector(".do-cut"));
            this.addDisableContextMenuItem(menus.querySelector(".do-delete"));
         } else {
            this.removeDisableContextMenuItem(menus.querySelector(".do-cut"));
            this.removeDisableContextMenuItem(menus.querySelector(".do-delete"));
         }
      }

      if (start === end) {
         this.addDisableContextMenuItem(menus.querySelector(".do-copy"));
      } else {
         this.removeDisableContextMenuItem(menus.querySelector(".do-copy"));
      }

   }

   addDisableContextMenuItem(el) {
      if (!el) return;

      if (!el.classList.contains("ag-menu-option-disabled"))
         el.classList.add("ag-menu-option-disabled");
   }

   removeDisableContextMenuItem(el) {
      if (!el) return;

      if (el.classList.contains("ag-menu-option-disabled"))
         el.classList.remove("ag-menu-option-disabled");
   }

   handlePaste() {
      navigator.clipboard.readText().then((text) => {
         this.elemEditor.value = text;

         this.elemEditor.focus();
      });
   }

   handleCut() {
      if ((this.$.contextMenu.querySelector(".do-cut").classList.contains("ag-menu-option-disabled"))) return;

      const v = this.elemEditor.value;
      const start = this.elemEditor.selectionStart;
      const end = this.elemEditor.selectionEnd;

      navigator.clipboard.writeText(v.substring(start, end)).then(() => {
         const newVal = v.substring(0, start) + v.substring(end);
         this.elemEditor.value = newVal;

         const params = {
            isErr: false,
            msg: "Cut text",
         };

         this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail: params}));
      });
   }

   handleCopy() {
      if ((this.$.contextMenu.querySelector(".do-copy").classList.contains("ag-menu-option-disabled"))) return;

      const v = this.elemEditor.value;
      const start = this.elemEditor.selectionStart;
      const end = this.elemEditor.selectionEnd;

      navigator.clipboard.writeText(v.substring(start, end)).then(() => {
         const params = {
            isErr: false,
            msg: "Copied text",
         };

         this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail: params}));
      });
   }

   // 부모 worklist-content 에서 호출
   approveOpinionisResult(result) {
      this.$.newFilm.approveOpinionisResult(result);
   }

   retrieveWorklist() {
      this.$.newFilm.retrieveWorklist();
   }

   createFilterParams() {
      return this.$.newFilm.createFilterParams();
   }

   setSort(userFilterModel) {
      const {sortModel} = userFilterModel;
      this.$.newFilm.setSortModel(sortModel);
   }

   clearFilter() {
      this.$.newFilm.clearFilter();
   }

   reloadFilter() {
      this.$.newFilm.reloadFilter();
   }

   getFilter() {
      return this.$.newFilm.getFilter();
   }

   getFilterOfStudy() {
      return this.$.study.getFilter();
   }

   getSort() {
      return this.$.newFilm.getSortModel();
   }

   getSortOfStudy() {
      return this.$.study.getSortModel();
   }

   // 자식 grid-newfilm 호출
   getFilterModel() {
      return this.$.newFilm.getFilterModel();
   }

   startPrefetchMark() {
      if (this.$.categoryTab.selected === 0) {
         this.$.newfilm.initStruct();
      } else {
         this.$.study.initStruct();
      }
   }

   completedPrefetchMark(result) {
      if (this.$.categoryTab.selected === 0) {
         this.$.newFilm.completedPrefetchMark(result);
      } else {
         this.$.study.completedPrefetchMark(result);
      }
      // this.$.newFilm.successPrefetchMark(result);
   }

   prefetcStatushMark(message) {
      if (this.$.categoryTab.selected === 0) {
         this.$.newFilm.prefetcStatushMark(message);
      } else {
         this.$.study.prefetcStatushMark(message);
      }

   }

   hideOverlay() {
      this.$.newFilm.hideOverlay();
   }

   pIdpNameSearch() {
      const searchText = (this.$.inputQueicSearch.value||"").trim();
      if (searchText) {
         const message = {};
         const detail = {};
         const filterModel = {};
         const patientID = {};
         const patientName = {};
         detail.isQuickFilter = true;
         patientID.filter = searchText;
         patientName.filter = searchText;
         filterModel.patientID = patientID;
         patientID.filterType = "text";
         patientID.type = "contains";
         filterModel.patientName = patientName;
         patientName.filterType = "text";
         patientName.type = "contains";
         detail.filterModel = filterModel;
         message.detail = detail;
         if (this.$.categoryTab.selected === 0) {
            store.dispatch({ type: WorklistActionType.SET_FILTER, payload: message });
         } else {
            store.dispatch({ type: TechlistActionType.SET_FILTER, payload: message });
         }
         this.pIdpNameClear();
         this.clearDateToggles();
      } else if (this.$.categoryTab.selected === 1) {
         this.studyReload();
      } else {
         this.reloadFilter();
      }
      // #18419 [HPACS > 윤혜경 선생님] 소팅 클리어 오류
      if (this.$.categoryTab.selected === 0) this.$.newFilm.setFilterSortClear();
      else this.$.study.setFilterSortClear();
      this.dispatchEvent(new CustomEvent("setFilterSortClearEvent", {bubbles: true, composed: true}));
   }

   pIdpNameClear() {
      this.$.inputQueicSearch.value = "";
   }

   dateFilterSearch(e) {
      const {active, dataset} = e.target;
      if (active) {
         const {value} = dataset;
         if (value === "today") {
            this.todaySearch();
         } else if (value === "threeDays") {
            this.threeDaysSearch();
         } else if (value === "week") {
            this.weekSearch();
         } else if (value === "oneMonths") {
            this.oneMonthsSearch();
         } else if (value === "twoMonths") {
            this.twoMonthsSearch();
         }
         this.clearOtherDateToggles(value);
      } else {
         this.clearDateFilterSearch();
      }
      // 데이터를 조회하던 중(prefetch Queue가 실행되던 중)에 필터 검색시 남아있던 Queue 제거
      this.dispatchEvent(new CustomEvent("stopWorkerEvent", {bubbles: true, composed: true }));
   }

   clearDateToggles() {
      this.clearOtherDateToggles();
   }

   clearOtherDateToggles(value) {
      if (value !== "today") this.$.todayFilterButton.active = false;
      if (value !== "threeDays") this.$.threeDaysFilterButton.active = false;
      if (value !== "week") this.$.weekFilterButton.active = false;
      if (value !== "oneMonths") this.$.oneMonthsFilterButton.active = false;
      if (value !== "twoMonths") this.$.twoMonthsFilterButton.active = false;
   }

   rememberDateToggle(oldTab) {
      if (oldTab === 1) {
         this.$.study.setDateFilter(this.getActiveDateToggle());
      } else {
         this.$.newFilm.setDateFilter(this.getActiveDateToggle());
      }
   }

   setActiveDateToggle(dateFilter) {
      this.clearDateToggles();

      if (dateFilter === "today") {
         this.$.todayFilterButton.active = true;
      } else if (dateFilter === "threeDays") {
         this.$.threeDaysFilterButton.active = true;
      } else if (dateFilter === "week") {
         this.$.weekFilterButton.active = true;
      } else if (dateFilter === "oneMonths") {
         this.$.oneMonthsFilterButton.active = true;
      } else if (dateFilter === "twoMonths") {
         this.$.twoMonthsFilterButton.active = true;
      }
   }

   getActiveDateToggle() {
      let dateFilter = null;
      if (this.$.todayFilterButton.active)
         dateFilter = "today";
      else if (this.$.threeDaysFilterButton.active)
         dateFilter = "threeDays";
      else if (this.$.weekFilterButton.active)
         dateFilter = "week";
      else if (this.$.oneMonthsFilterButton.active)
         dateFilter = "oneMonths";
      else if (this.$.twoMonthsFilterButton.active)
         dateFilter = "twoMonths";
      return dateFilter;
   }

   /**
    * 적용된 filter와 active 된 date toggle button을 비교하여 다른 경우 date toggle button clear. <br>
    * grid 내에서 filter를 변경하는 경우 date toggle을 따로 clear 할 수 없기 때문에 filter가 적용된 후 체크하여 처리한다. <br>
    * @param filterModel
    */
   checkDateFilter(filterModel) {
      if (!filterModel) return;
      const dateFilter = this.getActiveDateToggle();
      if (this.$.categoryTab.selected === 1 && filterModel.studyDtime) {
         if (dateFilter === "today" && !this.isToday(filterModel.studyDtime))
            this.clearDateToggles();
         else if (dateFilter === "threeDays" && !this.isLastThreeDays(filterModel.studyDtime))
            this.clearDateToggles();
         else if (dateFilter === "week" && !this.isLastWeeks(filterModel.studyDtime))
            this.clearDateToggles();
         else if (dateFilter === "oneMonths" && !this.isLastOneMonths(filterModel.studyDtime))
            this.clearDateToggles();
         else if (dateFilter === "twoMonths" && !this.isLastTwoMonths(filterModel.studyDtime))
            this.clearDateToggles();
      } else if (this.$.categoryTab.selected === 0 && filterModel.studyDtime) {
         if (dateFilter === "today" && !this.isToday(filterModel.studyDtime))
            this.clearDateToggles();
         else if (dateFilter === "threeDays" && !this.isLastThreeDays(filterModel.studyDtime))
            this.clearDateToggles();
         else if (dateFilter === "week" && !this.isLastWeeks(filterModel.studyDtime))
            this.clearDateToggles();
         else if (dateFilter === "oneMonths" && !this.isLastOneMonths(filterModel.studyDtime))
            this.clearDateToggles();
         else if (dateFilter === "twoMonths" && !this.isLastTwoMonths(filterModel.studyDtime))
            this.clearDateToggles();
      }
   }

   isToday(filter) {
      return moment(filter.dateFrom).isSame(moment(this.getToday())) && filter.type === "equals";
   }

   isLastThreeDays(filter) {
      return moment(filter.dateFrom).isSame(moment(this.lastThreeDays())) && moment(filter.dateTo).isSame(moment(this.getToday())) && filter.type === "inRelativeRange";
   }

   isLastWeeks(filter) {
      return moment(filter.dateFrom).isSame(moment(this.lastWeeks())) && moment(filter.dateTo).isSame(moment(this.getToday())) && filter.type === "inRelativeRange";
   }

   isLastOneMonths(filter) {
      return moment(filter.dateFrom).isSame(moment(this.lastOneMonths())) && moment(filter.dateTo).isSame(moment(this.getToday())) && filter.type === "inRelativeRange";
   }

   isLastTwoMonths(filter) {
      return moment(filter.dateFrom).isSame(moment(this.lastTwoMonths())) && moment(filter.dateTo).isSame(moment(this.getToday())) && filter.type === "inRelativeRange";
   }

   getToday() {
      return moment().format("YYYY-MM-DD");
   }

   lastThreeDays() {
      return moment().subtract(2, "days").format("YYYY-MM-DD");
   }

   lastWeeks() {
      return moment().subtract(1, "weeks").add(1, "days").format("YYYY-MM-DD");
   }

   lastOneMonths() {
      return moment().subtract(1, "months").add(1, "days").format("YYYY-MM-DD");
   }

   lastTwoMonths() {
      return moment().subtract(2, "months").add(1, "days").format("YYYY-MM-DD");
   }

   clearDateFilterSearch() {
      if (this.$.categoryTab.selected === 1) {
         this.$.study.dateFilterSearch();
         this.dispatchEvent(new CustomEvent("changeFilterTechlistEvent"));
      } else {
         this.$.newFilm.dateFilterSearch();
         this.dispatchEvent(new CustomEvent("clearWindowReportEvent"));
      }
   }

   techlistFilterDate(getFilterModel) {
      const { filterModel, appliedDateFilters } = this.appliedTechlistFilter??{};
      Object.keys(appliedDateFilters).forEach((key) => {
         if(filterModel[key])
            filterModel[key] = appliedDateFilters[key];
      });
      filterModel.studyDtime = getFilterModel;
      store.dispatch({ type: TechlistActionType.SET_FILTER, payload: {detail : { filterModel }} });
   }

   worklistFilterDate(getFilterModel) {
      const { filterModel, appliedDateFilters } = this.appliedWorklistFilter??{};
      Object.keys(appliedDateFilters).forEach((key) => {
         if(filterModel[key])
            filterModel[key] = appliedDateFilters[key];
      });
      filterModel.studyDtime = getFilterModel;
      store.dispatch({ type: WorklistActionType.SET_FILTER, payload: {detail : { filterModel }} });
   }

   todaySearch() {
      if (this.$.categoryTab.selected === 1) {
         this.techlistFilterDate(this.getTodayFilterModel());
         this.dispatchEvent(new CustomEvent("todaySearchEvent", {bubbles: true, composed: true}));
      } else {
         this.worklistFilterDate(this.getTodayFilterModel());
         this.dispatchEvent(new CustomEvent("clearWindowReportEvent", {bubbles: true, composed: true}));
      }
   }

   threeDaysSearch() {
      if (this.$.categoryTab.selected === 1) {
         this.techlistFilterDate(this.getThreeDaysFilterModel());
         this.dispatchEvent(new CustomEvent("threeDaysSearchEvent", {bubbles: true, composed: true}));
      } else {
         this.worklistFilterDate(this.getThreeDaysFilterModel());
         this.dispatchEvent(new CustomEvent("clearWindowReportEvent", {bubbles: true, composed: true}));
      }
   }

   weekSearch() {
      if (this.$.categoryTab.selected === 1) {
         this.techlistFilterDate(this.getWeekFilterModel());
         this.dispatchEvent(new CustomEvent("weekSearchEvent", {bubbles: true, composed: true}));
      } else {
         this.worklistFilterDate(this.getWeekFilterModel());
         this.dispatchEvent(new CustomEvent("clearWindowReportEvent", {bubbles: true, composed: true}));
      }
   }

   oneMonthsSearch() {
      if (this.$.categoryTab.selected === 1) {
         this.techlistFilterDate(this.getOneMonthsFilterModel());
         this.dispatchEvent(new CustomEvent("oneMonthsSearchEvent", {bubbles: true, composed: true}));
      } else {
         this.worklistFilterDate(this.getOneMonthsFilterModel());
         this.dispatchEvent(new CustomEvent("clearWindowReportEvent", {bubbles: true, composed: true}));
      }
   }

   twoMonthsSearch() {
      if (this.$.categoryTab.selected === 1) {
         // this.$.study.dateFilterSearch(this.getTwoMonthsFilterModel());
         this.techlistFilterDate(this.getTwoMonthsFilterModel());
         this.dispatchEvent(new CustomEvent("twoMonthsSearchEvent", {bubbles: true, composed: true}));
      } else {
         // this.$.newFilm.dateFilterSearch(this.getTwoMonthsFilterModel());
         this.worklistFilterDate(this.getTwoMonthsFilterModel());
         this.dispatchEvent(new CustomEvent("clearWindowReportEvent", {bubbles: true, composed: true}));
      }
   }

   getTodayFilterModel() {
      const date = {};
      date.dateFrom = this.getToday();
      date.dateTo = this.getToday();
      date.type = "equals";
      date.filterType = "date";
      date.isRelative = true;
      return date;
   }

   getThreeDaysFilterModel() {
      const date = {};
      date.dateFrom = this.lastThreeDays();
      date.dateTo = this.getToday();
      date.type = "inRelativeRange";
      date.filterType = "date";
      date.amount = 3;
      date.unit = "days";
      date.isRelative = true;
      return date;
   }

   getWeekFilterModel() {
      const date = {};
      date.dateFrom = this.lastWeeks();
      date.dateTo = this.getToday();
      date.type = "inRelativeRange";
      date.filterType = "date";
      date.amount = 1;
      date.unit = "weeks";
      date.isRelative = true;
      return date;
   }

   getOneMonthsFilterModel() {
      const date = {};
      date.dateFrom = this.lastOneMonths();
      date.dateTo = this.getToday();
      date.type = "inRelativeRange";
      date.filterType = "date";
      date.amount = 1;
      date.unit = "months";
      date.isRelative = true;
      return date;
   }

   getTwoMonthsFilterModel() {
      const date = {};
      date.dateFrom = this.lastTwoMonths();
      date.dateTo = this.getToday();
      date.type = "inRelativeRange";
      date.filterType = "date";
      date.amount = 2;
      date.unit = "months";
      date.isRelative = true;
      return date;
   }

   displayPrev() {
      if (this.$.categoryTab.selected === 1) {
         this.$.study.displayPrev();
      } else {
         this.$.newFilm.displayPrev();
      }
   }

   displayNext() {
      if (this.$.categoryTab.selected === 1) {
         this.$.study.displayNext();
      } else {
         this.$.newFilm.displayNext();
      }
   }

   clearSelectedRow() {
      this.$.newFilm.clearSelectedRow();
   }

   clearSelectedRowStudylist() {
      this.$.study.clearSelectedRow();
   }

   // doLogoutEvent(){
   //     _this.$.newFilm.doLogout();
   // }

   openKeyImageEvent(param) {
      this.$.newFilm.openKeyImageEvent(param);
   }

   studyReload() {
      this.$.study.reloadFilter();
   }

   // studyRedraw() {
   //    this.$.study.redrawStudyById();
   // }

   // replaceRowData(newData) {
   //    this.$.study.replaceRowData(newData);
   // }

   openToastMessage(params) {
      const message = {};
      message.isErr = params.isErr;
      message.text = params.message; // "Studylist Selected Firtst.";
      this.openToast(message);
   }

   openToast(message) {
      if (!message.isErr) {
         this.$.orderlistToast.style.backgroundColor = "#0087cb";
      } else {
         this.$.orderlistToast.style.backgroundColor = "#e46159";
      }
      this.$.orderlistToast.text = message.text;
      this.$.orderlistToast.open();
      if (message.isErr) {
         return false;
      }
      return null;
   }

   // diabledSelectedRows() {
   //    this.$.study.diabledSelectedRows();
   // }

   refreshCellByCVR(id) {
      this.$.newFilm.refreshCellByCVR(id);
   }

   // selectedRowToMatched(params) {
   //    this.$.study.selectedRowToMatched(params);
   // }

   // studyCountClearEvent() {
   //    this.$.study.studyCountClear();
   // }

   // selectedOrderRow(params) {
   //    this.$.study.selectedOrderRow(params);
   // }


   updateVerifyByObjectId() {
      this.$.newFilm.updateVerifyByObjectId();
   }

   getSelectedCategoryTab() {
      return this.$.categoryTab.selected;
   }

   setTabIndex(tabIndex) {
      this.selected = tabIndex || 0;
   }

   setFilterNSort() {
      // #18419 [HPACS > 윤혜경 선생님] 소팅 클리어 오류
      // this.$.newFilm.setFilterSortClear();
      // this.dispatchEvent(new CustomEvent("setFilterSortClearEvent", {bubbles: true, composed: true}));
      // const { filterModel, sortModel } = userFilterModel;
      // this.$.newFilm.setFilterNSort(filterModel, sortModel);
   }

   sinkViewing(request) {
      this.$.newFilm.sinkViewing(request);
      this.$.study.sinkViewing(request);
   }

   sinkVerify(requestList) {
      this.$.newFilm.redrawVerifiedRows(requestList);
      this.$.study.redrawVerifiedRows(requestList);
   }

   sinkApprove(requestList) {
      this.$.newFilm.approveOpinionisResult(requestList);
      this.$.study.redrawUpdateOpinionRows(requestList);
   }

   sinkAiState(aiAppProgress) {
      this.$.newFilm.redrawRequestAiRows(aiAppProgress);
   }

   sinkTeleRequest(params) {
      this.$.newFilm.redrawVerfiedRequestRow(params);
   }

   sinkTeleStatusVerify(request) {
      this.$.newFilm.redrawVerifiedTeleStatusRow(request);
   }

   changeCustomContextMenuState(customContextMenuState) {
      // console.log("[worklist-newfilm] changeCustomContextMenuState", this.customUniqueKey === customContextMenuState, this.customUniqueKey, customContextMenuState)

      if (this.customUniqueKey !== customContextMenuState) {
         if (!this.$.contextMenu.classList.contains("hideContextMenu"))
            this.$.contextMenu.classList.add("hideContextMenu"); // HIDE
      }
   }

   changeAppliedWorklistFilter(appliedWorklistFilter) {
      if (!appliedWorklistFilter) return;
      const { filterModel } = appliedWorklistFilter;
      this.dispatchEvent(new CustomEvent("clearWindowReportEvent"));

      // checkDateFilterEvent
      this.checkDateFilter(filterModel);
   }

   changeAppliedTechlistFilter(appliedTechlistFilter) {
      if (!appliedTechlistFilter) return;
      const { filterModel } = appliedTechlistFilter;
      this.clearSelectedRowStudylist();

      // checkDateFilterEvent
      // 적용된 필터와 선택된 Date 필터가 같은지 비교 후 다르면 deselect 처리
      this.checkDateFilter(filterModel);
   }
}

window.customElements.define("worklist-newfilm", WorklistNewfilm);
