/* eslint-disable no-unused-expressions,no-restricted-globals,prefer-destructuring,prefer-const,radix,no-const-assign,no-restricted-syntax,guard-for-in */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "@polymer/paper-dialog";
import "@polymer/paper-input/paper-input";
import "@polymer/paper-button";
import "@vaadin/vaadin-icons";

import { is_esc, is_enter, have_to_move_to_right, have_to_move_to_left, clickEvent } from "../dialog/common-keyboard-handler";
import {CommonActionType, DialogActionType} from "../redux/reducers/common";
import mixinCommons from "../common-mixin";
import store from "../redux/store";

/**
 * `hh-report-confirm-dialog`
 *
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
class HhReportConfirmDialog extends mixinCommons(PolymerElement) {
   static get template() {
      return html`
      <style>
         :host{
            top: 16px;
            right: 16px;
         }

         paper-dialog{
            width: 500px;
            height: 190px;
            background-color: #394451;
            z-index: 1;
         }

         paper-button{
            width: 145px;
            height: 35px;
            border: 0;
            font-size: 10pt;
            color: #ccc;
            cursor: pointer;
            border-radius: 2px;
            background-color: #4c5667;
         }

         paper-button[focused][active]  {
            background: #0087cb;
         }

         .class-title{
            display: flex;
         }

         .text-font{
            color: #ccc;
            font-size: 20px;
         }

         .class-btn{
            text-align: center;
            padding: 10px;
         }

      </style>

      <paper-dialog id="confirmDialog" on-click="prevent_event_propagation" on-keydown="close_if_esc" on-keypress="prevent_event_propagation" on-keyup="prevent_event_propagation" on-focus="focus_on_selected_button"  on-iron-overlay-opened="dialog_opened" on-iron-overlay-closed="dialog_closed" modal>
         <div class="class-title">
            <iron-icon icon="vaadin:warning" style="color: #aaaaaa; width: 30px"></iron-icon>
            <div id="title" class="text-font" style="margin: 3px 0 0 10px; display: flex; font-size: 20px;">{{message.title}}</div>
         </div>
         <div class="class-message">
            <template is="dom-repeat" items="{{message.contents}}" as="contents">
               <p class="text-font" style="font-size: 15px">{{contents}}</p>
            </template>
         </div>
         <div class="class-btn">
            <paper-button id="approveBtn" class="btn" on-click="doApprove" tabindex="2">Approve/Addendum</paper-button>
            <paper-button id="saveBtn" class="btn" on-click="doSave" tabindex="1">Save</paper-button>
            <paper-button id="discardBtn" class="btn" on-click="doDiscard" tabindex="0">Discard</paper-button>
         </div>
      </paper-dialog>
      `;
   }

   static get is() {
      return "hh-report-confirm-dialog";
   }

   static get properties() {
      return {
         message: {
            type: Object
         },
         _buttons: {
            type: Array,
            value: []
         },
         cnt: {
            type: Number,
            value: 0
         },
         checkBtn: {
            type: Array,
            value: []
         }
      };
   }

   ready() {
      super.ready();

      ["click", "keydown", "keypress", "keyup"].forEach(eventType => this.$.confirmDialog.addEventListener(eventType, e => e.stopPropagation()));

      const set_selected = button => (Object.assign(button, {focused:true, active:true}) && button.focus());
      const buttons = this.$.confirmDialog.getElementsByTagName("paper-button");

      this.$.confirmDialog.addEventListener("keyup", (e) => {
         if(have_to_move_to_right(e)) { // arrowRight || TAB
            for (const button of buttons) Object.assign(button, {focused: false, active:false});
            this.selected_index = (this.selected_index + 1) % this.checkBtn.length;
            set_selected( this.checkBtn[this.selected_index] );
         } else if(have_to_move_to_left(e)) { // arrowLeft || Shift-TAB
            for (const button of buttons) Object.assign(button, {focused: false, active:false});
            this.selected_index = this.selected_index===0 ? this.checkBtn.length - 1 : this.selected_index - 1;
            set_selected( this.checkBtn[this.selected_index] );
         } else if(is_enter(e)) {
            this.checkBtn[this.selected_index].dispatchEvent(clickEvent);
         } else if(is_esc(e)) {
            [...this.checkBtn].filter( button => button.id === "discardBtn" ).map(discardBtn => discardBtn.dispatchEvent(clickEvent));
         }
         e.stopPropagation();
      });
      // this.$.confirmDialog.addEventListener("opened-changed", (e) => {
      //    console.log("opened-changed", e);
      //    this.dispatchEvent(new CustomEvent("opened-changed", {detail: e.detail}));
      // });
   } // end ready

   close_if_esc(e) {
      if(is_esc(e)) {
         this.doDiscard();
      }
      e.stopPropagation();
   }

   prevent_event_propagation(e) {
      e.stopPropagation();
   }

   dialog_opened() {
      // if(window.report && window.report.blocking) window.report.blocking.open();
      window.dialog_opened({dialog: this.$.confirmDialog, is_modal: true});
   }

   dialog_closed() {
      // if(window.report && window.report.blocking) window.report.blocking.close();
      window.dialog_closed();
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   focus_on_selected_button() {
      this.checkBtn[this.selected_index].focus();
   }

   initBtnState() {
      this.selected_index = 0;
      this.checkBtn = [];
      this.$.approveBtn.show = true;
      this.$.approveBtn.hidden = false;
      this.$.approveBtn.active = true;
      this.$.approveBtn.focused = true;
      this.$.approveBtn.autofocus = true;
      this.$.saveBtn.active = false;
      this.$.saveBtn.focused = false;
      this.$.saveBtn.autofocus = false;
      this.$.approveBtn.focus();
   }

   setSaveBtn() {
      if(this.message.readingStatus === "3A") {
         this.$.saveBtn.show = false;
         this.$.saveBtn.hidden = true;
      } else {
         this.$.saveBtn.show = true;
         this.$.saveBtn.hidden = false;
      }
   }

   checkSaveState() {
      if(this.message.saveStatus === "O") {
         this.$.approveBtn.show = false;
         this.$.approveBtn.hidden = true;
         this.$.approveBtn.active = false;
         this.$.approveBtn.focused = false;
         this.$.approveBtn.autofocus = false;
         this.$.saveBtn.active = true;
         this.$.saveBtn.focused = true;
         this.$.saveBtn.autofocus = true;
         this.$.saveBtn.focus();
      }
   }

   doOpen(message) {
      const {filmbox} = window.document;
      if(window.report && typeof window.report.get === "function" && window.report.get()) window.report.get().open("", "worklist");
      if(filmbox) filmbox.open("", "worklist");

      // filmbox popup보다 focus 우선순위를 높게 가져야함
      self.focus();
      this.initBtnState();

      if(message.actionType !== undefined) {
         const initOption = this.getInitDialogOption(message.actionType, message.params);
         this.message = {...message, ...initOption};
      } else {
         this.message = message;
      }

      this.setSaveBtn();
      this.checkSaveState();

      const buttons = this.$.confirmDialog.getElementsByTagName("paper-button");
      for(const btn of buttons) {
         if(!btn.hidden) this.checkBtn.push(btn);
      }

      this.$.confirmDialog.open();
      window.$confirmDialog = this.$.confirmDialog;
   }

   doApprove() {
      Object.assign(this.message.params, {btn: "approve"});

      this.$.confirmDialog.close();

      const { action = () => Promise.resolve(), callback = () => {}, params } = this.message;
      action(params).then(result => callback(result, params));
   }

   doSave() {
      Object.assign(this.message.params, {btn: "save"});

      this.$.confirmDialog.close();

      const { action = () => Promise.resolve(), callback = () => {}, params } = this.message;
      action(params).then(result => callback(result, params));
   }

   doDiscard() {
      Object.assign(this.message.params, {btn: "discard"});

      this.$.confirmDialog.close();

      const { action = () => Promise.resolve(), callback = () => {}, params } = this.message;
      action(params).then(result => callback(result, params));

      if (this.message.cancelCallback) this.message.cancelCallback({ detail: this.message });
   }

   doCustomSize(width, height) {
      this.$.confirmDialog.style.width = `${width}px`;
      this.$.confirmDialog.style.height = `${height}px`;
   }

   getInitDialogOption(actionType, params) {
      const message = {};
      switch (actionType) {
      case DialogActionType.REPORT_NOT_SAVED: {
         this.doCustomSize(500,190);
         message.contents = [ this.t("msg.opinion.confirm.notSaved") ];
         message.title = this.t("label.approvalConfirmation");
         return message;
      }
      default: return message;
      }
   }
}

window.customElements.define(HhReportConfirmDialog.is, HhReportConfirmDialog);
