import React, { useRef, useState } from "react";
import {
   MenuList,
   MenuItem as BasicMenuItem,
   Popper,
   Paper,
   Divider,
   Icon, Grow, ListItemIcon, ListItemText, styled,
} from "@mui/material";
// import { styled } from "@mui/styles";
import { ChevronRight } from "@mui/icons-material";

const StyledMenuItem = styled(BasicMenuItem)({
   "&:hover": {
      color: "#fff",
      backgroundColor: "#0087cb",
   },
});

const StyledPaper = styled(Paper)({
   color: "#ccc",
   borderBottomLeftRadius: "7px",
   borderBottomRightRadius: "7px",
   backgroundColor: "#1d1e25",
   border: "1px solid #4C5667",
   height: "auto",
});

const StyledDivider = styled(Divider)({
   borderBottom: "1px solid #4C5667",
});

const StyledListItemIcon = styled(ListItemIcon)({
   color: "#ccc",
   "& .MuiSvgIcon-root": {
      color: "#ccc",
   },
});

const StyledListItemText = styled(ListItemText)({
   color: "#ccc",
   fontWeight: "lighter",
});

function MenuItem(props: any) {
   const { item, children } = props;
   const childNodes = item.childNode ?? [];

   const [open, setOpen] = useState(false);

   const ref = useRef(null);

   return (
      <StyledMenuItem
         ref={ref}
         { ...props }
         data-key={item.id}
         onMouseEnter={() => setOpen(true)}
         onMouseLeave={() => setOpen(false)}
      >
         <StyledListItemIcon style={{ minWidth: "30px" }}>
            {item.icon && <Icon>{item.icon}</Icon>}
         </StyledListItemIcon>
         <StyledListItemText disableTypography>
            {item.name}
         </StyledListItemText>
         {childNodes.length > 0 && <ChevronRight />}
         {childNodes.length > 0
         && <Popper open={open}
            anchorEl={ref.current}
            role={undefined}
            placement="right-start"
            transition
         >
            {({ TransitionProps, placement }) => (
               <Grow {...TransitionProps} style={{ transformOrigin: placement === "right-start" ? "left top" : "right top" }}>
                  <StyledPaper>
                     <MenuList autoFocusItem={open}>
                        {childNodes.map((child: { id: string; childNode: []; name: string; }, idx: number) => (child.id ? (
                           React.cloneElement(children, { ...props, key: idx, item: child, "data-children": child.childNode })
                        ) : (<StyledDivider key={idx} />)))}
                     </MenuList>
                  </StyledPaper>
               </Grow>
            )}
         </Popper>}
      </StyledMenuItem>
   );
}

export default MenuItem;
