import React, { useState, useEffect, useRef } from "react";
import { Box, Dialog, DialogTitle, IconButton } from "@mui/material";
import styled from "@emotion/styled";
import { Close } from "@mui/icons-material";
import { is_esc } from "../../dialog/common-keyboard-handler";
import i18n from "../../utils/i18n-utils";
import { DialogStyles } from "./styles/DialogStyles";

const DialogStyle = styled.div`
   width: 1000px;
   border-top: 5px #0087cb solid;
`;

const ContainerStyle = styled.div`
   display: flex;
   flex-direction: column;
   height: 100%;
`;

const Information = styled.div`
   font-size: .8em;
   line-height: 1.5em;
   background-color: #252934;
   padding: 20px;
   p {
      text-align: center;
   }
   img {
      height: 45px;
   }
`;

const Company = styled.div`
   color: #fff;
   border-radius: 10px;
   -moz-border-radius: 10px;
   -webkit-border-radius: 10px;
   border: 1px solid #fff;
   padding: 15px;
   margin-bottom: 15px;
`;

const Hpacs = styled.div`
   color: #fff;
   border-radius: 10px;
   -moz-border-radius: 10px;
   -webkit-border-radius: 10px;
   border: 1px solid #fff;
   padding: 15px;
`;

const CertificationDialog = (prop: any) => {
   const dialogRef = useRef(null);

   useEffect(() => {
      if (dialogRef.current) dialog_opened();
   }, [dialogRef.current]);

   function prevent_event_propagation(e: React.MouseEvent) { // onClick
      e.stopPropagation();
   }

   function close_if_esc(e: React.KeyboardEvent<HTMLDivElement>) {
      if (is_esc(e)) {
         doClose();
         e.stopPropagation();
      }
   }

   function dialog_opened() {
      // @ts-ignore
      window.dialog_opened({ dialog:dialogRef.current, is_modal:true });
   }

   function dialog_closed() {
      // @ts-ignore
      window.dialog_closed();
   }

   function doClose() {
      prop.setOpenDialog(false);
      dialog_closed();
   }

   const classes = DialogStyles();
   return (
      <Dialog
         open={prop.open}
         // onClick={prevent_event_propagation}
         onKeyDown={close_if_esc}
         maxWidth={"lg"}
      >
         <DialogStyle>
            <ContainerStyle>
               <Box
                  className={classes.container}
               >
                  <DialogTitle className={classes.dialogTitle} component={"p"}>Certifications</DialogTitle>
                  <IconButton sx={{ color: "#fff" }} onClick={doClose}>
                     <Close/>
                  </IconButton>
               </Box>
               <Information>
                  <p style={{ padding: "20px" }}><img src="/resource/images/ci/new_logo_hpacs.png" alt={"Healthhub"}/></p>
                  <Company>
                     {i18n("label.certifications.company.0")}<br/>
                     {i18n("label.certifications.company.1")}<br/>
                     {i18n("label.certifications.company.2")}<br/>
                     {i18n("label.certifications.company.3")}<br/>
                      &nbsp;&nbsp;{i18n("label.certifications.company.4")}<br/>
                      &nbsp;&nbsp;{i18n("label.certifications.company.5")}<br/>
                      &nbsp;&nbsp;{i18n("label.certifications.company.6")}<br/>
                     {i18n("label.certifications.company.7")}<br/>
                  </Company>
                  <Hpacs>
                     {i18n("label.certifications.hpacs.0")}<br/>
                     {i18n("label.certifications.hpacs.1")}<br/>
                     {i18n("label.certifications.hpacs.2")}<br/>
                     {i18n("label.certifications.hpacs.3")}<br/>
                     {i18n("label.certifications.hpacs.4")}<br/>
                     {i18n("label.certifications.hpacs.5")}<br/>
                     {i18n("label.certifications.hpacs.6")}
                     {i18n("label.certifications.hpacs.7")}<br/>
                  </Hpacs>
               </Information>
            </ContainerStyle>
         </DialogStyle>
         <div>
            {/* <table width="100%"> */}
            {/*   <tr> */}
            {/*      <td class="celogo" rowspan="2"><img src="../resource/images/CE/logo_ce.jpg" width="100px"></td> */}
            {/*      <td rowspan="2"> */}
            {/*         PRODUCT NAME : Picture Archiving Communication System(PACS)&nbsp;&nbsp;<img src="../resource/images/CE/ref_1.0.png" style="height: 17px"  align="absmiddle"><br/> */}
            {/*         MODEL NAME : HPACS<br/> */}
            {/*         Version : V1.0<br/> */}
            {/*         Manufacturer : Healthhub Co., Ltd.&nbsp;&nbsp;<img src="../resource/images/CE/_factory_1.0.png" style="height: 17px"  align="absmiddle"><br/> */}
            {/*         - Address<br /> */}
            {/*         &nbsp;&nbsp;&nbsp;[Headquater] 4F, Asia B/D, 31, Gangnam-daero 101-gil, Seocho-gu, Seoul, 06524, Republic of Korea<br /> */}
            {/*         &nbsp;&nbsp;&nbsp;[A.I. Lab.]&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;15F, 623, Gangnam-daero, Seocho-gu, Seoul, 06524, Republic of Korea<br /> */}
            {/*         &nbsp;&nbsp;&nbsp;[Marketing and Sales] 4F, Daeyang B/D, 617, Gangnam-daero, Seocho-gu, Seoul, 06524, Republic of Korea<br /> */}
            {/*         - TEL : +82 2 511 3601<br /> */}
            {/*         - Homepage : www.healthhub.kr */}
            {/*      </td> */}
            {/*      <td width="320"> */}
            {/*         MFG. DATE : DEC 29, 2020<br/> */}
            {/*         European Authorized Representation :&nbsp;&nbsp;<img src="../resource/images/CE/ec-rep_1.0.png" style="height: 17px" align="absmiddle"><br/> */}
            {/*         The Wise Europe GmbH<br/> */}
            {/*         - Addres : Oberhoechstaedter Weg 1 65760 Eschborn-Germany<br /> */}
            {/*         - TEL : +49 6173 997791<br /> */}
            {/*         - Homepage : www.the-wise.co.kr<br/> */}
            {/*         <img src="../resource/images/CE/icon01_1.0.png" style="height: 17px" align="absmiddle"> */}
            {/*         &nbsp;www.hpacsweb.com&nbsp; */}
            {/*         <img src="../resource/images/CE/icon02_1.0.png" style="height: 17px" align="absmiddle"> */}
            {/*      </td> */}
            {/*   </tr> */}
            {/* </table> */}
         </div>
      </Dialog>
   );
};

export default CertificationDialog;
