/* eslint-disable prefer-object-spread,no-unused-vars */
import {html, PolymerElement} from "@polymer/polymer/polymer-element";

import "./filter/worklist-filter";
import "./worklist-newfilm";
import "./worklist-counter";
import "./techlist-counter";
import "./worklist-oldfilm";
import "./grid-order";
import "./components/healthhub-confirm-dialog";
import "@vaadin/vaadin-split-layout/vaadin-split-layout";
import "@polymer/paper-tabs";
import CommonUtils from "../public/resource/js/utils/common";
import mixinCommons from "./common-mixin";
import store from "./redux/store";
import FilmboxUtils from "./utils/filmbox-utils";

class WorklistContent extends mixinCommons(PolymerElement) {

   static get template() {
      return html`
         <style>
            :host {
               display: block;
               height: 100%;
               width: 100%;
            }

            .container {
               display: flex;
               flex-direction: column;
               height: 100%;
               width: 100%;
            }

            .content-filter {
               width: 100%;
               height: 40px;
            }

            .centent-newfilm {
               /* margin-top: 8px; */
               /* height: -webkit-calc(100% - 33px); */
               height: calc(100% - 33px);
            }

            .centent-counter {
               display: flex;
               align-items: center;
               width: 100%;
               height: 33px;
            }

            .centent-oldfilm {
               width: 100%;
               height: -webkit-calc(100% - 839px);
               overflow: hidden;
            }

            .wrap-newflim {
               height: 839px;
               min-height: 50%;
               max-height: 70%;
               overflow: hidden;
            }

            .wrap-worklist {
               display: flex;
               height: calc(100% - 40px);
               padding-top: 8px;
               padding-left: 8px;
            }

            .centent-footer {
               width: 100%;
               height: 35px;
            }

            paper-button.openInputFilterName {
               --paper-button-ink-color: #0087cd;
               color: #ffffff;
               font-size: 11px;
               font-family: NotoSansCJKDemi, NotoSansCJK, NotoSansCJKLight;
               background-color: #1d1e25;
               text-transform: none;
               border-radius: 10px;
               width: 90px;
               height: 25px;
            }

            paper-tabs {
               --paper-tabs-selection-bar: {
                  border-bottom: 3px solid #0087cb;
               };

               --paper-tabs-content: {
                  font-family: NotoSansCJKMedium, NotoSansCJK, NotoSansCJKLight !important;
                  color: #aaaaaa;
                  background-color: #252934;
                  font-size: 14px;
               };
               height: 40px;
            }

            paper-tabs paper-tab.iron-selected {
               color: #0087cb;
            }

            iron-pages {
               height: 100%;
            }

            iron-pages > div {
               height: calc(100% - 40px);
            }
         </style>

         <div class="container">
            <paper-toast id="toastContent" duration="1000" class="content-toast"></paper-toast>
            <paper-toast id="" text=""></paper-toast>
            <div class="content-filter" id="divFilter">
               <worklist-filter id="worklistFilter" filter-model="[[filterModel]]" applied-filter="[[appliedWorklistFilter]]" date-filter="[[dateFilter]]"></worklist-filter>
               <worklist-filter id="techlistFilter" filter-model="[[filterModel]]" applied-filter="[[appliedTechlistFilter]]" style="display: none"></worklist-filter>
            </div>
            <vaadin-split-layout orientation="vertical" class="wrap-worklist" id="newfilmOldfilm" theme="minimal">
               <div class="wrap-newflim" id="newflim">
                  <div class="centent-newfilm">
                     <worklist-newfilm id="worklistNewFilm"></worklist-newfilm>
                  </div>
                  <div class="centent-counter">
                     <!-- radiology -->
                     <worklist-counter id="worklistCount"></worklist-counter>
                     <!-- technician -->
                     <techlist-counter id="techlistCount" style="display: none"></techlist-counter>
                  </div>
               </div>
               <div class="centent-oldfilm" id="oldfilm">
                  <!-- radiology -->
                  <worklist-oldfilm id="worklistOldFilm"></worklist-oldfilm>
                  <!-- technician -->
                  <div id="techlistOldFilm" style="height: 100%; width: 100%">
                     <div>
                        <paper-tabs id="techTabs" selected="{{activeTechTab}}" noink>
                           <paper-tab>{{ t("label.relatedExam") }}</paper-tab>
                           <paper-tab>{{ t("label.order") }}</paper-tab>
                        </paper-tabs>
                     </div>
                     <iron-pages selected="{{activeTechTab}}">
                        <div><worklist-oldfilm id="techOldFilm"></worklist-oldfilm></div>
                        <div><grid-order id="gridOrder"></grid-order></div>
                     </iron-pages>
                  </div>
               </div>
            </vaadin-split-layout>
         </div>
      `;
   }

   static get properties() {
      return {
         _ready: {
            type: Boolean,
            value: false
         },
         // selectedRow: {
         //    type: Object,
         //    observer: "onSelectRow"
         // },
         // selectedTechRow: {
         //    type: Object
         //    observer: "onSelectTechRow"
         // },
         activeTabCode: {
            type: Number,
            observer: "onActiveTabChange"
         },
         activeTechTab: {
            type: Number,
            value: 0
         },
         _styleObj: {
            type: Object,
            value: {}
         },
         _selectedStudies: {
            type: Array,
            value: []
         },
         _category: {
            type: Number,
            value: 0,
            observer: "onSelectCategory"
         },
         worklistFilterState: {
            type: Object,
            value: {},
            observer: "setWorklistFilter"
         },
         techlistFilterState: {
            type: Object,
            value: {},
            observer: "setTechlistFilter"
         },
         appliedWorklistFilter: {
            type: Object
         },
         appliedTechlistFilter: {
            type: Object
         }
      };
   }

   ready() {
      super.ready();

      store.subscribe(() => {
         this._selectedStudies = store.getState().worklist.row?.rows;
         this.worklistFilterState = store.getState().worklist.filter;
         this.techlistFilterState = store.getState().techlist.filter;
         this._category = store.getState().common.category;
         this.activeTabCode = store.getState().relatedWorklist.activeTabCode;
         if (this._category === 0) {
            this.appliedWorklistFilter = store.getState().worklist.appliedFilter;
         } else if (this._category === 1) {
            this.appliedTechlistFilter = store.getState().techlist.appliedFilter;
         }
      });

      this.getUserStyle().then((result) => {
         if(result) {
            const userStyle = result;

            if(!userStyle.tabIndex) userStyle.tabIndex = this.getDefaultTabIndex();
            else userStyle.tabIndex = Object.assign({}, this.getDefaultTabIndex(), userStyle.tabIndex);

            this.updateWorkListTabIndex(userStyle.id, userStyle.tabIndex);

            if(!userStyle.layout) userStyle.layout = this.getDefaultLayout();
            else userStyle.layout = Object.assign({}, this.getDefaultLayout(), userStyle.layout);

            this.updateNewfilmOldfilmStyle(userStyle.layout);

            this.setStyle(userStyle);
         }
         // else {
         //    this._styleObj.layout = this.getDefaultLayout();
         //    this._styleObj.tabIndex = this.getDefaultTabIndex();
         //    this.createStyle();
         // }
      }).catch((err) => {
         console.error(err);
      });

      this.$.worklistNewFilm.addEventListener("changeFilterTechlistEvent", () => {
         if(this._category === 1) {
            this.dispatchEvent(new CustomEvent("changeFilterTechlistEvent"));
         }
      });

      this.$.worklistNewFilm.addEventListener("todaySearchEvent",(e) => {
         if(this._category === 1) {
            this.dispatchEvent(new CustomEvent("changeFilterTechlistEvent"));
            this.$.gridOrder.dateFilterSearch(1, "days");
         }
      });

      this.$.worklistNewFilm.addEventListener("threeDaysSearchEvent",(e) => {
         if(this._category === 1) {
            this.$.gridOrder.dateFilterSearch(3, "days");
            this.dispatchEvent(new CustomEvent("changeFilterTechlistEvent"));
         }
      });

      this.$.worklistNewFilm.addEventListener("weekSearchEvent", (e) => {
         if (this._category === 1) {
            this.$.gridOrder.dateFilterSearch(1, "weeks");
            this.dispatchEvent(new CustomEvent("changeFilterTechlistEvent"));
         }
      });

      this.$.worklistNewFilm.addEventListener("oneMonthsSearchEvent", (e) => {
         if (this._category === 1) {
            this.$.gridOrder.dateFilterSearch(1, "months");
            this.dispatchEvent(new CustomEvent("changeFilterTechlistEvent"));
         }
      });

      this.$.worklistNewFilm.addEventListener("twoMonthsSearchEvent",(e) => {
         if(this._category === 1) {
            this.$.gridOrder.dateFilterSearch(2, "months");
            this.dispatchEvent(new CustomEvent("changeFilterTechlistEvent"));
         }
      });

      this.$.worklistFilter.addEventListener("getFilterEvent", () => {
         this.filterModel = this.$.worklistNewFilm.getFilter();
         this.$.worklistFilter.sortModel = this.$.worklistNewFilm.getSort();
      });

      this.$.techlistFilter.addEventListener("getFilterEvent", () => {
         this.filterModel = this.$.worklistNewFilm.getFilterOfStudy();
         this.$.techlistFilter.sortModel = this.$.worklistNewFilm.getSortOfStudy();
      });

      this.$.worklistFilter.addEventListener("stopWorkerEvent", () => {
         this.dispatchEvent(new CustomEvent("stopWorkerEvent"));
      });

      this.$.techlistFilter.addEventListener("stopWorkerEvent", () => {
         this.dispatchEvent(new CustomEvent("stopWorkerEvent"));
      });

      this.$.worklistFilter.addEventListener("reloadFilterEvent", (e) => {
         // this.$.worklistNewFilm.reloadFilter();
         // this.clearReportWindow(e);
      });

      this.$.techlistFilter.addEventListener("reloadFilterEvent", () => {
         // this.$.worklistNewFilm.studyReload();
         // this.clearTechTabWindow();
         this.$.gridOrder.reloadFilter();
      });

      // this.$.gridOrder.addEventListener("onSelectionOrderChangedEvent", (e) => {
      //    if(this._category === 1){
      //       if(!(e.detail.isMatch === undefined) && e.detail.isMatch === true){
      //          this.$.worklistNewFilm.selectedRowToMatched(e.detail);
      //       }
      //       else{
      //          this.$.worklistNewFilm.diabledSelectedRows();
      //       }
      //    }
      // });

      // #18495 [HPACS > 정혜두 원장님] 워크리스트에 새로운 검사가 올라오게 되면 Count 부분도 같이 업데이트
      // ag-grid infinite scroll로 인해 데이터가 꼬이는 현상이 있어 다음페이지 조회시 현재 count 체크하여 refresh
      this.$.worklistNewFilm.addEventListener("syncCountEvent", (e) => {
         const gridCount = +e.detail;
         const targetCount = this._category === 0 ? this.$.worklistCount.getCurrentAllCount() : this.$.techlistCount.getCurrentAllCount();

         if(gridCount !== targetCount) {
            if(this._category === 0) {
               this.$.worklistNewFilm.reloadFilter();
               this.clearReportWindow(e);
            } else {
               this.$.worklistNewFilm.studyReload();
               this.clearTechTabWindow();
               this.$.gridOrder.reloadFilter();
            }
         }
      });

      this.$.worklistNewFilm.addEventListener("downloadEvent", (e) => {
         this.dispatchEvent(new CustomEvent("downloadEvent", e));
      });

      this.$.worklistNewFilm.addEventListener("downloadedEvent", (e) => {
         this.dispatchEvent(new CustomEvent("downloadedEvent", e));
      });
      // this.$.worklistNewFilm.addEventListener("orderCountClearEvent", () => {
      //    this.$.gridOrder.orderCountClear();
      // });

      this.$.worklistNewFilm.addEventListener("openToastEvent",(e) => {
         this.openToast(e.detail.message);
         this.dispatchEvent(new CustomEvent("openToastEvent",e));
      });

      this.$.worklistNewFilm.addEventListener("clearWindowReportEvent",() => {
         this.clearReportWindow();
      });

      // this.$.worklistNewFilm.addEventListener("openMofieidExamDialogEvent",(e) => {
      //    this.dispatchEvent(new CustomEvent("openMofieidExamDialogEvent", e));
      // });

      // let flag1 = false;
      // this.$.newfilmOldfilm.addEventListener("iron-resize", () => {
      //    flag1 = true;
      // });

      // #17213 [HPACS] 테크니션이 Modify exam 하고 있는 검사를 판독의가 같은 검사를 열었을 경우 노출되는 팝업 메세지 UI 이슈
      const style = ":host { overflow: visible !important; }";
      const styleElem = document.createElement("style");
      styleElem.appendChild(document.createTextNode(style));
      this.$.newfilmOldfilm.shadowRoot.append(styleElem);

      this.$.newfilmOldfilm.addEventListener("splitter-dragend", () => {
         if(this._styleObj){
            if (this._styleObj.layout.splitNewFilmOldFilm !== this.$.newflim.offsetHeight) {
               this.updateSplitNewFilmOldFilm(this.$.newflim.offsetHeight);
            }
         }
      });

      this.$.gridOrder.addEventListener("openToastEvent",(e) => {
         this.dispatchEvent(new CustomEvent("openToastEvent",e));
      });


      // this.$.gridOrder.addEventListener("studyCountClearEvent",() => {
      //    this.$.worklistNewFilm.studyCountClearEvent();
      // });

      // this.$.gridOrder.addEventListener("selectedOrderRowEvent",(e) => {
      //    this.$.worklistNewFilm.selectedOrderRow(e.detail);
      // });

      // this.$.worklistNewFilm.addEventListener("reloadOrderGridEvent", () => {
      //    this.$.gridOrder.reloadFilter();
      // });


      this.$.worklistFilter.addEventListener("getDateToggleEvent", () => {
         this.dateFilter = this.$.worklistNewFilm.getActiveDateToggle();
      });

      this.$.techTabs.addEventListener("selected-changed", () => {
         this.changeTabIndex("techlistBottom", this.activeTechTab);
      });

      this.$.worklistNewFilm.addEventListener("selectUserFilterButtonEvent", (e) => {
         if(this._category === 0)
            this.$.worklistFilter.selectFilterButton(e.detail.id);
         if(this._category === 1)
            this.$.techlistFilter.selectFilterButton(e.detail.id);
      });


      const links = [ /* To adjust the width of FLeader's POPUP Menu */
         {id: "$worklistOldFilm", value: this.$.worklistOldFilm}
      ]; window.__element_refers__ = window.__element_refers__ ? [...window.__element_refers__, ...links] : links;

      window.worklistCountData = () => {
         const countData = {
            WAIT: this.$.worklistCount.WAIT,
            APPROVE: this.$.worklistCount.APPROVE,
            TRANSCRIBE: this.$.worklistCount.TRANSCRIBE,
            TEMP: this.$.worklistCount.TEMP,
            isShowCount: this.$.worklistCount.showCount
         };

         return countData;
      };

      window.addEventListener("navbarMenuItemClickReloadEvent", () => {
         this.menuItemClickReload();
      });

      this._ready = true;
   }

   setWorklistFilter(e = {}) {
      if(e.detail) {
         this._selectedStudies = [];
         this.clearReportWindow(e);
         if(e.detail.sortModel) {
            this.$.worklistFilter.sortModel = e.detail.sortModel;
         }
      }
   }

   setTechlistFilter(e = {}) {
      if(e.detail) {
         this.clearTechTabWindow();
         this._selectedStudies = [];
         this.$.worklistNewFilm.clearSelectedRowStudylist();
         if(e.detail.sortModel) {
            this.$.techlistFilter.sortModel = e.detail.sortModel;
         }
      }
   }

   onSelectRow(e) {
      if (this._category === 1) {
         // Order Tab
         if (!(e.detail.isMatch === undefined) && e.detail.isMatch === true) {
            this.$.gridOrder.selectedRowToMatched(e.detail);
         } else {
            this.$.gridOrder.diabledSelectedRows();
         }
      }
   }

   onActiveTabChange(tab) {
      if (this._category === 0) {
         this.changeTabIndex("worklistRelated", tab);
         this.getTabCodeRelStudyId();
      } else {
         this.changeTabIndex("techlistRelated", tab);
      }
   }

   onSelectCategory(_category) {
      if (this._ready) {
         this._selectedStudies = [];
         this.clearReportWindow();
         this.clearTechTabWindow();

         switch (_category) {
         case 0:
            this.changeRadiology();
            break;
         case 1:
            this.changeTechnician();
            break;
         default:
         }
         dispatchEvent(new CustomEvent("stopWorkerEvent", {bubbles: true, composed: true }));
         this.changeTabIndex("worklistNewFilm", _category);
      }
   }

   changeTabIndex(tabIndex, selected = 0) {
      if(!tabIndex) return;
      let styleObj = {};
      this.getUserStyle().then((result) => {
         if(result){
            styleObj = result;
            if(!styleObj.tabIndex) styleObj.tabIndex = {};
            styleObj.tabIndex[tabIndex] = selected;
            this.updateWorkListTabIndex(styleObj.id, styleObj.tabIndex);
         }
      });
   }

   setStyle(result) {
      this._styleObj = result;
      // Layout
      const oldHeight = parseInt(result.layout.splitNewFilmOldFilm, 10) + 10;
      this.$.newflim.style.height = `${result.layout.splitNewFilmOldFilm}px`;
      this.$.oldfilm.style.height = `calc(100% - ${oldHeight}px)`;
      // Tab Index
      const { worklistNewFilm, worklistRelated, techlistBottom, techlistRelated } = result.tabIndex;
      this.$.worklistNewFilm.setTabIndex(worklistNewFilm);
      this.activeTechTab = techlistBottom || 0;

      // grid-oldfilm은 같은 컴포넌트 이기 때문에 setTabIndex가 같이 적용되기 때문에 category별로 분기처리함
      if(this._category === 0) {
         this.$.worklistOldFilm.setTabIndex(worklistRelated);
      } else {
         this.$.techOldFilm.setTabIndex(techlistRelated);
      }
   }

   updateSplitNewFilmOldFilm(newFlimHeight) {
      if (this._styleObj.layout.splitNewFilmOldFilm === newFlimHeight) {
      } else {
         this.getUserStyle().then((result) => {
            if(result) {
               const userStyle = result;

               if(!userStyle.layout) userStyle.layout = this.getDefaultLayout();
               else userStyle.layout = Object.assign({}, this.getDefaultLayout(), userStyle.layout);

               this._styleObj = userStyle;
               this._styleObj.layout.splitNewFilmOldFilm = newFlimHeight;
               const {splitNewFilmOldFilm} = this._styleObj.layout;
               this.updateNewfilmOldfilmStyle({splitNewFilmOldFilm});
            }
            // else {
            //    this._styleObj.layout.splitNewFilmOldFilm = newFlimHeight;
            //    this.createStyle();
            // }
         }).catch((err) => {
            console.error(err);
         });
      }
   };

   getDefaultLayout() {
      const layout = {};
      layout.splitNewFilmOldFilm = "839";
      layout.splitFilmOpinion = "470";
      layout.splitClinicalReport = "140";
      // layout.splitFindingConclusion = "271";
      // layout.splitConclusionRecommend = "104";
      layout.splitBodyTop = "300";
      layout.splitBodyTopRight = "500";
      layout.splitBodyTopRightFirst = "200";
      layout.splitBodyBottomLeft = "330";

      layout.isIndividualScroll = false;
      layout.isReportScroll = false;
      layout.splitFindings = null;
      layout.splitConclusion = null;

      layout.splitFilmOpinionTech = "470";
      layout.splitBodyTopTech = "300";

      return layout;
   }

   getDefaultTabIndex() {
      const tabIndex = {};
      tabIndex.worklistNewFilm = 0;
      tabIndex.hhReportWindowExpand = 0;
      tabIndex.hhReportWindowNonExpand = 0;
      tabIndex.worklistRelated = 0;
      tabIndex.techlistBottom = 0;
      tabIndex.techlistRelated = 0;
      return tabIndex;
   }

   clearReportWindow(event = {}){
      // window.postMessage({popupClose: true}, document.location.href);
      // clear ag-grid row
      this.$.worklistOldFilm.clearOldfilm();
      this.$.worklistNewFilm.clearSelectedRow();

   }

   clearTechTabWindow(){
      // this.dispatchEvent(new CustomEvent("clearTechTabClinicalInfoEvent"));
      // this.dispatchEvent(new CustomEvent("clearTechTabThumbnailEvent"));
      // this.dispatchEvent(new CustomEvent("clearTechTabFunctionEvent"));
      // this.dispatchEvent(new CustomEvent("clearTechTabReportEvent"));
      // this.$.techOldFilm.clearOldfilm();
   }

   startPrefetchMark(){
      this.$.worklistNewFilm.startPrefetchMark();
   }

   completedPrefetchMark(message) {
      if(message.target === "oldFilm") {
         // this.$.worklistOldFilm.batchCompletedPrefetchMark(message.data);
      } else {
         this.$.worklistNewFilm.completedPrefetchMark(message.data);
         // this.$.worklistOldFilm.completedPrefetchMark(message.data);
      }
   }

   prefetcStatushMark(message) {
      if(message.target === "newFilm") {
         this.$.worklistNewFilm.prefetcStatushMark(message);
      }
   }

   hideOverlay() {
      this.$.worklistNewFilm.hideOverlay();
      this.$.worklistOldFilm.hideOverlay();
   }

   /**
    * Approve Opinion Reulst
    * @param result {result, approve, save}
    */
   // approveOpinionisResult(result) {
   //    if (result) {
   //       const {ids} = result.result;
   //       this.getSelectedRowsCaseInfo(ids).then( (res) => {
   //          // #16299 related 에서 판독했을 경우 결과값을 new, related에 같이 전달
   //          this.$.worklistNewFilm.approveOpinionisResult(res);
   //          this.$.worklistOldFilm.approveOpinionisResult(res);
   //
   //          window.postMessage({ event: "POPUP_CHANGE_WORKLIST_COUNT" }, document.location.href);
   //       });
   //    }
   // }

   getSelectedRowsCaseInfo(ids) {
      return new Promise((resolve, reject) => {
         fetch(`/api/case/list?ids=${ids.join(",")}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   };

   refreshCellByCVR(id) {
      this.$.worklistNewFilm.refreshCellByCVR(id);
   }

   updateWorklistCounter(){
      Promise.resolve()
        .then(() => this.$.worklistCount.getWorklistCount())
        .then(r => this.$.worklistCount.setCount(r));
   }

   // createUserStyle(layout) {
   //    return new Promise((resolve, reject) => {
   //       const param = { request : layout };
   //       fetch(`/api/user/option/layout`, {
   //          method: "POST",
   //          headers: {
   //             "Authorization": localStorage.getItem("jwt"),
   //             "Content-Type": "application/json"
   //          },
   //          body: JSON.stringify(param.request)
   //       }).then((response) => {
   //          if (!response.ok) {
   //             console.log("Layout Save Failed");
   //             reject(new Error(`${response.status} ${response.statusText}`));
   //          }
   //          else {
   //             console.log("Layout Save Success");
   //             response.json().then((rows) => {
   //                if (rows) resolve(rows);
   //             });
   //          }
   //       });
   //    });
   // }

   getUserStyle() {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/style`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   updateWorkListTabIndex(id, tabIndex) {
      const param = { request : tabIndex };
      fetch(`/api/user/option/tabIndex/${id}`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(param.request)
      }).then((response) => {
         if (!response.ok) {
            console.debug(`${response.status} : Response Update TabIndex Failed`);
         }
      });
   }

   updateNewfilmOldfilmStyle(layout) {
      fetch(`/api/user/option/style/layout`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(layout)
      }).then((response) => {
         if (!response.ok) {
            console.debug(new Error(`${response.status} ${response.statusText}`));
         }
      });
   }

   displayPrev() {
      this.$.worklistNewFilm.displayPrev();
   }

   getRelatedSyncOpenStatus() {
      return this.$.worklistOldFilm.relatedSyncOpenStatus.checked;
   }

   displayNext() {
      this.$.worklistNewFilm.displayNext();
   }

   displayPageUp() {
      // this.$.worklistNewFilm.displayPageUp();
   }

   displayPageDown() {
      // this.$.worklistNewFilm.displayPageDown();
   }

   openKeyImageEvent(param) {
      this.$.worklistNewFilm.openKeyImageEvent(param);
   }

   changeRadiology(){
      this.$.worklistFilter.style.display = "block";
      this.$.techlistFilter.style.display = "none";
      this.$.worklistCount.style.display = "block";
      this.$.worklistOldFilm.style.display = "block";
      this.$.worklistCount.style.display = "block";
      this.$.techlistOldFilm.style.display = "none";
      this.$.techlistCount.style.display = "none";
   }

   changeTechnician(){
      this.$.divFilter.style.backgroundColor = "#252934";
      this.$.worklistFilter.style.display = "none";
      this.$.techlistFilter.style.display = "block";
      this.$.worklistCount.style.display = "none";
      this.$.worklistOldFilm.style.display = "none";
      this.$.worklistCount.style.display = "none";
      this.$.techlistOldFilm.style.display = "block";
      this.$.techlistCount.style.display = "block";
   }

   openToast(message){
      if (!message.isErr) {
         this.$.toastContent.style.backgroundColor = "#0087cb";
      } else {
         this.$.toastContent.style.backgroundColor = "#e46159";
      }
      this.$.toastContent.text = message.text;
      this.$.toastContent.open();
      // if(message.isErr) {
      //    return false;
      // }
   }

   menuItemClickReload() {
      if(this._category === 0) this.$.worklistFilter.reloadFilter();
      if(this._category === 1) this.$.techlistFilter.reloadFilter();
   }

   setSyncTabIndex(index) {
      this.$.worklistOldFilm.selected = index;
   }


   // subWaitCount(count = 0) {
   //    this.$.worklistCount.WAIT -= count;
   // }
   //
   // subTempCount(count = 0) {
   //    this.$.worklistCount.TEMP -= count;
   // }
   //
   // subTranscribeCount(count = 0) {
   //    this.$.worklistCount.TRANSCRIBE -= count;
   // }

   setTechWidth() {
      this.$.worklistNewFilm.onIronResize();
      this.$.techlistFilter.onIronResize();
   }

   sinkViewing(request) {
      this.$.worklistNewFilm.sinkViewing(request);
   }

   sinkVerify(requestList) {
      this.$.worklistNewFilm.sinkVerify(requestList);
   }

   sinkApprove(requestList) {
      this.$.worklistNewFilm.sinkApprove(requestList);
   }

   sinkAiState(aiAppProgress) {
      this.$.worklistNewFilm.sinkAiState(aiAppProgress);
   }

   sinkTeleRequest(params) {
      this.$.worklistNewFilm.sinkTeleRequest(params);
   }

   sinkTeleStatusVerify(request) {
      this.$.worklistNewFilm.sinkTeleStatusVerify(request);
   }

   getOldStudyIds(studyId, related) {
      let url = `/api/exchange/worklist/struct/filmbox/ids/${studyId}`;
      if(related) url += `?related=${related}`;
      return fetch(url, {
         method: "GET",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
      }).then((response) => {
         if (response.ok && response.status === 200) {
            return response.json();
         }

         return Promise.reject(new Error("getStructFilmbox fail"));
      });
   }

   getTabCodeRelStudyId() {
      if(CommonUtils.isEmptyArr(this._selectedStudies)) return;

      const {id} = this._selectedStudies[this._selectedStudies.length-1];

      this.getOldStudyIds(id, FilmboxUtils.convertRelatedTabCode(this.activeTabCode)).then((relIds) => {
         window.postMessage({event: "CHANGE_RELATED_TAB", relIds}, document.location.href);
      });
   }
}

window.customElements.define("worklist-content", WorklistContent);
