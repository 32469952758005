import React from "react";

function FLeader() {
   return (
      <React.Fragment>
         <link rel="stylesheet" href="/resource/style/shortcut.css"/>
         <style>{`
         #snackbar {
            visibility: hidden;
            z-index: 1;
         }

         #snackbar.show {
            visibility: visible;
            -webkit-animation: fadein 0.2s;
            animation: fadein 0.2s;
         }

         .class-advance{
            display: none;
         }

         .input-shortcut {
            background-color: transparent!important;
            width: calc(100% - 10px);
            border-width:0px;
            border:none;
            border-color: inherit;
            -webkit-box-shadow: none;
            box-shadow: none;
         }

         .input-shortcut:focus{
            outline: none;
         }
         `}</style>
         <div id="snackbar" className="shortcut">
            <div className="class-basic" id="basicMode">
               <div className="class-functionlist shortcut-scroll active" id="functionList"></div>
               <input id="inputShortcut" className="input-shortcut" style={
                  { display: "none" }
               } />
               <div className="shortcut-spc" id="displayInputSCP">
                        SPC
               </div>
            </div>
            <div className="class-advance" id="advancedMode">
               <input id="searchKeywords" className="input-shortcut" />
               <div id="searchResult" style={
                  { overflow: "auto" }
               } />
            </div>
         </div>
         <div id="ToastBox" className="shortcut" style={
            { display: "none", zIndex: "100", outline: "none" }
         }>
            <input id="displayKey" className="input-shortcut shortcut-spc" disabled />
         </div>
      </React.Fragment>
   );
}

export default FLeader;
