/* eslint-disable no-unused-vars */
import moment from "moment-timezone";
import i18next from "i18next";

class DateFilterUtils {
   constructor() {
      if (DateFilterUtils._instance) return DateFilterUtils._instance;

      this.dateFilterUnit = [
         {key: "days", value: i18next.t("label.dateFilter.days")},
         {key: "weeks", value: i18next.t("label.dateFilter.weeks")},
         {key: "months", value: i18next.t("label.dateFilter.months")},
         {key: "years", value: i18next.t("label.dateFilter.years")}
      ];

      this.relativeDateFilterOption = {
         displayKey: "inRelativeRange",
         displayName: i18next.t("label.dateFilter.inRelativeRange"),
         test(filterValue, cellValue) { return true; },
         hideFilterInput: true
      };

      DateFilterUtils._instance = this;
   }

   /**
    * Setting date filter component
    * * relative date filter show/hide
    * * relative date filter init value
    * @param floatingFilter
    * @param filterInstance
    * @param initModel
    */
   setDateFilterComponent(floatingFilter, filterInstance, initModel) {
      if(!floatingFilter || !filterInstance) return;

      // const customFilter = floatingFilter.querySelector(".relative-date-filter-body");
      // const customButton = floatingFilter.querySelector(".relative-date-filter-button");
      // const applyButton = floatingFilter.querySelector(".ag-filter-apply-panel-button");
      const els = [floatingFilter.querySelector(".relative-date-filter-body"), ...floatingFilter.querySelectorAll(".ag-filter-apply-panel-button")];
      if(els.filter(el => el).length > 0) {
         const model = filterInstance.getModelFromUi();
         const isRelativeRange = model && model.filterType === "date" && model.type === "inRelativeRange";

         if(isRelativeRange) {
            els.forEach((el) => {
               if(el.classList.contains("relative-date-filter-body") || el.classList.contains("relative-date-filter-button")) {
                  // custom clear button 제외, date filter 에는 custom clear button 항상 표시 (#14734)
                  if(el.id !== "relativeDateFilterClearButton" && el.classList.contains("ag-hidden")) el.classList.remove("ag-hidden");
               } else if(!el.classList.contains("ag-hidden")) {
                  el.classList.add("ag-hidden");
               }
            });
            if(initModel) {
               const relativeDateFilterInput = floatingFilter.querySelector(".relative-date-filter-input");
               const relativeDateFilterList = floatingFilter.querySelector(".relative-date-filter-list");
               if(relativeDateFilterInput && relativeDateFilterList) {
                  relativeDateFilterInput.setAttribute("value", initModel.amount);
                  relativeDateFilterList.setAttribute("selected", initModel.unit);
               }
            }
         } else {
            els.forEach((el) => {
               if(el.classList.contains("relative-date-filter-body") || el.classList.contains("relative-date-filter-button")) {
                  // custom clear button 제외, date filter 에는 custom clear button 항상 표시 (#14734)
                  if(el.id !== "relativeDateFilterClearButton" && !el.classList.contains("ag-hidden")) el.classList.add("ag-hidden");
               } else if(el.classList.contains("ag-hidden")) {
                  el.classList.remove("ag-hidden");
               }
            });
         }
      }
   }

   /**
    * Apply Relative Date Filter
    * @param floatingFilter - filter popup
    * @param columnId - 적용할 columnId
    * @param filterModel - grid 에 적용된 filterModel
    * @param fnSetFilter - function setFilter
    */
   applyRelativeDateFilter(floatingFilter, columnId, filterModel, fnSetFilter) {
      if(floatingFilter && filterModel) {
         const relativeDateFilterInput = floatingFilter.querySelector(".relative-date-filter-input");
         const relativeDateFilterList = floatingFilter.querySelector(".relative-date-filter-list");
         const amount = relativeDateFilterInput.value;
         const unit = relativeDateFilterList.selected;
         if (!amount || !unit) return;

         // set filterModel
         // eslint-disable-next-line no-param-reassign
         filterModel[columnId] = this.getDateFilterModel(amount, unit);
         fnSetFilter(filterModel);

         // popup 닫기
         if(floatingFilter) {
            floatingFilter.setAttribute("hidden", true);
         }
      }
   }

   /**
    * Clear Relative Date Filter
    * @param floatingFilter - filter popup
    * @param columnId - 적용할 columnId
    * @param filterModel - grid 에 적용된 filterModel
    * @param fnSetFilter - function setFilter
    */
   clearRelativeDateFilter(floatingFilter, columnId, filterModel, fnSetFilter) {
      if(floatingFilter && filterModel) {
         // set filterModel
         // eslint-disable-next-line no-param-reassign
         delete filterModel[columnId];
         fnSetFilter(filterModel);

         // popup 닫기
         if(floatingFilter) {
            floatingFilter.setAttribute("hidden", true);
         }
      }
   }

   /**
    * Render Relative Date Filter
    * @param floatingFilter - filter popup element
    * @param fnApply - apply function
    * @param fnCallback - callback function
    */
   renderRelativeDateFilter(floatingFilter, fnApply, fnClear, fnCallback) {
      const filterBody = floatingFilter.querySelector(".ag-filter-body");
      const filterButton = floatingFilter.querySelector(".ag-filter-apply-panel");
      if(filterBody && filterButton) {
         // body
         const div = document.createElement("div");
         div.setAttribute("id", "relativeDateFilter");
         div.setAttribute("class", "relative-date-filter-body ag-hidden");

         const input = document.createElement("paper-input");
         input.setAttribute("id", "relativeDateFilterInput");
         input.setAttribute("class", "relative-date-filter-input");
         input.setAttribute("label", "During past");
         input.setAttribute("type", "number");
         input.setAttribute("min", "1");
         input.setAttribute("value", "1");
         input.setAttribute("always-float-label", true);
         input.style.width = "80px";
         input.style.marginLeft = "5px";

         div.appendChild(input);

         const dropdown = document.createElement("paper-dropdown-menu");
         dropdown.setAttribute("class", "relative-date-filter-menu");
         dropdown.style.width = "100px";
         dropdown.style.marginRight = "5px";

         const listbox = document.createElement("paper-listbox");
         listbox.setAttribute("class", "relative-date-filter-list");
         listbox.setAttribute("slot", "dropdown-content");
         listbox.setAttribute("attr-for-selected", "data-value");
         listbox.setAttribute("selected", "months");

         this.dateFilterUnit.forEach((o) => {
            const item = document.createElement("paper-item");
            item.setAttribute("id", o.key);
            item.setAttribute("data-value", o.key);
            item.setAttribute("value", o.key);
            item.innerText = o.value;
            listbox.appendChild(item);
         });

         dropdown.appendChild(listbox);
         div.appendChild(dropdown);

         filterBody.appendChild(div);

         // button
         const clearButton = document.createElement("button");
         clearButton.setAttribute("type", "button");
         clearButton.setAttribute("id", "relativeDateFilterClearButton");
         clearButton.setAttribute("class", "ag-standard-button ag-filter-apply-panel-button relative-date-filter-button"); // ag-hidden, custom clear button 항상 표시 (#14734)
         clearButton.innerText = i18next.t("label.dateFilter.clear");
         clearButton.addEventListener("click", (e) => {
            fnClear();
         });
         //
         filterButton.prepend(clearButton);

         const applyButton = document.createElement("button");
         applyButton.setAttribute("type", "button");
         applyButton.setAttribute("id", "relativeDateFilterApplyButton");
         applyButton.setAttribute("class", "ag-standard-button ag-filter-apply-panel-button relative-date-filter-button ag-hidden");
         applyButton.innerText = i18next.t("label.dateFilter.apply");
         applyButton.addEventListener("click", (e) => {
            fnApply();
         });

         filterButton.appendChild(applyButton);

         fnCallback();
      }
   }

   /**
    * Get DateFilterModel
    * @param amount
    * @param unit
    * @returns {{}}
    */
   getDateFilterModel(amount, unit) {
      if (!amount || !unit) return;

      const dateFilter = {};
      if(Number(amount) === 1 && unit === "days") {
         dateFilter.dateFrom = this.getToday();
         dateFilter.dateTo = this.getToday();
         dateFilter.type = "equals";
         dateFilter.filterType = "date";
         dateFilter.isRelative = true;
      } else {
         dateFilter.dateFrom = this.getRelativeDate(amount, unit);
         dateFilter.dateTo = this.getToday();
         dateFilter.type = "inRelativeRange";
         dateFilter.filterType = "date";
         dateFilter.amount = amount;
         dateFilter.unit = unit;
         dateFilter.isRelative = true;
      }

      return dateFilter;
   }

   /**
    * Get Relative Date
    * @param amount
    * @param unit
    * @returns {string}
    */
   getRelativeDate(amount, unit) {
      return moment().subtract(amount, unit).add(1, "days").format("YYYY-MM-DD");
   }

   /**
    * Get Today
    * @returns {string}
    */
   getToday() {
      return moment().format("YYYY-MM-DD");
   }
}

const dateFilterUtils = new DateFilterUtils();
Object.freeze(dateFilterUtils);

export default dateFilterUtils;
