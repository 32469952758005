/* eslint-disable     import/extensions */
import {isUndefined, isFunction, tern, Noop, if_then_else, arrayfy} from "./FP.mjs";

/**
 * ① 전달된 인수 가운데 undefined 값이 있으면 실행을 정지한다
 * ② 상태전이 룰을 해석해서 Map->Map 테이블을 만든다
 * ③ 초기상태에 대한 정보가 없으면 실행을 정지한다
 * ④ 인스턴스 값을 초기화 한다
 */
export default class Pathway {
   constructor(/* 상태테이블의 이름(디버깅용도) */title, initial_state, /* 콜백호출이 공유할 변수 */ctx={}, rules=[], UncaughtHandler=Noop) {

      [title, initial_state, rules].filter(isUndefined).forEach(() => {
         throw new Error(`CRITICAL: missing argument(s) in "new Pathway(title, initial_state, context, rules)`);
      });

      this.title = title;
      this.previous_state = initial_state;
      this.current_state = initial_state;
      this.ctx = ctx;
      this.rules = rules;
      this.states =  new Map(); // 전체 룰을 가지는 Map 객체
      this.__uncaughthandler = UncaughtHandler;

      this.parseRules(rules);

      // 현재 상태를 가지는 Map 객체
      this.state =  this.states.get(this.current_state);

      if (!this.state) {
         throw new Error(`${title}: 초기상태 '${initial_state}'에 대한 정보가 주어지지 않습니다.\n\n진행할 수 없습니다`);
      }
   }// constructor

   parseRules (rules=[]) {

      // 'to'에만 설정되고 'from'에는 없는 Rule을 걸러내기위해 Set 객체를 준비한다
      const checks = new Set();

      // rules = [[[Symbol,Symbol...], "from", "to", function(){}],[[Symbol],"from", "to", callback],...]
      rules.forEach(([_trigger, from, to, _cb]) => {

         if(_trigger.length === 0) {
            throw new Error(`PANIC: trigger is empty at index #{index} (from:${from}, to:${to}, cb:{cb})`);
         }

         // triggger, from, cb 등을 Array 타입으로 지정
         const [triggers, froms, cb] = [_trigger, from, _cb].map(arrayfy);

         froms.forEach((from) => {
            // get existing state by from, or create new one
            const _st = tern(() => this.states.has(from), () => this.states.get(from), () => new Map());

            // state[trigger] = RuleObject({from, to, cb});
            triggers.forEach(key => _st.set(key, {from, to, cb}));

            // set new-state onto each state '_st'
            this.states.set(from, _st);
         });

         // 룰이 하나씩 처리될 때 마다 'to'로 설정된 상태값을 점검대상으로 넣는다
         checks.add(to);
      });

      // 'to'로 설정된 상태가 'from' 상태에 등록되어 있는지 검사
      const not_founds = [...checks].filter( state => !this.states.get(state));
      if (not_founds.length) {
         throw new Error(`유효성검사 실패: 다음 상태이름에 대한 정보가 테이블에 없습니다 <${not_founds.join(", ")}> -- 'from/to' 항목에서 이름을 점검해주세요`);
      }

      // console.log(`Total states -- ${this.states.size}`);

      // make chaining
      return this;
   }

   // 하나의 룰을 더하거나 업데이트
   setOneRule(key, {from, to, cb}, force=true) {
      const set = () => [true, this.state.set(key,{from, to, cb})];
      return if_then_else(
         () => force,
         set,
         if_then_else(
            () => this.state.has(key),
            set,
            () => [false, "no such key"]
         )
      );
   }

   /**
    * ① "현재상태객체" 에서 등록된 'trigger' 키에 등록된 룰이 있는지 검사하고, 없으면 그냥 리턴
    * ② 룰을 얻어오고 상태전이
    * ③ 콜백호출
    */
   emit(trigger, ...args) {
      if (!this.state.has(trigger)) {

         // 하위 클래스에서 찾지 못한 상태에대해 처리해야 한다
         return [false, []];
      }

      // TODO: 이 위치에서 this.pushOnKeyStack(trigger.getKey()) 호출이 이뤄져야 한다.

      // 현재 상태에 해당 trigger가 등록되어 있다면, Rule 정보를 얻어옴
      const rule = this.state.get(trigger);

      this.previous_state = this.current_state;
      this.current_state = rule.to;

      // 현재 상태를 가지는 Map객체 업데이트
      this.state = this.states.get(this.current_state);

      this.onChangeState();

      // TODO: DEBUG 플래그에 따라 표시하도록
      if(window && window.__debug__) console.warn(` ↳ ${this.previous_state}(`, trigger, `) --> ${this.current_state}︎`);

      /* 주의: 상태정보가 변경된 뒤에 등록된 콜백이 실행되므로 무한루프에 빠지지 않도록 주의해야함! (예: setTimeout(()=> this.emit(__signal__)}, 0) */
      return [true, rule.cb.map(f => f(this.ctx, ...args))];
   }

   // make 'Pathway' iterable
   *[Symbol.iterator]() {
      yield *this.states.keys();
   }

   // prepare inheritance
   static get [Symbol.species]() {
      return this;
   }

   // 등록된 고유한 상태의 갯수
   get length () {
      return this.states.size;
   }

   get UncaughtHandler () {
      return () => this.__uncaughthandler;
   }

   set UncaughtHandler (cb) {
      this.__uncaughthandler = isFunction(cb) ? cb : Noop ;
      return this.__uncaughthandler;
   }

   // prepare inheritance
   clone(...args) {
      return new this.constructor[Symbol.species](...args);
   }

   // constructor() 호출 이후 상태를 추가할 때 사용하는 함수
   add (rules) {
      this.parseRules(rules);

      // TODO: DEBUG flag에 따라 표시할 것
      // console.log(`Added ${rules.length} rule(s)`);

      return this.states;
   }

   // 상태전이 규칙에 기술할 수 없어 상태를 강제로 전이시킬 필요가 있을 때 사용.
   forceTransitionTo(to) {
      const state = this.states.get(to);
      if(!state) throw new Error(`전이대상 상태정보 '${to}'가 존재하지 않습니다`);
      return Object.assign(this, {previous_state:this.current_state, current_state: to, state});
   }

   onChangeState() {
      // 상태가 바뀐 직후에 처리
   }
}

