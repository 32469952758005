/* eslint-disable no-unused-vars */
import { PolymerElement, html } from "@polymer/polymer/polymer-element";

import "@polymer/paper-dropdown-menu/paper-dropdown-menu";
import "@polymer/paper-item/paper-item";
import "@polymer/paper-listbox/paper-listbox";
import "@vaadin/vaadin-context-menu/vaadin-context-menu";
import "@vaadin/vaadin-list-box/vaadin-list-box";
import "@vaadin/vaadin-item/vaadin-item";
import "@vaadin/vaadin-combo-box/vaadin-combo-box";

import "@polymer/paper-dialog";
import "@polymer/paper-icon-button";

import "../components/healthhub-confirm-dialog";
import { is_esc } from "./common-keyboard-handler";
import CommonUtils from "../../public/resource/js/utils/common";

import mixinCommons from "../common-mixin";
import store from "../redux/store";
import {CommonActionType} from "../redux/reducers/common";

class TechtabVerifyDialog extends mixinCommons(PolymerElement) {
   static get is() {
      return "techtab-verify-dialog";
   }

   static get template() {
      return html`
      <style>
         paper-icon-button {
            width: 50px;
            height: 50px;
            float: right;
            color: #aaaaaa;
         }

         paper-dropdown-menu, paper-listbox {
            --paper-input-container-focus-color: #0087cb;
            --paper-input-container-label: {
               color: #aaaaaa;
            };
            --paper-input-container-input: {
               text-align: center;
               color: #aaaaaa;
            };

            width: 140px;
            color: #aaaaaa;
            /*background: #252934;*/
            background: #2d333f;
         }

         paper-item {
            --paper-item-min-height: 30px;
            --paper-item-focused: {
               color: #0087cb;
            };
            font-size: 14px;
         }

         #okBtn {
            background: #0087cb;
         }

         #cancelBtn {
            background: #4c5667;
         }

         .btn {
            width: 122px;
            height: 35px;
            border: 0;
            font-size: 12pt;
            color: #ffffff;
            cursor: pointer;
            border-radius: 2px;
         }

         .classA {
            text-align: center;
            padding: 15px;
         }

         /* dialog size background */
         #techtabVerify {
            background: #2d333f;
            width: 400px;
            height: 205px;
         }

         .container{
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
         }

         .verify-title {
            background-color: #252934;
            height: 50px;
            width: 100%;
            color: #fff;
            border-bottom: 1px solid #4c5667;
         }

         .verify-container {
            width: 100%;
            height: 100%;
         }

         .verify-doctor{
            display: flex;
            flex-direction: row;
            margin: 15px;
            padding-left: 25px;
         }
      </style>
      <paper-dialog id="techtabVerify" on-click="prevent_event_propagation" on-keydown="close_if_esc" on-keypress="prevent_event_propagation" on-keyup="prevent_event_propagation" on-focus="focus_on_selected_button" on-iron-overlay-opened="dialog_opened" on-iron-overlay-closed="dialog_closed">
         <div class="container">
            <div class="verify-title">
               <p style="margin: 0; padding: 15px; float: left;">{{t("label.verify")}}</p>
               <paper-icon-button on-click="doClose" icon="healthhub:close"></paper-icon-button>
            </div>
            <div class="verify-container">
               <div class="verify-doctor">
                  <div style="font-size: 15px; color: #aaaaaa; margin-top: 10px; white-space:nowrap;">{{t("label.assignee")}} : </div>
                  <vaadin-combo-box id="doctorList" class="comboboxMenu-container" placeholder={{t("label.verifyDialog.placeholder.assignee")}} allow-custom-value theme="small" autofocus ></vaadin-combo-box>
               </div>
               <div class="classA">
                  <button id="okBtn" on-click="doOk" class="btn">{{t("button.verify")}}</button>
                  &nbsp;
                  <button id="cancelBtn" on-click="doClose" class="btn">{{t("button.cancel")}}</button>
               </div>
            </div>
         </div>
      </paper-dialog>
<!--      <hh-confirm-dialog id="hhTechtabVerifyConfirmDialog"></hh-confirm-dialog>-->
      `;
   }

   static get properties() {
      return {
         _selectedDoctors: {
            type: Object,
            value: {}
         },
         _selectedRows: {
            type: Object,
            value: {}
         },
         doctors: {
            type: Array,
            value: []
         },
         message: {
            type: Object
         }
      };
   }

   ready() {
      super.ready();
   }

   close_if_esc(e) {
      e.stopPropagation();
      if(is_esc(e)) {
         this.doClose();
      }
   }

   prevent_event_propagation(e) {
      e.stopPropagation();
   }

   dialog_opened() {
      window.dialog_opened({dialog:this.$.techtabVerify, is_modal:true});
   }

   dialog_closed() {
      window.dialog_closed();
   }

   doOpen(message) {
      if (message.selectedRows) {
         this.setSelectedRows(message.selectedRows);
      }
      this.message = message;
      this.$.techtabVerify.open();
      this.getDoctorlist();
   }

   doClear() {
      this.$.doctorList.selectedItem = "";
      if(this.$.doctorList) this.$.doctorList.selected = -1;
   }

   doClose() {
      this.doClear();
      this.$.techtabVerify.close();
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   doOk() {
      const verifyInfo = {};
      // const sDocName = this.$.doctorList.selectedItem.label;
      if(this.$.doctorList.selectedItem) {
         const sDocId = this.$.doctorList.selectedItem.value;
         verifyInfo.userId = sDocId;
      }
      // this.doctors.forEach((doctor) => {
      //   if(`${doctor.name}(${doctor.email})` === sDocName && doctor.id === sDocId) {
      //      this._selectedDoctors = doctor;
      //      verifyInfo.userId = doctor.id;
      //      verifyInfo.username = doctor.name;
      //   }
      // });

      // #16815 [HPACS] 판독의를 지정하지 않고 verify할 경우 오류 메시지가 뜸
      // if(CommonUtils.isEmptyObject(verifyInfo)) {
      //    this.openToast({msg: "Please select one in the list.", isErr: true});
      //    return;
      // }

      const params = { verifyInfo };
      const rowsId = this._selectedRows.map(row => row.id);
      this.setVerifyStudylist(rowsId, params).then((result) => {
         if (this.message.callback) this.message.callback(result);
         // if (result) this.dispatchEvent(new CustomEvent("verifyEvent", { detail : result }));
      });

      this.doClear();
      this.$.techtabVerify.close();
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   setSelectedRows(selectedRows) {
      this._selectedRows = selectedRows;
   }

   getDoctorlist(){
      const user = JSON.parse(localStorage.getItem("user"));
      this.getFacilityListByUserEmail(user.email).then((result) => {
         if(result) {

            // 로그인 사용자가 판독의사 목록에 있는지 찾는다.
            // eslint-disable-next-line no-return-assign,no-param-reassign
            const loginDoctorIndex = result.findIndex(doctor => doctor.email === user.email);

            // 판독의사 목록에서 찾으면 로그인 사용자를 맨 앞으로 정렬시킨다.
            if (loginDoctorIndex !== -1) {
               result.unshift(result.splice(loginDoctorIndex, 1)[0]);
            }

            this.doctors = result;
            this.setDoctors();
         }
      });
   }

   setDoctors() {
      this.$.doctorList.items = this.doctors.map((doctor) => {
         return {label : `${doctor.name}(${doctor.email})`, value : doctor.id};
      });
      this.$.doctorList.inputElement.style.fontSize = "15px";
      this.$.doctorList.inputElement.style.color = "#aaaaaa";
      this.$.doctorList.inputElement.className = "doctorItem";
      this.$.doctorList.opened = true;
      this.$.doctorList.inputElement.style.width = "320px";

      // const verifyDoctors = this._selectedRows.map(row => `${row.verify}(${row.verifyEmail})`);
      const verifyDoctorsName = this._selectedRows.map(row => row.verify);
      const verifyDoctorsEmail = this._selectedRows.map(row => row.verifyEmail);
      const verifyDoctorsId = this._selectedRows.map(row => row.verifyInfo?.userId);
      // if(verifyDoctorsName && verifyDoctorsName.length === 1) {
      if(verifyDoctorsName && verifyDoctorsName.length === 1 && verifyDoctorsEmail && verifyDoctorsEmail.length === 1 && verifyDoctorsId && verifyDoctorsId.length === 1) {
         const [verifyDoctorName] = verifyDoctorsName;
         const [verifyDoctorEmail] = verifyDoctorsEmail;
         const [verifyDoctorId] = verifyDoctorsId;
         // if(verifyDoctor) this.$.doctorList.selectedItem = verifyDoctor;
         if(verifyDoctorName && verifyDoctorEmail && verifyDoctorId) {
            this.$.doctorList.selectedItem = {label: `${verifyDoctorName}(${verifyDoctorEmail})`, value: verifyDoctorId};
         }
      }
   }

   setVerifyStudylist(rowsId, params) {
      return new Promise((resolve, reject) => {
         fetch(`/api/verify/case/${rowsId}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(params.verifyInfo)
         }).then((response) => {
            if(response.ok) {
               resolve(true);
            } else {
               console.log("TechTab verify studylist failed!");
               resolve(false);
            }
         }).catch(err => reject(err));
      });
   }

   getFacilityListByUserEmail(email) {
      const cases = this._selectedRows.map(row => row.id);

      // ObjectId("5bbdd16c919f5ac1bf70e34f")
      // 휴먼 의뢰 facility id
      return new Promise((resolve, reject) => {
         fetch(`/api/facility/email/${email}/doctors`, {
            method: "POST",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(cases)
         }).then((response) => {
            if(response.ok) {
               if(response.status === 200) {
                  response.json().then((json) => {
                     resolve(json);
                  });
               } else {
                  resolve([]);
               }
            } else {
               console.log("Your doctor list is empty.");
               resolve(null);
            }
         }).catch(err => reject(err));
      });
   }

   openToast(detail) {
      this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
   }
}

window.customElements.define(TechtabVerifyDialog.is, TechtabVerifyDialog);
