/* eslint-disable no-prototype-builtins,no-lonely-if,no-restricted-syntax,no-param-reassign */
/* eslint-disable prefer-promise-reject-errors,no-useless-escape,no-unused-vars */
import { PolymerElement, html } from "@polymer/polymer/polymer-element";

import "ag-grid-polymer";
import "@vaadin/vaadin-icons";
import "@hpacs/healthhub-icons/healthhub-icons";
import "@hpacs/spinner/hpacs-spinner";

import "./components/healthhub-confirm-dialog";
import "./dialog/techtab-merge-dialog";

import moment from "moment-timezone";
// import {Debouncer} from "@polymer/polymer/lib/utils/debounce";
// import {timeOut} from "@polymer/polymer/lib/utils/async";
import download from "../public/resource/js/download";
import GridUtils from "./utils/grid-utils";

import {CustomDateComponent} from "../public/resource/js/custom-date-component";
import CommonUtils from "../public/resource/js/utils/common";
import DateFilterUtils from "../public/resource/js/utils/date-filter-utils";
import {CustomDateFloatingComponent} from "../public/resource/js/custom-date-floating-component";
import mixinCommons from "./common-mixin";
import store from "./redux/store";
import {TechlistActionType} from "./redux/reducers/techlist";
import {TechlistReportActionType} from "./redux/reducers/techlist-report";
import TechnicianUtils from "./utils/technician-utils";
import {CommonActionType, CustomContextMenuType, DialogActionType, DialogType} from "./redux/reducers/common";
import {RelatedTechlistActionType} from "./redux/reducers/related-techlist";
import FilmboxUtils from "./utils/filmbox-utils";
import {WorklistActionType} from "./redux/reducers/worklist";
import i18n from "./utils/i18n-utils";

class GridStudy extends mixinCommons(PolymerElement) {
   static get is() {
      return "grid-study";
   }

   static get template() {
      return html`
      <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-grid.min.css">
      <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-theme-balham-dark.min.css">
      <link rel="stylesheet" href="/resource/style/ag-grid-hpacs.css">

      <style>
         :host {
            display: block;
            height: 100%;
            width: 100%;
         }

         .class-container {
            height: 100%;
            width: 100%;
         }

         .contextMenuIcon {
            --iron-icon-width: 16px;
            --iron-icon-height: 16px;
         }

         paper-progress {
            --paper-progress-height: 12px;
            --paper-progress-active-color: #0087cb;
            --paper-progress-container-color: #ccc;
            width: 100%;
            top: 7px;
         }

         .prefetchIcon {
            width: 20px;
            height: 20px;
         }

         .none {
            color: #4c5667;
         }

         .success {
            color: #0087cb;
         }

         .fail {
            color: #ec971f;
         }

         .empty {
            color: #d9534f;
         }

         .container-spinner {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
         }

         .container-spinner > paper-spinner-lite {
            width: 20px;
            height: 20px;
            --paper-spinner-color: #449d44;
         }

         .contextMenuIcon {
            --iron-icon-width: 16px;
            --iron-icon-height: 16px;
         }

         ::-webkit-scrollbar {
               width: 15px;
               height: 15px;
            }

         ::-webkit-scrollbar-thumb {
            background-color: #6b6b6b;
            border-radius: 10px;
            background-clip: content-box;
            border: 3px solid rgba(255,255,255,0);
         }

         paper-input {
            --paper-input-container-input-color: #aaaaaa;
            --paper-input-container-focus-color: #0087cb;
            --paper-input-container-label: {
               text-align: left;
               font-size: 13px;
            };
         }

         .relative-date-filter-input {
            text-align: center;
         }

         paper-dropdown-menu, paper-listbox {
            --paper-input-container-focus-color: #0087cb;
            --paper-input-container-label: {
               text-align: center;
               color: #aaaaaa;
               font-size: 13px;
            };
            --paper-input-container-input: {
               text-align: center;
               color: #aaaaaa;
               font-size: 13px;
            };
            color: #aaaaaa;
            background: var(--ag-background-color,#2d3436);
         }

         paper-item {
            --paper-item-min-height: 30px;
            --paper-item-focused: {
               color: #0087cb;
            };
            font-size: 13px;
         }

         .relative-date-filter-body {
             display: flex;
             justify-content: space-evenly;
             align-items: flex-end;
             width: 100%;
         }

         #duplicationDownloadToast {
            display: flex;
            position: fixed;
            bottom: 85px;
            width: 420px;
            left: 245px;
            padding: 15px 20px;
            transform: translate(-50%, 10px);
            border-radius: 20px;
            overflow: hidden;
            font-size: .8rem;
            opacity: 0;
            visibility: hidden;
            transition: opacity .5s, visibility .5s, transform .5s;
            background: rgba(0, 0, 0, .8);
            color: #aaa;
            z-index: 10000;
         }

         #duplicationDownloadToast.reveal {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, 0)
         }

         #fileDownloadToast {
            display: flex;
            position: fixed;
            bottom: 12px;
            width: 530px;
            left: 300px;
            padding: 15px 20px;
            transform: translate(-50%, 10px);
            border-radius: 20px;
            overflow: hidden;
            font-size: .8rem;
            opacity: 0;
            visibility: hidden;
            transition: opacity .5s, visibility .5s, transform .5s;
            background: rgba(0, 0, 0, .8);
            color: #aaa;
            z-index: 10000;
         }

         #fileDownloadToast.reveal {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, 0)
         }

         #fileDownloadToast_title {
            display: flex;
            align-items: center;
            width: 84%;
         }

         .yellow-button {
            text-transform: none;
            color: #eeff41;
         }
      </style>
      <div class="class-container">
         <ag-grid-polymer
                  id="gridStudyList"
                  class="ag-theme-balham-dark"
                  gridOptions="{{gridOptions}}"
                  columnDefs="{{columnDefs}}"
         ></ag-grid-polymer>
      </div>
      <techtab-merge-dialog id="techtabMergeDialog"></techtab-merge-dialog>

      <div id="duplicationDownloadToast">
         <div id="duplicationDownloadToast_title"></div>
         <paper-button id="closedDuplicationToast" class="yellow-button">Close now!</paper-button>
      </div>
      <div id="fileDownloadToast">
         <div id="fileDownloadToast_title"></div>
         <paper-button id="closedDownloadToast" class="yellow-button">Close now!</paper-button>
      </div>
      `;
   }

   static get properties() {
      return {
         // g_objId: {
         //    type: String
         // },
         activeTabCode: {
            type: Number,
            observer: "onActiveTabChange"
         },
         category: {
            type: Number,
            observer: "onSelectCategory"
         },
         gridApi: {
            type: Object
         },
         gridOptions: {
            type: Object
         },
         columnDefs: {
            type: Array,
            value: []
         },
         _filters: {
            type: Object,
            value: {}
         },
         _selectedRows: {
            type: Array,
            value: []
         },
         _altKey: {
            type: Boolean,
            value: false
         },
         _selectedOrder: {
            type: Object,
            observer: "onSelectOrder"
         },
         _utcOffset: {
            type: Number
         },
         _startRow: {
            type: Object
         },
         g_quickFilter: {
            type: Boolean,
            value: false
         },
         rowNodes: {
            type: Array,
            value: []
         },
         // studyCnt: {
         //    type: Number,
         //    value: 0
         // },
         popupName: {
            type: String,
            value: "popup"
         },
         popupOpts: {
            type: String,
            value: "resizable=yes,toolbar=no,status=0,location=no,menubar=no,scrollbars=0,dependent=yes"
         },
         _filterParams: {
            type: Object,
            value: {}
         },
         _flagFilter: {
            type: Boolean,
            value: false
         },
         popup_tech: {
            type: Object
         },
         g_dateFilter: {
            type: String
         },
         _shiftKey: {
            type: Boolean,
            value: false
         },
         isDisabledMatch: {
            type: Boolean,
            value: true
         },
         isDisabledUnMatch: {
            type: Boolean,
            value: true
         },
         matchMsgCode: {
            type: String
         },
         filmboxHash: {
            type: Object,
            value: {}
         },
         appliedDateFilters: {
            type: Object,
            value: {}
         },
         createdRelativeDateFilter: {
            type: Object,
            value: {
               studyDtime: false,
               requestDtime: false
            }
         },
         activeDateToggle: {
            type: String,
            value: null
         },
         selectedTab: {
            type: Number,
            value: null
         },
         initDefaultFilter: {
            type: Boolean,
            value: false
         },
         dblClickedId: {
            type: String
         },
         datasourceUpdateRows: {
            type: Array,
            value: []
         },
         _row: {
            type: Object,
            // value: {},
            observer: "_rowSelectedChange"
         },
         filterTimeStamp: {
            type: Number
         },
         fetchTimeStamp: {
            type: Number
         },
         abortController: {
            type: Object
         },
         eventSource: {
            type: EventSource,
            value: null
         },
         dcmImgCnt: {
            type: Number,
            value: 1
         },
         techlistFilterState: {
            type: Object,
            value: {},
            observer: "setTechlistFilter"
         },
         _ready: {
            type: Boolean,
            value: false
         },
         redrawSelectedRows: {
            type: Boolean,
            value: false,
            observer: "changeRedrawSelectedRows"
         },
         refreshAndSelectRows: {
            type: Array,
            observer: "changeRefreshAndSelectRows"
         },
         customContextMenuState: {
            type: Number
         },
         prefetchState: {
            type: Boolean,
            observer: "changePrefetchState"
         },
         appliedTechlistFilter: {
            type: Object,
         },
         filmboxExpand: { // hangingProtocol 확장여부
            type: String,
            value: "T" // T or F or A
         },
         filmboxState: {
            type: Object,
            observer: "changeFilmboxState"
         },
         techlistSortModel: {
            type: Object,
         }
      };
   }

   constructor() {
      super();

      this._utcOffset = this.utcCheck();

      this.gridOptions = {
         defaultColDef: {
            filter: true,
            suppressMenu: true,
            sortable: true,
            resizable: true,
            floatingFilter: true,
            filterParams: {
               newRowsAction: "keep" // applyButton, set filter 에는 적용안됨
            },
            suppressKeyboardEvent: params => GridUtils.disableRowDeselectionBySpace(params),
         },
         rowModelType: "serverSide",
         serverSideStoreType: "partial",
         animateRows: true,
         components: {
            agDateInput: CustomDateComponent,
            customDateFloatingFilter: CustomDateFloatingComponent
         },
         // floatingFilter: true,
         rowSelection: "multiple",
         sideBar: {
            toolPanels: [
               {
                  id: "columns",
                  labelDefault: this.t("label.showHideColumns"),
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel",
                  toolPanelParams: {
                     suppressRowGroups: true,
                     suppressValues: true,
                     suppressPivots: true,
                     suppressPivotMode: true,
                     suppressSideButtons: true,
                     suppressColumnFilter: true,
                     suppressColumnSelectAll: true,
                     suppressColumnExpandAll: true,
                  }
               }
            ]
         },
         postProcessPopup: (params) => {
            if (params.type !== "columnMenu") return;

            const columnId = params.column.getId();
            const {ePopup} = params;

            if(columnId === "studyDtime" || columnId === "requestDtime") {
               let count = 0;
               const interval = setInterval(() => {
                  if(!this.createdRelativeDateFilter[columnId]) {
                     const filterBody = ePopup.querySelector(".ag-filter-body");
                     const filterButton = ePopup.querySelector(".ag-filter-apply-panel");
                     if (filterBody && filterButton) {
                        clearInterval(interval);

                        DateFilterUtils.renderRelativeDateFilter(ePopup, () => {
                           const floatingFilter = this.$.gridStudyList.querySelector(".ag-popup"); // 팝업이 한번 닫힌 후 ePopup 에는 하위 element 값이 없어서 다시 불러줌
                           const filterModel = this.getFilterModelIncludeDateFilter();
                           DateFilterUtils.applyRelativeDateFilter(floatingFilter, columnId, filterModel, (filterModel) => {
                              this.setFilter(filterModel);
                           });
                        }, () => {
                           const floatingFilter = this.$.gridStudyList.querySelector(".ag-popup"); // 팝업이 한번 닫힌 후 ePopup 에는 하위 element 값이 없어서 다시 불러줌
                           const filterModel = this.getFilterModelIncludeDateFilter();
                           DateFilterUtils.clearRelativeDateFilter(floatingFilter, columnId, filterModel, (filterModel) => {
                              this.setFilter(filterModel);
                           });
                        }, () => {
                           const filterInstance = this.gridApi.getFilterInstance(columnId);
                           DateFilterUtils.setDateFilterComponent(ePopup, filterInstance, this.appliedDateFilters[columnId]);
                        });

                        this.createdRelativeDateFilter[columnId] = true;
                     }
                  } else {
                     const customFilter = ePopup.querySelector(".relative-date-filter-body");
                     const customButton = ePopup.querySelector(".relative-date-filter-button");
                     const applyButton = ePopup.querySelector(".ag-filter-apply-panel-button");
                     const relativeDateFilterInput = ePopup.querySelector(".relative-date-filter-input");
                     const relativeDateFilterList = ePopup.querySelector(".relative-date-filter-list");
                     if(customFilter && customButton && applyButton && relativeDateFilterInput && relativeDateFilterList) {
                        clearInterval(interval);

                        const filterInstance = this.gridApi.getFilterInstance(columnId);
                        DateFilterUtils.setDateFilterComponent(ePopup, filterInstance, this.appliedDateFilters[columnId]);
                     }
                  }
                  if(this.createdRelativeDateFilter[columnId] || count > 100) {
                     clearInterval(interval);
                  }
                  count++;
               }, 100);
            }
         },
         overlayNoRowsTemplate: `<span style='font-size: 13px'>${this.t("label.noRecordsFound")}</span>`,
         cacheBlockSize: 50,
         tooltipShowDelay: 0,
         // filterModel: this.filterModel,
         navigateToNextCell: params => this.navigateToNextCell(params),
         // serverSideDatasource : { getRows: params => this.enterpriseDatasource(params) },
         // onGridReady: params => this.onGridReady(params),
         // data의 id를 RowNodeId로 초기화
         getRowNodeId: data => this.getRowNodeId(data),
         onRowClicked: e => this.onRowClicked(e),
         onSelectionChanged: () => this.onSelectionChanged(),
         onCellFocused: () => this.onCellFocused(),
         onRowSelected: evt => this.onRowSelected(evt),
         onRowDoubleClicked: evt => this.onRowDoubleClicked(evt),
         onFilterChanged: v => this.filterChange(v),
         onFilterModified: (e) => {
            const columnId = e.column.getId();
            if(columnId === "studyDtime" || columnId === "requestDtime") {
               const floatingFilter = this.$.gridStudyList.querySelector(".ag-popup");
               const filterInstance = this.gridApi.getFilterInstance(e.column.colId);
               DateFilterUtils.setDateFilterComponent(floatingFilter, filterInstance);
            }
         },
         onSortChanged: () => this.dispatchEvent(new CustomEvent("stopWorkerEvent", {bubbles: true, composed: true })),
         getContextMenuItems: params => this.getContextMenuItems(params),
         localeText: GridUtils.localeText()
      };

      this.gridOptions.onColumnMoved = () => {
         this.gridOptions.onDragStopped = () => {
            const tempArr = this.gridOptions.columnApi.columnController.gridColumns;
            const headerArr = tempArr.reduce((acc, {colDef}) => {
               acc.push(colDef);
               return acc;
            }, []);

            this.updateGridHeader(headerArr);
         };
      };

      this.gridOptions.onColumnResized = (v) => {
         if (v.finished) {
            const tempArr = this.gridOptions.columnApi.columnController.gridColumns;

            const headerArr = tempArr.reduce((acc, {colDef, actualWidth}) => {
               const newColDef = colDef;
               newColDef.width = actualWidth;
               acc.push(newColDef);
               return acc;
            }, []);

            this.updateGridHeader(headerArr);
         };
      };

      this.gridOptions.onColumnVisible = () => {
         const tempArr = this.gridOptions.columnApi.columnController.gridColumns;

         const headerArr = tempArr.reduce((acc, {colDef, visible}) => {
            const isHide = visible ? Boolean(false) : Boolean(true);
            const newColDef = colDef;
            newColDef.hide = isHide;
            acc.push(newColDef);
            return acc;
         }, []);

         this.updateGridHeader(headerArr);
      };

      this.gridOptions.onGridReady = (params) => {
         this.gridApi = params.api;
         // this.setDefaultFilterModel();
         store.subscribe(() => {
            this.activeTabCode = store.getState().relatedTechlist.activeTabCode;
            this.category = store.getState().common.category;
            // this.g_objId = store.getState().report.thumbnailRow;
            this._selectedOrder = store.getState().relatedTechlist.order;
            this.techlistFilterState = store.getState().techlist.filter;
            this.redrawSelectedRows = store.getState().techlist.redrawSelectedRows;
            this.refreshAndSelectRows = store.getState().techlist.refreshAndSelectRows;
            this.customContextMenuState = store.getState().common.customContextMenu;
            this.prefetchState = store.getState().common.prefetch;
            this.appliedTechlistFilter = store.getState().techlist.appliedFilter;
            if (this.category === 1) this.filmboxState = store.getState().filmbox.filmbox;
            this.techlistSortModel = store.getState().techlist.appliedSortModel;
         });

         this.initGridColumns().then(() => {
            params.api.setServerSideDatasource(this.createDatasource());
         });
      };

      this.gridOptions.onCellKeyDown = (param) => {
         const { code, ctrlKey, metaKey } = param.event;
         const { userAgent } = window.navigator;
         const [ isWindow, isMac ] = [ /Windows/.test(userAgent), /Mac OS/.test(userAgent)];

         // #16447 [HWP-UT-W-001] Worklist 단축키 관련
         if(isWindow && ctrlKey || isMac && metaKey) {
            if(code === "ArrowLeft") {
               // scrolls to the first column
               // 고정되어 있는 프리패치 컬럼을 제외한 첫번째 컬럼에 포커싱
               // console warning 출력되는건 ag-grid v.26에서 해결될 예정
               // https://www.ag-grid.com/changelog/ (AG-5579)
               const firstCol = this.gridOptions.columnApi.getAllDisplayedColumns()
                  .filter((c) => !c.isPinned())
                  .find((c) => c);
               this.gridApi.ensureColumnVisible(firstCol);
            }
            if(code === "ArrowRight") {
               // scrolls to the last column
               const lastCol = this.gridOptions.columnApi.getAllDisplayedColumns()[this.gridOptions.columnApi.getAllDisplayedColumns().length-1];
               this.gridApi.ensureColumnVisible(lastCol);
            }
         }
      };
   }

   ready() {
      super.ready();

      // store.subscribe(() => {
      //    this.activeTabCode = store.getState().relatedTechlist.activeTabCode;
      //    this.category = store.getState().common.category;
      //    // this.g_objId = store.getState().report.thumbnailRow;
      //    this._selectedOrder = store.getState().relatedTechlist.order;
      //    this.techlistFilterState = store.getState().techlist.filter;
      //    this.redrawSelectedRows = store.getState().techlist.redrawSelectedRows;
      //    this.refreshAndSelectRows = store.getState().techlist.refreshAndSelectRows;
      //    this.customContextMenuState = store.getState().common.customContextMenu;
      //    this.prefetchState = store.getState().common.prefetch;
      //    this.appliedTechlistFilter = store.getState().techlist.appliedFilter;
      //    if (this.category === 1) this.filmboxState = store.getState().filmbox.filmbox;
      //    this.techlistSortModel = store.getState().techlist.appliedSortModel;
      // });

      // this.$.gridStudyList.addEventListener("click", (e) => {
      //    if(e.altKey){
      //       this._altKey = true;
      //       // return false;
      //    }
      // });

      /** grid-order -> grid-study
       * grid-order의 선택 row가 초기화 되면 grid-study에서 갖고 있는 order row도 초기화 시켜준다.
       * */
      // document.addEventListener("clearOrderRowsInStudy", () => {
      //    // this._selectedOrder = {};
      //    store.dispatch({ type: RelatedTechlistActionType.CLEAR_ORDER });// TODO: 확인필요
      // });

      document.addEventListener("click", () => {
         if (this.customContextMenuState !== undefined) store.dispatch({ type: CommonActionType.HIDE_CONTEXT_MENU });
      });

      this.$.closedDownloadToast.addEventListener("click", () => {
         this.closeDownloadToast();
      });

      this.$.closedDuplicationToast.addEventListener("click", () => {
         this.closeDuplicationDownloadToast();
      });

      this._ready = true;
   } // ready

   setTechlistFilter(e = {}) {
      if(this._ready && e.detail) {
         const { filterModel, sortModel } = e.detail;

         // techtab, clear할때 기본 적용 필터
         if (CommonUtils.isEmptyObject(filterModel)) {
            const studyDtime = DateFilterUtils.getDateFilterModel(2, "months");
            this.setFilter({ studyDtime });
         } else {
            if(e.detail.isQuickFilter) {
               this.g_quickFilter = true;
            }
            this.setFilter(filterModel);
         }

         // sortModel을 filter 설정전에 Clear 시 조회가 이루어지는 이슈로 인한 수정
         // sortModel의 조회여부는 getWorklist function에서 분기처리로 이루어짐
         this.setFilterSortClear();

         // #18582 [HPACS > 홍새롬 선생님] 소팅 된 컬럼 필터 저장 -> 저장 필터 선택하면 소팅이 풀리는 오류
         if(!CommonUtils.isEmptyArr(sortModel)) this.gridOptions.columnApi.applyColumnState({state: sortModel});
      }
   }


   onActiveTabChange(tab) {
      if (this.category === 1) {
         this.relatedTabChanged(tab);
      }
   }

   onSelectCategory(category) {
      this.filterChange();
   }

   relatedTabChanged(relatedTabCode) {
      const key = this.dblClickedId;
      const stopRelatedSyncOpen = JSON.parse(localStorage.getItem("stopRelatedSyncOpen"));
      if(key && (window.filmbox && window.filmbox.get() && window.filmbox.get().name === "popup") && !stopRelatedSyncOpen) {
         const url = `/filmbox#${key}&related=${FilmboxUtils.convertRelatedTabCode(relatedTabCode)}`;
         this.popup_tech = window.open(url, this.popupName, this.popupOpts);
         // window.document.filmbox = this.popup_tech;
         this.popup_tech.focus();
      }
   }

   // #16581 updateGridHeader로 통합
   // updateGridHeader(headerArr) {
   //    this.getUserStyle().then((result) => {
   //       this.updateGridTech(result.id, headerArr);
   //    }).catch((err) => {
   //       console.info(err);
   //       this.gridApi.setColumnDefs(headerArr);
   //    });
   // }

   getUserStyle() {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/style`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   updateGridHeader(headerArr) {
      const type = "techFilm";
      fetch(`/api/user/option/gridheader/${type}`, {
         method: "PATCH",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(headerArr)
      }).then((response) => {
         if (!response.ok) {
            console.debug(new Error(`${response.status} ${response.statusText}`));
         }
      });
   }

   // #16581 updateGridHeader로 통합
   // updateGridTech(id, headerArr) {
   //    fetch(`/api/user/option/gridHeaderTech/${id}`, {
   //       method: "PATCH",
   //       headers: {
   //          "Authorization": localStorage.getItem("jwt"),
   //          "Content-Type": "application/json"
   //       },
   //       body: JSON.stringify(headerArr)
   //    }).then((response) => {
   //       if (!response.ok) {
   //          console.debug(new Error(`${response.status} ${response.statusText}`));
   //       }
   //    });
   // }

   /**
    * Create ag-grid columns
    */
   createColumnDefs() {
      const columns = [
         {headerName: this.t("label.gridHeader.name.pf"),               field: "prefetch",                    headerTooltip: this.t("label.gridHeader.tooltip.pf"),               width: 33,  filter: false, sortable: false, resizable: false, cellStyle: {"text-align": "center"}, cellRenderer: this.pfCellRenderer, suppressMenu: true, pinned: "left" },
         {headerName: this.t("label.gridHeader.name.l"),                field: "edit",                        headerTooltip: this.t("label.gridHeader.tooltip.modifyLock"),       width: 33,  filter: false, sortable: false, resizable: false, cellStyle: {"text-align": "center"}, cellRenderer: this.lockCellRenderer, suppressMenu: true },
         {headerName: this.t("label.gridHeader.name.no"),               field: "no",                          headerTooltip: this.t("label.gridHeader.tooltip.no"),               width: 33,  filter: false, sortable: false, resizable: false, cellStyle: {"text-align": "right"} },
         {headerName: this.t("label.gridHeader.name.count"),            field: "imageCount",                  headerTooltip: this.t("label.gridHeader.tooltip.count"),            width: 45,  filter: false, sortable: false, resizable: false, cellStyle: {"text-align": "right"} },
         {headerName: this.t("label.gridHeader.name.seriesCount"),      field: "seriesCount",                 headerTooltip: this.t("label.gridHeader.tooltip.seriesCount"),      width: 45,  filter: false, sortable: false, resizable: false, cellStyle: {"text-align": "right"} },
         {headerName: this.t("label.gridHeader.name.em"),               field: "isEmergency",                 headerTooltip: this.t("label.gridHeader.tooltip.em"),               width: 50,  filter: false,                                    cellStyle: {"text-align": "right"}, cellRenderer: this.emCellRenderer},
         {headerName: this.t("label.gridHeader.name.rs"),               field: "readingStatus",               headerTooltip: this.t("label.gridHeader.tooltip.rs"),               width: 50,  filter: "agSetColumnFilter",    filterParams: {applyButton: true, clearButton: true, values: this._filters.readingStatus}, cellStyle: {"text-align": "center"}, cellRenderer: this.rsCellRenderer },
         {headerName: this.t("label.gridHeader.name.accessionNo"),      field: "accessionNumber",             headerTooltip: this.t("label.gridHeader.tooltip.accessionNo"),      width: 100, filter: "agTextColumnFilter",   filterParams: {filterOptions: ["contains", "notContains"], caseSensitive: true, suppressAndOrCondition: true, applyButton: true, newRowsAction: "keep"}, floatingFilterComponentParams: {suppressFilterButton: true}},
         {headerName: this.t("label.gridHeader.name.ss"),               field: "studyStatus",                 headerTooltip: this.t("label.gridHeader.tooltip.ss"),               width: 92,  filter: "agSetColumnFilter",    filterParams: {applyButton: true, clearButton: true, values: this._filters.studyStatus}},
         {headerName: this.t("label.gridHeader.name.verifyTo"),         field: "verify",                      headerTooltip: this.t("label.gridHeader.tooltip.verifyTo"),         width: 95,  sortable: false, filter: "agTextColumnFilter",   filterParams: {filterOptions: ["contains", "notContains"], caseSensitive: true, suppressAndOrCondition: true, applyButton: true, newRowsAction: "keep"}, floatingFilterComponentParams: {suppressFilterButton: true }, cellRenderer: GridUtils.verifyCellRenderer},
         {headerName: this.t("label.gridHeader.name.matched"),          field: "isMatch",                     headerTooltip: this.t("label.gridHeader.tooltip.matched"),          width: 92,  filter: "agSetColumnFilter",    filterParams: {applyButton: true, clearButton: true, values: this._filters.isMatch}, cellRenderer: this.matchedCellRenderer, cellStyle: { "text-align": "center" }},
         {headerName: this.t("label.gridHeader.name.merged"),           field: "isMerge",                     headerTooltip: this.t("label.gridHeader.tooltip.merged"),           width: 92,  filter: "agSetColumnFilter",    filterParams: {applyButton: true, clearButton: true, values: this._filters.isMerge}, cellRenderer: this.mergedCellRenderer, cellStyle: { "text-align": "center" }},
         {headerName: this.t("label.gridHeader.name.id"),               field: "patientID",                   headerTooltip: this.t("label.gridHeader.tooltip.id"),               width: 105, filter: "agTextColumnFilter",   filterParams: {applyButton: true, caseSensitive: true, newRowsAction: "keep"}, floatingFilterComponentParams: {suppressFilterButton: true}, cellStyle: { "text-align": "center" }},
         {headerName: this.t("label.gridHeader.name.name"),             field: "patientName",                 headerTooltip: this.t("label.gridHeader.tooltip.name"),             width: 95,  filter: "agTextColumnFilter",   filterParams: {filterOptions: ["contains", "notContains"], caseSensitive: true, suppressAndOrCondition: true, applyButton: true, newRowsAction: "keep"}, floatingFilterComponentParams: {suppressFilterButton: true}},
         {headerName: this.t("label.gridHeader.name.age"),              field: "patientAge",                  headerTooltip: this.t("label.gridHeader.tooltip.age"),              width: 70,  filter: false, sortable: false, cellStyle: { "text-align": "center" }},
         {headerName: this.t("label.gridHeader.name.birthDate"),        field: "patientBirthDate",            headerTooltip: this.t("label.gridHeader.tooltip.birthDate"),        width: 115, filter: "agTextColumnFilter",   filterParams: {applyButton: true, caseSensitive: true, newRowsAction: "keep"}, floatingFilterComponentParams: {suppressFilterButton: true}, cellStyle: { "text-align": "center" }},
         {headerName: this.t("label.gridHeader.name.sex"),              field: "patientSex",                  headerTooltip: this.t("label.gridHeader.tooltip.sex"),              width: 85,  filter: "agSetColumnFilter",    filterParams: {applyButton: true, clearButton: true, values: this._filters.patientSex}, cellRenderer: this.sexCellRenderer, cellStyle: { "text-align": "center" }},
         {headerName: this.t("label.gridHeader.name.modality"),         field: "modality",                    headerTooltip: this.t("label.gridHeader.tooltip.modality"),         width: 105, filter: "agSetColumnFilter",    filterParams: {applyButton: true, clearButton: true, values: this._filters.modality}},
         {headerName: this.t("label.gridHeader.name.bodyPart"),         field: "bodypart",                    headerTooltip: this.t("label.gridHeader.tooltip.bodyPart"),         width: 105, filter: "agSetColumnFilter",    filterParams: {applyButton: true, clearButton: true, values: this._filters.bodypart}},
         {headerName: this.t("label.gridHeader.name.studyDesc"),        field: "studyDescription",            headerTooltip: this.t("label.gridHeader.tooltip.studyDesc"),        width: 225, filter: "agTextColumnFilter",   filterParams: {applyButton: true, caseSensitive: true, newRowsAction: "keep"}, floatingFilterComponentParams: {suppressFilterButton: true}},
         {headerName: this.t("label.gridHeader.name.studyDate"),        field: "studyDtime",                  headerTooltip: this.t("label.gridHeader.tooltip.studyDate"),        width: 140, filter: "agDateColumnFilter",   floatingFilterComponent: "customDateFloatingFilter", filterParams: {applyButton: true, clearButton: true, browserDatePicker: true, filterOptions: ["equals", "lessThanOrEqual", "greaterThanOrEqual", "inRange", DateFilterUtils.relativeDateFilterOption], newRowsAction: "keep", suppressAndOrCondition: true, comparator: this.dateComparator}, cellStyle: { "text-align": "center" }},
         {headerName: this.t("label.gridHeader.name.reqDate"),          field: "requestDtime",                headerTooltip: this.t("label.gridHeader.tooltip.reqDate"),          width: 140, filter: "agDateColumnFilter",   floatingFilterComponent: "customDateFloatingFilter", filterParams: {applyButton: true, clearButton: true, browserDatePicker: true, filterOptions: ["equals", "lessThanOrEqual", "greaterThanOrEqual", "inRange", DateFilterUtils.relativeDateFilterOption], newRowsAction: "keep", suppressAndOrCondition: true, comparator: this.dateComparator}, cellStyle: { "text-align": "center" }},
         {headerName: this.t("label.gridHeader.name.reqDept"),          field: "institutionalDepartmentName", headerTooltip: this.t("label.gridHeader.tooltip.reqDept"),          width: 100, filter: false, sortable: false, },
         {headerName: this.t("label.gridHeader.name.studyInstanceUID"), field: "studyInstanceUID",            headerTooltip: this.t("label.gridHeader.tooltip.studyInstanceUID"), width: 240, filter: "agTextColumnFilter",   filterParams: {applyButton: true, caseSensitive: true, newRowsAction: "keep"}, floatingFilterComponentParams: {suppressFilterButton: true}},
         {headerName: this.t("label.gridHeader.name.reporting"),        field: "reader.userName",             headerTooltip: this.t("label.gridHeader.tooltip.reporting"),        width: 80,  filter: false, tooltipField: "reader.userName",   },
         {headerName: this.t("label.gridHeader.name.reqHosp"),          field: "requestHospital",             headerTooltip: this.t("label.gridHeader.tooltip.reqHosp"),          width: 150, filter: "agSetColumnFilter",    filterParams: {applyButton: true, clearButton: true, values: this._filters.requestHospital} },
      ];

      return GridUtils.changeFilterParams(columns);
   }

   utcCheck() {
      const now = new Date();
      const _zone = moment.tz.guess();
      const timeOffset = moment(now.getTime()).tz(_zone);
      return (timeOffset._offset / 60);
   }

   // enterpriseDatasource() {
   //    let index = 0;
   //    return {
   //       getRows: (v) => {
   //          // setDatasource
   //          // this.datasourceObject = v;
   //          const params = v;
   //          const {model} = params.request;
   //
   //          // this.applyTechFilters();
   //          if(index === 0) {
   //             this.getTechFilters().then((result) => {
   //                this._filters = result;
   //
   //                this.getUserStyle().then((result) => {
   //                   if (result.grid && result.grid.techFilm && result.grid.techFilm.length > 0) {
   //                      const {techFilm} = result.grid;
   //                      // const columnDefs = this.cellRenderer(techFilm);
   //                      // this.columnDefs = this.changeFilterParams(columnDefs);
   //
   //                      const columnDefs = GridUtils.mergeFilterParams(techFilm, this.createColumnDefs());
   //                      this.columnDefs = columnDefs;
   //                      params.api.setColumnDefs(columnDefs);
   //                   } else {
   //                      // const columnDefs = this.cellRenderer(this.createColumnDefs());
   //                      const columnDefs = this.createColumnDefs();
   //                      params.api.setColumnDefs(columnDefs);
   //
   //                      this.columnDefs = columnDefs;
   //                      this.updateGridHeader(this.createColumnDefs());
   //                   }
   //                   this.initStudyList();
   //                   // this.setDefaultFilterModel();
   //                }).catch((err) => {
   //                   console.info(err);
   //                   // const columnDefs = this.cellRenderer(this.createColumnDefs());
   //                   const columnDefs = this.createColumnDefs();
   //                   this.columnDefs = columnDefs;
   //
   //                   params.api.setColumnDefs(columnDefs);
   //                   // this.setDefaultFilterModel();
   //                });
   //             });
   //          }
   //
   //          if(index !== 0) {
   //             this.getStudylist(params).then((result) => {
   //                let refreshFlag = false;
   //
   //                for (const row of result.rows) {
   //                   const duplicateRow = this.gridOptions.api.getRowNode(row.id);
   //                   if(duplicateRow) {
   //                      refreshFlag = true;
   //                      break;
   //                   }
   //                }
   //
   //                if(refreshFlag) {
   //                   this.purgeEnterpriseCache();
   //                   this.filterChange();
   //                } else {
   //                   params.success({
   //                      rowData: result.rows||[],
   //                      rowCount: result.lastRow
   //                   });
   //                }
   //
   //                // Merge/Split처리후 row select
   //                if (this.datasourceUpdateRows.length > 0) {
   //                   this.gridOptions.api.deselectAll();
   //                   result.rows.forEach((row) => {
   //                      this.datasourceUpdateRows.forEach((updateRow) => {
   //                         if (row.id === updateRow.id) {
   //                            this.gridOptions.api.getRowNode(row.id).setSelected(true);
   //                         }
   //                      });
   //                   });
   //                   this.datasourceUpdateRows = [];
   //                }
   //
   //                // prefetch 구조체 생성
   //                this.initStruct(result.rows);
   //
   //                if ((result.rows||[]).length === 0) {
   //                   params.api.showNoRowsOverlay();
   //                }
   //
   //             }).catch((err) => {
   //                console.error(err);
   //                params.fail();
   //             });
   //          }
   //          index++;
   //
   //          this.dispatchEvent(new CustomEvent("clearTechTabWindowEvent"));
   //       }
   //    };
   // }

   initStudyList() {
      return new Promise((resolve) => {
         if (this.selectedTab !== 1) {
            resolve();
            return;
         }
         this.initDefaultFilter = true;

         const queryParams = CommonUtils.getQueryParams();
         const keys = Object.keys(queryParams);
         if(keys.length > 0 && (keys.includes("patientId") || keys.includes("accessionNumber"))) {
            console.log("patientId >> ", queryParams.patientId, "accessionNumber >> ", queryParams.accessionNumber);
            const filterModel = {};
            if(keys.includes("patientId") && queryParams.patientId[0] !== "") {
               filterModel.patientID = { filter: queryParams.patientId[0], filterType: "text", type: "contains" };
            }

            if(keys.includes("accessionNumber") && queryParams.accessionNumber[0] !== "") {
               filterModel.accessionNumber = { filter: queryParams.accessionNumber[0], filterType: "text", type: "contains" };
            }
            this.setFilter(filterModel);
            resolve();
         } else {
            this.getFirstFilter().then((result) => {
               if (!result) {
                  const studyDtime = {};
                  studyDtime.dateFrom = this.lastThreeDays();
                  studyDtime.dateTo = this.getToday();
                  studyDtime.type = "inRelativeRange";
                  studyDtime.filterType = "date";
                  studyDtime.isRelative = true;
                  studyDtime.amount = 3;
                  studyDtime.unit = "days";
                  this.setFilter({"studyDtime": studyDtime});
               } else if (result.userFilterModel.isQuickFilter) {
                  const param = {};
                  param.pId = result.userFilterModel.filterModel.patientID.filter;
                  param.pName = result.userFilterModel.filterModel.patientName.filter;
                  this.pIdpNameSearchNewFilm(param);
                  this.dispatchEvent(new CustomEvent("selectUserFilterButtonEvent", {detail: {id: result.id}}));
               } else {
                  // console.log(result);
                  const {filterModel} = (this._filterParams || {}).userFilterModel || {};
                  if (!filterModel || !Object.keys(filterModel).length) {
                     // filterModel이 없을 경우 로직이 그냥 끝나기 때문에 재호출
                     this.gridApi.onFilterChanged();
                  }
                  // #18582 [HPACS > 홍새롬 선생님] 소팅 된 컬럼 필터 저장 -> 저장 필터 선택하면 소팅이 풀리는 오류
                  // Init 시 sortModel이 FilterModel 보다 나중에 적용 되면, 조회를 2번 하는 이슈로 인한 수정
                  const {sortModel} = (this._filterParams || {}).userFilterModel || {};
                  if (!CommonUtils.isEmptyArr(sortModel)) this.gridOptions.columnApi.applyColumnState({state: sortModel});

                  this.setFilter(filterModel);
                  this.dispatchEvent(new CustomEvent("selectUserFilterButtonEvent", {detail: {id: result.id}}));
               }

               resolve();
            }).catch((err) => {
               console.log(err);
            }).finally(() => {
               // #18582 [HPACS > 홍새롬 선생님] 소팅 된 컬럼 필터 저장 -> 저장 필터 선택하면 소팅이 풀리는 오류
               // const { sortModel } = (this._filterParams || {}).userFilterModel || {};
               // if(!CommonUtils.isEmptyArr(sortModel)) this.gridOptions.columnApi.applyColumnState({ state: sortModel });
            });
         }
      });
   }

   getFirstFilter() {
      return new Promise((resolve) => {
         this.fetchGetUserFiltersByUserId()
            .then((result) => {
               if (result != null) {
                  if (result.length > 0) {
                     const obj = {};
                     for (let i = 0; i < result.length; i++) {
                        if (result[i].userFilterModel.pin) {
                           this._flagFilter = true;
                           this._filterParams = result[i];
                           obj.target = result[i];
                           break;
                        }
                     }
                     if (this._flagFilter) {
                        this.retrieveWorklistByFilter(obj).then((result) => {
                           resolve(result);
                        });
                     } else {
                        resolve(null);
                     }
                  } else {
                     resolve(null);
                  }
               } else {
                  resolve(null);
               }
            });
      });
   }

   fetchGetUserFiltersByUserId() {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/filter`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok) {
               if(response.status === 200) {
                  response.json().then((result) => {
                     resolve(result);
                  });
               } else {
                  resolve([]);
               }
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   lastThreeDays() {
      return moment().subtract(2, "days").format("YYYY-MM-DD");
   }

   getRowNodeId(data) {
      return data.id;
   }

   onRowClicked(evt) {
      // console.log("-> [study] onRowClicked", evt.node.selected)

      // #16116 doubleClick 이슈로 추가했으나 match를 위해 order 선택 후 alt+study를 선택하면 order쪽에 deselect 되는 문제로 인해 주석처리
      // this._debouncer = Debouncer.debounce(this._debouncer, timeOut.after(500), () => {
      // console.log("onRowClicked", e);

      this._shiftKey = evt.event.shiftKey;
      this._altKey = evt.event.altKey;
      // alt + click 인경우 order의 선택값을 초기화 하지 않는다.
      // if (!this._altKey) {
      //    this.dispatchEvent(new CustomEvent("orderCountClearEvent"));
      // }

      // #15390 같은 row 클릭시 tech report 정보 반영
      // this._selectedRows = evt.api.getSelectedRows();
      // this.getCaseID();
      if (evt.node.selected) {

         // this.setSelectedRowInfo(param); // thumbnail, clinicalInfo
         // store.dispatch({ type: TechlistActionType.SELECT_ROW, payload: { ...param, rows: this._selectedRows, altKey: this._altKey } }); // patientInfo
         if (this._selectedRows.find(row => row.id === evt.data?.id)) { // 이미 선택된 row 인 경우만 report row dispatch
            store.dispatch({ type: TechlistReportActionType.SELECT_REPORT_ROW, payload: { detail: evt.data } }); // thumbnail, clinicalInfo, opinion
         }

         // Object.assign(param, {detail: this._selectedRows});
         // this.dispatchEvent(new CustomEvent("newSelectedRowsEvent", param)); // multiple row
         // store.dispatch({type: TechlistActionType.SELECT_ROWS, payload: this._selectedRows });
      }
   }

   onSelectionChanged() {
      // console.log("-> [study] onSelectionChanged (multi)")

      // const param = { detail: this._selectedRows };
      // this.dispatchEvent(new CustomEvent("newSelectedRowsEvent", param)); // multiple row
      // store.dispatch({type: TechlistActionType.SELECT_ROWS, payload: this._selectedRows });
   }

   onCellFocused() {

   }

   onRowSelected(evt) {
      // console.log("-> [study] onRowSelected", evt.node.selected, this._altKey);

      this._selectedRows = evt.api.getSelectedRows();
      // this.getCaseID();
      if (evt.node.selected) {
         this._row = evt.data;

         // this.setSelectedRowInfo(param); // thumbnail, clinicalInfo
         store.dispatch({ type: TechlistActionType.SELECT_ROW, payload: { detail: this._row, rows: this._selectedRows, altKey: this._altKey } }); // patientInfo
         store.dispatch({ type: TechlistReportActionType.SELECT_REPORT_ROW, payload: { detail: this._row } }); // thumbnail, clinicalInfo, opinion

         // multiple row
         // Object.assign(param, { detail: this._selectedRows });
         // this.dispatchEvent(new CustomEvent("newSelectedRowsEvent", param));
         // store.dispatch({type: TechlistActionType.SELECT_ROWS, payload: this._selectedRows });
      }

      if (!evt.node.selected && this._selectedRows.length === 0) {
         this._row = null;
         store.dispatch({ type: TechlistActionType.CLEAR_ROW_SELECTION });
         store.dispatch({ type: TechlistReportActionType.CLEAR_REPORT_ROW });
      } else if (!this._selectedRows.find(row => row.id === evt.data?.id)) {
         // selectedRows 에 evt.data(deselect 된 row)가 없다면
         // multi 선택 후 deselect 시 처리를 위함
         if (!this._selectedRows.find(row => row.id === this._row?.id)) { // row 변경으로 인한 이전 row deselect인 경우 아래 작업을 하지 않는다.
            // eslint-disable-next-line prefer-destructuring
            this._row = this._selectedRows[0];
            store.dispatch({ type: TechlistActionType.SELECT_ROW, payload: { detail: this._row, rows: this._selectedRows, altKey: this._altKey } }); // patientInfo
            store.dispatch({ type: TechlistReportActionType.SELECT_REPORT_ROW, payload: { detail: this._row } }); // thumbnail, clinicalInfo, opinion
         }
      }

      this._altKey = false;
   }

   _rowSelectedChange(newValue, oldValue) {
      // console.log("-> [study] _rowSelectedChange", newValue, oldValue)

      // if (!newValue.id) return;
   }

   onRowDoubleClicked(event) {

      // console.log("onRowDoubleClicked", event);

      this.dblClickedId = event.data.id;
      this.gridStudyDblClickEvent();
      // this.dispatchEvent(new CustomEvent("gridStudyDblClickEvent", { bubbles: true, composed: true}));
      // filmbox open
      // this.popup_tech = window.open(`/filmbox#${id}`, this.popupName, this.popupOpts);
   }

   setRightSelectedRows(row) {
      // #16041 마우스 우클릭으로 row가 선택되게끔 추가
      row.node.setSelected(true, true);

      // #16192
      // onRowSelected 이벤트 보다 먼저 타기 때문에 우클릭으로 row 선택시 this._selectedRows 값 세팅
      this._selectedRows = [ row.node.data ];
   }

   setFirstSelectedRows(id) {
      this.clearSelectedRow();
      this.gridOptions.api.getRowNode(id).setSelected(true);
   }

   getContextMenuItems(params) {

      if(!params.node) return;

      // #16192
      // 우클릭으로 row 선택시 onRowSelected 이벤트만 타고 onRowClicked 이벤트는 타지 않는다.
      // onRowSelected에서는 보조key를 잡을 수 없어 ( alt, ctrl, shift ) 멀티선택 불가

      const {studyId, old} = this.filmboxHash;
      const rows = params.api.getSelectedRows();

      if(rows.length > 1) {
         // 다중 선택시 index sort
         rows.sort((a, b) => a.no - b.no);
         this.dblClickedId = rows[0].id;

         // #16192 우클릭으로 선택되어있지 않는 row를 선택했을시 선택값을 초기화 한다.
         const row = rows.find( row => row.id === params.node.data.id);
         if (!row) {
            // this.dispatchEvent(new CustomEvent("orderCountClearEvent")); //TODO: orderCountClearEvent
            this.setRightSelectedRows(params);
         }

      } else {

         // #16192 우클릭으로 다른 row를 선택했을시 선택값을 초기화 한다.
         // if(this._selectedRows.length) {
         //    const {id} = this._selectedRows[0];
         //    if (id !== params.node.data.id) this.dispatchEvent(new CustomEvent("orderCountClearEvent")); //TODO: orderCountClearEvent
         // }

         this.setRightSelectedRows(params);
      }


      if (this.customContextMenuState !== undefined) {
         store.dispatch({ type: CommonActionType.SHOW_CONTEXT_MENU, payload: CustomContextMenuType.SYSTEM });
      }

      this.getStudyMatchStatusCode();
      const result = [
         {
            name: this.t("label.newReplaceTab"),
            disabled: this.isDisabledForReplaceContextMenu(rows),
            action: () => {
               this.getUserStyle().then((s) => {
                  if (s && s.filmbox && s.filmbox.expand) {
                     const {expand} = s.filmbox;
                     this.filmboxExpand = expand;
                  }

                  const related = {rel1: params.node.id, rel2: old, type: "replace", group: "new", expand: this.filmboxExpand};
                  this.oldFilmFilmboxOpen(related);
               });
            }
         },
         {
            name: this.t("label.newAddTab"),
            action: () => {
               this.getUserStyle().then((s) => {
                  if (s && s.filmbox && s.filmbox.expand) {
                     const {expand} = s.filmbox;
                     this.filmboxExpand = expand;
                  }
                  const related = {rel1: params.node.id, rel2: old, type: "tab", group: "new", expand: this.filmboxExpand};

                  // 다중 선택시 선택된 모든 검사 추가
                  if (rows.length > 1) {
                     const ids = [];
                     rows.forEach(r => ids.push(r.id));
                     related.rel1 = ids.toString();

                     // #18066 [HPACS > 탭뷰 사용성 개선] 멀티로 new tab을 오픈 시 첫번째 검사가 focus되도록 변경 필요
                     this.setFirstSelectedRows(rows[0].id);
                  }

                  this.oldFilmFilmboxOpen(related);
               });
            }
         },
         {
            name: this.t("label.relReplaceTab"),
            disabled: this.isDisabledForReplaceContextMenu(rows),
            action: () => {
               this.getUserStyle().then((s) => {
                  if (s && s.filmbox && s.filmbox.expand) {
                     const {expand} = s.filmbox;
                     this.filmboxExpand = expand;
                  }
                  // if(!this.g_objId) this.g_objId = params.node.id;

                  const related = {rel1: studyId, rel2: params.node.id, type: "replace", group: "old", expand: this.filmboxExpand};
                  this.oldFilmFilmboxOpen(related);
               });
            }
         },
         {
            name: this.t("label.relAddTab"),
            action: () => {
               this.getUserStyle().then((s) => {
                  if (s && s.filmbox && s.filmbox.expand) {
                     const {expand} = s.filmbox;
                     this.filmboxExpand = expand;
                  }
                  // if(!this.g_objId) this.g_objId = params.node.id;

                  const related = {rel1: studyId, rel2: params.node.id, type: "tab", group: "old", expand: this.filmboxExpand};

                  // 다중 선택시 선택된 모든 검사 추가
                  if (rows.length > 1) {
                     const ids = [];
                     rows.forEach(r => ids.push(r.id));
                     related.rel2 = ids.toString();

                     // #18066 [HPACS > 탭뷰 사용성 개선] 멀티로 new tab을 오픈 시 첫번째 검사가 focus되도록 변경 필요
                     this.setFirstSelectedRows(rows[0].id);
                  }

                  this.oldFilmFilmboxOpen(related);
               });
            }
         },
         "separator",
         {
            name: this.t("label.match"),
            disabled: this.isDisabledMatch,
            action: () => {
               this.match();
            }
         },
         {
            name: this.t("label.unmatch"),
            disabled: this.isDisabledUnMatch,
            action: () => {
               this.unMatch();
            }
         },
         {
            name: this.getLabelForMergeSplitContextmenu(),
            disabled: this.isDisabledForMergeSplitContextmenu(),
            action: () => {
               this.viewingStudy(this._selectedRows).then((viewing) => {
                  if (viewing.isViewing || viewing.edit) {
                     this.showCustomDialog(viewing);
                  } else if (this.isToBeMerge()) {
                     this.mergeExam();
                  } else {
                     this.cancelMerge();
                  }
               });
            }
         },
         {
            name: this.t("label.splitExam"),
            disabled: this.isDisabledForSplitExamContextmenu(),
            action: () => {
               this.viewingStudy(this._selectedRows).then((viewing) => {
                  if (viewing.isViewing || viewing.edit) {
                     this.showCustomDialog(viewing);
                  } else {
                     this.splitExam();
                  }
               });
            }
         },
         {
            name: this.t("label.modifyExam"),
            disabled: this.isDisabledForModifyExamContextmenu(),
            action: () => {
               this.viewingStudy(this._selectedRows).then((viewing) => {
                  if (viewing.isViewing || viewing.edit) {
                     this.showCustomDialog(viewing);
                  } else {
                     this.modifyExam();
                  }
               });
            }
         },
         {
            name: this.t("label.verify"),
            disabled: this.isDisabledForVerifyContextMenu(),
            action: () => {
               this.verify();
            }
         },
         {
            name: this.t("label.unverify"),
            disabled: this.isDisabledForUnverifyContextMenu(),
            action: () => {
               this.unVerify();
            }
         },
         {
            name: this.t("label.delete"),
            disabled: this.isDisabledForDeleteContextmenu(),
            action: () => {
               this.viewingStudy(this._selectedRows).then((viewing) => {
                  if (viewing.isViewing || viewing.edit) {
                     this.showCustomDialog(viewing);
                  } else {
                     this.deleteStudy();
                  }
               });
            }
         },
         {
            name: this.t("label.unlock"),
            disabled: this.isDisabledForUnlockContextmenu(),
            action: () => {
               this.caseUnlock();
            }
         },
         "separator",
         {
            name: this.t("label.export"),
            subMenu: [
               {
                  name: this.t("label.dicomSend"),
                  disabled: (this.gridApi.getSelectedRows()||[]).length === 0,
                  action: () => this.showDicomSend()
               }
            ]

         },
         "separator",
         {
            name: this.t("label.download"),
            subMenu: [
               {
                  name: this.t("label.dicom"),
                  action: () => {
                     const requestInfoList = []; // new Array();
                     for (const obj of params.api.getSelectedNodes()) {
                        const requestInfo = {
                           id : obj.data.id,
                           patientId : obj.data.patientID,
                           patientName : obj.data.patientName,
                           studyDtime : this.convertDateToString(new Date(obj.data.studyDtime)),
                           modality : obj.data.modality
                        };

                        requestInfoList.push(requestInfo);
                     }
                     this.downloadStart(requestInfoList, "dcm");
                  }
               },
               {
                  name: this.t("label.jpeg"),
                  action: () => {
                     const requestInfoList = []; // new Array();
                     for (const obj of params.api.getSelectedNodes()) {
                        const requestInfo = {
                           id : obj.data.id,
                           patientId : obj.data.patientID,
                           patientName : obj.data.patientName,
                           studyDtime : this.convertDateToString(new Date(obj.data.studyDtime)),
                           modality : obj.data.modality
                        };

                        requestInfoList.push(requestInfo);
                     }
                     this.downloadStart(requestInfoList, "jpeg");
                  }
               },
               "excelExport",
               "csvExport"
            ],

            icon: "<iron-icon class=\"contextMenuIcon\" icon=\"\" slot=\"prefix\"></iron-icon>"
         },
         "separator",
         {
            name: this.t("label.copy"),
            subMenu: [
               "copy",
               "copyWithHeaders"
            ]
         },

      ];

      return result;
   }

   cellRenderer(headerArr) {
      return headerArr.reduce((acc, it) => {
         const header = it;
         const {field, filterParams, floatingFilterComponentParams} = it;
         if (field === "isEmergency")     header.cellRenderer = v => this.emCellRenderer(v);
         if (field === "readingStatus")   header.cellRenderer = v => this.rsCellRenderer(v);
         if (field === "patientSex")      header.cellRenderer = v => this.psCellRenderer(v);
         if (field === "prefetch")        header.cellRenderer = v => this.pfCellRenderer(v);
         if (field === "edit")            header.cellRenderer = v => this.lockCellRenderer(v);
         if (field === "isMatch")         header.cellRenderer = v => this.matchedCellRenderer(v);
         if (field === "isMerge")         header.cellRenderer = v => this.mergedCellRenderer(v);
         if(field === "verify")           header.cellRenderer = v => GridUtils.verifyCellRenderer(v);

         acc.push(header);
         return acc;
      }, []);
   }

   emCellRenderer(params) {
      if(params.value === "normal") {
         return "<div class='emStatus'>N</div>";
      }
      if(params.value === "em") {
         return "<div class='emStatus em'>E</div>";
      }
      if(params.value === "cvr") {
         return "<div class='emStatus cvr'><iron-icon icon='healthhub:cvr'></iron-icon></div>";
      }
   }

   rsCellRenderer(params) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (params.value === "3A") return "A";
      if (params.value === "1W") return "W";
      if (params.value === "2DT") return "T";
      // opinionUpdateUserId = readingDoctorId
      if (params.value === "2T" && (user.id === params.data.opinionUpdateUserId)) return "H";
      return "O";
   }

   psCellRenderer(params) {
      if (params.value === "F") return "F";
      if (params.value === "M") return "M";
      return "O";
   }

   pfCellRenderer(params) {
      switch(params.value) {
      case "begin":
         return `<hpacs-spinner class="begin"></hpacs-spinner>`;
      case "success":
         return `<iron-icon class="prefetchIcon success" icon="healthhub:prefetchDone"></iron-icon>`;
      case "fail":
      case "empty":
         return `<iron-icon class="prefetchIcon fail" icon="healthhub:prefetchErr"></iron-icon>`;
      default:
         return `<iron-icon class='prefetchIcon none' icon='vaadin:thin-square'></iron-icon>`;
      }
   }

   lockCellRenderer(params) {
      if (params.value === true) {
         return `<iron-icon class="caseLockIcon" icon='vaadin:eye'></iron-icon>`;
      }
   }

   matchedCellRenderer(params) {
      return (params.value === true) ? "<div class=\"check\">M</div>" : "<div class=\"normal\">N</div>";
   }

   mergedCellRenderer(params) {
      return (params.value === true) ? "<div class=\"check\">M</div>" : "<div class=\"normal\">N</div>";
   }

   sexCellRenderer(params) {
      if (params.value === "F") return "F";
      return ((params.value === "M")  ? "M" : "O");
   }

   getTechFilters() {
      return new Promise((resolve, reject) => {
         fetch("/api/tech/studylist/filters", {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            }
         }).then((response) => {
            if(response.ok) {
               response.json().then((json) => {
                  resolve(json);
               });
            } else {
               reject(`Message: ${ response.status}`);
            }
         }).catch((err) => {
            reject(`Message: ${ JSON.stringify(err.message)}`);
         });
      });
   }

   retrieveWorklistByFilter(e) {
      return new Promise((resolve) => {
         const key = e.target.id;
         this.fetchGetUserFilter(key).then((result) => {
            resolve(result);
         }, (err) => {
            console.log(err);
         });
      });
   }

   fetchGetUserFilter(id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/filter/${id}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")
            }
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json()
                  .then((result) => {
                     resolve(result);
                  });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         });
      });
   }

   getToday() {
      return moment().format("YYYY-MM-DD");
   }

   getStudylist(params) {
      // #17774 [HPACS] 페이지 첫 진입시 다른탭에 있는 grid가 조회되는 문제
      if(this.selectedTab !== 1) {
         return;
      }

      // console.log("getStuidyList", params.request.filterModel);
      // this.dispatchEvent(new CustomEvent("clearTechTabWindowEvent"));
      const { filterModel, sortModel } = params.request;
      params.request.filterModel = TechnicianUtils.conversionFilter(filterModel);
      // SortModel이 적용된 상태에서 AG-GRID에서 필터 변경 이벤트 마다 바인드 해서 sort model이 있을때, 모든 파라미터 전달을 위해 return
      if (this.techlistFilterState) {
         // 적용된 sort Model이 있는 경우
         if (!CommonUtils.isEmptyArr(this.techlistFilterState.detail.sortModel)) {
            // 적용된 sort Model이 있지만, request에서 sortModel이 없는 경우 => filterModel 부터 Set 하기 때문
            if (CommonUtils.isEmptyArr(sortModel)) {
               // Worklist SortModel을 통해서 이미 적용된 Sorting에 대해 분기 처리
               if (!this.techlistSortModel) {
                  return;
               } else if(CommonUtils.isEmptyArr(this.techlistSortModel.appliedSortModel)) {
                  return;
               }
            }
            // 적용된 Sort Model이 없을때(Clear, Month 변경) 파라미터에 sortModel이 없으면 조회 X
         } else if(!CommonUtils.isEmptyArr(this.techlistSortModel) && !CommonUtils.isEmptyArr(sortModel)) {
            if (!CommonUtils.isEmptyArr(this.techlistSortModel.appliedSortModel)) {
               const appliedSort = this.techlistSortModel.appliedSortModel[0];
               const filterModelKeys = Object.keys(filterModel).sort();
               const filterStateKeys = Object.keys(this.techlistFilterState.detail.filterModel).sort();
               // sort Id, sort direction과 filter의 정보를 통해 조회
               if (appliedSort.colId === sortModel[0].colId && appliedSort.sort === sortModel[0].sort
                  && CommonUtils.arrayEquals(filterModelKeys, filterStateKeys)
                  && this._startRow === params.request.startRow) return;
            }
         }
      }

      if(this.g_quickFilter){
         params.request.filterModel.isQuickFilter = true;
         this.g_quickFilter = false;
      }
      // TODO: #17446 퀵필터 여부 체크, 추후 체크 방법 변경 필요
      else if(params.request.filterModel.patientID && params.request.filterModel.patientName
         && params.request.filterModel.patientID.filter === params.request.filterModel.patientName.filter
         && params.request.filterModel.patientID.filterType === params.request.filterModel.patientName.filterType) {
         params.request.filterModel.isQuickFilter = true;
      }

      // Object.entries(params.request.filterModel)
      //    .map(m => m[1])
      //    .filter(m => m.filterType === "date")
      //    .map((m) => {
      //       if(m.dateFrom) Object.assign(m, {dateFrom: this.formatDate(m.dateFrom, "YYYY-MM-DD")});
      //       if(m.dateTo) Object.assign(m, {dateTo: this.formatDate(m.dateTo, "YYYY-MM-DD")});
      //       return m;
      //    });

      // #17773 [HPACS > 전수령 원장님] 워크리스트 하단 W 값이 - 로 표기 되는 오류
      if(this.abortController && this.fetchTimeStamp !== this.filterTimeStamp) {
         this.abortController.abort();
      }

      this.fetchTimeStamp = this.filterTimeStamp;
      this.abortController = new AbortController();
      const { signal } = this.abortController;
      fetch("/api/tech/studylist", {
         signal,
         method: "POST",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(params.request)
      }).then((response) => {
         if (response.ok) {
            response.json().then((httpResponse) => {
               let refreshFlag = false;
               if (httpResponse.rows.length > 0) {
                  // UTC 시간 추가(Asia/Seoul +9)
                  for (let i = 0; i < httpResponse.rows.length; i++) {
                     const row = httpResponse.rows[i];
                     const duplicateRow = this.gridOptions.api.getRowNode(row.id);

                     if(!refreshFlag && duplicateRow) {
                        refreshFlag = true;
                     }

                     if (row.requestDtime) {
                        // row.requestDtime = this.toLocalTime(row.requestDtime);
                        row.requestDtime = CommonUtils.convertTimestampToDate(row.requestDtime);
                     }

                     if (row.confirm) {
                        // row.confirm = this.toLocalTime(row.confirm);
                        row.confirm = CommonUtils.convertTimestampToDate(row.confirm);
                     }
                  }

                  this._startRow = params.request.startRow; // 프리패치, 두번째 페이지 확인용
                  let cnt = this._startRow;
                  for (const row of httpResponse.rows) {
                     cnt++;
                     row.no = cnt;
                  }

                  if(refreshFlag) {
                     this.purgeEnterpriseCache();
                     this.filterChange();
                  } else {
                     params.success({
                        rowData: httpResponse.rows||[],
                        rowCount: httpResponse.lastRow
                     });
                     this.initStruct(httpResponse.rows);
                     this.gridOptions.api.hideOverlay();
                  }

                  // Merge/Split처리후 row select
                  if (this.datasourceUpdateRows.length > 0) {
                     this.gridOptions.api.deselectAll();
                     httpResponse.rows.forEach((row) => {
                        this.datasourceUpdateRows.forEach((updateRow) => {
                           if (row.id === updateRow.id) {
                              this.gridOptions.api.getRowNode(row.id).setSelected(true);
                           }
                        });
                     });
                     this.datasourceUpdateRows = [];
                  }

                  if(params.request.startRow > 0 && httpResponse.lastRow !== null) {
                     this.dispatchEvent(new CustomEvent("syncCountEvent", {detail: httpResponse.lastRow}));
                  }
               } else {
                  params.success({
                     rowData: httpResponse.rows,
                     rowCount: httpResponse.lastRow
                  });
                  this.initStruct(httpResponse.rows);
                  this.gridOptions.api.showNoRowsOverlay();
               }
               store.dispatch({ type: TechlistActionType.SET_APPLIED_SORT_MODEL, payload: { appliedSortModel: this.getSortModel() } });
            });
         } else if(response.status === 401 || response.status === 403) {
            const detail = {msg: "Access denined!.", isErr: true};
            this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
            setTimeout(() => {window.location.href = "/home";}, 100);
         } else {
            const detail = {msg: "Worklist loading error.", isErr: true};
            this.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail}));
         }
      }).catch((err) => {
         console.error(err);
      });
   }

   /**
    * 선택한 탭의 date toggle 설정(탭 변경시 기억)
    * @param dateFilter
    */
   setDateFilter(dateFilter) {
      this.g_dateFilter = dateFilter;
   }

   /**
    * 선택한 탭의 date toggle 정보
    * @returns {*}
    */
   getDateFilter() {
      return this.g_dateFilter;
   }

   /**
    * Date Filter Toggle 을 위한 이름 조회
    * @param filterModel
    * @returns {null}
    */
   getDateFilterName(filterModel) {
      if(!filterModel) return null;

      let dateFilter = null;
      const {studyDtime: model} = filterModel;
      if(model && model.isRelative) {
         if(model.type === "equals")
            dateFilter = "today";
         else if(Number(model.amount) === 3 && model.unit === "days")
            dateFilter = "threeDays";
         else if(Number(model.amount) === 1 && model.unit === "weeks")
            dateFilter = "week";
         else if(Number(model.amount) === 1 && model.unit === "months")
            dateFilter = "oneMonths";
         else if(Number(model.amount) === 2 && model.unit === "months")
            dateFilter = "twoMonths";
      }
      return dateFilter;
   }

   setFilter(filterModel) {
      // set global date filters
      Object.entries(filterModel).forEach(([key, value]) => {
         const {filterType, type, amount, unit, isRelative} = value;
         if(filterType === "date" && type === "inRelativeRange") { // relative range
            filterModel[key].dateFrom = DateFilterUtils.getRelativeDate(amount, unit);
            filterModel[key].dateTo = DateFilterUtils.getToday();
            this.appliedDateFilters[key] = value;
         } else if(filterType === "date" && type === "equals" && isRelative) { // today
            filterModel[key].dateFrom = DateFilterUtils.getToday();
            filterModel[key].dateTo = DateFilterUtils.getToday();
            this.appliedDateFilters[key] = value;
         }
      });
      // set toggle button
      if(!CommonUtils.isEmptyObject(this.appliedDateFilters)) {
         // active date toggle 조회
         this.dispatchEvent(new CustomEvent("getActiveDateToggleEvent")); // w-newfilm getActiveDateToggle
         // filterModel 의 date filter name 조회
         const dateFilter = this.getDateFilterName(filterModel);
         // active date toggle 과 filterModel 의 date filter name 이 다르면 set toggle button
         if(this.activeDateToggle !== dateFilter) {
            this.dispatchEvent(new CustomEvent("setDateToggleEvent", { detail: dateFilter }));
         }
      }

      // ag-grid-enterprise.min.noStyle.js:8 Uncaught TypeError: Cannot read property 'length' of undefined 오류 수정(2021. 3. 11 - dave.oh)
      try {
         this.gridOptions.api.setFilterModel(filterModel);
      } catch(e) {}
   }

   reloadFilter() {
      const filterModel = this.getFilterModelIncludeDateFilter();
      this.setFilter(filterModel);
      // this.gridOptions.api.deselectAll();
   }

   /** 선택되어 있는 study를 새로 만들어진 study로 교체한다. */
   // replaceRowData(newData) { // changeRefreshAndSelectRows 로 대체
   //    // ModifyExam이 완료되면 새로운 Case아이디가 만들어지므로 Grid에서 이전 아이디를 찾기 위해 변경 내역을 조회한다.
   //    fetch(`/api/case/modifyInfo/${newData.modifiedInfoId}`, {
   //       method: "GET",
   //       headers: {
   //          "Authorization": localStorage.getItem("jwt"),
   //          "Content-Type": "application/json"
   //       }
   //    }).then((response) => {
   //       if (response.ok) {
   //          response.json().then((item) => {
   //             this.datasourceUpdateRows.push({id: newData.id});
   //             this.datasourceUpdateRows.push({id: item.originCaseId});
   //             this.gridOptions.api.refreshServerSideStore({ route: [], purge: true });
   //             this.filterChange();
   //             // 이전 Case id
   //             // let prevId = "";
   //             // if (item.modifiedInfoList.length === 1) {
   //             //    prevId = item.originCaseId;
   //             // } else {
   //             //    prevId = item.modifiedInfoList.slice(-2)[0].modifiedCaseId;
   //             // }
   //             //
   //             // this.gridOptions.api.forEachNode((node) => {
   //             //    const {data} = node;
   //             //    if (data.id === prevId) {
   //             //       data.id = newData.id;
   //             //       data.patientID = newData.patientID;
   //             //       data.patientAge = newData.patientAge;
   //             //       data.patientBirthDate = newData.patientBirthDate;
   //             //       data.patientName = newData.patientName;
   //             //       data.patientSex = newData.patientSex;
   //             //       data.accessionNumber = newData.accessionNumber;
   //             //       data.modality = newData.modality;
   //             //       data.bodypart = newData.bodypart;
   //             //       data.studyDtime = newData.studyDtime;
   //             //       data.studyDescription = newData.studyDescription;
   //             //       data.imageCount = newData.imageCount;
   //             //       this.gridOptions.api.redrawRows({row: data});
   //             //    }
   //             // });
   //          });
   //       }
   //    });
   //    this.filterChange();
   // }

   changeRefreshAndSelectRows(selectRows = []) {
      this.datasourceUpdateRows = selectRows;
      this.gridOptions.api.refreshServerSideStore({ route: [], purge: true });
      // count adjustment
      this.filterChange();
      // this.dispatchEvent(new CustomEvent("reloadOrderGridEvent"));
      // store.dispatch({ type: RelatedTechlistActionType.REFRESH_ORDER, payload: true });
   }

   changeRedrawSelectedRows(redraw = false) {
      if (!redraw) return;
      this.redrawStudyById();
      store.dispatch({ type: TechlistActionType.REDRAW_SELECTED_ROWS, payload: false });
   }

   /** study grid redraw */
   redrawStudyById() {
      const studies = [];
      for (const row of this.gridOptions.api.getSelectedRows()) {
         studies.push(row.id);
      }
      if (studies.length === 0) return;

      fetch(`/api/tech/studylist/studies`, {
         method: "POST",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(studies)
      }).then((response) => {
         if (response.ok) {
            response.json().then((study) => {
               this.gridOptions.api.forEachNode((rowNode) => {
                  const {data} = rowNode;
                  if (data === undefined) return;
                  study.forEach((item) => {
                     if (data.id === item.id) {
                        data.isMatch = item.isMatch;
                        data.accessionNumber = item.accessionNumber;
                        data.modality = CommonUtils.isEmptyValue(item.modality) ? "-" : item.modality;
                        data.bodypart = CommonUtils.isEmptyValue(item.bodypart) ? "-" : item.bodypart;
                        data.patientID = item.patientID;
                        data.patientAge = CommonUtils.isEmptyValue(item.patientAge) ? "-" : item.patientAge;
                        data.patientBirthDate = CommonUtils.isEmptyValue(item.patientBirthDate) ? "-" : item.patientBirthDate;
                        data.patientName = item.patientName;
                        data.patientSex = item.patientSex;
                        data.studyDescription = item.studyDescription;
                        data.studies = item.studies;
                        data.readingStatus = item.readingStatus;

                        data.verify = item.verify;
                        data.verifyEmail = item.verifyEmail;
                        data.verifyInfo = item.verifyInfo;
                        data.studyStatus = item.studyStatus;
                        data.edit = item.edit;

                        // #19162 [HPACS] 워크리스트의 Rep Doc 필터 오류
                        data.institutionalDepartmentName = item.institutionalDepartmentName;
                        data.requestHospital = item.requestHospital;

                        this.gridOptions.api.redrawRows({row: data});
                     }
                  });
               });
            });
         }
      });

      // this.filterChange();
   }

   clearSelectedRow() {
      this.gridOptions?.api?.deselectAll();
   }

   purgeEnterpriseCache() {
      this.gridOptions.api.refreshServerSideStore({ route: [], purge: true });
   }

   toLocalTime(dtime) {
      const localTime = moment(dtime).add(this._utcOffset, "h").toDate();
      return moment(localTime).format("YYYY-MM-DD HH:mm:ss");
   }

   /**
    * Prefetch 구조체 생성
    * index == 0 : First
    * index == 1 : Filter
    * index == 2 : reload
    * */
   initStruct(rows) {
      const requestObjectIdList = (rows||[]).map(row => {
         const returnObj = {}
         returnObj.id = row.id;
         returnObj.no = row.no;
         return returnObj;
      });

      const type = "filter";

      const request = { detail: { jwt : localStorage.getItem("jwt"), data : { requestObjectIdList }, type, startRow: this._startRow  } }; // Prefetch에서 계산을 위한 Row 시작 번호 추가
      this.dispatchEvent(new CustomEvent("initStructEvent", {bubbles: true, composed: true, detail: request.detail })); // 의뢰 및 이미지 구조체 생성 worker 실행
   }

   filterChange(v) {
      this.dispatchEvent(new CustomEvent("stopWorkerEvent", {bubbles: true, composed: true }));
      this.filterTimeStamp = new Date().getTime();
      // console.log("filter changed => ", this.gridApi.getFilterModel());
      // console.log("applied date filters => ", this.appliedDateFilters);
      const filterModel = this.gridApi.getFilterModel();

      if(this.g_quickFilter) {
         filterModel.isQuickFilter = true;
      }
      // TODO: #17446 퀵필터 여부 체크, 추후 체크 방법 변경 필요
      else if(filterModel.patientID && filterModel.patientName
         && filterModel.patientID.filter === filterModel.patientName.filter
         && filterModel.patientID.filterType === filterModel.patientName.filterType) {
         filterModel.isQuickFilter = true;
      }

      // global applied date filter 정리
      Object.entries(this.appliedDateFilters).forEach(([columnId, {type: globalType, dateFrom: globalDateFrom}]) => {
         // - global date filter 에는 있는데 현재 적용된 filter model 에 없거나
         // - 적용된 filter model 에 있는데 global filter 와 타입이 맞지 않거나
         // - global filter 에 today 인데 적용된 filter 와 date 가 다른 경우
         if(!filterModel[columnId]
            || (filterModel[columnId] && filterModel[columnId].type !== globalType)
            || (filterModel[columnId] && filterModel[columnId].type === "equals" && !moment(filterModel[columnId].dateFrom).isSame(moment(globalDateFrom)))) {
            delete this.appliedDateFilters[columnId];
         }
      });

      store.dispatch({ type: TechlistActionType.SET_APPLIED_FILTER, payload: { filterModel, appliedDateFilters: this.appliedDateFilters } });

      // const detail = TechnicianUtils.conversionFilter(filterModel);
      // detail.category = 1;

      // console.log("filterChange", request, detail);
      // this.dispatchEvent(new CustomEvent("checkDateFilterEvent", { detail: filterModel }));
      // this.dispatchEvent(new CustomEvent("checkUserFilterButtonEvent", { detail: filterModel }));
      // this.dispatchEvent(new CustomEvent("initCountEvent", { detail }));
      // this.displayFilter(detail);
      // this.displayFilter(filterModel);

      // apply button 클릭 후 filter List modal hidden
      // const floatingFilter = this.$.gridStudyList.querySelector(".ag-popup");
      // if(floatingFilter && v.afterFloatingFilter) {
      //    floatingFilter.hidden = true;
      // }
   }

   setDefaultFilterModel() {
      if(this.selectedTab !== 1)
         return;
      this.initDefaultFilter = true;
      // // const filter = this.gridApi.getFilterInstance("studyDtime");
      // const model = {
      //    dateFrom : this.lastThreeDays(),
      //    dateTo : this.getToday(),
      //    type : "inRelativeRange",
      //    filterType : "date",
      //    isRelative : true,
      //    amount : 3,
      //    unit : "days"
      // };
      // // filter.setModel(model);
      // // filter.applyModel();
      // this.setDateFilter("threeDays");
      // this.appliedDateFilters.studyDtime = model; // set global date filters
      // this.gridApi.setFilterModel({studyDtime : model});
      const model = {
         dateFrom: this.getToday(),
         dateTo: this.getToday(),
         type: "equals",
         filterType: "date",
         isRelative: true
      };
      this.setFilter({studyDtime : model});
   }

   dateComparator(filterLocalDateAtMidnight, cellValue) {
      // console.log("dateComparator", filterLocalDateAtMidnight, cellValue);

      const dateAsString = cellValue;
      const dateParts = dateAsString.split("/");
      const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
         return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
         return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
         return 1;
      }

      return 0;
   }

   // new Date -> YYYYMMDDHHMMSS
   convertDateToString(d) {
      if(!d || (d === "Invalid date") || (d === "null null")) return null;
      return moment(d.getTime()).format("YYYYMMDDHHmmss");
   }

   pIdpNameSearchNewFilm(param) {
      const patientID = {};
      const patientName = {};
      const filterModel = {};
      this.g_quickFilter = true;
      if (param.pId && param.pName) {
         patientID.filter = param.pId;
         patientID.filterType = "text";
         patientID.type = "contains";
         patientName.filter = param.pName;
         patientName.filterType = "text";
         patientName.type = "contains";
         filterModel.patientID = patientID;
         filterModel.patientName = patientName;
         // filterModel.isQuickFilter = this.g_quickFilter;

         // ag-grid-enterprise.min.noStyle.js:8 Uncaught TypeError: Cannot read property 'length' of undefined 오류 수정(2021. 3. 11 - dave.oh)
         try {
            this.gridOptions.api.setFilterModel(filterModel);
         } catch(e) {}
      }
   }

   getDateStr(myDate) {
      return (`${myDate.getFullYear()}-${myDate.getMonth()+1}-${myDate.getDate()}`);
   }

   dateFilterSearch(studyDtime) {
      const filterModel = this.getFilterModelIncludeDateFilter();
      if(studyDtime) filterModel.studyDtime = studyDtime;
      else delete filterModel.studyDtime;
      this.setFilter(filterModel);
   }

   // getClinicalInfo() {
   //    this.dispatchEvent(new CustomEvent("getClinicalInfoEvent", {bubbles: true, composed: true, detail: this.g_objId}));
   // }

   completedPrefetchMark(result) {
      if (Array.isArray(result)) {
         // Array 중복 제거
         result = result.reduce((a, b) => {
            if (a.indexOf(b) < 0) a.push(b);
            return a;
         }, []);

         for (const requestObjectId of result) {
            this.gridOptions.api.forEachNode((rowNode) => {
               if (rowNode.data) {
                  if (rowNode.data.id === requestObjectId) {
                     // rowNodes.push(rowNode);
                     rowNode.setDataValue("prefetch", "success");
                  }
               }
            });
         }
      } else {
         this.gridOptions.api.forEachNode((rowNode) => {
            if (rowNode.data.id === result) {
               rowNode.setDataValue("prefetch", "success");
               // rowNodes.push(rowNode);
            }
         });
      }

      this.gridOptions.getRowClass = (params) => {
         for (const i in this.rowNodes) {
            if (params.node === this.rowNodes[i]) {
               return "prefetch";
            }
         }
         return null;
      };

      // this.gridOptions.api.hideOverlay();
      // this.gridOptions.api.redrawRows({rowNodes: this.rowNodes});
   }

   prefetcStatushMark(message) {
      this.gridOptions.api.forEachNode((rowNode) => {
         if (rowNode.data) {
            if (rowNode.data.id === message.data) {
               if (message.msgType === "startPrefetch") {
                  rowNode.setDataValue("prefetch", "begin");
               } else if (message.msgType === "failPrefetch") {
                  rowNode.setDataValue("prefetch", "fail");
               } else if (message.msgType === "emptyPrefetch") {
                  rowNode.setDataValue("prefetch", "empty");
               } else if (message.msgType === "success") {
                  rowNode.setDataValue("prefetch", "success");
               } else if (message.msgType === "none") {
                  rowNode.setDataValue("prefetch", "none");
               }
            }
         }
      });
   }

   downloadToast(requestInfoList, title) {
      this.openDownloadToast(title);
   }

   openDuplicationDownloadToast(string) {
      const toast = this.$.duplicationDownloadToast;
      const toastTitle = this.$.duplicationDownloadToast_title;

      if (!toast.classList.contains("reveal")) {
         toast.classList.add("reveal");
      }
      toastTitle.innerText = string;
   }

   closeDuplicationDownloadToast() {
      this.$.duplicationDownloadToast.classList.remove("reveal");
   }

   openDownloadToast(string) {
      const toast = this.$.fileDownloadToast;
      const toastTitle = this.$.fileDownloadToast_title;

      if (!toast.classList.contains("reveal")) {
         toast.classList.add("reveal");
      }
      toastTitle.innerText = string;
   }

   closeDownloadToast() {
      this.$.fileDownloadToast.classList.remove("reveal");
   }

   downloadStart(requestInfoList, type) {
      if (this.eventSource) {
         const msg = "진행 중인 다운로드 완료 후 진행해 주세요.\n만약 다운로드가 완료된 경우 페이지 새로고침 후 진행해 주세요.";
         this.openDuplicationDownloadToast(msg);
         return;
      }

      const key = CommonUtils.uuidv4();
      this.closeDuplicationDownloadToast();
      this.openDownloadToast("Download Start !!");
      this.dcmImgCnt = 1;

      // eslint-disable-next-line no-undef
      this.eventSource = new EventSource(`${__API_URL__}/events?key=${key}`);
      this.eventSource.onmessage = (result) => {
         const {data} = result;
         const {eventId, msg, totalCount, completeCount, totalImgCount, completeImgCount, patientId, patientName, modality, studyDtime, type} = JSON.parse(data);
         switch(eventId) {
         case "DOWNLOAD": {
            let message = "Downloading File...";
            if (completeCount && totalCount) message += ` (${completeCount} / ${totalCount})`;
            if (patientId && patientName && modality && studyDtime) message += `\n ${patientId} / ${patientName} / ${modality} / ${moment(studyDtime, "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss")} `;
            if (totalImgCount && completeImgCount) {
               if (type === "dcm") {
                  message += ` (${this.dcmImgCnt} / ${totalImgCount}) `;
                  this.dcmImgCnt = this.dcmImgCnt !== totalImgCount ? this.dcmImgCnt + 1 : 1;
               }
               else message += ` (${completeImgCount} / ${totalImgCount}) `;
            }

            this.openDownloadToast(message);
            break;
         }
         case "COMPRESS": {
            this.openDownloadToast("Compressing File...");
            break;
         }
         case "COMPLETE": {
            this.stopServerSentEvent();
            break;
         }
         case "ERROR": {
            this.stopServerSentEvent();
            this.openDownloadToast(type === "dcm" ? `DICOM download error! - ${msg}` : `JPEG download error! - ${msg}`);
            break;
         }
         default:
         }
         // if (msg === "DOWNLOAD") {
         //    let message = "Downloading File...";
         //    if (completeCount && totalCount) message += ` (${completeCount} / ${totalCount})`;
         //    if (patientId && patientName && modality && studyDtime) message += `\n '${patientId}_${patientName}_${modality}_${studyDtime}'`;
         //    if (totalImgCount && completeImgCount) {
         //       if (type === "dcm") {
         //          message += ` (${this.dcmImgCnt} / ${totalImgCount}) `;
         //          this.dcmImgCnt = this.dcmImgCnt !== totalImgCount ? this.dcmImgCnt + 1 : 1;
         //       }
         //       else message += ` (${completeImgCount} / ${totalImgCount}) `;
         //    }
         //
         //    this.openDownloadToast(message);
         // } else if (msg === "COMPRESS"){
         //    this.openDownloadToast("Compressing File...");
         // } else if (msg === "COMPLETE") {
         //    this.stopServerSentEvent();
         // } else if (msg === "ERROR") {
         //    this.stopServerSentEvent();
         //    this.openDownloadToast(type === "dcm" ? "DICOM download error!" : "JPEG download error!");
         // }
      };

      this.eventSource.onopen = () => {
         console.log("EventSource open");
      };

      this.eventSource.onerror = (err) => {
         console.log("EventSource error: ", err);
         this.stopServerSentEvent();
      };

      if (requestInfoList && type) {
         this.downloadStudy(requestInfoList, type, key);
      }
   }

   stopServerSentEvent() {
      if (this.eventSource) {
         this.eventSource.close();
         console.log("close EventSource! ");
         this.eventSource = null;
      }
   }

   downloadStudy(requestInfoList, type, key) {
      let fileName = "";
      // eslint-disable-next-line no-undef
      fetch(`${__API_URL__}/dicom/download/study/list/${type}/key/${key}`, {
         method: "POST",
         headers: {
            "Authorization": localStorage.getItem("jwt"),
            "Content-Type": "application/json"
         },
         body: JSON.stringify(requestInfoList)
      }).then((response) => {
         if (response.ok && response.status === 200) {
            const header = response.headers.get("content-disposition");
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(header);
            if (matches != null && matches[1]) {
               fileName = decodeURIComponent(matches[1].replace(/['"]/g, ""));
            }
            return response.blob();
         }
         throw new Error(`[${response.status}] ${response.statusText}`);
      }).then((blob) => {
         download(blob, `${fileName}`, "application/octet-stream");
         this.openDownloadToast("Download Completed !!");
         setTimeout(() => this.closeDownloadToast(), 1000);
      }).catch((e) => {
         console.error(e.message, e);
         this.stopServerSentEvent();
         this.openDownloadToast([(type === "dcm" ? `DICOM download error!` : `JPEG download error!`), e.message].filter(v => v && v.trim() !== "").join("\n"));
      });
   }

   /**
    * Properties에서는 값을 가지고 있지만, 화면상에 selected된게 없을경우를 체크하기위함
    */
   getSelectRowLength() {
      if (CommonUtils.isEmptyObject(this.gridApi.getSelectedNodes())) return 0;
      return this.gridApi.getSelectedNodes().length;
   }

   openToast(message) {
      if (!message.isErr) {
         this.$.studyDescToast.style.backgroundColor = "#0087cb";
      } else {
         this.$.studyDescToast.style.backgroundColor = "#e46159";
      }

      this.$.studyDescToast.text = message.text;
      this.$.studyDescToast.open();
      // if (message.isErr) {
      //    return false;
      // }
   }

   splitStudylist(id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/tech/studylist/split/${id}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            }
         }).then((response) => {
            if(response.ok) {
               resolve(response);
            } else {
               // console.log("split studylist is Failed");
               resolve(false);
            }
         })
            .catch((err) => {
               reject(err);
            });
      });
   }

   navigateToNextCell(params) {
      let previousCell = params.previousCellPosition;
      const suggestedNextCell = params.nextCellPosition;

      const KEY_UP = 38;
      const KEY_DOWN = 40;
      const KEY_LEFT = 37;
      const KEY_RIGHT = 39;
      switch (params.key) {
      case KEY_DOWN:
         previousCell = params.previousCellPosition;
         // set selected cell on current cell + 1
         // TODO: orderCountClearEvent
         // store.dispatch({ type: RelatedTechlistActionType.CLEAR_ORDER });//this.dispatchEvent(new CustomEvent("orderCountClearEvent"));
         this.gridOptions.api.forEachNode((node) => {
            node.setSelected(false);
            if (previousCell.rowIndex + 1 === node.rowIndex) {
               node.setSelected(true);
            }
         });
         return suggestedNextCell;
      case KEY_UP:
         previousCell = params.previousCellPosition;
         // set selected cell on current cell - 1
         // TODO: orderCountClearEvent
         // store.dispatch({ type: RelatedTechlistActionType.CLEAR_ORDER });//this.dispatchEvent(new CustomEvent("orderCountClearEvent"));
         this.gridOptions.api.forEachNode((node) => {
            node.setSelected(false);
            if (previousCell.rowIndex - 1 === node.rowIndex) {
               node.setSelected(true);
            }
         });
         return suggestedNextCell;
      case KEY_LEFT:
         previousCell = params.previousCellPosition;
         // set selected cell on current cell - 1
         this.gridOptions.api.forEachNode((node) => {
            node.setSelected(false);
            if (previousCell.rowIndex === node.rowIndex) {
               node.setSelected(true);
            }
         });
         return suggestedNextCell;
      case KEY_RIGHT:
         previousCell = params.previousCellPosition;
         // set selected cell on current cell - 1
         this.gridOptions.api.forEachNode((node) => {
            node.setSelected(false);
            if (previousCell.rowIndex === node.rowIndex) {
               node.setSelected(true);
            }
         });
         return suggestedNextCell;
      default:
         throw new Error("this will never happen, navigation is always one of the 4 keys above");
      }
   }// navigation

   /**
    * ContextMenu 활성화 여부, Replace
    * @return {boolean}
    */
   isDisabledForReplaceContextMenu(rows) {
      if(rows.length > 1) return true;
      return false;
   }

   /**
    * ContextMenu 활성화 여부, Verify
    * @return {boolean}
    */
   isDisabledForVerifyContextMenu() {

   }

   /**
    * ContextMenu 활성화 여부, Unverify
    * @returns {boolean}
    */
   isDisabledForUnverifyContextMenu() {
      // 전부 verify 상태여야만 unverify 할 수 있음
      const verifyRows = this._selectedRows.filter(row => row.studyStatus === "verified");
      if(verifyRows.length === this._selectedRows.length)
         return false;
      return true;
   }

   /**
    * Contextmenu 활성화 여부, Merge/Split
    * @return {boolean}
    */
   isDisabledForMergeSplitContextmenu() {
      // Study가 한개만 선택되었을때 활성화 되는 조건은 선택한 Study가 Merge되어 있는 상태로 Split할수 있는 상태, Merge 상태라도 판독된 경우 Split 할 수 없음(#17582)
      if (this._selectedRows.length === 1 && this._selectedRows[0].isMerge && this._selectedRows[0].readingStatus !== "3A") return false;

      // Study가 여러개 선택되었을때는 활성화 그외는 비활성화
      if (this._selectedRows.length > 1) return false;
      return true;
   }

   getLabelForMergeSplitContextmenu() {
      const [ row ] = this._selectedRows;
      return row && row.merge ? this.t("label.cancelMerge") : this.t("label.mergeExam");
   }

   isDisabledForSplitExamContextmenu() {
      if (this._selectedRows.length === 1) return false;
      return true;
   }

   /**
    * Contextmenu 활성화 여부, Modify exam
    * @return {boolean}
    */
   isDisabledForModifyExamContextmenu() {
      // #15427
      // 한개의 Study가 선택되어야 하고, 선택된 Study가 verified상태가 아닌것만 활성화
      // if (this._selectedRows.length === 1 && this._selectedRows[0].studyStatus !== "verified") return false;
      if (this._selectedRows.length === 1) return false;
      return true;
   }

   /**
    * Contextmenu 활성화 여부, Delete
    * @return {boolean}
    */
   isDisabledForDeleteContextmenu() {
      if (this._selectedRows.length > 0 && this._selectedRows.find(row => row.studyStatus === "verified") === undefined) return false;
      return true;
   }

   /**
    * Contextmenu 활성화 여부, Unlock
    * @return {boolean}
    */
   isDisabledForUnlockContextmenu() {
      if (this._selectedRows.length > 0) return false;
      return true;
   }

   /**
    * Merge or Split, 어떤 처리를 할지 결정
    * 조건 - Merge/Split Contextmenu 활성화 조건이 선행되어야함.
    * @return {boolean}
    */
   isToBeMerge() {
      if (this._selectedRows.length === 1) return false;
      return true;
   }

   /**
    * 선택된 row 를 비활성화
    *
    */
   // diabledSelectedRows() {
   //    this.gridOptions.api.forEachNode((node) => {
   //       node.setSelected(false);
   //    });
   // } // selectedRow

   // studyCountClear(){
   //    this.studyCnt = 0;
   //    this._selectedRows = [];
   //    store.dispatch({ type: TechlistActionType.CLEAR_ROW_SELECTION });
   //    this.gridOptions.api.deselectAll();
   //
   //    // grid-order에서 갖고 있는 select study row를 초기화
   //    // this.dispatchEvent(new CustomEvent("clearStudyRowsInOrder", {bubbles: true, composed: true}));
   // }

   // selectedOrderRow(order){
   //    this._selectedOrder = order;
   // }

   /**
    * 넘겨진 params 와 매칭된 row를 선택
    *
    */
   selectedRowToMatched(params) {
      const selectedOrderRow = params;
      // order 선택시 matching 된 study 를 찾아 선택
      // 이미 matching 된 study 가 선택되어 있다면 return
      // if (JSON.stringify(selectedOrderRow.studies.sort()) === JSON.stringify(this.gridOptions.api.getSelectedRows().map(r => r.id).sort())) return;
      // 이미 matching 된 study 가 하나라도 선택되어 있다면 return
      if (selectedOrderRow.studies.includes(this._row?.id)) return;

      let lastSelectedRowIndex;
      this.gridOptions.api.forEachNode((node) => {
         if (node.isSelected()) node.setSelected(false);
         if (node.data !== undefined && node.data.isMatch) {
            if(node.data.studies.includes(selectedOrderRow.id)) {
               node.setSelected(true);
               lastSelectedRowIndex = node.rowIndex;
            }
         }
      });
      if (lastSelectedRowIndex) this.gridApi.ensureIndexVisible(lastSelectedRowIndex); // 선택된 row 로 자동 스크롤
      // if(this.studyCnt === 0) {
      //    this.studyCnt++;
      // }
   } // selectedRow

   displayPrev() {
      if (this.gridOptions.api.getSelectedNodes()[0]) {
         let {rowIndex} = this.gridOptions.api.getSelectedNodes()[0];
         if (rowIndex > 0) {
            rowIndex--;
         }
         this.gridOptions.api.forEachNode((node) => {
            if (node.rowIndex === rowIndex) {
               node.setSelected(true);
               this.displayFilmbox(node.data.id);
               this.gridOptions.api.ensureIndexVisible(rowIndex);
            } else {
               node.setSelected(false);
            }
         });
      } else {
         this.gridOptions.api.forEachNode((node) => {
            if (node.rowIndex === 0) {
               // node.selected(false, false);
               node.setSelected(true);
               this.displayFilmbox(node.data.id);
               this.gridOptions.api.ensureIndexVisible(node.rowIndex);
            } else {
               node.setSelected(false);
            }
         });
      }
   }

   displayNext() {
      if (this.gridOptions.api.getSelectedNodes().length > 0) {
         let maxIndex = Math.max(...this.gridOptions.api.getSelectedNodes().map(o => o.rowIndex), 0);
         maxIndex++;
         this.gridOptions.api.forEachNode((node) => {
            if (node.rowIndex === maxIndex) {
               node.setSelected(true);
               this.displayFilmbox(node.data.id);
               this.gridOptions.api.ensureIndexVisible(maxIndex);
            } else {
               node.setSelected(false);
            }
         });
      } else {
         this.gridOptions.api.forEachNode((node) => {
            if (node.rowIndex === 0) {
               node.setSelected(true);
               this.displayFilmbox(node.data.id);
               this.gridOptions.api.ensureIndexVisible(node.rowIndex);
            } else {
               node.setSelected(false);
            }
         });
      }
   }

   getFilter() {
      return this.getFilterModelIncludeDateFilter();
   }

   oldFilmFilmboxOpen(related) {
      // related.rel1 = !related.rel1 ? this.g_objId : related.rel1;
      related.rel1 = !related.rel1 ? this._row?.id : related.rel1;

      let rel = related.rel1;
      if(related.group === "old") rel = related.rel2;

      // #18030 [HPACS > Dev2] 필름박스 리팩토링 : Related / New Exam 나오지 않는 이슈
      if(related.group === "old" && related.type === "click") related.rel1 = this._row?.id; // this.g_objId;

      const prevHash = !this.popup_tech ? "" : this.popup_tech.location.hash;
      const type = `&group=${related.group}&type=${related.type}&expand=${related.expand}`;
      let hash = `#${rel.split(`,`).map(r => r.concat(type)).toString()}`;

      // #18030 [HPACS > Dev2] 필름박스 리팩토링 : Related / New Exam 나오지 않는 이슈
      if(related.group === "old" && related.type === "click") hash += `&newId=${related.rel1}`;

      const url = `/filmbox${hash}`;

      this.popup_tech = window.open(url, this.popupName, this.popupOpts);
      // window.document.filmbox = this.popup_tech;
      this.popup_tech.focus();

      if(prevHash === hash) {
         this.popup_tech.dispatchEvent(new CustomEvent("hashchange"));
      }

      // if(related.rel2) {
      //    const prevHash = !this.popup_tech ? "" : this.popup_tech.location.hash;
      //    const hash = `#${related.rel1}&old=${related.rel2}`;
      //    const url = `/filmbox${hash}`;
      //
      //    this.popup_tech = window.open(url, this.popupName, this.popupOpts);
      //    window.document.filmbox = this.popup_tech;
      //    this.popup_tech.focus();
      //    if(prevHash === hash) {
      //       this.popup_tech.dispatchEvent(new CustomEvent("hashchange"));
      //    }
      // } else { // new 지정없이 filmbox를 띄울 수 없음
      //    this.dblClickedId = related.rel1;
      //    this.dispatchEvent(new CustomEvent("gridStudyDblClickEvent", { bubbles: true, composed: true}));
      // }
   }

   caseUnlock() {
      const params = {};
      params.caseIdList = [];

      this._selectedRows.forEach((row) => {
         params.caseIdList.push(row.id);
      });

      TechnicianUtils.caseLockUpdate({ flag: false, list: params }).then((result) => {
         if (result) {
            // redraw selected technician rows
            store.dispatch({ type: TechlistActionType.REDRAW_SELECTED_ROWS, payload: true });
         } else {
            document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: this.t("msg.caseLockFail"), isErr: true} }));
         }
      });
   }

   /**
    * Study를 판독의사가 보고있는지, 누가 보고있는지 알려줌
    * @param rows
    * @return {Promise<unknown>}
    */
   viewingStudy(rows = []) {
      if (rows.length === 0) {
         document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: "Please select one study.", isErr: true} }));
         return;
      }
      return new Promise((resolve, reject) => {
         const studies = rows.map(row => row.id);
         fetch(`/api/tech/studylist/studies`, {
            method: "POST",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(studies)
         }).then((response) => {
            if (response.ok) {
               response.json().then((study) => {
                  const r = {};
                  // 편집여부
                  r.edit = study.map(s => s.edit).some(st => st);
                  // 판독여부
                  r.isViewing = study.map(s => s.reader).some(st => st && !CommonUtils.isEmptyObject(st) && st.userId); // #18511 reader 값이 빈 값이 들어가는 경우가 있어 userId 체크 추가

                  // eslint-disable-next-line prefer-destructuring
                  r.reader = study.map(s => s.reader).filter(s => s!==undefined)[0];
                  resolve(r);
               });
            }
         });
      });
   }

   /**
    * 사용자에게 판독의사 정보를 표시하는 Dialog
    * @param reader
    *          reader.edit, 편집중인 study
    *          reader.isViewing, 판독중인 study
    *          reader.reader, 판독의사 정보
    */
   showCustomDialog(viewing) {
      const message = {};
      message.title = "Cannot be edited";
      message.ok = this.t("button.ok");
      if (viewing.edit) {
         message.contents = [
            `Another Technician is editing the study now.`
         ];
         message.size = { width: 325, height: 160 };
      } else {
         message.contents = [
            `${viewing.reader.userName} is reading now.`,
            `When reading, it cannot be edited.`
         ];
         message.size = { width: 260, height: 195 };
      }
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, message, open: true } });
   }

   /**
    * #14374
    * Contextmenu의 Match, Unmatch 활성화 및 비활성화 상태 리턴
    * @return  N  -> Match, Unmatch가 비활성화 상태
    *          U  -> Unmatch만 활성화 상태
    *          M  -> Match 활성화 상태, 1:n 매칭칭
    *          MS -> Match만 활성화 상태, 같은 Match group에 Unmatch된 Study를 추가할때
    *          MD -> Match만 활성화 상태, 그외의 Order, Study를 Match할때
    */
   getStudyMatchStatusCode() {
      this.matchMsgCode = "N";
      if (this.isIncludedVerified(this._selectedRows)) this.matchMsgCode = "W";

      // Study의 row가 하나도 선택되지 않은 상태에서는 Match/Unmatch가 비활성화 된다.
      if (Object.keys(this._selectedRows).length === 0) {
         // console.log("====>1");
         this.isDisabledMatch = true;
         this.isDisabledUnMatch = true;
         this.matchMsgCode += ",N";
         return;
      }

      // 선택한 Study가 같은 group일때 Unmatch만 활성화
      if (Object.keys(this._selectedRows).length > 1 && this.isSameStudyMatchGroup()) {
         // console.log("====>10");
         this.isDisabledMatch = true;
         this.isDisabledUnMatch = false;
         this.matchMsgCode += ",U";
         return;
      }

      // Match할 대상 Order가 선택이 안되었을때
      if (!this._selectedOrder) {
         // Study 한개 선택, Match된 row이면 Unmatch 활성화
         if (Object.keys(this._selectedRows).length === 1 && this.isIncludedMatch(this._selectedRows)) {
            // console.log("====>2");
            this.isDisabledMatch = true;
            this.isDisabledUnMatch = false;
            this.matchMsgCode += ",U";
            return;
         }
         // console.log("====>3");
         this.isDisabledMatch = true;
         this.isDisabledUnMatch = true;
         this.matchMsgCode += ",N";
         return;
      }

      // 같은 Match group이 선택됐을때 또는 Study에서는 Match를 선택했지만 Order에서는 찾을수 없을때
      if (this.isSameMatchGroup(false)) {
         // console.log("====>4");
         this.isDisabledMatch = true;
         this.isDisabledUnMatch = false;
         this.matchMsgCode += ",U";
         return;
      }

      // Match만 활성화 상태
      this.isDisabledMatch = false;
      this.isDisabledUnMatch = true;

      // 같은 Match group에 Unmatch된 study를 추가할때
      // if (this.isSameMatchGroup(true)) {
      //    console.log("====>5");
      //    this.matchMsgCode += ",MS";
      //    return;
      // }

      // 사용자에게 보여줄 메시지 분기
      // 1:N의 일반적인 Match 메시지
      if (Object.keys(this._selectedRows).length > 0 && this.isAllUnmatch(this._selectedRows) &&
         this._selectedOrder && !this._selectedOrder.rows[0].isMatch) {
         // console.log("====>6");
         this.matchMsgCode += ",M";
         return;
      }

      // 그외
      // console.log("====>7");
      this.matchMsgCode += ",MD";
   }

   /**
    * Study, Order에서 선택되어 있는 row의 Match가 같은 그룹의 Match인지 체크
    * @param chk Boolean
    * @return Boolean
    */
   isSameMatchGroup(chk) {
      let isSame = true;
      if (!CommonUtils.isEmptyValue(this._selectedOrder?.rows[0]?.isMatch) && this._selectedOrder?.rows[0]?.isMatch) {
         // const orderMatchId = this._selectedOrder[0].studies[0];
         const orderMatchId = this._selectedOrder?.rows[0]?.id;
         for (const study of this._selectedRows) {
            // if (study.isMatch) {
            if (isSame && orderMatchId !== study.studies) isSame = false;
            // } else if (!chk) isSame = false;
         }
      } else {
         isSame = false;
      }
      return isSame;
   }

   /**
    * 한개 이상의 study를 선택했을때 선택한 study가 같은 그룹인지 체크
    * @return {boolean}
    */
   isSameStudyMatchGroup() {
      let isSame = true;
      let baseMatchId = "";
      let orderId = "";
      if (this._selectedOrder && Object.keys(this._selectedOrder.rows).length === 1) {
         orderId = this._selectedOrder.rows[0].id;
      }
      if (CommonUtils.isEmptyObject(this._selectedRows)) isSame = false;
      for (const study of this._selectedRows) {
         if (!CommonUtils.isEmptyValue(study.isMatch) && study.isMatch) {
            if (CommonUtils.isEmptyValue(baseMatchId)) {
               baseMatchId = study.studies;
            } else if (baseMatchId !== study.studies) {
               isSame = false;
            } else if (baseMatchId !== orderId) isSame = false;
         } else {
            // eslint-disable-next-line no-return-assign
            return isSame = false;
         }
      }

      return isSame;
   }

   /**
    * 선택된 row가 모두 Unmatch상태인지 체크
    * @param rows
    * @return {boolean}
    */
   isAllUnmatch(rows) {
      let isUnmatch = true;
      if (CommonUtils.isEmptyObject(rows)) return false;
      for (const row of rows) {
         if (row.isMatch) isUnmatch = false;
      }
      return isUnmatch;
   }

   /**
    * 선택된 row에 Match가 포함되는지 체크
    * @param rows
    * @return {boolean}
    */
   isIncludedMatch(rows) {
      let isMatch = false;
      if (CommonUtils.isEmptyObject(rows)) return false;
      for (const row of rows) {
         if (row.isMatch) {
            isMatch = true;
            return isMatch;
         }
      }
      return isMatch;
   }

   /**
    * Verified 된 Study를 포함하고 있는지 체크
    * @param rows
    * @return {boolean}
    */
   isIncludedVerified(rows) {
      let isVerified = false;
      if (CommonUtils.isEmptyObject(rows)) return false;
      for (const row of rows) {
         if (!CommonUtils.isEmptyValue(row.studyStatus) && row.studyStatus === "verified") {
            // eslint-disable-next-line no-return-assign
            return isVerified = true;
         }
      }
      return isVerified;
   }

   /**
    * 적용된 DateFilter 정보를 반영한 Grid FilterModel
    * @returns {{[p: string]: any} | void}
    */
   getFilterModelIncludeDateFilter() {
      const filterModel = this.gridApi.getFilterModel();
      Object.keys(this.appliedDateFilters).forEach((key) => {
         if(filterModel[key])
            filterModel[key] = this.appliedDateFilters[key];
      });
      return filterModel;
   }

   /**
    * DICOM Send 창을 표시한다(main-app 에서 표시한다.).
    */
   showDicomSend() {
      const rows = this.gridApi.getSelectedRows()||[];
      if(rows.length === 0) return;

      const message = { detail: { caseIds: rows.map(m => m.id) } };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.DICOM_SEND_DIALOG, actionType: DialogActionType.DICOM_SEND, message, open: true } });
   }

   sinkViewing(request) {
      if(!request) return;
      this.gridOptions.api.forEachNode((rowNode) => {
         const {data} = rowNode;
         if(data && data.id === request.id) {
            data.reader = request.reader;
            this.gridOptions.api.redrawRows({row: data});
         }
      });
   }

   getDefaultFilters() {
      return {
         bodypart: [],
         isMatch: ["(M) Matched", "(NM) Not Matched"],
         isMerge: ["(M) Merged", "(NM) Not Merged"],
         modality: [],
         patientSex: ["F", "M", "O"],
         readingStatus: ["W", "A", "H/O", "T"],
         requestHospital: [],
         isEmergency: ["E", "N", "C"],
         studyStatus: [
            this.t("label.studyStatus.inProgress"),
            this.t("label.studyStatus.verified"),
            this.t("label.studyStatus.completed")
         ]
      };

   }

   initGridColumns() {
      return new Promise((resolve) => {
         Promise.all([this.getTechFilters(), this.getUserStyle()]).then((values) => {
            const [filterResult, styleResult] = values;
            if((filterResult instanceof Error)) {
               this._filters = this.getDefaultFilters();
            } else {
               this._filters = filterResult;
            }

            if(styleResult instanceof Error) {
               const columnDefs = this.createColumnDefs();
               this.columnDefs = columnDefs;
               this.gridOptions.api.setColumnDefs(columnDefs);
            } else {
               if (styleResult.grid && styleResult.grid.techFilm && styleResult.grid.techFilm.length > 0) {
                  const {techFilm} = styleResult.grid;
                  // const columnDefs = this.cellRenderer(techFilm);
                  // this.columnDefs = this.changeFilterParams(columnDefs);

                  const columnDefs = GridUtils.mergeFilterParams(techFilm, this.createColumnDefs());
                  this.columnDefs = columnDefs;
                  this.gridOptions.api.setColumnDefs(columnDefs);
               } else {
                  // const columnDefs = this.cellRenderer(this.createColumnDefs());
                  const columnDefs = this.createColumnDefs();
                  this.gridOptions.api.setColumnDefs(columnDefs);

                  this.columnDefs = columnDefs;
                  this.updateGridHeader(this.createColumnDefs());
               }
            }

            this.initStudyList().then(resolve);
         }).catch((err) => {
            console.error(`init grid columns error[${err}]`);
            this.columnDefs = this.createColumnDefs();
            this.initStudyList.then(resolve);
         });
      });
   }

   createDatasource() {
      return {
         getRows: this.getStudylist.bind(this)
      };
   }

   setFilterSortClear() {
      const colDefs = this.gridOptions.api.getColumnDefs();
      colDefs.forEach((col) => {
         if(col.sort) {
            col.sort = null;
         }
      });

      this.gridOptions.api.setColumnDefs(colDefs);
      this.gridOptions.api.refreshHeader();
   }

   redrawVerifiedRows(requestList) {
      if(!requestList) return;
      this.gridOptions.api.forEachNode((rowNode) => {
         const {data} = rowNode;
         requestList.forEach((item) => {
            if (data && data.id === item.id) {
               data.verify = item.verify;
               data.verifyEmail = item.verifyEmail;
               data.verifyInfo = item.verifyInfo;
               data.studyStatus = item.studyStatus;
               this.gridOptions.api.redrawRows({row: data});
            }
         });
      });
   }

   redrawUpdateOpinionRows(requestList) {
      if(!requestList) return;
      this.gridOptions.api.forEachNode((rowNode) => {
         const {data} = rowNode;
         requestList.forEach((selectedRow) => {
            if (data && data.id === selectedRow.id) {
               data.readingStatus = selectedRow.readingStatus;
               data.studyStatus = selectedRow.studyStatus;
               data.readingDoctor = selectedRow.opinionUpdateUser;
               data.readingDoctorId = selectedRow.opinionUpdateUserId;
               data.teleStatus = selectedRow.teleStatus;
               data.verify = null;
               if(selectedRow.opinionUpdateDtime) data.confirm = CommonUtils.convertTimestampToDate(selectedRow.opinionUpdateDtime);
               if (selectedRow.verifyInfo && selectedRow.verifyInfo.length > 0) {
                  const verify = selectedRow.verifyInfo.find(v => (v.isActive));
                  if(verify) data.verify = verify.username;
               }
               this.gridOptions.api.redrawRows({row: data});
            }
         });
      });
   }

   onSelectOrder(selected) {
      if (this.category !== 1) return;

      if (!selected) return;
      const { row, rows, altKey } = selected;

      if (!altKey) {
         // order 선택시 matching 된 study 선택
         if (row?.isMatch === true) {
            this.selectedRowToMatched(row);
         } else {
            this.clearSelectedRow(); // this.diabledSelectedRows();
         }
      }
   }

   /**
    * Verify
    */
   verify() {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      if (CommonUtils.isEmptyObject(selectedRows)) {
         document.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail: {msg : this.t("msg.verify.selected"), isErr : true}}));
         return;
      }

      const message = {
         selectedRows,
         callback: (result) => {
            if (result) {
               this.redrawStudyById();
            } else {
               document.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail: {msg: this.t("msg.verify.fail"), isErr: true}}));
            }
         }
      };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.VERIFY_DIALOG, actionType: DialogActionType.VERIFY, message, open: true } });
   }

   /**
    * UnVerify
    */
   unVerify() {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      if (CommonUtils.isEmptyObject(selectedRows)) {
         document.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail: {msg : this.t("msg.verify.selected"), isErr : true}}));
         return;
      }

      const unVerify = () => {
         TechnicianUtils.unVerify(selectedRows).then(result => {
            if (result) {
               this.redrawStudyById();
               document.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail: {msg: "Exam Unverified.", isErr: false}}));
            } else {
               document.dispatchEvent(new CustomEvent("toastEvent", {bubbles: true, composed: true, detail: {msg: "Unverify Failed.", isErr: true}}));
            }
         });
      };
      const message = {
         contents: i18n("msg.unverify", {returnObjects: true}),
         title: i18n("label.unverify"),
         ok: i18n("button.accept"),
         cancel: i18n("button.cancel"),
         onOk: unVerify,
      };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.UN_VERIFY, message, open: true } });
   }

   /**
    * Match
    */
   match() {
      const studyRows = this.gridOptions.api.getSelectedRows();
      const orderRow = this._selectedOrder?.row;
      const match = () => {
         TechnicianUtils.match(studyRows, orderRow).then((result) => {
            if (result) {
               this.redrawStudyById();
               const orderIds = [ orderRow?.id ];
               //  #20261 기존 matched study인 경우 unmatch 되기 때문에 redraw 처리
               studyRows?.filter(study => study.studies).forEach(study => orderIds.push(study.studies));
               store.dispatch({ type: RelatedTechlistActionType.REDRAW_ORDER_ROWS, payload: orderIds });

               document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: this.t("msg.match.success"), isErr: false} }));
            } else {
               document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: this.t("msg.match.fail"), isErr: true} }));
            }
         });
      };
      const message = {
         contents: TechnicianUtils.makeMatchUnMatchMessage({matchMsgCode: this.matchMsgCode}),
         title: i18n("label.match"),
         ok: i18n("button.yes"),
         cancel: i18n("button.no"),
         onOk: match,
      };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.MATCH, message, open: true } });
   }

   /**
    * UnMatch
    */
   unMatch() {
      const studyRows = this.gridOptions.api.getSelectedRows();
      const orderRow = this._selectedOrder?.row;
      const unMatch = () => {
         TechnicianUtils.unMatch(studyRows, orderRow).then((result) => {
            if (result) {
               this.redrawStudyById();
               store.dispatch({ type: RelatedTechlistActionType.REDRAW_ORDER_ROWS, payload: [ orderRow?.id ] });
               document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: this.t("msg.unmatch.success"), isErr: false} }));
            } else {
               document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: this.t("msg.unmatch.fail"), isErr: true} }));
            }
         });
      };
      const message = {
         contents: TechnicianUtils.makeMatchUnMatchMessage({matchMsgCode: this.matchMsgCode}),
         title: i18n("label.unmatch"),
         ok: i18n("button.yes"),
         cancel: i18n("button.no"),
         onOk: unMatch,
      };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.UN_MATCH, message, open: true } });
   }

   /**
    * Delete Study
    */
   deleteStudy() {
      if (this._selectedRows.length === 0) {
         document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: "Please select study.", isErr: true} }));
         return;
      }
      const existVerifiedRows = this._selectedRows.find(row => row.studyStatus === "verified") !== undefined;
      if (existVerifiedRows) {
         document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: "Please change verified first.", isErr: true} }));
         return;
      }
      const studyRows = this._selectedRows;
      const deleteStudy = () => {
         TechnicianUtils.deleteStudyByIds(studyRows).then((result) => {
            if (result) {
               this.gridOptions.api.refreshServerSideStore({ route: [], purge: true });
               // count adjustment
               this.filterChange();

               const orderIds = studyRows?.filter(study => study.studies).map(study => study.studies);
               // 삭제하는 검사가 match 된 검사라면 order 를 찾아 redraw 해줌
               if (!CommonUtils.isEmptyArr(orderIds)) store.dispatch({ type: RelatedTechlistActionType.REDRAW_ORDER_ROWS, payload: orderIds });
               document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: "Exam Deleted.", isErr: false} }));
            } else {
               document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: "Delete Failed.", isErr: true} }));
            }
         });
      };
      const message = {
         contents: [ "Deleted studies cannot be recovered. Do you want to proceed?" ],
         title: i18n("label.delete"),
         ok: i18n("button.yes"),
         cancel: i18n("button.no"),
         onOk: deleteStudy,
      }
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.DELETE_STUDY, message, open: true } });
   }

   modifyExam() {
      const message = { detail : this._selectedRows[0] };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.MODIFY_EXAM_DIALOG, actionType: DialogActionType.MODIFY_EXAM, message, open: true } });
   }

   splitExam() {
      const message = { detail: this._selectedRows[0] };
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.SPLIT_EXAM_DIALOG, actionType: DialogActionType.SPLIT_EXAM, message, open: true } });
   }

   /**
    * Merge Exam
    */
   mergeExam() {
      if (this._selectedRows.length < 2) {
         document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: "Please select more than one study.", isErr: true} }));
         return;
      }

      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.MERGE_EXAM_DIALOG, open: true } });
      // this.$.techtabMergeDialog.setRowDatas(this._selectedRows);
      // this.$.techtabMergeDialog.doOpen();
   }

   /**
    * Cancel Merge
    */
   cancelMerge() {
      if (this._selectedRows.length === 0) {
         document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: "Please select study.", isErr: true} }));
         return;
      }
      const cancelMerge = () => {
         this.splitStudylist(this._selectedRows[0].id).then((result) => {
            if (result) {
               result.json().then((rows) => {
                  this.datasourceUpdateRows = rows;
                  this.gridOptions.api.refreshServerSideStore({ route: [], purge: true });
                  // count adjustment
                  this.filterChange();

                  // this.dispatchEvent(new CustomEvent("reloadOrderGridEvent"));
                  store.dispatch({ type: RelatedTechlistActionType.REFRESH_ORDER, payload: true });
               });

               document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: "Exam Split", isErr: false} }));
            } else {
               document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: "Split Fail.", isErr: true} }));
            }
         });
      };
      const message = {
         contents: ["Merged studies will be canceled.", "Do you want to proceed?"],
         title: i18n("label.cancelMerge"),
         ok: i18n("button.yes"),
         cancel: i18n("button.no"),
         onOk: cancelMerge,
      }
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.CONFIRM_DIALOG, actionType: DialogActionType.CANCEL_MERGE, message, open: true } });
   }

   changePrefetchState(prefetchState) {
      if (!prefetchState) {
         this.gridApi.forEachNode((rowNode) => {
            if(rowNode.data) {
               rowNode.setDataValue("prefetch", "success");
            }
         });
      }
   }

   displayFilmbox(id) {
      this.dblClickedId = id;
      this.gridStudyDblClickEvent();
   }

   gridStudyDblClickEvent() {
      if (!this.dblClickedId) return;

      this.getUserStyle().then((s) => {
         if (s && s.filmbox && s.filmbox.expand) {
            const {expand} = s.filmbox;
            this.filmboxExpand = expand;
         }
         let activeTechTab = 0;
         if (s?.tabIndex && s?.tabIndex.techlistBottom) {
            activeTechTab = s?.tabIndex.techlistBottom;
         }

         // this.dispatchEvent(new CustomEvent("gridStudyDblClickRespEvent", { bubbles: true, composed: true, detail: {activeTabCode, isActiveRelatedTab, expand: this.expand}}));
         // const {isActiveRelatedTab, activeTabCode, expand} = e.detail;
         // gridStudyDblClickRespEvent
         const key = this.dblClickedId;
         const {activeTabCode} = this;
         const expand = this.filmboxExpand;
         const isActiveRelatedTab = activeTechTab === 0;

         const prevHash = !this.popup_tech ? "" : this.popup_tech.location.hash;
         let hash = `#${key}&group=new&type=click&expand=${expand}`;
         if (isActiveRelatedTab) hash += `&related=${FilmboxUtils.convertRelatedTabCode(activeTabCode)}`;
         const url = `/filmbox${hash}`;

         this.popup_tech = window.open(url, this.popupName, this.popupOpts);
         // window.document.filmbox = this.popup_tech;
         this.popup_tech.focus();
         if (prevHash === hash) {
            this.popup_tech.dispatchEvent(new CustomEvent("hashchange"));
         }
      });
   }

   changeFilmboxState(filmboxState) {
      if (filmboxState) {
         const { caseId, seriesId, imageIndex, related } = filmboxState;
         // TODO: 로직 체크 필요

         // ThumbnailDoubleClickEvent
         if (seriesId && imageIndex) {
            const url = `/filmbox#${caseId}&series=${encodeURIComponent(seriesId)}&index=${imageIndex}&expand=F`;
            this.popup_tech = window.open(url, this.popupName, this.popupOpts);
         } else if (related) {
            this.oldFilmFilmboxOpen(related);
         }
      }
   }

   getSortModel() {
      const columnState = this.gridOptions.columnApi.getColumnState();
      return columnState.filter(item => item.sort !== null);
   }
}
window.customElements.define(GridStudy.is, GridStudy);
