// eslint-disable-next-line  import/extensions
// import {Noop} from "./FP.js";

export function hasSequenceKey(triggers=[]) {
   return triggers.reduce((acc, tr) => {
      if(acc===true) return true;
      return isSequenceKey(tr);
   }, false);
}

export function isSequenceKey(trigger) {
   return (typeof trigger === "string") && trigger.trim().split(" ").length > 1;
}

