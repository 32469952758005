import React, { useEffect, useRef, useState } from "react";

import "../../../public/ag-license";

import "../../hh-worklist";

// import * as Sentry from "@sentry/browser";
// import { Integrations } from "@sentry/tracing";

// import $ from "cash-dom";
// @ts-ignore
import { useEventListener } from "usehooks-ts";
import store from "../../redux/store";
import { QueryKey } from "../../redux/reducers/query";
import Dialog from "../../components/global/Dialog";
import Toast from "../../components/global/Toast";
import Worklist from "../worklist/Worklist";
import { useToastDispatch } from "../../context/ToastContext";
import useServerSentEvent from "../../hooks/useServerSentEvent";
import usePrefetch from "../../components/prefetch/usePrefetch";

// if (__SENTRY_DSN__ && __SENTRY_DSN__.trim() !== "") {
//    Sentry.init({
//       dsn: __SENTRY_DSN__,
//       integrations: [
//          new Integrations.BrowserTracing(),
//       ],
//
//       tracesSampleRate: 1.0,
//    });
// }

function MainApp() {
   const toastDispatch = useToastDispatch();

   usePrefetch();
   useServerSentEvent();

   useEffect(() => {
      initModalBody();
   }, []);

   // TODO: FLeader.popupNotice 에서 호출하고 있어 임시로 추가 (2022.09.08)
   useEventListener("toastEvent", (e: any) => {
      const { isErr, msg } = e.detail;
      toastDispatch({ type: "SET_TOAST", open: true, msg, isErr });
   });

   function initModalBody() {
      getColFilter("modality").then((result) => {
         store.dispatch({ type: QueryKey.GET_MODALITY_LIST, payload: { result } });
      });

      getColFilter("bodypart").then((result) => {
         store.dispatch({ type: QueryKey.GET_BODYPART_LIST, payload: { result } });
      });
   }

   function getColFilter(col: string) {
      return new Promise((resolve, reject) => {
         fetch(`/api/exchange/worklist/colfilter/${col}`, {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
            },
         }).then((response) => {
            if (response.status === 200) {
               response.json().then((data) => {
                  resolve(data);
               });
            } else {
               resolve(null);
            }
         }).catch(err => reject(err));
      });
   }

   // const g_caseId = useSelector((state : RootState) => state.report.thumbnailRow);
   // const g_oldCaseId = useSelector((state : RootState) => state.relatedWorklist.row);
   // const downloadList : Array<object> = [];

   // download toast state
   // const [downloadToastState, setDownloadToastState] = useState({ open:false, msg:"" });

   // useEventListener("downloadedEvent", (e) => {
   //    downloadedToast(e.detail);
   // });

   /**
    * CD burning CaseID 전달
    *
    * Create by 김민정 on 2018-11-20 오후 4:24
    * @param {} g_caseId
    * @return {} g_caseId
    * */
   // function getCaseID() {
   //    // console.log(g_caseId);
   //    return g_caseId;
   // }

   // function downloadToast(requestInfoList) {
   //    if (downloadList.length < 1) {
   //       downloadList = requestInfoList;
   //       const title = createDownloadTitle();
   //       openDownlaodToast(title);
   //    } else {
   //       // for (const requestInfo of requestInfoList) {
   //       //    downloadList.push(requestInfo);
   //       // }
   //       for (let i = 0; i < requestInfoList.length; i++) {
   //          downloadList.push(requestInfoList[i]);
   //       }
   //       const title = createDownloadTitle();
   //       openDownlaodToast(title);
   //    }
   // }
   //
   // function downloadedToast(id) {
   //    for (let i = 0; i < downloadList.length; i++) {
   //       if (downloadList[i].id === id) {
   //          downloadList.splice(i, 1);
   //       }
   //    }
   //
   //    if (!downloadList.length) {
   //       closeDownloadToast();
   //    } else {
   //       const title = createDownloadTitle();
   //       openDownlaodToast(title);
   //    }
   // }
   //
   // function createDownloadTitle() {
   //    if (downloadList.length) {
   //       const prefix = `"${downloadList[0].patientId}_${downloadList[0].patientName}_${downloadList[0].modality}_${downloadList[0].studyDtime}.zip" `;
   //       let suffix;
   //       if (downloadList.length > 1) {
   //          suffix = ` and ${downloadList.length - 1} other. Compressing and collecting originals.`;
   //       } else {
   //          suffix = " Compressing and collecting originals.";
   //       }
   //       return prefix + suffix;
   //    }
   //    return "Compressing and collecting originals.";
   // }

   // function openDownlaodToast(string) {
   //    const toast = $.downloadToast;
   //    const toastTitle = $.downloadToast_title;
   //
   //    if (!toast.classList.contains("reveal")) {
   //       toast.classList.add("reveal");
   //    }
   //    toastTitle.innerText = string;
   // }
   //
   // function closeDownloadToast() {
   //    $.downloadToast.classList.remove("reveafunction hideOverlay() {
   //    //    $.worklist.hideOverlay();
   //    // }l");
   // }

   return (
      <>
         <Dialog/>
         <Toast/>
         <Worklist />
      </>
   );
}

export default MainApp;
