import { PolymerElement, html } from "@polymer/polymer/polymer-element";

import "@polymer/paper-dialog";
import "@polymer/paper-icon-button";
import "@polymer/iron-icon";
import "../components/healthhub-confirm-dialog";
import CommonUtils from "../../public/resource/js/utils/common";
import "@hpacs/healthhub-icons";
import GridUtils from "../utils/grid-utils";

import "ag-grid-polymer";
import { is_esc } from "./common-keyboard-handler";
import mixinCommons from "../common-mixin";
import TechnicianUtils from "../utils/technician-utils";
import store from "../redux/store";
import {TechlistActionType} from "../redux/reducers/techlist";
import {CommonActionType, DialogActionType, DialogType} from "../redux/reducers/common";
import {RelatedTechlistActionType} from "../redux/reducers/related-techlist";

class TechtabMergeDialog extends mixinCommons(PolymerElement) {
   static get is() {
      return "techtab-merge-dialog";
   }

   static get template() {
      return html`
      <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-grid.min.css">
      <link rel="stylesheet" href="/vendor/ag-grid-enterprise/dist/styles/ag-theme-balham-dark.min.css">
      <link rel="stylesheet" href="/resource/style/ag-grid-hpacs.css">
      <style>
         /* ag-grid css */
         ag-grid-polymer {
            width: calc(100% - 20px) !important;
            height: calc(100% - 20px) !important;
         }

         /* other css */
         paper-icon-button {
            width: 50px;
            height: 50px;
            float: right;
            color: #aaaaaa;
         }

         #okBtn {
            background: #0087cb;
         }

         #cancelBtn {
            background: #4c5667;
         }

         .btn {
            width: 122px;
            height: 35px;
            border: 0;
            font-size: 12pt;
            color: #ffffff;
            cursor: pointer;
            border-radius: 2px;
         }

         .classA {
            text-align: center;
            padding: 15px;
         }

         /* dialog size background */
         #techtabMerge {
            background: #2d333f;
            width: 580px;
            height: 405px;
         }

         .container{
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
         }

         .merge-title {
            background-color: #252934;
            height: 50px;
            width: 100%;
            color: #fff;
            border-bottom: 1px solid #4c5667;
         }

         .merge-list{
            height: calc(100% - 135px);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
         }

         .merge-container {
            width: 100%;
            height: 85px;
         }

         .text-attention{
            font-size: 13px;
            color: #fff;
            text-align: left;
            margin-left: 10px;
         }

         /*icon css*/
         .none {
            color: #4c5667;
         }

         .based {
            color: #0087cb;
         }

         ::-webkit-scrollbar {
               width: 15px;
               height: 15px;
            }

         ::-webkit-scrollbar-thumb {
            background-color: #6b6b6b;
            border-radius: 10px;
            background-clip: content-box;
            border: 3px solid rgba(255,255,255,0);
         }
      </style><!-- WARN: 하나이상의 on-iron-overlay-closed 핸들러를 설정하지 마세요. 반드시 모든 다이얼로그는 닫히는 신호를 FLeader에 보내야합니다 -->
      <paper-dialog id="techtabMerge" on-click="prevent_event_propagation" on-keydown="close_if_esc" on-keypress="prevent_event_propagation" on-keyup="prevent_event_propagation" on-iron-overlay-opened="dialog_opened" on-iron-overlay-closed="dialog_closed">
         <div class="container">
            <div class="merge-title">
               <p style="margin: 0; padding: 15px; float: left;">Merge</p>
               <paper-icon-button on-click="doClose" icon="healthhub:close"></paper-icon-button>
            </div>
            <div class="merge-list">
               <ag-grid-polymer
                        class="ag-theme-balham-dark"
                        rowData="{{rowData}}"
                        columnDefs="{{columnDefs}}"
                        gridOptions="{{gridOptions}}"
               ></ag-grid-polymer>
            </div>
            <div class="merge-container">
               <div class="text-attention">
                  (Please double-click a study on the list to base the merger on.)
               </div>
               <div class="classA">
                  <button id="okBtn" class="btn">Merge</button>
                  &nbsp;
                  <button id="cancelBtn" class="btn">Cancel</button>
               </div>
            </div>
         </div>
      </paper-dialog>
      <hh-confirm-dialog id="hhTechtabMergeConfirmDialog"></hh-confirm-dialog>
      `;
   }

   static get properties() {
      return {
         gridOptions: {
            type: Object,
         },
         columnDefs: {
            type: Array
         },
         _selectedRows: {
            type: Array,
            value: []
         }
      };
   }

   constructor() {
      super();

      this.gridOptions = {
         defaultColDef: {
            suppressMenu: true,
            sortable: true,
            resizable: true
         },
         rowSelection: "single",
         overlayNoRowsTemplate: `<span class="ag-overlay-no-rows-center">${this.t("label.noRecordsFound")}</span>`,
         onGridReady: () => {

         },
         onRowSelected: () => {

         },
         getContextMenuItems: (params) => {

            if(!params.node) return;

            return [
               "separator",
               {
                  name: "delete",
                  action: () => {
                     // 삭제기능
                     console.log("merge cancle -> delete row");
                  }
               },
               "separator"
            ];
         },
         onRowDoubleClicked: (e) => {
            // console.log("double click");
            this.changeBasedStudylist(e.node.data).then(() => {
               this.gridOptions.api.forEachNode((node) => {
                  node.setSelected(false);
                  if (node.data.based === "based") {
                     node.setSelected(true);
                  }
               });
            });
         }
      };

      this.columnDefs = [
         {
            headerName: "Based",
            field: "based",
            width: 60,
            cellRenderer: (params) => {
               if (params.value === "based") {
                  return "<iron-icon class=\"based\" icon=\"vaadin:check-square-o\"></iron-icon>";
               }
               return "<iron-icon class=\"none\" icon=\"vaadin:thin-square\"></iron-icon>";
            }
         },
         {headerName: "Count", field: "imageCount", width: 45, cellStyle: { "text-align": "right" } },
         {headerName: "No", field: "no", width: 40, cellStyle: { "text-align": "center" }},
         {headerName: "RS", field: "readingStatus", width: 50, cellStyle: { "text-align": "center" }, cellRenderer: params => GridUtils.rsCellRenderer(params.value)},
         {headerName: "EM", field: "isEmergency", width: 40, cellStyle: { "text-align": "center" }, cellRenderer: params => GridUtils.emCellRenderer(params.value)},
         {headerName: "Accession No", field: "accessionNumber", width: 90},
         {headerName: "ID", field: "patientID", width: 90, cellStyle: { "text-align": "center" }},
         {headerName: "Name", field: "patientName", width: 90},
         {headerName: "Sex", field: "patientSex", width: 40, cellStyle: { "text-align": "center" }},
         {headerName: "BirthDate", field: "patientBirthDate", width: 80, cellStyle: { "text-align": "center" }},
         {headerName: "StudyDate", field: "studyDtime", width: 138, cellStyle: { "text-align": "center" }},
         {headerName: "ReqDate", field: "requestDtime", width: 138, cellStyle: { "text-align": "center" }},
         {headerName: "StudyDesc", field: "studyDescription", width: 200},
         {headerName: "StudyInstanceUID", field: "studyInstanceUID", width: 100},
      ];
   }

   ready() {
      super.ready();

      ["click", "keydown", "keypress", "keyup"].forEach(eventType => this.$.techtabMerge.addEventListener(eventType, e => e.stopPropagation()));

      this.$.okBtn.addEventListener("click", () => {
         this.doOk();
      });

      this.$.cancelBtn.addEventListener("click", () => {
         this.doClose();
      });

      this.$.hhTechtabMergeConfirmDialog.addEventListener("confirm", () => {
         const studylist = [];
         let basedId;
         for(let i = 0; i < this._selectedRows.length; i++){
            studylist.push(this._selectedRows[i].id);
            if(this._selectedRows[i].based === "based") basedId = this._selectedRows[i].id;
         }

         this.mergeStudylist(studylist, basedId).then((result) => {
            if (result) {
               store.dispatch({ type: TechlistActionType.REFRESH_AND_SELECT_ROWS, payload: [ result ] });
               store.dispatch({ type: RelatedTechlistActionType.REFRESH_ORDER, payload: true });
               document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: "Exams Merged.", isErr: false} }));
               this.doClose();
            } else {
               document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: "Merge Failed.", isErr: true} }));
            }
         });
      });
   }

   close_if_esc(e) {
      e.stopPropagation();
      if(is_esc(e)) {
         this.doClose();
      }
   }

   prevent_event_propagation(e) {
      e.stopPropagation();
   }

   dialog_opened() {
      window.dialog_opened({dialog:this.$.techtabMerge, is_modal:true});
      store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.MERGE_EXAM_DIALOG, actionType: DialogActionType.MERGE_EXAM, open: true } });
   }

   dialog_closed() {
      this.caseLockUpdate(false);
      this.doClear();
      window.dialog_closed();
      store.dispatch({ type: CommonActionType.CLOSE_DIALOG });
   }

   doOpen() {
      if(this._selectedRows.length > 0) {
         this.gridOptions.api.setRowData(this._selectedRows);
         this.$.techtabMerge.open();
         this.caseLockUpdate(true);
      } else {
         console.log("not selected studies");
      }
   }

   setRowDatas(rows){
      this._selectedRows = rows;
   }

   doClear(){
      for (let i = 0; i < this._selectedRows.length; i++) {
         if (this._selectedRows[i].based === "based") {
            this._selectedRows[i].based = null;
         }
      }
   }

   doClose() {
      this.$.techtabMerge.close();
   }

   doOk() {
      let flag = false;
      // let baseId = "";
      for(let i=0; i < this._selectedRows.length; i++) {
         if(this._selectedRows[i].based === "based") {
            // baseId = this._selectedRows[i].id;
            flag = true;
            // break;
         }
         delete Object.getPrototypeOf(this._selectedRows[i]);
      }

      const msg = [];

      let isVerified = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const row of this._selectedRows) {
         if (!CommonUtils.isEmptyValue(row.studyStatus) && row.studyStatus === "verified") {
            isVerified = true;
         }
      }

      if (isVerified) {
         msg.push("Warning! One or more of the selected studies are verified.");
      }

      msg.push("Studies will be merged into one study.");
      // msg.push("If there's a report on the other side, the report will be deleted.");
      msg.push("Do you want to proceed?");

      if(flag) {
         const message = {
            contents : msg,
            title : "Merge",
            ok : "Yes",
            cancel : "No"
         };

         this.$.hhTechtabMergeConfirmDialog.doCustomSizeOnlyWidth("430");
         this.$.hhTechtabMergeConfirmDialog.doOpen(message);
      } else {
         const params = { detail : { message : { msg : "You must select one study to base the merger on.", isErr : true } } };
         this.dispatchEvent(new CustomEvent("openToastEvent", params));
      }
   }

   changeBasedStudylist(selectedRow){
      return new Promise((resolve) => {
         for (let i = 0; i < this._selectedRows.length; i++) {
            if (selectedRow.id === this._selectedRows[i].id) {
               this._selectedRows[i].based = "based";
            } else {
               this._selectedRows[i].based = null;
            }
         }

         this.gridOptions.api.setRowData(this._selectedRows);
         resolve(true);
      });
   }

   mergeStudylist(param,id) {
      return new Promise((resolve, reject) => {
         fetch(`/api/tech/studylist/merge/${id}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt"),
               "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
         }).then((response) => {
            if (response.ok) {
               response.json().then((httpResponse) => {
                  resolve(httpResponse);
               });
            } else {
               reject(new Error(`${response.status} ${response.statusText}`));
            }
         }).catch(err => reject(err));
      });
   }

   caseLockUpdate(flag) {
      const params = {};
      params.caseIdList = [];

      this._selectedRows.forEach((item) => {
         params.caseIdList.push(item.id);
      });

      TechnicianUtils.caseLockUpdate({ flag, list: params }).then((result) => {
         if (result) {
            // redraw selected technician rows
            store.dispatch({ type: TechlistActionType.REDRAW_SELECTED_ROWS, payload: true });
         } else {
            document.dispatchEvent(new CustomEvent("toastEvent", { bubbles: true, composed: true, detail: {msg: this.t("msg.caseLockFail"), isErr: true} }));
         }
      });
   }
}

window.customElements.define(TechtabMergeDialog.is, TechtabMergeDialog);
