import React, { useEffect, useRef, useState } from "react";
import $ from "cash-dom";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { useEventListener } from "usehooks-ts";
import localization from "../../../public/resource/js/localization";
import FLeaderApp from "../../../public/fleader/fleader-app.mjs";
import { defaultShortcutSetting } from "../../../public/resource/js/shortcut";
import { QueryKey } from "../../redux/reducers/query";
import { RootState } from "../../redux/reducers";
import store from "../../redux/store";
import { useToastDispatch } from "../../context/ToastContext";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import { CommonActionType, DialogType } from "../../redux/reducers/common";
import Menu from "../../components/global/Menu";
import FLeader from "../../components/global/FLeader";
import ReportUtils from "../../utils/report-utils";
import WorklistConfigMenu from "../../components/dialog/WorklistConfigMenu";
import SettingDialog, { SettingDialogType } from "../../components/dialog/Setting/SettingDialog";
import CertificationDialog from "../../components/dialog/CertificationDialog";

export default function WorklistNavbar() {
   // let _preformList: Array<any> = [];
   // let _worklistShortcut: Array<any> = [];
   // let _worklistShortcutObj: object = {};
   // let _macroShortcut: Array<any> = [];
   // let _filmboxShortcut: Array<any> = [];
   // let _functionShortcut: Array<any> = [];
   // const [settingDialogOpen, setSettingDialogOpen] = useState<boolean>(false);
   // const [settingDialogType, setSettingDialogType] = useState<SettingDialogType | null>(null);

   // useEffect(() => {
   //    if (settingDialogType) setSettingDialogOpen(true);
   // }, [settingDialogType])

   const hotkeyList: Array<any> = [];
   // let print: WindowProxy | null;

   let g_selectImageReportList: Array<any> = [];

   // let _shortcut: object = {};
   const [preformList, setPreformList] = useState([]);
   const [userShortcut, setUserShortcut] = useState(); // component userShortcut
   const [menuItems, setMenuItems] = useState([]);
   const [certDialog, setCertDialog] = useState(false);

   const [shortcutId, setShortcutId] = useState();

   const fleader = useRef();

   const selectedWorklistRows = useSelector((state: RootState) => state.worklist.row?.rows);
   const shortcutState = useSelector((state: RootState) => state.query[QueryKey.GET_SHORTCUT_LIST]); // store userShortcut

   const dispatch = useDispatch();
   const toastDispatch = useToastDispatch();

   useEffect(() => {
      // console.log("-----> ready WorklistNavbar.tsx");

      initMenuItems();
      setWorklistShortcutObj();
      initFleader();

      // FLeader Event
      document.addEventListener("callFleaderFuncEvent", (e) => {
         callWorklistFunctionEvent(e.detail?.functionName);
      });

      // 사용하지 않음
      // document.addEventListener("windowFocusOut", (/* e */) => {
      //    if (window.document.activeElement.$ && window.document.activeElement.$.worklist) window.document.activeElement.$.worklist.blur();
      // });

      // FLeader Event
      document.addEventListener("callReadingTemplateEvent", (e) => {
         callReadingTemplateEvent(e);
      });

      // FLeader Event
      // 호출하는 이벤트가 없음 (2022.08.26 확인)
      // $("#healthhubConfigMenu")[0].addEventListener("deletedRawCaseIDEvent", (e) => {
      //    dispatchEvent(new CustomEvent("deletedRawCaseIDEvent", e));
      // });

      // window.addEventListener("message", (event) => {
      //    switch ((event.data || {}).event) {
      //    case "POPUP_PRINT": {
      //       prePrintOpen();
      //       break;
      //    }
      //    default:
      //    }
      // });

      // window.addEventListener("printEvent", () => {
      //    prePrintOpen();
      // });
   }, []);

   function callShortcutUpdateEvent(e) {
      updateFkeyObj(e.detail);
   }

   useDidMountEffect(() => {
      document.addEventListener("callShortcutUpdateEvent", callShortcutUpdateEvent);
      return () => {
         document.removeEventListener("callShortcutUpdateEvent", callShortcutUpdateEvent);
      };
   }, [userShortcut]);

   useDidMountEffect(() => {
      changeShortcutState(shortcutState);
   }, [shortcutState]);

   function openAiMarketPage() {
      const { email, facilityId } = JSON.parse(localStorage.getItem("user")!);
      window.open(`${__MARKET_URL__}?email=${email}&facilityId=${facilityId}`, "AI MarketPlace");
   }

   /* *
    * HH_MENU EVEN
    * Create by BohyunJang on 2019-04-21 오후 11:40
    * */
   function prePrintOpen() {
      try {
         ReportUtils.openPopupPrintReport(selectedWorklistRows, g_selectImageReportList);
      } catch ({ message }) {
         if (message) {
            toastDispatch({ type: "SET_TOAST", open: true, msg: message, isErr: true });
         }
      }
   }

   // function currentUsageFileSystem() {
   //    // this.$.healthhubConfigMenu.currentUsageFileSystem();
   // }

   // function setPopup(popup) {
   //    $("#healthhubConfigMenu")[0].setPopup(popup);
   // }

   function readingTemplateShortcutChecker(stringShortcut, prepend = "") {
      let found = false;
      getPreformByUserId().then((resolve) => {
         // setPreformList(resolve);
         const preformList = resolve;
         if (preformList) {
            for (let k = 0; k < preformList.length; k++) {
               if (preformList[k].shortcut) {
                  if (preformList[k].shortcut === stringShortcut) {
                     const { finding, conclusion, recommendation } = preformList[k];
                     const param = {
                        detail: {
                           finding,
                           conclusion,
                           recommendation,
                           prepend,
                        },
                     };
                     dispatchEvent(new CustomEvent("navbarHotkeyOpinionPaste", param));
                     found = true;
                  }
               }
            }
         }
         // @ts-ignore
         if (!found) window.rejected({ message: `No such named template -- ${stringShortcut}` });
      });
   }

   function updateFkeyObj(params: object) {
      const data = { ...userShortcut };
      let found = false;
      if (params.category === "worklistFunction") {
         for (let i = 0; i < data.worklistFunction.length; i++) {
            if (data.worklistFunction[i].functionName === params.functionName) {
               data.worklistFunction[i].fkey = params.fkey;
               updateWorklistShortcuts(data);
               found = true;
               break;
            }
         }
      } else if (params.category === "filmboxFunction") {
         for (let i = 0; i < data.filmboxFunction.length; i++) {
            if (data.filmboxFunction[i].functionName === params.functionName) {
               data.filmboxFunction[i].fkey = params.fkey;
               updateFilmboxShortcuts(data);
               found = true;
               break;
            }
         }
      } else if (params.category === "macroFunction") {
         for (let i = 0; i < data.macroFunction.length; i++) {
            if (data.macroFunction[i].functionName === params.functionName) {
               data.macroFunction[i].fkey = params.fkey;
               const macro = {};
               macro.functionList = data.macroFunction[i].functionList;
               macro.shortcut = data.macroFunction[i].shortcut;
               macro.title = data.macroFunction[i].functionName;
               macro.seqNo = data.macroFunction[i].seqNo;
               macro.fkey = params.fkey;
               macro.id = data.id;
               updateMacroShortcuts(macro);
               found = true;
               break;
            }
         }
      } else if (params.category === "userPreferences") {
         data.userPreferences = params.value; // override all!
         updateUserPreferences(data);
         found = true;
      }

      setUserShortcut(formatUserShortcut(data));

      if (!found) {
         // @ts-ignore
         window.failed({ message: "No such function in Database", isErr: true });
      }
   }

   function initFleader() {
      getUserShortcutByUserId().then((resolve) => {
         const data = resolve;
         const macroFunction = [];

         // if not fkey, set default fkey
         const isWorklistFkey = isFkey(resolve.worklistFunction);
         if (!isWorklistFkey) {
            data.worklistFunction = initFkey("worklistFunction", resolve.worklistFunction);
            updateWorklistShortcuts(data);
         }
         const isFilmboxFkey = isFkey(resolve.filmboxFunction);
         if (!isFilmboxFkey) {
            data.filmboxFunction = initFkey("filmboxFunction", resolve.filmboxFunction);
            updateFilmboxShortcuts(data);
         }

         if (resolve.macroFunction) {
            resolve.macroFunction.forEach((rMacro) => {
               const macro = {};
               macro.functionList = rMacro.functionList;
               macro.shortcut = rMacro.shortcut;
               if (String(rMacro.fkey).length !== 1 || [92, 34].includes(String(rMacro.fkey).charCodeAt(0))) { /* backslash|double-qoute가 아닌 한글자만 받아들인다 */
                  macro.fkey = "";
               } else {
                  macro.fkey = rMacro.fkey;
               }

               macro.functionName = rMacro.title;
               macro.displayedName = rMacro.title;
               macro.seqNo = rMacro.seqNo;
               macroFunction.push(macro);
            });
         }
         data.macroFunction = macroFunction;

         setUserShortcut(formatUserShortcut(data));

         const shortcutObj = {
            macroFunction : data.macroFunction,
            worklistFunction : data.worklistFunction,
            filmboxFunction : data.filmboxFunction,
            userPreferences: (JSON.parse(localStorage.getItem("userPreferences")!) || resolve.userPreferences),
         };
         // IMPORTANT: prevent to not make FLeader Instance more than one!
         if (fleader.current) {
            fleader.current.reloadRules(shortcutObj);
         } else {
            // TODO: 확인 필요
            fleader.current = FLeaderApp(window, $, shortcutObj);
         }
      });
   }

   function isFkey(shortcutArr) { // FIX: 로직오류!
      let isFkey = false;
      shortcutArr.forEach((shortcut) => {
         isFkey = Object.keys(shortcut).includes("fkey");
      });
      return isFkey;
   }

   function initFkey(category, shortcutObj) {
      const resultShortcut = shortcutObj;
      const defaultShortcut = defaultShortcutSetting();
      defaultShortcut[category].forEach((shortcut, index) => {
         resultShortcut[index].fkey = shortcut.fkey;
      });
      return resultShortcut;
   }

   function callReadingTemplateEvent(e) {
      const { shortcut, keyStack, functionName, prepend } = e.detail;
      if (shortcut && !keyStack) {
         readingTemplateShortcutChecker(shortcut, prepend);
      } else {
         let found = false;
         getPreformByUserId().then((resolve) => {
            // setPreformList(resolve);
            const preformList = resolve;
            if (preformList) {
               for (let i = 0; i < preformList.length; i++) {
                  if (functionName && preformList[i].title === functionName) {
                     const { finding, conclusion, recommendation } = preformList[i];
                     const param = {
                        detail: {
                           finding,
                           conclusion,
                           recommendation,
                           prepend,
                           isMacro: true,
                        },
                     };
                     dispatchEvent(new CustomEvent("navbarHotkeyOpinionPaste", param));
                     found = true;
                     break;
                  } else if (keyStack && preformList[i].shortcut && preformList[i].shortcut === keyStack) {
                     const { finding, conclusion, recommendation } = preformList[i];
                     const param = {
                        detail: {
                           finding,
                           conclusion,
                           recommendation,
                           prepend,
                           isMacro: true,
                        },
                     };
                     dispatchEvent(new CustomEvent("navbarHotkeyOpinionPaste", param));
                     found = true;
                     break;
                  }
               }// for
            }
         }).finally(() => {
            if (!found) {
               // @ts-ignore
               window.rejected({ message: `No such named template -- ${shortcut || functionName}` });
            }
            /* IMPORTANT: window.fulfilled()는 src/components/hh-report-window.js 파일내 setCopyOpinion() 함수 끝 부분에서 보낸다 */
         });
      }
   }

   function callWorklistFunctionEvent(functionName: string) {
      switch (functionName) {
      case "logout":
         $("#logout")[0].popupLogoutDialog();
         break;
      case "setting":
         // dispatchEvent(new CustomEvent("navbarShortcutSetting"));
         dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.SETTING_DIALOG, message: { type: SettingDialogType.DDP, open: true } } });
         break;
      case "userInfo":
         // $("#hhPassword")[0].popupPasswordDialog();
         store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.PASSWORD_DIALOG, open: true } });
         break;
      case "prev":
         dispatchEvent(new CustomEvent("navbarDisplayPrevEvent"));
         break;
      case "next":
         dispatchEvent(new CustomEvent("navbarDisplayNextEvent"));
         break;
      case "pageUp":
         dispatchEvent(new CustomEvent("navbarDisplayPageUpEvent"));
         break;
      case "pageDown":
         dispatchEvent(new CustomEvent("navbarDisplayPageDownEvent"));
         break;
      case "filmbox":
         filmbox();
         break;
      default:
         throw new Error(`${functionName} is not listed on callWorklistFunctionEvent()`);
      }
   }

   function filmbox() {
      window.postMessage({ checkFilmboxFocus: true }, document.location.href);
   }

   function setWorklistShortcutObj() {
      // getPreformByUserId().then((resolve) => {
      //    setPreformList(resolve);
      // });
      // getUserShortcutByUserId().then((resolve) => {
      //    _worklistShortcut = resolve.worklistFunction || [];
      //    _worklistShortcutObj = _worklistShortcut.reduce(
      //       (obj: object, item: object) =>
      //          ({ ...obj, [item.functionName]: item.shortcut }),
      //       {},
      //    );
      //    _macroShortcut = resolve.macroFunction;
      //    _filmboxShortcut = resolve.filmboxFunction;
      //    _functionShortcut = _worklistShortcut.concat(_filmboxShortcut);
      // }, (err) => {
      //    // 단축키 객체가 없다면
      //    console.log(err.message);
      // });
   }

   function getPreformByUserId() {
      return new Promise((resolve) => {
         fetch("/api/user/option/preforms", {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
            },
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else if (response.status !== 204) {
               toastDispatch({ type: "SET_TOAST", open: true, msg: "Preform response fail.", isErr: true });
            } else {
               resolve();
            }
         });
      });
   }

   // 단축키에 해당하는 객체를 미리 가져온뒤
   // 해당 단축키가 눌렸을때 가져온 리스트를 검색하여 출력
   // 단축키 리스트를 불러옴
   // function getListSelectHotkey() {
   //    fetch("/api/preforms/hotkeys", {
   //       method: "GET",
   //       headers: {
   //          "Authorization": localStorage.getItem("jwt")!,
   //       },
   //    }).then((response) => {
   //       if (response.ok && response.status === 200) {
   //          response.json().then((rows) => {
   //             hotkeyList = [];
   //             rows.forEach((i) => {
   //                const preform = {};
   //                preform.finding = rows[i].finding;
   //                preform.conclusion = rows[i].conclusion;
   //                preform.recommendation = rows[i].recommendation;
   //                preform.hotkey = rows[i].hotKey2;
   //                hotkeyList.push(preform);
   //             });
   //          });
   //       } else if (response.status !== 204) {
   //          console.log("preform response failed");
   //          console.log(response.status);
   //       }
   //    });
   // }

   function getUserShortcutByUserId() {
      return new Promise((resolve) => {
         fetch("/api/user/option/shortcuts", {
            method: "GET",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
            },
         }).then((response) => {
            if (response.ok && response.status === 200) {
               response.json().then((rows) => {
                  resolve(rows);
               });
            } else {
               const defaultShortcut = defaultShortcutSetting();
               resolve(defaultShortcut);
            }
         });
      });
   }

   /* *
    * 디비에 저장되어 있는 단축키정보를 가져옴
    * Create by BohyunJang on 2018-07-16 오후 7:48
    * */
   function updateWorklistShortcuts(shortcut: object) {
      return new Promise((resolve, reject) => {
         const param = { request : shortcut.worklistFunction };
         fetch(`/api/user/option/shortcut/worklist/${shortcut.id}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
               "Content-Type": "application/json",
            },
            body: JSON.stringify(param.request),
         }).then((response) => {
            if (response.ok && response.status === 200) {
               resolve(response.ok);
               toastDispatch({ type: "SET_TOAST", open: true, msg: i18next.t("msg.settingDialog.shortcut.save.success"), isErr: false });
            }
            else {
               toastDispatch({ type: "SET_TOAST", open: true, msg: i18next.t("msg.settingDialog.shortcut.save.fail"), isErr: true });
            }
         }).catch(err => reject(err));
      });
   }

   function updateFilmboxShortcuts(shortcut: object) {
      return new Promise((resolve, reject) => {
         const param = { request : shortcut.filmboxFunction };
         fetch(`/api/user/option/shortcut/filmbox/${shortcut.id}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
               "Content-Type": "application/json",
            },
            body: JSON.stringify(param.request),
         }).then((response) => {
            if (response.ok && response.status === 200) {
               resolve(response.ok);
               toastDispatch({ type: "SET_TOAST", open: true, msg: "Hotkey has been successfully updated!", isErr: false });
            }
            else {
               toastDispatch({ type: "SET_TOAST", open: true, msg: "Update Hotkey is Failed", isErr: true });
            }
         }).catch(err => reject(err));
      });
   }

   function updateMacroShortcuts(macro: object) {
      return new Promise((resolve) => {
         fetch(`/api/user/option/shortcut/macro/${macro.id}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
               "Content-Type": "application/json",
            },
            body: JSON.stringify(macro),
         }).then((response) => {
            if (response.ok && response.status === 200) {
               resolve(response.result);
               toastDispatch({ type: "SET_TOAST", open: true, msg: "Success Update Macro Shortcut", isErr: false });
            }
            else {
               toastDispatch({ type: "SET_TOAST", open: true, msg: "Failed Update Macro Shortcut", isErr: true });
            }
         });
      });
   }

   function updateUserPreferences(shortcut: object) {
      // localStorage.setItem("userPreferences", JSON.stringify(shortcut.userPreferences)); // FIXME: DB연결이 완료되면 삭제!
      // this.openToast({isErr: false, msg:"Preferences have been successfully updated"});
      return new Promise((resolve, reject) => {
         fetch(`/api/user/option/shortcut/preferences/${shortcut.id}`, {
            method: "PATCH",
            headers: {
               "Authorization": localStorage.getItem("jwt")!,
               "Content-Type": "application/json",
            },
            body: JSON.stringify(shortcut.userPreferences),
         }).then((response) => {
            if (response.ok && response.status === 200) {
               resolve(response.ok);
               toastDispatch({ type: "SET_TOAST", open: true, msg: "Configurations have been successfully updated!", isErr: false });
            }
            else {
               localStorage.setItem("userPreferences", JSON.stringify(shortcut.userPreferences)); // FIXME: DB연결이 완료되면 삭제!
               toastDispatch({ type: "SET_TOAST", open: true, msg: "Preferences have been successfully updated on localStorage", isErr: false });

               // this._message.msg = "Update Preference is Failed";
               // this._message.isErr = true;
               // this.openToast(this._message);
            }
         }).catch(err => reject(err));
      });
   }

   function initMenuItems() {
      const fileReader = new XMLHttpRequest();
      fileReader.open("GET", "../resource/worklist-menu.json", true);
      fileReader.onload = () => {
         if (fileReader.status === 200) {
            const properties = JSON.parse(fileReader.responseText);
            const userInfo = JSON.parse(localStorage.getItem("user")!);
            let resultMenuProperties = properties;

            if (!userInfo.activeAi) {
               resultMenuProperties = properties.filter((property) => {
                  if (property.id !== "aimarket") {
                     return property;
                  }

                  return null;
               });
            }

            // i18n 적용
            // $("#worklistMenu")[0].menuItems = localization.convertMenuJson(resultMenuProperties);
            setMenuItems(localization.convertMenuJson(resultMenuProperties));
         }
      };
      fileReader.send(null);
   }

   useEventListener("printEvent", () => {
      prePrintOpen();
   });

   useEventListener("setImageReportEvent", (e) => {
      setImageReport(e);
   });

   function setImageReport(e: any) {
      if (selectedWorklistRows) {
         const obj: any = {};
         obj.id = selectedWorklistRows[0].id;
         obj.imageReport = e.detail;
         getSelectedRowsImageReport(obj);
         // $.navbar.getSelectedRowsImageReport(obj);
      }
      else {
         // openToast(i18next.t("msg.notSelectedRow"), true);
      }
   }

   function getSelectedRowsImageReport(imageReportList: Array<any>) {
      if (selectedWorklistRows?.length > 1) {
         if (imageReportList) {
            g_selectImageReportList.push(imageReportList);
         } else {
            g_selectImageReportList.push("");
         }
      } else {
         g_selectImageReportList = [];
         if (imageReportList) {
            g_selectImageReportList.push(imageReportList);
         }
      }
   }

   // TODO: 확인 필요
   // set sseItems(sseItem) {
   //    $("#healthhubConfigMenu")[0].push("sseItems", sseItem);
   // }

   // function sseItemsInit() {
   //    $("#healthhubConfigMenu")[0].sseItemsInit();
   // }

   function popupManual() {
      const url = "../resource/html/manual.html";
      const name = "Manual";
      const option = "width = 500, height = 700";
      window.open(url, name, option);
   }

   // function _shortcutChanged(data) {
   function formatUserShortcut(shortcut = {}) {
      const formatShortcut = { ...shortcut };
      const { worklistFunction, filmboxFunction, userPreferences } = formatShortcut;
      if (worklistFunction) {
         formatShortcut.worklistFunction = worklistFunction.map((item) => {
            const displayedName = i18next.t(`shortcut.worklist.${item.functionName}`);
            return {
               ...item,
               displayedName,
            };
         });
      }

      if (filmboxFunction) {
         formatShortcut.filmboxFunction = filmboxFunction.map((item) => {
            const displayedName = i18next.t(`shortcut.filmbox.${item.functionName}`);
            return {
               ...item,
               displayedName,
            };
         });
      }

      if (userPreferences) {
         formatShortcut.userPreferences = userPreferences.map((item) => {
            const displayedName = i18next.t(`shortcut.userPreferences.${item.functionName}`);
            return {
               ...item,
               displayedName,
            };
         });
      }

      return formatShortcut;
   }

   function changeShortcutState(shortcutState) {
      if (!shortcutState) return;
      // setWorklistShortcutObj();
      initFleader();
   }

   function handleMenuClick(event) {
      const { key } = event.currentTarget.dataset ?? {};
      switch (key) {
      // Home
      case "reload":
         dispatchEvent(new CustomEvent("navbarMenuItemClickReloadEvent"));
         break;
         // Report
      case "macro":
         store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.SETTING_DIALOG, message: { type: SettingDialogType.MACRO, open: true } } });
         // dispatchEvent(new CustomEvent("navbarDialogOpenEvent", { detail: { id: key } }));
         break;
      case "report":
         store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.SETTING_DIALOG, message: { type: SettingDialogType.REPORT, open: true } } });
         // dispatchEvent(new CustomEvent("navbarDialogOpenEvent", { detail: { id: key } }));
         break;
      case "ddp":
         store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.SETTING_DIALOG, message: { type: SettingDialogType.DDP, open: true } } });
         // dispatchEvent(new CustomEvent("navbarDialogOpenEvent", { detail: { id: key } }));
         break;
      case "shortcut":
         store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.SETTING_DIALOG, message: { type: SettingDialogType.SHORTCUT, open: true } } });
         // dispatchEvent(new CustomEvent("navbarDialogOpenEvent", { detail: { id: key } }));
         break;
      case "filmboxConfig":
         store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.SETTING_DIALOG, message: { type: SettingDialogType.FILMBOX, open: true } } });
         // dispatchEvent(new CustomEvent("navbarDialogOpenEvent", { detail: { id: key } }));
         break;
      case "printReport":
         prePrintOpen();
         break;
      case "userInfo":
         // $("#hhPassword")[0].popupPasswordDialog();
         store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.PASSWORD_DIALOG, open: true } });
         break;
      case "extension":
         window.open("http://hresizer.hpacsweb.com", "hresizer");
         break;
         // Help
      case "support":
         // $("#supportDialog")[0].open();
         store.dispatch({ type: CommonActionType.OPEN_DIALOG, payload: { type: DialogType.SUPPORT_DIALOG, open: true } });
         break;
      case "history":
         window.open("https://docs.google.com/document/d/1xPQ79h-FyhoRTg8rXYY6twJ_BObytka9y3NMkeMwaFA/edit#");
         break;
      case "qna":
         window.open("https://github.com/HealthHub-Project/HPACS/issues");
         break;
      case "manual":
         // popupGuid
         popupManual();
         break;
      case "filmbox":
         filmbox();
         break;
      case "aimarket":
         openAiMarketPage();
         break;
      case "certifications": {
         setCertDialog(true);
         break;
      }
      default:
         break;
      }
   }

   return (
      <>
         <style>{`
         .containerNavbar {
            display: flex;
            flex-wrap: wrap-reverse;
            justify-content: space-between;
         }
         .containerSnackbar {
            text-align: left;
            overflow: hidden;
            // height: 100%;
            width: 100%;
            margin: 0 auto;
            display: table;
         }

         .class-function-shortcut-input {
            float: left;
            width: 35px;
            height: inherit;
            line-height: inherit;
            margin-right: 5px;
            border: 1px solid #3d5994;
            background-color: #0a0f1f;
            box-sizing: border-box;
            color: #a3e9a5;
            text-align: center;
            font-weight: bold;
            letter-spacing: -1px;
            font-size: 12px;
         }

         .warning-duplicated-shortcut {
            border-color: #f26;
         }
         `}</style>
         <div className="containerNavbar">
            <Menu menuItems={menuItems} handleMenuClick={handleMenuClick}></Menu>
            {/* <hh-menu id="worklistMenu"></hh-menu> */}
            {/* <hh-password id="hhPassword"></hh-password> */}
            {/* <common-dialog id="supportDialog"></common-dialog> */}
            {/* <worklist-config-menu id="healthhubConfigMenu"></worklist-config-menu> */}
            <WorklistConfigMenu />
            {/* <certifications-dialog id="certificationsDialog"></certifications-dialog> */}
            <CertificationDialog open={certDialog} setOpenDialog={setCertDialog}/>
         </div>
         <div className="containerSnackbar">
            <FLeader />
         </div>
         {/* <SettingDialog open={settingDialogOpen} type={settingDialogType} /> */}
      </>
   );
}
